import { createIcon } from "@chakra-ui/icons";

export const MenuIcon = createIcon({
  displayName: "MenuIcon",
  viewBox: "0 0 48 48",
  path: (
    <g fill="none">
      <g clipPath="url(#clip0_1400_460)">
        <path d="M6 36H42V32H6V36ZM6 26H42V22H6V26ZM6 12V16H42V12H6Z" fill="#1A1A1A" />
      </g>
      <defs>
        <clipPath id="clip0_1400_460">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </g>
  )
});

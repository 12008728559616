import { createIcon } from "@chakra-ui/icons";

export const PartnerIcon = createIcon({
  displayName: "PartnerIcon",
  viewBox: "0 0 36 36",
  path: (
    <g fill="currentColor">
      <path
        d="M24.1875 9.28125C24.1875 9.12656 24.0609 9 23.9062 9H10.4062C10.2516 9 10.125 9.12656 10.125 9.28125V10.9688C10.125 11.1234 10.2516 11.25 10.4062 11.25H23.9062C24.0609 11.25 24.1875 11.1234 24.1875 10.9688V9.28125ZM23.9062 14.0625H10.4062C10.2516 14.0625 10.125 14.1891 10.125 14.3438V16.0312C10.125 16.1859 10.2516 16.3125 10.4062 16.3125H23.9062C24.0609 16.3125 24.1875 16.1859 24.1875 16.0312V14.3438C24.1875 14.1891 24.0609 14.0625 23.9062 14.0625ZM16.875 19.125H10.4062C10.2516 19.125 10.125 19.2516 10.125 19.4062V21.0938C10.125 21.2484 10.2516 21.375 10.4062 21.375H16.875C17.0297 21.375 17.1563 21.2484 17.1563 21.0938V19.4062C17.1563 19.2516 17.0297 19.125 16.875 19.125ZM15.1875 29.9531H7.3125V5.20312H27V17.2969C27 17.4516 27.1266 17.5781 27.2812 17.5781H29.25C29.4047 17.5781 29.5313 17.4516 29.5313 17.2969V3.79688C29.5313 3.17461 29.0285 2.67188 28.4062 2.67188H5.90625C5.28398 2.67188 4.78125 3.17461 4.78125 3.79688V31.3594C4.78125 31.9816 5.28398 32.4844 5.90625 32.4844H15.1875C15.3422 32.4844 15.4688 32.3578 15.4688 32.2031V30.2344C15.4688 30.0797 15.3422 29.9531 15.1875 29.9531ZM27.7313 27.3375C28.7508 26.4129 29.3906 25.077 29.3906 23.5898C29.3906 20.7949 27.123 18.5273 24.3281 18.5273C21.5332 18.5273 19.2656 20.7949 19.2656 23.5898C19.2656 25.077 19.9055 26.4129 20.925 27.3375C18.9211 28.4801 17.543 30.5965 17.4375 33.0363C17.4305 33.1945 17.5605 33.3281 17.7188 33.3281H19.4098C19.5574 33.3281 19.6805 33.2121 19.691 33.0609C19.8281 30.6281 21.8566 28.6875 24.3281 28.6875C26.7996 28.6875 28.8281 30.6281 28.9652 33.0609C28.9723 33.2086 29.0953 33.3281 29.2465 33.3281H30.9375C31.0992 33.3281 31.2258 33.1945 31.2188 33.0363C31.1168 30.593 29.7352 28.4801 27.7313 27.3375ZM24.3281 20.7773C25.882 20.7773 27.1406 22.0359 27.1406 23.5898C27.1406 25.1437 25.882 26.4023 24.3281 26.4023C22.7742 26.4023 21.5156 25.1437 21.5156 23.5898C21.5156 22.0359 22.7742 20.7773 24.3281 20.7773Z"
        fill="#18A3A7"
      />
    </g>
  )
});

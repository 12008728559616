import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  useToast
} from "@chakra-ui/react";
import {
  Input,
  SettingIcon,
  Text,
  Avatar,
  Button,
  Select,
  OptionType,
  ErrorMessage,
  Loading
} from "@/components";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  UpdateMyProfileInput,
  useUpdateProfileMutation,
  useGetProfileQuery,
  useGetMyTeamsQuery,
  useGetEnterpriseQuery
} from "@/apollo/generated";
import theme from "@/shared/theme";
import { nonEmpty } from "@/utils/nonEmpty";

type Form = UpdateMyProfileInput;

type InputType = {
  key: "familyName" | "givenName" | "tel";
  title: string;
  placeholder: string;
}[];

export const UserProfileForm: React.FC = () => {
  const { t } = useTranslation(["profile"]);
  const toast = useToast();
  const [avatar, setAvatar] = useState<File | null>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [teamId, setTeamId] = useState<string>();
  const inputFileRef = React.useRef<HTMLInputElement | null>(null);
  const { data, loading } = useGetProfileQuery();
  const { data: enterpriseData } = useGetEnterpriseQuery();
  const { data: myTeamsData } = useGetMyTeamsQuery();

  const teamNodes = nonEmpty(myTeamsData?.myEnterprise.teams);
  const [updateProfileMutation, { loading: updateProfileLoading, error }] =
    useUpdateProfileMutation();

  const teamOptions: OptionType[] = useMemo(
    () =>
      teamNodes.map((t) => {
        return {
          value: t.id,
          label: t.name
        };
      }),
    [teamNodes]
  );

  const inputs: InputType = [
    { key: "familyName", title: t("form.familyName"), placeholder: "" },
    { key: "givenName", title: t("form.givenName"), placeholder: "" },
    { key: "tel", title: t("form.tel"), placeholder: "" }
  ];

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<Form>({
    resolver: zodResolver(
      z.object({
        familyName: z
          .string()
          .min(1, { message: t("form.validation.required", { ns: "common" }) ?? "" }),
        givenName: z
          .string()
          .min(1, { message: t("form.validation.required", { ns: "common" }) ?? "" }),
        tel: z.string().min(1, { message: t("form.validation.required", { ns: "common" }) ?? "" }),
        goal: z.string().max(5000, { message: t("form.limitationTips") ?? "" })
      })
    ),
    defaultValues: {}
  });

  const uploadImageToClient: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    if (e.target.files) setAvatar(e.target.files[0]);
  }, []);

  const updateProfile = useCallback(
    (values: Form) => {
      const input = teamId ? { ...values, avatar, teamId } : { ...values, avatar };

      updateProfileMutation({
        variables: { input: input }
      }).then(() => {
        toast({
          position: "top",
          description: t("form.successMsg"),
          status: "success",
          duration: 3000,
          isClosable: true
        });
      });
    },
    [updateProfileMutation, avatar, toast, t, teamId]
  );

  useEffect(() => {
    setValue("familyName", data?.me.familyName ?? "");
    setValue("givenName", data?.me.givenName ?? "");
    setValue("tel", data?.me.tel ?? "");
    setValue("goal", data?.me.goal ?? "");
    setTeamId(data?.me.team?.id ?? "");
    setAvatarUrl(data?.me.avatarUrl ?? "");
  }, [data, setValue, setAvatarUrl, setTeamId]);

  if (loading) return <Loading />;

  return (
    <>
      <input
        type="file"
        name="imagefile"
        ref={inputFileRef}
        accept=".png, .jpg, .jpeg"
        hidden
        onChange={uploadImageToClient}
        onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
          const element = event.target as HTMLInputElement;
          element.value = "";
        }}
      />
      <Box p="24px">
        <Flex alignItems="center">
          <SettingIcon w="30px" h="31px" mr="8px" />
          <Text textType="title32">{t("pageName")}</Text>
        </Flex>
        <ErrorMessage message={t("form.errorMsg")} isVisible={!!error} mt="32px" />
        <Box p="16px" mt="24px">
          <Text textType="title24" mb="24px">
            {t("profileSettings")}
          </Text>
          <form onSubmit={handleSubmit(updateProfile)}>
            <Text textType="body14b" color={theme.text.black500}>
              {t("profileImage")}
            </Text>
            <Flex pt="16px">
              <Box w="116px" textAlign="center" pr="16px">
                <Avatar size="xl" src={avatar ? URL.createObjectURL(avatar) : avatarUrl ?? ""} />
                <Text
                  textType="body12r"
                  mt="8px"
                  cursor="pointer"
                  _hover={{ textDecoration: "underline" }}
                  onClick={() => {
                    setAvatar(null);
                    setAvatarUrl("");
                  }}
                >
                  {t("delete", { ns: "common" })}
                </Text>
              </Box>
              <Box w="450px">
                <InputGroup onClick={() => inputFileRef.current?.click()} h="38px">
                  <Input
                    h="38px"
                    isReadOnly
                    value={avatar ? avatar.name ?? "" : t("pleaseSelectAFile") ?? ""}
                    fontSize="14px"
                    pr="120px"
                    color={theme.text.black700}
                  />
                  <InputRightElement w="120px">
                    <Button
                      w="120px"
                      h="38px"
                      buttonType="fill"
                      mt="-2px"
                      borderRadius="0 4px 4px 0"
                    >
                      <Text textType="body12b">{t("selectFile")}</Text>
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>
            </Flex>
            <Box w="630px">
              {inputs.map((input) => (
                <FormControl key={input.key} isInvalid={!!errors[input.key]} mt="16px">
                  <Text textType="body14b" color={theme.text.black500}>
                    {input.title}
                  </Text>
                  <Input
                    id={input.key}
                    placeholder={input.placeholder}
                    styleType="flushed"
                    {...register(input.key)}
                    type={input.key === "tel" ? "number" : undefined}
                  />
                  <FormErrorMessage>{errors[input.key]?.message}</FormErrorMessage>
                </FormControl>
              ))}
            </Box>
            <Box pt="16px">
              <Flex color={theme.text.black700}>
                <Text textType="body16r" mr="16px">
                  {t("form.teams")}
                </Text>
                <Text textType="body16r">{enterpriseData?.myEnterprise.nameHan}</Text>
              </Flex>
              <Flex pt="6px" pl="94px">
                <Select
                  defaultValue={teamId}
                  options={teamOptions}
                  w="306px"
                  h="44px"
                  placeholder="未選択"
                  onChange={setTeamId}
                />
              </Flex>
            </Box>
            <Box w="630px">
              <Divider m="16px 0" w="100%" h="1px" color={theme.text.black200} />
              <Flex align="center">
                <Box>
                  <Text textType="body16r" color={theme.text.gray500} w="32px" mr="62px">
                    {t("form.goal")}
                  </Text>
                </Box>
                <FormControl isInvalid={!!errors["goal"]}>
                  <Input w="463px" h="39px" {...register("goal")} />
                  <FormErrorMessage>{errors["goal"]?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
            </Box>
            <Button
              buttonType="fill"
              w="196px"
              mt="36px"
              type="submit"
              isLoading={updateProfileLoading}
            >
              <Text textType="body16b">{t("form.saveForm")}</Text>
            </Button>
          </form>
        </Box>
      </Box>
    </>
  );
};

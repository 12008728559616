import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISO8601Date: Date;
  ISO8601DateTime: Date;
  JSON: Object;
  Upload: any;
};

/** Autogenerated input type of AddJournaling */
export type AddJournalingInput = {
  anger: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  confidence: Scalars['Int'];
  expectation: Scalars['Int'];
  fear: Scalars['Int'];
  goodThing: GoodThingInput;
  pleasure: Scalars['Int'];
  repugnance: Scalars['Int'];
  sadness: Scalars['Int'];
  situation?: InputMaybe<Scalars['String']>;
  surprise: Scalars['Int'];
  switchingAction: SwitchingActionInput;
};

/** Autogenerated return type of AddJournaling. */
export type AddJournalingPayload = {
  __typename?: 'AddJournalingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  journaling: Journaling;
};

export type Admin = {
  __typename?: 'Admin';
  id: Scalars['ID'];
  name: Scalars['String'];
  uid: Scalars['String'];
};

export type AdminHome = {
  __typename?: 'AdminHome';
  enterprise: Enterprise;
  enterprises: EnterpriseConnection;
  me: Admin;
};


export type AdminHomeEnterpriseArgs = {
  id: Scalars['ID'];
};


export type AdminHomeEnterprisesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated input type of AdminLogin */
export type AdminLoginInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  uid: Scalars['String'];
};

/** Autogenerated return type of AdminLogin. */
export type AdminLoginPayload = {
  __typename?: 'AdminLoginPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  problemMsg?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of AdminLogout */
export type AdminLogoutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of AdminLogout. */
export type AdminLogoutPayload = {
  __typename?: 'AdminLogoutPayload';
  admin: Admin;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of AdminUpdatePassword */
export type AdminUpdatePasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

/** Autogenerated return type of AdminUpdatePassword. */
export type AdminUpdatePasswordPayload = {
  __typename?: 'AdminUpdatePasswordPayload';
  admin?: Maybe<Admin>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** WRONG_PASSWORD will be returned if the current_password isn't right */
  problemMsg?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of AdminVerifyOtp */
export type AdminVerifyOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  otp: Scalars['String'];
  uid: Scalars['String'];
};

/** Autogenerated return type of AdminVerifyOtp. */
export type AdminVerifyOtpPayload = {
  __typename?: 'AdminVerifyOtpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  credential?: Maybe<Scalars['String']>;
  problemMsg?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of BatchUpdateTeams */
export type BatchUpdateTeamsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  enterpriseId: Scalars['ID'];
  /** After clicking invitation email's link, the client will be redirect to this URL */
  redirectUrl: Scalars['String'];
  teams: Array<TeamInput>;
};

/** Autogenerated return type of BatchUpdateTeams. */
export type BatchUpdateTeamsPayload = {
  __typename?: 'BatchUpdateTeamsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  teams?: Maybe<Array<Team>>;
};

export type CoachingSchedule = {
  __typename?: 'CoachingSchedule';
  coachingGroups?: Maybe<Array<UserCoachingGroup>>;
  date?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
};

/** The connection type for CoachingSchedule. */
export type CoachingScheduleConnection = {
  __typename?: 'CoachingScheduleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CoachingScheduleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CoachingSchedule>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CoachingScheduleEdge = {
  __typename?: 'CoachingScheduleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CoachingSchedule>;
};

/** Autogenerated input type of CreateEnterprise */
export type CreateEnterpriseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nameHan: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  questions: Array<QuestionInput>;
  status: EnterpriseStatus;
};

/** Autogenerated return type of CreateEnterprise. */
export type CreateEnterprisePayload = {
  __typename?: 'CreateEnterprisePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  enterprise: Enterprise;
};

export type Enterprise = {
  __typename?: 'Enterprise';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  currentActiveAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  lastActiveAt?: Maybe<Scalars['ISO8601DateTime']>;
  name?: Maybe<Scalars['String']>;
  nameHan?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Question>>;
  status?: Maybe<EnterpriseStatus>;
  teams?: Maybe<Array<Team>>;
};

/** The connection type for Enterprise. */
export type EnterpriseConnection = {
  __typename?: 'EnterpriseConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EnterpriseEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Enterprise>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type EnterpriseEdge = {
  __typename?: 'EnterpriseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Enterprise>;
};

export enum EnterpriseStatus {
  /** 利用中 */
  Activated = 'ACTIVATED',
  /** 停止中 */
  Stopped = 'STOPPED'
}

export type GoodThing = {
  __typename?: 'GoodThing';
  id: Scalars['ID'];
  reactionStamps?: Maybe<Array<ReactionStamp>>;
  text?: Maybe<Array<Scalars['String']>>;
};

export type GoodThingInput = {
  text: Array<Scalars['String']>;
};

/** Autogenerated input type of InviteUser */
export type InviteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** メールアドレス */
  emails: Array<Scalars['String']>;
  /** After clicking invitation email's link, the client will be redirect to this URL */
  redirectUrl: Scalars['String'];
};

/** Autogenerated return type of InviteUser. */
export type InviteUserPayload = {
  __typename?: 'InviteUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** These email are already in a user's unconfirmed_email */
  duplicateEmails?: Maybe<Array<Scalars['String']>>;
  /** Only first invited users other than re-invited users will be returned */
  users?: Maybe<Array<User>>;
};

export type Journaling = {
  __typename?: 'Journaling';
  /** 怒り */
  anger?: Maybe<Scalars['Int']>;
  /** 信頼 */
  confidence?: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601DateTime'];
  /** 期待 */
  expectation?: Maybe<Scalars['Int']>;
  /** 恐れ */
  fear?: Maybe<Scalars['Int']>;
  goodThing?: Maybe<GoodThing>;
  id: Scalars['ID'];
  /** 喜び */
  pleasure?: Maybe<Scalars['Int']>;
  /** 嫌悪 */
  repugnance?: Maybe<Scalars['Int']>;
  /** 悲しみ */
  sadness?: Maybe<Scalars['Int']>;
  /** 状況 */
  situation?: Maybe<Scalars['String']>;
  /** 驚き */
  surprise?: Maybe<Scalars['Int']>;
  switchingAction?: Maybe<SwitchingAction>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

/** The connection type for Journaling. */
export type JournalingConnection = {
  __typename?: 'JournalingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<JournalingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Journaling>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type JournalingEdge = {
  __typename?: 'JournalingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Journaling>;
};

export type JournalingFilterInput = {
  dateFrom?: InputMaybe<Scalars['ISO8601Date']>;
  dateTo?: InputMaybe<Scalars['ISO8601Date']>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addJournaling?: Maybe<AddJournalingPayload>;
  adminLogin?: Maybe<AdminLoginPayload>;
  adminLogout?: Maybe<AdminLogoutPayload>;
  adminUpdatePassword?: Maybe<AdminUpdatePasswordPayload>;
  adminVerifyOtp?: Maybe<AdminVerifyOtpPayload>;
  batchUpdateTeams?: Maybe<BatchUpdateTeamsPayload>;
  createEnterprise?: Maybe<CreateEnterprisePayload>;
  inviteUser?: Maybe<InviteUserPayload>;
  reactingToGoodThing?: Maybe<ReactingToGoodThingPayload>;
  reactingToSwitchingAction?: Maybe<ReactingToSwitchingActionPayload>;
  removeEnterprise?: Maybe<RemoveEnterprisePayload>;
  removeUser?: Maybe<RemoveUserPayload>;
  repairingCoaching?: Maybe<RepairingCoachingPayload>;
  resetMyEmail?: Maybe<ResetMyEmailPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  sendPasswordResetInstruction?: Maybe<SendPasswordResetInstructionPayload>;
  setCoachingGroup?: Maybe<SetCoachingGroupPayload>;
  updateEnterprise?: Maybe<UpdateEnterprisePayload>;
  updateJournaling?: Maybe<UpdateJournalingPayload>;
  updateMyPassword?: Maybe<UpdateMyPasswordPayload>;
  updateMyProfile?: Maybe<UpdateMyProfilePayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  userLogin?: Maybe<UserLoginPayload>;
  userLogout?: Maybe<UserLogoutPayload>;
  userSignUp?: Maybe<UserSignUpPayload>;
  verifyOtp?: Maybe<VerifyOtpPayload>;
};


export type MutationAddJournalingArgs = {
  input: AddJournalingInput;
};


export type MutationAdminLoginArgs = {
  input: AdminLoginInput;
};


export type MutationAdminLogoutArgs = {
  input: AdminLogoutInput;
};


export type MutationAdminUpdatePasswordArgs = {
  input: AdminUpdatePasswordInput;
};


export type MutationAdminVerifyOtpArgs = {
  input: AdminVerifyOtpInput;
};


export type MutationBatchUpdateTeamsArgs = {
  input: BatchUpdateTeamsInput;
};


export type MutationCreateEnterpriseArgs = {
  input: CreateEnterpriseInput;
};


export type MutationInviteUserArgs = {
  input: InviteUserInput;
};


export type MutationReactingToGoodThingArgs = {
  input: ReactingToGoodThingInput;
};


export type MutationReactingToSwitchingActionArgs = {
  input: ReactingToSwitchingActionInput;
};


export type MutationRemoveEnterpriseArgs = {
  input: RemoveEnterpriseInput;
};


export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


export type MutationRepairingCoachingArgs = {
  input: RepairingCoachingInput;
};


export type MutationResetMyEmailArgs = {
  input: ResetMyEmailInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSendPasswordResetInstructionArgs = {
  input: SendPasswordResetInstructionInput;
};


export type MutationSetCoachingGroupArgs = {
  input: SetCoachingGroupInput;
};


export type MutationUpdateEnterpriseArgs = {
  input: UpdateEnterpriseInput;
};


export type MutationUpdateJournalingArgs = {
  input: UpdateJournalingInput;
};


export type MutationUpdateMyPasswordArgs = {
  input: UpdateMyPasswordInput;
};


export type MutationUpdateMyProfileArgs = {
  input: UpdateMyProfileInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUserLoginArgs = {
  input: UserLoginInput;
};


export type MutationUserLogoutArgs = {
  input: UserLogoutInput;
};


export type MutationUserSignUpArgs = {
  input: UserSignUpInput;
};


export type MutationVerifyOtpArgs = {
  input: VerifyOtpInput;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  adminHome?: Maybe<AdminHome>;
  me: User;
  myEnterprise: Enterprise;
  myTeam: Team;
};

export type Question = {
  __typename?: 'Question';
  description: Scalars['String'];
  id: Scalars['ID'];
  kind: QuestionKind;
  title: Scalars['String'];
};

export type QuestionInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<QuestionKind>;
  title?: InputMaybe<Scalars['String']>;
};

export enum QuestionKind {
  GoodThing = 'GOOD_THING',
  SwitchingAction = 'SWITCHING_ACTION'
}

/** Autogenerated input type of ReactingToGoodThing */
export type ReactingToGoodThingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emojiIndex: Scalars['Int'];
  id: Scalars['ID'];
};

/** Autogenerated return type of ReactingToGoodThing. */
export type ReactingToGoodThingPayload = {
  __typename?: 'ReactingToGoodThingPayload';
  action: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  reactionStamp: ReactionStamp;
};

/** Autogenerated input type of ReactingToSwitchingAction */
export type ReactingToSwitchingActionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emojiIndex: Scalars['Int'];
  id: Scalars['ID'];
};

/** Autogenerated return type of ReactingToSwitchingAction. */
export type ReactingToSwitchingActionPayload = {
  __typename?: 'ReactingToSwitchingActionPayload';
  action: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  reactionStamp: ReactionStamp;
};

export type ReactionStamp = {
  __typename?: 'ReactionStamp';
  emojiIndex?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  user?: Maybe<User>;
};

/** Autogenerated input type of RemoveEnterprise */
export type RemoveEnterpriseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of RemoveEnterprise. */
export type RemoveEnterprisePayload = {
  __typename?: 'RemoveEnterprisePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  enterprise?: Maybe<Enterprise>;
};

/** Autogenerated input type of RemoveUser */
export type RemoveUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of RemoveUser. */
export type RemoveUserPayload = {
  __typename?: 'RemoveUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of RepairingCoaching */
export type RepairingCoachingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of RepairingCoaching. */
export type RepairingCoachingPayload = {
  __typename?: 'RepairingCoachingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  coachingSchedule: CoachingSchedule;
};

/** Autogenerated input type of ResetMyEmail */
export type ResetMyEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  /** Client will be redirected to this URL after clicking confirmation email */
  redirectUrl: Scalars['String'];
  /** A confirmation success notification email will be sent after confirming the email-changing, in which this URL is */
  successNotificationRedirectUrl: Scalars['String'];
};

/** Autogenerated return type of ResetMyEmail. */
export type ResetMyEmailPayload = {
  __typename?: 'ResetMyEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  problemMsg?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of ResetPassword */
export type ResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

/** Autogenerated return type of ResetPassword. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  problemMsg?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of SendPasswordResetInstruction */
export type SendPasswordResetInstructionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  /** The front URL for reset password */
  redirectUrl: Scalars['String'];
};

/** Autogenerated return type of SendPasswordResetInstruction. */
export type SendPasswordResetInstructionPayload = {
  __typename?: 'SendPasswordResetInstructionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of SetCoachingGroup */
export type SetCoachingGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  startTime: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of SetCoachingGroup. */
export type SetCoachingGroupPayload = {
  __typename?: 'SetCoachingGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  coachingGroup: UserCoachingGroup;
};

export type SwitchingAction = {
  __typename?: 'SwitchingAction';
  id: Scalars['ID'];
  reactionStamps?: Maybe<Array<ReactionStamp>>;
  text?: Maybe<Array<Scalars['String']>>;
};

export type SwitchingActionInput = {
  text: Array<Scalars['String']>;
};

export type Team = {
  __typename?: 'Team';
  coachingSchedules?: Maybe<CoachingScheduleConnection>;
  fakeCoachingSchedule?: Maybe<CoachingSchedule>;
  id: Scalars['ID'];
  /** ジャーナリングノート【一覧】 */
  journalings?: Maybe<JournalingConnection>;
  name: Scalars['String'];
  /** メンバーの３Good Things & Switching Actions  */
  recentJournalings?: Maybe<JournalingConnection>;
  users?: Maybe<UserConnection>;
};


export type TeamCoachingSchedulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type TeamJournalingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<JournalingFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type TeamRecentJournalingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type TeamUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TeamInput = {
  id?: InputMaybe<Scalars['ID']>;
  /** メールアドレス */
  invitedEmails?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateEnterprise */
export type UpdateEnterpriseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  nameHan?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  questions: Array<QuestionInput>;
  status?: InputMaybe<EnterpriseStatus>;
};

/** Autogenerated return type of UpdateEnterprise. */
export type UpdateEnterprisePayload = {
  __typename?: 'UpdateEnterprisePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  enterprise: Enterprise;
};

/** Autogenerated input type of UpdateJournaling */
export type UpdateJournalingInput = {
  anger: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  confidence: Scalars['Int'];
  expectation: Scalars['Int'];
  fear: Scalars['Int'];
  goodThing: GoodThingInput;
  id: Scalars['ID'];
  pleasure: Scalars['Int'];
  repugnance: Scalars['Int'];
  sadness: Scalars['Int'];
  situation?: InputMaybe<Scalars['String']>;
  surprise: Scalars['Int'];
  switchingAction: SwitchingActionInput;
};

/** Autogenerated return type of UpdateJournaling. */
export type UpdateJournalingPayload = {
  __typename?: 'UpdateJournalingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  journaling: Journaling;
};

/** Autogenerated input type of UpdateMyPassword */
export type UpdateMyPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

/** Autogenerated return type of UpdateMyPassword. */
export type UpdateMyPasswordPayload = {
  __typename?: 'UpdateMyPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** WRONG_PASSWORD will be returned if the current_password isn't right */
  problemMsg?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdateMyProfile */
export type UpdateMyProfileInput = {
  avatar?: InputMaybe<Scalars['Upload']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  goal?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
  tel?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateMyProfile. */
export type UpdateMyProfilePayload = {
  __typename?: 'UpdateMyProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  status?: InputMaybe<UserStatus>;
  teamId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']>;
  currentSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  goal?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  journaling?: Maybe<Journaling>;
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** リフレクション（線グラフ） */
  lineGraph?: Maybe<Array<Journaling>>;
  /** メンバーの気持ち（最近４週間） */
  recentFeeling?: Maybe<Scalars['JSON']>;
  status?: Maybe<UserStatus>;
  team?: Maybe<Team>;
  tel?: Maybe<Scalars['String']>;
};

export type UserCoachingGroup = {
  __typename?: 'UserCoachingGroup';
  id?: Maybe<Scalars['ID']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  users?: Maybe<Array<User>>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

/** Autogenerated input type of UserLogin */
export type UserLoginInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

/** Autogenerated return type of UserLogin. */
export type UserLoginPayload = {
  __typename?: 'UserLoginPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  problemMsg?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UserLogout */
export type UserLogoutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UserLogout. */
export type UserLogoutPayload = {
  __typename?: 'UserLogoutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

/** Autogenerated input type of UserSignUp */
export type UserSignUpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  password: Scalars['String'];
  tel: Scalars['String'];
};

/** Autogenerated return type of UserSignUp. */
export type UserSignUpPayload = {
  __typename?: 'UserSignUpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  problemMsg?: Maybe<Scalars['String']>;
  user: User;
};

export enum UserStatus {
  /** アクティブ */
  Activated = 'ACTIVATED',
  /** 登録待ち */
  Pending = 'PENDING',
  /** お休み中 */
  Resting = 'RESTING'
}

/** Autogenerated input type of VerifyOtp */
export type VerifyOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  otp: Scalars['String'];
};

/** Autogenerated return type of VerifyOtp. */
export type VerifyOtpPayload = {
  __typename?: 'VerifyOtpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  credential?: Maybe<Scalars['String']>;
  problemMsg?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type EnterpriseEdgeNodeFragment = { __typename?: 'EnterpriseEdge', node?: { __typename?: 'Enterprise', createdAt?: Date | null, id: string, nameHan?: string | null, status?: EnterpriseStatus | null, teams?: Array<{ __typename?: 'Team', id: string, name: string }> | null } | null };

export type JournalingFragment = { __typename?: 'Journaling', anger?: number | null, confidence?: number | null, updatedAt: Date, expectation?: number | null, fear?: number | null, id: string, pleasure?: number | null, repugnance?: number | null, sadness?: number | null, situation?: string | null, surprise?: number | null, goodThing?: { __typename?: 'GoodThing', id: string, text?: Array<string> | null } | null, switchingAction?: { __typename?: 'SwitchingAction', id: string, text?: Array<string> | null } | null };

export type LienGraphFragment = { __typename?: 'Journaling', id: string, updatedAt: Date, pleasure?: number | null, confidence?: number | null, fear?: number | null, surprise?: number | null, sadness?: number | null, repugnance?: number | null, anger?: number | null, expectation?: number | null };

export type MemberFragment = { __typename?: 'User', id: string, avatarUrl?: string | null, familyName?: string | null, givenName?: string | null, email?: string | null, status?: UserStatus | null, team?: { __typename?: 'Team', id: string } | null };

export type ReactionStampFragment = { __typename?: 'ReactionStamp', id: string, emojiIndex?: number | null, user?: { __typename?: 'User', id: string, familyName?: string | null, givenName?: string | null } | null };

export type AdminLoginMutationVariables = Exact<{
  input: AdminLoginInput;
}>;


export type AdminLoginMutation = { __typename?: 'Mutation', adminLogin?: { __typename?: 'AdminLoginPayload', uid?: string | null, problemMsg?: string | null } | null };

export type AdminVerifyOtpMutationVariables = Exact<{
  input: AdminVerifyOtpInput;
}>;


export type AdminVerifyOtpMutation = { __typename?: 'Mutation', adminVerifyOtp?: { __typename?: 'AdminVerifyOtpPayload', credential?: string | null, problemMsg?: string | null } | null };

export type RemoveEnterpriseMutationVariables = Exact<{
  input: RemoveEnterpriseInput;
}>;


export type RemoveEnterpriseMutation = { __typename?: 'Mutation', removeEnterprise?: { __typename?: 'RemoveEnterprisePayload', enterprise?: { __typename?: 'Enterprise', id: string } | null } | null };

export type CreateEnterpriseMutationVariables = Exact<{
  input: CreateEnterpriseInput;
}>;


export type CreateEnterpriseMutation = { __typename?: 'Mutation', createEnterprise?: { __typename?: 'CreateEnterprisePayload', enterprise: { __typename?: 'Enterprise', id: string } } | null };

export type UpdateEnterpriseMutationVariables = Exact<{
  input: UpdateEnterpriseInput;
}>;


export type UpdateEnterpriseMutation = { __typename?: 'Mutation', updateEnterprise?: { __typename?: 'UpdateEnterprisePayload', enterprise: { __typename?: 'Enterprise', id: string } } | null };

export type BatchUpdateTeamsMutationVariables = Exact<{
  input: BatchUpdateTeamsInput;
}>;


export type BatchUpdateTeamsMutation = { __typename?: 'Mutation', batchUpdateTeams?: { __typename?: 'BatchUpdateTeamsPayload', teams?: Array<{ __typename?: 'Team', id: string }> | null } | null };

export type AdminLogoutMutationVariables = Exact<{
  input: AdminLogoutInput;
}>;


export type AdminLogoutMutation = { __typename?: 'Mutation', adminLogout?: { __typename?: 'AdminLogoutPayload', admin: { __typename?: 'Admin', id: string } } | null };

export type AdminUpdatePasswordMutationVariables = Exact<{
  input: AdminUpdatePasswordInput;
}>;


export type AdminUpdatePasswordMutation = { __typename?: 'Mutation', adminUpdatePassword?: { __typename?: 'AdminUpdatePasswordPayload', problemMsg?: string | null, admin?: { __typename?: 'Admin', id: string, name: string, uid: string } | null } | null };

export type RepairingCoachingMutationVariables = Exact<{
  input: RepairingCoachingInput;
}>;


export type RepairingCoachingMutation = { __typename?: 'Mutation', repairingCoaching?: { __typename?: 'RepairingCoachingPayload', coachingSchedule: { __typename?: 'CoachingSchedule', date?: Date | null, coachingGroups?: Array<{ __typename?: 'UserCoachingGroup', id?: string | null, startTime?: Date | null, users?: Array<{ __typename?: 'User', id: string, familyName?: string | null, givenName?: string | null, avatarUrl?: string | null }> | null }> | null } } | null };

export type SetCoachingGroupMutationVariables = Exact<{
  input: SetCoachingGroupInput;
}>;


export type SetCoachingGroupMutation = { __typename?: 'Mutation', setCoachingGroup?: { __typename?: 'SetCoachingGroupPayload', coachingGroup: { __typename?: 'UserCoachingGroup', id?: string | null, startTime?: Date | null } } | null };

export type RemoveMemberMutationVariables = Exact<{
  input: RemoveUserInput;
}>;


export type RemoveMemberMutation = { __typename?: 'Mutation', removeUser?: { __typename?: 'RemoveUserPayload', user?: { __typename?: 'User', id: string } | null } | null };

export type UpdateMemberMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateMemberMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'UpdateUserPayload', user?: { __typename?: 'User', id: string } | null } | null };

export type AddJournalingMutationVariables = Exact<{
  input: AddJournalingInput;
}>;


export type AddJournalingMutation = { __typename?: 'Mutation', addJournaling?: { __typename?: 'AddJournalingPayload', journaling: { __typename?: 'Journaling', id: string } } | null };

export type UpdateJournalingMutationVariables = Exact<{
  input: UpdateJournalingInput;
}>;


export type UpdateJournalingMutation = { __typename?: 'Mutation', updateJournaling?: { __typename?: 'UpdateJournalingPayload', journaling: { __typename?: 'Journaling', id: string } } | null };

export type ReactingToGoodThingMutationVariables = Exact<{
  input: ReactingToGoodThingInput;
}>;


export type ReactingToGoodThingMutation = { __typename?: 'Mutation', reactingToGoodThing?: { __typename?: 'ReactingToGoodThingPayload', action: string, reactionStamp: { __typename?: 'ReactionStamp', id: string, emojiIndex?: number | null, user?: { __typename?: 'User', id: string, familyName?: string | null, givenName?: string | null } | null } } | null };

export type ReactingToSwitchingActionMutationVariables = Exact<{
  input: ReactingToSwitchingActionInput;
}>;


export type ReactingToSwitchingActionMutation = { __typename?: 'Mutation', reactingToSwitchingAction?: { __typename?: 'ReactingToSwitchingActionPayload', action: string, reactionStamp: { __typename?: 'ReactionStamp', id: string, emojiIndex?: number | null, user?: { __typename?: 'User', id: string, familyName?: string | null, givenName?: string | null } | null } } | null };

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateMyProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateMyProfile?: { __typename?: 'UpdateMyProfilePayload', user: { __typename?: 'User', id: string, familyName?: string | null, givenName?: string | null, tel?: string | null, avatarUrl?: string | null } } | null };

export type UserSignUpMutationVariables = Exact<{
  input: UserSignUpInput;
}>;


export type UserSignUpMutation = { __typename?: 'Mutation', userSignUp?: { __typename?: 'UserSignUpPayload', user: { __typename?: 'User', id: string, email?: string | null } } | null };

export type UserLoginMutationVariables = Exact<{
  input: UserLoginInput;
}>;


export type UserLoginMutation = { __typename?: 'Mutation', userLogin?: { __typename?: 'UserLoginPayload', problemMsg?: string | null, email?: string | null } | null };

export type InviteUsersMutationVariables = Exact<{
  input: InviteUserInput;
}>;


export type InviteUsersMutation = { __typename?: 'Mutation', inviteUser?: { __typename?: 'InviteUserPayload', duplicateEmails?: Array<string> | null, users?: Array<{ __typename?: 'User', id: string }> | null } | null };

export type UserLogoutMutationVariables = Exact<{
  input: UserLogoutInput;
}>;


export type UserLogoutMutation = { __typename?: 'Mutation', userLogout?: { __typename?: 'UserLogoutPayload', user: { __typename?: 'User', id: string } } | null };

export type VerifyOtpMutationVariables = Exact<{
  input: VerifyOtpInput;
}>;


export type VerifyOtpMutation = { __typename?: 'Mutation', verifyOtp?: { __typename?: 'VerifyOtpPayload', credential?: string | null, problemMsg?: string | null, user?: { __typename?: 'User', id: string } | null } | null };

export type SendPasswordResetInstructionMutationVariables = Exact<{
  input: SendPasswordResetInstructionInput;
}>;


export type SendPasswordResetInstructionMutation = { __typename?: 'Mutation', sendPasswordResetInstruction?: { __typename?: 'SendPasswordResetInstructionPayload', user?: { __typename?: 'User', id: string, email?: string | null } | null } | null };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'ResetPasswordPayload', problemMsg?: string | null, user?: { __typename?: 'User', id: string, email?: string | null } | null } | null };

export type ResetMyEmailMutationVariables = Exact<{
  input: ResetMyEmailInput;
}>;


export type ResetMyEmailMutation = { __typename?: 'Mutation', resetMyEmail?: { __typename?: 'ResetMyEmailPayload', problemMsg?: string | null, user?: { __typename?: 'User', id: string, email?: string | null } | null } | null };

export type UpdateMyPasswordMutationVariables = Exact<{
  input: UpdateMyPasswordInput;
}>;


export type UpdateMyPasswordMutation = { __typename?: 'Mutation', updateMyPassword?: { __typename?: 'UpdateMyPasswordPayload', problemMsg?: string | null, user?: { __typename?: 'User', id: string } | null } | null };

export type GetAdminHomeQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;


export type GetAdminHomeQuery = { __typename?: 'Query', adminHome?: { __typename?: 'AdminHome', enterprises: { __typename?: 'EnterpriseConnection', totalCount: number, edges?: Array<{ __typename?: 'EnterpriseEdge', cursor: string, node?: { __typename?: 'Enterprise', createdAt?: Date | null, id: string, nameHan?: string | null, status?: EnterpriseStatus | null, teams?: Array<{ __typename?: 'Team', id: string, name: string }> | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } } | null };

export type GetAdminSidebarQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;


export type GetAdminSidebarQuery = { __typename?: 'Query', adminHome?: { __typename?: 'AdminHome', enterprises: { __typename?: 'EnterpriseConnection', totalCount: number, edges?: Array<{ __typename?: 'EnterpriseEdge', cursor: string, node?: { __typename?: 'Enterprise', id: string, nameHan?: string | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } } | null };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', adminHome?: { __typename?: 'AdminHome', me: { __typename?: 'Admin', id: string, name: string, uid: string } } | null };

export type GetEnterpriseInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetEnterpriseInfoQuery = { __typename?: 'Query', adminHome?: { __typename?: 'AdminHome', enterprise: { __typename?: 'Enterprise', id: string, name?: string | null, nameHan?: string | null, note?: string | null, status?: EnterpriseStatus | null, questions?: Array<{ __typename?: 'Question', description: string, id: string, kind: QuestionKind, title: string }> | null } } | null };

export type GetTeamInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTeamInfoQuery = { __typename?: 'Query', adminHome?: { __typename?: 'AdminHome', enterprise: { __typename?: 'Enterprise', id: string, nameHan?: string | null, teams?: Array<{ __typename?: 'Team', id: string, name: string }> | null } } | null };

export type GetFakeDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFakeDataQuery = { __typename?: 'Query', myTeam: { __typename?: 'Team', id: string, fakeCoachingSchedule?: { __typename?: 'CoachingSchedule', id: string, date?: Date | null, coachingGroups?: Array<{ __typename?: 'UserCoachingGroup', id?: string | null, startTime?: Date | null, users?: Array<{ __typename?: 'User', id: string, familyName?: string | null, givenName?: string | null, avatarUrl?: string | null }> | null }> | null } | null } };

export type GetMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMembersQuery = { __typename?: 'Query', myTeam: { __typename?: 'Team', id: string, users?: { __typename?: 'UserConnection', nodes?: Array<{ __typename?: 'User', id: string, avatarUrl?: string | null, familyName?: string | null, givenName?: string | null, email?: string | null, status?: UserStatus | null, team?: { __typename?: 'Team', id: string } | null } | null> | null } | null } };

export type GetTeamQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamQuery = { __typename?: 'Query', myEnterprise: { __typename?: 'Enterprise', id: string, nameHan?: string | null }, myTeam: { __typename?: 'Team', id: string, name: string } };

export type GetMyTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyTeamsQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string }, myEnterprise: { __typename?: 'Enterprise', id: string, teams?: Array<{ __typename?: 'Team', id: string, name: string }> | null } };

export type GetJournalingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetJournalingQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, journaling?: { __typename?: 'Journaling', anger?: number | null, confidence?: number | null, updatedAt: Date, expectation?: number | null, fear?: number | null, id: string, pleasure?: number | null, repugnance?: number | null, sadness?: number | null, situation?: string | null, surprise?: number | null, goodThing?: { __typename?: 'GoodThing', id: string, text?: Array<string> | null } | null, switchingAction?: { __typename?: 'SwitchingAction', id: string, text?: Array<string> | null } | null } | null } };

export type GetCoachingSchedulesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCoachingSchedulesQuery = { __typename?: 'Query', myTeam: { __typename?: 'Team', id: string, coachingSchedules?: { __typename?: 'CoachingScheduleConnection', nodes?: Array<{ __typename?: 'CoachingSchedule', id: string, date?: Date | null, coachingGroups?: Array<{ __typename?: 'UserCoachingGroup', id?: string | null, startTime?: Date | null, users?: Array<{ __typename?: 'User', id: string, familyName?: string | null, givenName?: string | null, avatarUrl?: string | null }> | null }> | null } | null> | null } | null } };

export type GetRecentFeelingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRecentFeelingQuery = { __typename?: 'Query', myTeam: { __typename?: 'Team', id: string, users?: { __typename?: 'UserConnection', nodes?: Array<{ __typename?: 'User', id: string, avatarUrl?: string | null, familyName?: string | null, givenName?: string | null, recentFeeling?: Object | null, status?: UserStatus | null } | null> | null } | null } };

export type GetRecentJournalingsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;


export type GetRecentJournalingsQuery = { __typename?: 'Query', myTeam: { __typename?: 'Team', id: string, recentJournalings?: { __typename?: 'JournalingConnection', totalCount: number, nodes?: Array<{ __typename?: 'Journaling', id: string, situation?: string | null, updatedAt: Date, pleasure?: number | null, confidence?: number | null, fear?: number | null, surprise?: number | null, sadness?: number | null, repugnance?: number | null, anger?: number | null, expectation?: number | null, user?: { __typename?: 'User', id: string, familyName?: string | null, givenName?: string | null, avatarUrl?: string | null, goal?: string | null } | null, goodThing?: { __typename?: 'GoodThing', id: string, text?: Array<string> | null, reactionStamps?: Array<{ __typename?: 'ReactionStamp', id: string, emojiIndex?: number | null, user?: { __typename?: 'User', id: string, familyName?: string | null, givenName?: string | null } | null }> | null } | null, switchingAction?: { __typename?: 'SwitchingAction', id: string, text?: Array<string> | null, reactionStamps?: Array<{ __typename?: 'ReactionStamp', id: string, emojiIndex?: number | null, user?: { __typename?: 'User', id: string, familyName?: string | null, givenName?: string | null } | null }> | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null } };

export type GetJournalingsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<JournalingFilterInput>;
}>;


export type GetJournalingsQuery = { __typename?: 'Query', myTeam: { __typename?: 'Team', id: string, journalings?: { __typename?: 'JournalingConnection', nodes?: Array<{ __typename?: 'Journaling', id: string, situation?: string | null, updatedAt: Date, pleasure?: number | null, confidence?: number | null, fear?: number | null, surprise?: number | null, sadness?: number | null, repugnance?: number | null, anger?: number | null, expectation?: number | null, user?: { __typename?: 'User', id: string, familyName?: string | null, givenName?: string | null, avatarUrl?: string | null, goal?: string | null } | null, goodThing?: { __typename?: 'GoodThing', id: string, text?: Array<string> | null, reactionStamps?: Array<{ __typename?: 'ReactionStamp', id: string, emojiIndex?: number | null, user?: { __typename?: 'User', id: string, familyName?: string | null, givenName?: string | null } | null }> | null } | null, switchingAction?: { __typename?: 'SwitchingAction', id: string, text?: Array<string> | null, reactionStamps?: Array<{ __typename?: 'ReactionStamp', id: string, emojiIndex?: number | null, user?: { __typename?: 'User', id: string, familyName?: string | null, givenName?: string | null } | null }> | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null } };

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, familyName?: string | null, givenName?: string | null, tel?: string | null, avatarUrl?: string | null, email?: string | null, goal?: string | null, team?: { __typename?: 'Team', id: string } | null } };

export type GetEnterpriseQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEnterpriseQuery = { __typename?: 'Query', myEnterprise: { __typename?: 'Enterprise', id: string, nameHan?: string | null } };

export type GetLineGraphQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLineGraphQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, lineGraph?: Array<{ __typename?: 'Journaling', id: string, updatedAt: Date, pleasure?: number | null, confidence?: number | null, fear?: number | null, surprise?: number | null, sadness?: number | null, repugnance?: number | null, anger?: number | null, expectation?: number | null }> | null } };

export type GetQuestionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuestionsQuery = { __typename?: 'Query', myEnterprise: { __typename?: 'Enterprise', id: string, questions?: Array<{ __typename?: 'Question', id: string, description: string, title: string, kind: QuestionKind }> | null } };

export const EnterpriseEdgeNodeFragmentDoc = gql`
    fragment EnterpriseEdgeNode on EnterpriseEdge {
  node {
    createdAt
    id
    nameHan
    status
    teams {
      id
      name
    }
  }
}
    `;
export const JournalingFragmentDoc = gql`
    fragment Journaling on Journaling {
  anger
  confidence
  updatedAt
  updatedAt
  expectation
  fear
  goodThing {
    id
    text
  }
  id
  pleasure
  repugnance
  sadness
  situation
  surprise
  switchingAction {
    id
    text
  }
}
    `;
export const LienGraphFragmentDoc = gql`
    fragment LienGraph on Journaling {
  id
  updatedAt
  pleasure
  confidence
  fear
  surprise
  sadness
  repugnance
  anger
  expectation
}
    `;
export const MemberFragmentDoc = gql`
    fragment Member on User {
  id
  avatarUrl
  familyName
  givenName
  email
  status
  team {
    id
  }
}
    `;
export const ReactionStampFragmentDoc = gql`
    fragment ReactionStamp on ReactionStamp {
  id
  emojiIndex
  user {
    id
    familyName
    givenName
  }
}
    `;
export const AdminLoginDocument = gql`
    mutation AdminLogin($input: AdminLoginInput!) {
  adminLogin(input: $input) {
    uid
    problemMsg
  }
}
    `;
export type AdminLoginMutationFn = Apollo.MutationFunction<AdminLoginMutation, AdminLoginMutationVariables>;

/**
 * __useAdminLoginMutation__
 *
 * To run a mutation, you first call `useAdminLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdminLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminLoginMutation(baseOptions?: Apollo.MutationHookOptions<AdminLoginMutation, AdminLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminLoginMutation, AdminLoginMutationVariables>(AdminLoginDocument, options);
      }
export type AdminLoginMutationHookResult = ReturnType<typeof useAdminLoginMutation>;
export type AdminLoginMutationResult = Apollo.MutationResult<AdminLoginMutation>;
export type AdminLoginMutationOptions = Apollo.BaseMutationOptions<AdminLoginMutation, AdminLoginMutationVariables>;
export const AdminVerifyOtpDocument = gql`
    mutation AdminVerifyOtp($input: AdminVerifyOtpInput!) {
  adminVerifyOtp(input: $input) {
    credential
    problemMsg
  }
}
    `;
export type AdminVerifyOtpMutationFn = Apollo.MutationFunction<AdminVerifyOtpMutation, AdminVerifyOtpMutationVariables>;

/**
 * __useAdminVerifyOtpMutation__
 *
 * To run a mutation, you first call `useAdminVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminVerifyOtpMutation, { data, loading, error }] = useAdminVerifyOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminVerifyOtpMutation(baseOptions?: Apollo.MutationHookOptions<AdminVerifyOtpMutation, AdminVerifyOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminVerifyOtpMutation, AdminVerifyOtpMutationVariables>(AdminVerifyOtpDocument, options);
      }
export type AdminVerifyOtpMutationHookResult = ReturnType<typeof useAdminVerifyOtpMutation>;
export type AdminVerifyOtpMutationResult = Apollo.MutationResult<AdminVerifyOtpMutation>;
export type AdminVerifyOtpMutationOptions = Apollo.BaseMutationOptions<AdminVerifyOtpMutation, AdminVerifyOtpMutationVariables>;
export const RemoveEnterpriseDocument = gql`
    mutation RemoveEnterprise($input: RemoveEnterpriseInput!) {
  removeEnterprise(input: $input) {
    enterprise {
      id
    }
  }
}
    `;
export type RemoveEnterpriseMutationFn = Apollo.MutationFunction<RemoveEnterpriseMutation, RemoveEnterpriseMutationVariables>;

/**
 * __useRemoveEnterpriseMutation__
 *
 * To run a mutation, you first call `useRemoveEnterpriseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEnterpriseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEnterpriseMutation, { data, loading, error }] = useRemoveEnterpriseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveEnterpriseMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEnterpriseMutation, RemoveEnterpriseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEnterpriseMutation, RemoveEnterpriseMutationVariables>(RemoveEnterpriseDocument, options);
      }
export type RemoveEnterpriseMutationHookResult = ReturnType<typeof useRemoveEnterpriseMutation>;
export type RemoveEnterpriseMutationResult = Apollo.MutationResult<RemoveEnterpriseMutation>;
export type RemoveEnterpriseMutationOptions = Apollo.BaseMutationOptions<RemoveEnterpriseMutation, RemoveEnterpriseMutationVariables>;
export const CreateEnterpriseDocument = gql`
    mutation CreateEnterprise($input: CreateEnterpriseInput!) {
  createEnterprise(input: $input) {
    enterprise {
      id
    }
  }
}
    `;
export type CreateEnterpriseMutationFn = Apollo.MutationFunction<CreateEnterpriseMutation, CreateEnterpriseMutationVariables>;

/**
 * __useCreateEnterpriseMutation__
 *
 * To run a mutation, you first call `useCreateEnterpriseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnterpriseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnterpriseMutation, { data, loading, error }] = useCreateEnterpriseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEnterpriseMutation(baseOptions?: Apollo.MutationHookOptions<CreateEnterpriseMutation, CreateEnterpriseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEnterpriseMutation, CreateEnterpriseMutationVariables>(CreateEnterpriseDocument, options);
      }
export type CreateEnterpriseMutationHookResult = ReturnType<typeof useCreateEnterpriseMutation>;
export type CreateEnterpriseMutationResult = Apollo.MutationResult<CreateEnterpriseMutation>;
export type CreateEnterpriseMutationOptions = Apollo.BaseMutationOptions<CreateEnterpriseMutation, CreateEnterpriseMutationVariables>;
export const UpdateEnterpriseDocument = gql`
    mutation UpdateEnterprise($input: UpdateEnterpriseInput!) {
  updateEnterprise(input: $input) {
    enterprise {
      id
    }
  }
}
    `;
export type UpdateEnterpriseMutationFn = Apollo.MutationFunction<UpdateEnterpriseMutation, UpdateEnterpriseMutationVariables>;

/**
 * __useUpdateEnterpriseMutation__
 *
 * To run a mutation, you first call `useUpdateEnterpriseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnterpriseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnterpriseMutation, { data, loading, error }] = useUpdateEnterpriseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnterpriseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnterpriseMutation, UpdateEnterpriseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnterpriseMutation, UpdateEnterpriseMutationVariables>(UpdateEnterpriseDocument, options);
      }
export type UpdateEnterpriseMutationHookResult = ReturnType<typeof useUpdateEnterpriseMutation>;
export type UpdateEnterpriseMutationResult = Apollo.MutationResult<UpdateEnterpriseMutation>;
export type UpdateEnterpriseMutationOptions = Apollo.BaseMutationOptions<UpdateEnterpriseMutation, UpdateEnterpriseMutationVariables>;
export const BatchUpdateTeamsDocument = gql`
    mutation BatchUpdateTeams($input: BatchUpdateTeamsInput!) {
  batchUpdateTeams(input: $input) {
    teams {
      id
    }
  }
}
    `;
export type BatchUpdateTeamsMutationFn = Apollo.MutationFunction<BatchUpdateTeamsMutation, BatchUpdateTeamsMutationVariables>;

/**
 * __useBatchUpdateTeamsMutation__
 *
 * To run a mutation, you first call `useBatchUpdateTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchUpdateTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchUpdateTeamsMutation, { data, loading, error }] = useBatchUpdateTeamsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchUpdateTeamsMutation(baseOptions?: Apollo.MutationHookOptions<BatchUpdateTeamsMutation, BatchUpdateTeamsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BatchUpdateTeamsMutation, BatchUpdateTeamsMutationVariables>(BatchUpdateTeamsDocument, options);
      }
export type BatchUpdateTeamsMutationHookResult = ReturnType<typeof useBatchUpdateTeamsMutation>;
export type BatchUpdateTeamsMutationResult = Apollo.MutationResult<BatchUpdateTeamsMutation>;
export type BatchUpdateTeamsMutationOptions = Apollo.BaseMutationOptions<BatchUpdateTeamsMutation, BatchUpdateTeamsMutationVariables>;
export const AdminLogoutDocument = gql`
    mutation AdminLogout($input: AdminLogoutInput!) {
  adminLogout(input: $input) {
    admin {
      id
    }
  }
}
    `;
export type AdminLogoutMutationFn = Apollo.MutationFunction<AdminLogoutMutation, AdminLogoutMutationVariables>;

/**
 * __useAdminLogoutMutation__
 *
 * To run a mutation, you first call `useAdminLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLogoutMutation, { data, loading, error }] = useAdminLogoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminLogoutMutation(baseOptions?: Apollo.MutationHookOptions<AdminLogoutMutation, AdminLogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminLogoutMutation, AdminLogoutMutationVariables>(AdminLogoutDocument, options);
      }
export type AdminLogoutMutationHookResult = ReturnType<typeof useAdminLogoutMutation>;
export type AdminLogoutMutationResult = Apollo.MutationResult<AdminLogoutMutation>;
export type AdminLogoutMutationOptions = Apollo.BaseMutationOptions<AdminLogoutMutation, AdminLogoutMutationVariables>;
export const AdminUpdatePasswordDocument = gql`
    mutation AdminUpdatePassword($input: AdminUpdatePasswordInput!) {
  adminUpdatePassword(input: $input) {
    admin {
      id
      name
      uid
    }
    problemMsg
  }
}
    `;
export type AdminUpdatePasswordMutationFn = Apollo.MutationFunction<AdminUpdatePasswordMutation, AdminUpdatePasswordMutationVariables>;

/**
 * __useAdminUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useAdminUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdatePasswordMutation, { data, loading, error }] = useAdminUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdatePasswordMutation, AdminUpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdatePasswordMutation, AdminUpdatePasswordMutationVariables>(AdminUpdatePasswordDocument, options);
      }
export type AdminUpdatePasswordMutationHookResult = ReturnType<typeof useAdminUpdatePasswordMutation>;
export type AdminUpdatePasswordMutationResult = Apollo.MutationResult<AdminUpdatePasswordMutation>;
export type AdminUpdatePasswordMutationOptions = Apollo.BaseMutationOptions<AdminUpdatePasswordMutation, AdminUpdatePasswordMutationVariables>;
export const RepairingCoachingDocument = gql`
    mutation RepairingCoaching($input: RepairingCoachingInput!) {
  repairingCoaching(input: $input) {
    coachingSchedule {
      date
      coachingGroups {
        id
        startTime
        users {
          id
          familyName
          givenName
          avatarUrl
        }
      }
    }
  }
}
    `;
export type RepairingCoachingMutationFn = Apollo.MutationFunction<RepairingCoachingMutation, RepairingCoachingMutationVariables>;

/**
 * __useRepairingCoachingMutation__
 *
 * To run a mutation, you first call `useRepairingCoachingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRepairingCoachingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [repairingCoachingMutation, { data, loading, error }] = useRepairingCoachingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRepairingCoachingMutation(baseOptions?: Apollo.MutationHookOptions<RepairingCoachingMutation, RepairingCoachingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RepairingCoachingMutation, RepairingCoachingMutationVariables>(RepairingCoachingDocument, options);
      }
export type RepairingCoachingMutationHookResult = ReturnType<typeof useRepairingCoachingMutation>;
export type RepairingCoachingMutationResult = Apollo.MutationResult<RepairingCoachingMutation>;
export type RepairingCoachingMutationOptions = Apollo.BaseMutationOptions<RepairingCoachingMutation, RepairingCoachingMutationVariables>;
export const SetCoachingGroupDocument = gql`
    mutation SetCoachingGroup($input: SetCoachingGroupInput!) {
  setCoachingGroup(input: $input) {
    coachingGroup {
      id
      startTime
    }
  }
}
    `;
export type SetCoachingGroupMutationFn = Apollo.MutationFunction<SetCoachingGroupMutation, SetCoachingGroupMutationVariables>;

/**
 * __useSetCoachingGroupMutation__
 *
 * To run a mutation, you first call `useSetCoachingGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCoachingGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCoachingGroupMutation, { data, loading, error }] = useSetCoachingGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCoachingGroupMutation(baseOptions?: Apollo.MutationHookOptions<SetCoachingGroupMutation, SetCoachingGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCoachingGroupMutation, SetCoachingGroupMutationVariables>(SetCoachingGroupDocument, options);
      }
export type SetCoachingGroupMutationHookResult = ReturnType<typeof useSetCoachingGroupMutation>;
export type SetCoachingGroupMutationResult = Apollo.MutationResult<SetCoachingGroupMutation>;
export type SetCoachingGroupMutationOptions = Apollo.BaseMutationOptions<SetCoachingGroupMutation, SetCoachingGroupMutationVariables>;
export const RemoveMemberDocument = gql`
    mutation RemoveMember($input: RemoveUserInput!) {
  removeUser(input: $input) {
    user {
      id
    }
  }
}
    `;
export type RemoveMemberMutationFn = Apollo.MutationFunction<RemoveMemberMutation, RemoveMemberMutationVariables>;

/**
 * __useRemoveMemberMutation__
 *
 * To run a mutation, you first call `useRemoveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberMutation, { data, loading, error }] = useRemoveMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMemberMutation, RemoveMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMemberMutation, RemoveMemberMutationVariables>(RemoveMemberDocument, options);
      }
export type RemoveMemberMutationHookResult = ReturnType<typeof useRemoveMemberMutation>;
export type RemoveMemberMutationResult = Apollo.MutationResult<RemoveMemberMutation>;
export type RemoveMemberMutationOptions = Apollo.BaseMutationOptions<RemoveMemberMutation, RemoveMemberMutationVariables>;
export const UpdateMemberDocument = gql`
    mutation UpdateMember($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      id
    }
  }
}
    `;
export type UpdateMemberMutationFn = Apollo.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>;

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, options);
      }
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = Apollo.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = Apollo.BaseMutationOptions<UpdateMemberMutation, UpdateMemberMutationVariables>;
export const AddJournalingDocument = gql`
    mutation AddJournaling($input: AddJournalingInput!) {
  addJournaling(input: $input) {
    journaling {
      id
    }
  }
}
    `;
export type AddJournalingMutationFn = Apollo.MutationFunction<AddJournalingMutation, AddJournalingMutationVariables>;

/**
 * __useAddJournalingMutation__
 *
 * To run a mutation, you first call `useAddJournalingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddJournalingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addJournalingMutation, { data, loading, error }] = useAddJournalingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddJournalingMutation(baseOptions?: Apollo.MutationHookOptions<AddJournalingMutation, AddJournalingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddJournalingMutation, AddJournalingMutationVariables>(AddJournalingDocument, options);
      }
export type AddJournalingMutationHookResult = ReturnType<typeof useAddJournalingMutation>;
export type AddJournalingMutationResult = Apollo.MutationResult<AddJournalingMutation>;
export type AddJournalingMutationOptions = Apollo.BaseMutationOptions<AddJournalingMutation, AddJournalingMutationVariables>;
export const UpdateJournalingDocument = gql`
    mutation UpdateJournaling($input: UpdateJournalingInput!) {
  updateJournaling(input: $input) {
    journaling {
      id
    }
  }
}
    `;
export type UpdateJournalingMutationFn = Apollo.MutationFunction<UpdateJournalingMutation, UpdateJournalingMutationVariables>;

/**
 * __useUpdateJournalingMutation__
 *
 * To run a mutation, you first call `useUpdateJournalingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJournalingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJournalingMutation, { data, loading, error }] = useUpdateJournalingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJournalingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJournalingMutation, UpdateJournalingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJournalingMutation, UpdateJournalingMutationVariables>(UpdateJournalingDocument, options);
      }
export type UpdateJournalingMutationHookResult = ReturnType<typeof useUpdateJournalingMutation>;
export type UpdateJournalingMutationResult = Apollo.MutationResult<UpdateJournalingMutation>;
export type UpdateJournalingMutationOptions = Apollo.BaseMutationOptions<UpdateJournalingMutation, UpdateJournalingMutationVariables>;
export const ReactingToGoodThingDocument = gql`
    mutation ReactingToGoodThing($input: ReactingToGoodThingInput!) {
  reactingToGoodThing(input: $input) {
    action
    reactionStamp {
      ...ReactionStamp
    }
  }
}
    ${ReactionStampFragmentDoc}`;
export type ReactingToGoodThingMutationFn = Apollo.MutationFunction<ReactingToGoodThingMutation, ReactingToGoodThingMutationVariables>;

/**
 * __useReactingToGoodThingMutation__
 *
 * To run a mutation, you first call `useReactingToGoodThingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactingToGoodThingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactingToGoodThingMutation, { data, loading, error }] = useReactingToGoodThingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReactingToGoodThingMutation(baseOptions?: Apollo.MutationHookOptions<ReactingToGoodThingMutation, ReactingToGoodThingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactingToGoodThingMutation, ReactingToGoodThingMutationVariables>(ReactingToGoodThingDocument, options);
      }
export type ReactingToGoodThingMutationHookResult = ReturnType<typeof useReactingToGoodThingMutation>;
export type ReactingToGoodThingMutationResult = Apollo.MutationResult<ReactingToGoodThingMutation>;
export type ReactingToGoodThingMutationOptions = Apollo.BaseMutationOptions<ReactingToGoodThingMutation, ReactingToGoodThingMutationVariables>;
export const ReactingToSwitchingActionDocument = gql`
    mutation ReactingToSwitchingAction($input: ReactingToSwitchingActionInput!) {
  reactingToSwitchingAction(input: $input) {
    action
    reactionStamp {
      ...ReactionStamp
    }
  }
}
    ${ReactionStampFragmentDoc}`;
export type ReactingToSwitchingActionMutationFn = Apollo.MutationFunction<ReactingToSwitchingActionMutation, ReactingToSwitchingActionMutationVariables>;

/**
 * __useReactingToSwitchingActionMutation__
 *
 * To run a mutation, you first call `useReactingToSwitchingActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactingToSwitchingActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactingToSwitchingActionMutation, { data, loading, error }] = useReactingToSwitchingActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReactingToSwitchingActionMutation(baseOptions?: Apollo.MutationHookOptions<ReactingToSwitchingActionMutation, ReactingToSwitchingActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactingToSwitchingActionMutation, ReactingToSwitchingActionMutationVariables>(ReactingToSwitchingActionDocument, options);
      }
export type ReactingToSwitchingActionMutationHookResult = ReturnType<typeof useReactingToSwitchingActionMutation>;
export type ReactingToSwitchingActionMutationResult = Apollo.MutationResult<ReactingToSwitchingActionMutation>;
export type ReactingToSwitchingActionMutationOptions = Apollo.BaseMutationOptions<ReactingToSwitchingActionMutation, ReactingToSwitchingActionMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($input: UpdateMyProfileInput!) {
  updateMyProfile(input: $input) {
    user {
      id
      familyName
      givenName
      tel
      avatarUrl
    }
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const UserSignUpDocument = gql`
    mutation UserSignUp($input: UserSignUpInput!) {
  userSignUp(input: $input) {
    user {
      id
      email
    }
  }
}
    `;
export type UserSignUpMutationFn = Apollo.MutationFunction<UserSignUpMutation, UserSignUpMutationVariables>;

/**
 * __useUserSignUpMutation__
 *
 * To run a mutation, you first call `useUserSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSignUpMutation, { data, loading, error }] = useUserSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserSignUpMutation(baseOptions?: Apollo.MutationHookOptions<UserSignUpMutation, UserSignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserSignUpMutation, UserSignUpMutationVariables>(UserSignUpDocument, options);
      }
export type UserSignUpMutationHookResult = ReturnType<typeof useUserSignUpMutation>;
export type UserSignUpMutationResult = Apollo.MutationResult<UserSignUpMutation>;
export type UserSignUpMutationOptions = Apollo.BaseMutationOptions<UserSignUpMutation, UserSignUpMutationVariables>;
export const UserLoginDocument = gql`
    mutation UserLogin($input: UserLoginInput!) {
  userLogin(input: $input) {
    problemMsg
    email
  }
}
    `;
export type UserLoginMutationFn = Apollo.MutationFunction<UserLoginMutation, UserLoginMutationVariables>;

/**
 * __useUserLoginMutation__
 *
 * To run a mutation, you first call `useUserLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLoginMutation, { data, loading, error }] = useUserLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserLoginMutation(baseOptions?: Apollo.MutationHookOptions<UserLoginMutation, UserLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserLoginMutation, UserLoginMutationVariables>(UserLoginDocument, options);
      }
export type UserLoginMutationHookResult = ReturnType<typeof useUserLoginMutation>;
export type UserLoginMutationResult = Apollo.MutationResult<UserLoginMutation>;
export type UserLoginMutationOptions = Apollo.BaseMutationOptions<UserLoginMutation, UserLoginMutationVariables>;
export const InviteUsersDocument = gql`
    mutation InviteUsers($input: InviteUserInput!) {
  inviteUser(input: $input) {
    users {
      id
    }
    duplicateEmails
  }
}
    `;
export type InviteUsersMutationFn = Apollo.MutationFunction<InviteUsersMutation, InviteUsersMutationVariables>;

/**
 * __useInviteUsersMutation__
 *
 * To run a mutation, you first call `useInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersMutation, { data, loading, error }] = useInviteUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUsersMutation(baseOptions?: Apollo.MutationHookOptions<InviteUsersMutation, InviteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUsersMutation, InviteUsersMutationVariables>(InviteUsersDocument, options);
      }
export type InviteUsersMutationHookResult = ReturnType<typeof useInviteUsersMutation>;
export type InviteUsersMutationResult = Apollo.MutationResult<InviteUsersMutation>;
export type InviteUsersMutationOptions = Apollo.BaseMutationOptions<InviteUsersMutation, InviteUsersMutationVariables>;
export const UserLogoutDocument = gql`
    mutation UserLogout($input: UserLogoutInput!) {
  userLogout(input: $input) {
    user {
      id
    }
  }
}
    `;
export type UserLogoutMutationFn = Apollo.MutationFunction<UserLogoutMutation, UserLogoutMutationVariables>;

/**
 * __useUserLogoutMutation__
 *
 * To run a mutation, you first call `useUserLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLogoutMutation, { data, loading, error }] = useUserLogoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserLogoutMutation(baseOptions?: Apollo.MutationHookOptions<UserLogoutMutation, UserLogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserLogoutMutation, UserLogoutMutationVariables>(UserLogoutDocument, options);
      }
export type UserLogoutMutationHookResult = ReturnType<typeof useUserLogoutMutation>;
export type UserLogoutMutationResult = Apollo.MutationResult<UserLogoutMutation>;
export type UserLogoutMutationOptions = Apollo.BaseMutationOptions<UserLogoutMutation, UserLogoutMutationVariables>;
export const VerifyOtpDocument = gql`
    mutation VerifyOtp($input: VerifyOtpInput!) {
  verifyOtp(input: $input) {
    credential
    problemMsg
    user {
      id
    }
  }
}
    `;
export type VerifyOtpMutationFn = Apollo.MutationFunction<VerifyOtpMutation, VerifyOtpMutationVariables>;

/**
 * __useVerifyOtpMutation__
 *
 * To run a mutation, you first call `useVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtpMutation, { data, loading, error }] = useVerifyOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyOtpMutation(baseOptions?: Apollo.MutationHookOptions<VerifyOtpMutation, VerifyOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyOtpMutation, VerifyOtpMutationVariables>(VerifyOtpDocument, options);
      }
export type VerifyOtpMutationHookResult = ReturnType<typeof useVerifyOtpMutation>;
export type VerifyOtpMutationResult = Apollo.MutationResult<VerifyOtpMutation>;
export type VerifyOtpMutationOptions = Apollo.BaseMutationOptions<VerifyOtpMutation, VerifyOtpMutationVariables>;
export const SendPasswordResetInstructionDocument = gql`
    mutation SendPasswordResetInstruction($input: SendPasswordResetInstructionInput!) {
  sendPasswordResetInstruction(input: $input) {
    user {
      id
      email
    }
  }
}
    `;
export type SendPasswordResetInstructionMutationFn = Apollo.MutationFunction<SendPasswordResetInstructionMutation, SendPasswordResetInstructionMutationVariables>;

/**
 * __useSendPasswordResetInstructionMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetInstructionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetInstructionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetInstructionMutation, { data, loading, error }] = useSendPasswordResetInstructionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPasswordResetInstructionMutation(baseOptions?: Apollo.MutationHookOptions<SendPasswordResetInstructionMutation, SendPasswordResetInstructionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPasswordResetInstructionMutation, SendPasswordResetInstructionMutationVariables>(SendPasswordResetInstructionDocument, options);
      }
export type SendPasswordResetInstructionMutationHookResult = ReturnType<typeof useSendPasswordResetInstructionMutation>;
export type SendPasswordResetInstructionMutationResult = Apollo.MutationResult<SendPasswordResetInstructionMutation>;
export type SendPasswordResetInstructionMutationOptions = Apollo.BaseMutationOptions<SendPasswordResetInstructionMutation, SendPasswordResetInstructionMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    problemMsg
    user {
      id
      email
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResetMyEmailDocument = gql`
    mutation ResetMyEmail($input: ResetMyEmailInput!) {
  resetMyEmail(input: $input) {
    problemMsg
    user {
      id
      email
    }
  }
}
    `;
export type ResetMyEmailMutationFn = Apollo.MutationFunction<ResetMyEmailMutation, ResetMyEmailMutationVariables>;

/**
 * __useResetMyEmailMutation__
 *
 * To run a mutation, you first call `useResetMyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetMyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetMyEmailMutation, { data, loading, error }] = useResetMyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetMyEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResetMyEmailMutation, ResetMyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetMyEmailMutation, ResetMyEmailMutationVariables>(ResetMyEmailDocument, options);
      }
export type ResetMyEmailMutationHookResult = ReturnType<typeof useResetMyEmailMutation>;
export type ResetMyEmailMutationResult = Apollo.MutationResult<ResetMyEmailMutation>;
export type ResetMyEmailMutationOptions = Apollo.BaseMutationOptions<ResetMyEmailMutation, ResetMyEmailMutationVariables>;
export const UpdateMyPasswordDocument = gql`
    mutation UpdateMyPassword($input: UpdateMyPasswordInput!) {
  updateMyPassword(input: $input) {
    problemMsg
    user {
      id
    }
  }
}
    `;
export type UpdateMyPasswordMutationFn = Apollo.MutationFunction<UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables>;

/**
 * __useUpdateMyPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateMyPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyPasswordMutation, { data, loading, error }] = useUpdateMyPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMyPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables>(UpdateMyPasswordDocument, options);
      }
export type UpdateMyPasswordMutationHookResult = ReturnType<typeof useUpdateMyPasswordMutation>;
export type UpdateMyPasswordMutationResult = Apollo.MutationResult<UpdateMyPasswordMutation>;
export type UpdateMyPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables>;
export const GetAdminHomeDocument = gql`
    query GetAdminHome($after: String, $before: String, $first: Int, $last: Int) {
  adminHome {
    enterprises(after: $after, before: $before, first: $first, last: $last) {
      edges {
        cursor
        ...EnterpriseEdgeNode
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
}
    ${EnterpriseEdgeNodeFragmentDoc}`;

/**
 * __useGetAdminHomeQuery__
 *
 * To run a query within a React component, call `useGetAdminHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminHomeQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetAdminHomeQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminHomeQuery, GetAdminHomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminHomeQuery, GetAdminHomeQueryVariables>(GetAdminHomeDocument, options);
      }
export function useGetAdminHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminHomeQuery, GetAdminHomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminHomeQuery, GetAdminHomeQueryVariables>(GetAdminHomeDocument, options);
        }
export type GetAdminHomeQueryHookResult = ReturnType<typeof useGetAdminHomeQuery>;
export type GetAdminHomeLazyQueryHookResult = ReturnType<typeof useGetAdminHomeLazyQuery>;
export type GetAdminHomeQueryResult = Apollo.QueryResult<GetAdminHomeQuery, GetAdminHomeQueryVariables>;
export const GetAdminSidebarDocument = gql`
    query GetAdminSidebar($after: String, $before: String, $first: Int, $last: Int) {
  adminHome {
    enterprises(after: $after, before: $before, first: $first, last: $last) {
      edges {
        cursor
        node {
          id
          nameHan
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
}
    `;

/**
 * __useGetAdminSidebarQuery__
 *
 * To run a query within a React component, call `useGetAdminSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminSidebarQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetAdminSidebarQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminSidebarQuery, GetAdminSidebarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminSidebarQuery, GetAdminSidebarQueryVariables>(GetAdminSidebarDocument, options);
      }
export function useGetAdminSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminSidebarQuery, GetAdminSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminSidebarQuery, GetAdminSidebarQueryVariables>(GetAdminSidebarDocument, options);
        }
export type GetAdminSidebarQueryHookResult = ReturnType<typeof useGetAdminSidebarQuery>;
export type GetAdminSidebarLazyQueryHookResult = ReturnType<typeof useGetAdminSidebarLazyQuery>;
export type GetAdminSidebarQueryResult = Apollo.QueryResult<GetAdminSidebarQuery, GetAdminSidebarQueryVariables>;
export const GetMeDocument = gql`
    query GetMe {
  adminHome {
    me {
      id
      name
      uid
    }
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetEnterpriseInfoDocument = gql`
    query GetEnterpriseInfo($id: ID!) {
  adminHome {
    enterprise(id: $id) {
      id
      name
      nameHan
      note
      questions {
        description
        id
        kind
        title
      }
      status
    }
  }
}
    `;

/**
 * __useGetEnterpriseInfoQuery__
 *
 * To run a query within a React component, call `useGetEnterpriseInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnterpriseInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnterpriseInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEnterpriseInfoQuery(baseOptions: Apollo.QueryHookOptions<GetEnterpriseInfoQuery, GetEnterpriseInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnterpriseInfoQuery, GetEnterpriseInfoQueryVariables>(GetEnterpriseInfoDocument, options);
      }
export function useGetEnterpriseInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnterpriseInfoQuery, GetEnterpriseInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnterpriseInfoQuery, GetEnterpriseInfoQueryVariables>(GetEnterpriseInfoDocument, options);
        }
export type GetEnterpriseInfoQueryHookResult = ReturnType<typeof useGetEnterpriseInfoQuery>;
export type GetEnterpriseInfoLazyQueryHookResult = ReturnType<typeof useGetEnterpriseInfoLazyQuery>;
export type GetEnterpriseInfoQueryResult = Apollo.QueryResult<GetEnterpriseInfoQuery, GetEnterpriseInfoQueryVariables>;
export const GetTeamInfoDocument = gql`
    query GetTeamInfo($id: ID!) {
  adminHome {
    enterprise(id: $id) {
      id
      nameHan
      teams {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetTeamInfoQuery__
 *
 * To run a query within a React component, call `useGetTeamInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamInfoQuery(baseOptions: Apollo.QueryHookOptions<GetTeamInfoQuery, GetTeamInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamInfoQuery, GetTeamInfoQueryVariables>(GetTeamInfoDocument, options);
      }
export function useGetTeamInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamInfoQuery, GetTeamInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamInfoQuery, GetTeamInfoQueryVariables>(GetTeamInfoDocument, options);
        }
export type GetTeamInfoQueryHookResult = ReturnType<typeof useGetTeamInfoQuery>;
export type GetTeamInfoLazyQueryHookResult = ReturnType<typeof useGetTeamInfoLazyQuery>;
export type GetTeamInfoQueryResult = Apollo.QueryResult<GetTeamInfoQuery, GetTeamInfoQueryVariables>;
export const GetFakeDataDocument = gql`
    query GetFakeData {
  myTeam {
    id
    fakeCoachingSchedule {
      id
      date
      coachingGroups {
        id
        startTime
        users {
          id
          familyName
          givenName
          avatarUrl
        }
      }
    }
  }
}
    `;

/**
 * __useGetFakeDataQuery__
 *
 * To run a query within a React component, call `useGetFakeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFakeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFakeDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFakeDataQuery(baseOptions?: Apollo.QueryHookOptions<GetFakeDataQuery, GetFakeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFakeDataQuery, GetFakeDataQueryVariables>(GetFakeDataDocument, options);
      }
export function useGetFakeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFakeDataQuery, GetFakeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFakeDataQuery, GetFakeDataQueryVariables>(GetFakeDataDocument, options);
        }
export type GetFakeDataQueryHookResult = ReturnType<typeof useGetFakeDataQuery>;
export type GetFakeDataLazyQueryHookResult = ReturnType<typeof useGetFakeDataLazyQuery>;
export type GetFakeDataQueryResult = Apollo.QueryResult<GetFakeDataQuery, GetFakeDataQueryVariables>;
export const GetMembersDocument = gql`
    query GetMembers {
  myTeam {
    id
    users {
      nodes {
        ...Member
      }
    }
  }
}
    ${MemberFragmentDoc}`;

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
      }
export function useGetMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
        }
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>;
export type GetMembersLazyQueryHookResult = ReturnType<typeof useGetMembersLazyQuery>;
export type GetMembersQueryResult = Apollo.QueryResult<GetMembersQuery, GetMembersQueryVariables>;
export const GetTeamDocument = gql`
    query GetTeam {
  myEnterprise {
    id
    nameHan
  }
  myTeam {
    id
    name
  }
}
    `;

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
      }
export function useGetTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
        }
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>;
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>;
export type GetTeamQueryResult = Apollo.QueryResult<GetTeamQuery, GetTeamQueryVariables>;
export const GetMyTeamsDocument = gql`
    query GetMyTeams {
  me {
    id
  }
  myEnterprise {
    id
    teams {
      id
      name
    }
  }
}
    `;

/**
 * __useGetMyTeamsQuery__
 *
 * To run a query within a React component, call `useGetMyTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyTeamsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyTeamsQuery, GetMyTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyTeamsQuery, GetMyTeamsQueryVariables>(GetMyTeamsDocument, options);
      }
export function useGetMyTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyTeamsQuery, GetMyTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyTeamsQuery, GetMyTeamsQueryVariables>(GetMyTeamsDocument, options);
        }
export type GetMyTeamsQueryHookResult = ReturnType<typeof useGetMyTeamsQuery>;
export type GetMyTeamsLazyQueryHookResult = ReturnType<typeof useGetMyTeamsLazyQuery>;
export type GetMyTeamsQueryResult = Apollo.QueryResult<GetMyTeamsQuery, GetMyTeamsQueryVariables>;
export const GetJournalingDocument = gql`
    query GetJournaling {
  me {
    id
    journaling {
      ...Journaling
    }
  }
}
    ${JournalingFragmentDoc}`;

/**
 * __useGetJournalingQuery__
 *
 * To run a query within a React component, call `useGetJournalingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJournalingQuery(baseOptions?: Apollo.QueryHookOptions<GetJournalingQuery, GetJournalingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJournalingQuery, GetJournalingQueryVariables>(GetJournalingDocument, options);
      }
export function useGetJournalingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJournalingQuery, GetJournalingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJournalingQuery, GetJournalingQueryVariables>(GetJournalingDocument, options);
        }
export type GetJournalingQueryHookResult = ReturnType<typeof useGetJournalingQuery>;
export type GetJournalingLazyQueryHookResult = ReturnType<typeof useGetJournalingLazyQuery>;
export type GetJournalingQueryResult = Apollo.QueryResult<GetJournalingQuery, GetJournalingQueryVariables>;
export const GetCoachingSchedulesDocument = gql`
    query GetCoachingSchedules {
  myTeam {
    id
    coachingSchedules {
      nodes {
        id
        date
        coachingGroups {
          id
          startTime
          users {
            id
            familyName
            givenName
            avatarUrl
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCoachingSchedulesQuery__
 *
 * To run a query within a React component, call `useGetCoachingSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachingSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachingSchedulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoachingSchedulesQuery(baseOptions?: Apollo.QueryHookOptions<GetCoachingSchedulesQuery, GetCoachingSchedulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoachingSchedulesQuery, GetCoachingSchedulesQueryVariables>(GetCoachingSchedulesDocument, options);
      }
export function useGetCoachingSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoachingSchedulesQuery, GetCoachingSchedulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoachingSchedulesQuery, GetCoachingSchedulesQueryVariables>(GetCoachingSchedulesDocument, options);
        }
export type GetCoachingSchedulesQueryHookResult = ReturnType<typeof useGetCoachingSchedulesQuery>;
export type GetCoachingSchedulesLazyQueryHookResult = ReturnType<typeof useGetCoachingSchedulesLazyQuery>;
export type GetCoachingSchedulesQueryResult = Apollo.QueryResult<GetCoachingSchedulesQuery, GetCoachingSchedulesQueryVariables>;
export const GetRecentFeelingDocument = gql`
    query GetRecentFeeling {
  myTeam {
    id
    users {
      nodes {
        id
        avatarUrl
        familyName
        givenName
        recentFeeling
        status
      }
    }
  }
}
    `;

/**
 * __useGetRecentFeelingQuery__
 *
 * To run a query within a React component, call `useGetRecentFeelingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentFeelingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentFeelingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecentFeelingQuery(baseOptions?: Apollo.QueryHookOptions<GetRecentFeelingQuery, GetRecentFeelingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecentFeelingQuery, GetRecentFeelingQueryVariables>(GetRecentFeelingDocument, options);
      }
export function useGetRecentFeelingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentFeelingQuery, GetRecentFeelingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecentFeelingQuery, GetRecentFeelingQueryVariables>(GetRecentFeelingDocument, options);
        }
export type GetRecentFeelingQueryHookResult = ReturnType<typeof useGetRecentFeelingQuery>;
export type GetRecentFeelingLazyQueryHookResult = ReturnType<typeof useGetRecentFeelingLazyQuery>;
export type GetRecentFeelingQueryResult = Apollo.QueryResult<GetRecentFeelingQuery, GetRecentFeelingQueryVariables>;
export const GetRecentJournalingsDocument = gql`
    query GetRecentJournalings($after: String, $before: String, $first: Int, $last: Int) {
  myTeam {
    id
    recentJournalings(after: $after, before: $before, first: $first, last: $last) {
      nodes {
        id
        user {
          id
          familyName
          givenName
          avatarUrl
          goal
        }
        situation
        updatedAt
        pleasure
        confidence
        fear
        surprise
        sadness
        repugnance
        anger
        expectation
        goodThing {
          id
          reactionStamps {
            ...ReactionStamp
          }
          text
        }
        switchingAction {
          id
          reactionStamps {
            ...ReactionStamp
          }
          text
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
    ${ReactionStampFragmentDoc}`;

/**
 * __useGetRecentJournalingsQuery__
 *
 * To run a query within a React component, call `useGetRecentJournalingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentJournalingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentJournalingsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetRecentJournalingsQuery(baseOptions?: Apollo.QueryHookOptions<GetRecentJournalingsQuery, GetRecentJournalingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecentJournalingsQuery, GetRecentJournalingsQueryVariables>(GetRecentJournalingsDocument, options);
      }
export function useGetRecentJournalingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentJournalingsQuery, GetRecentJournalingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecentJournalingsQuery, GetRecentJournalingsQueryVariables>(GetRecentJournalingsDocument, options);
        }
export type GetRecentJournalingsQueryHookResult = ReturnType<typeof useGetRecentJournalingsQuery>;
export type GetRecentJournalingsLazyQueryHookResult = ReturnType<typeof useGetRecentJournalingsLazyQuery>;
export type GetRecentJournalingsQueryResult = Apollo.QueryResult<GetRecentJournalingsQuery, GetRecentJournalingsQueryVariables>;
export const GetJournalingsDocument = gql`
    query GetJournalings($after: String, $before: String, $first: Int, $last: Int, $filter: JournalingFilterInput) {
  myTeam {
    id
    journalings(
      after: $after
      before: $before
      first: $first
      last: $last
      filter: $filter
    ) {
      nodes {
        id
        user {
          id
          familyName
          givenName
          avatarUrl
          goal
        }
        situation
        updatedAt
        pleasure
        confidence
        fear
        surprise
        sadness
        repugnance
        anger
        expectation
        goodThing {
          id
          reactionStamps {
            id
            user {
              id
              familyName
              givenName
            }
            emojiIndex
          }
          text
        }
        switchingAction {
          id
          reactionStamps {
            id
            user {
              id
              familyName
              givenName
            }
            emojiIndex
          }
          text
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
    `;

/**
 * __useGetJournalingsQuery__
 *
 * To run a query within a React component, call `useGetJournalingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalingsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetJournalingsQuery(baseOptions?: Apollo.QueryHookOptions<GetJournalingsQuery, GetJournalingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJournalingsQuery, GetJournalingsQueryVariables>(GetJournalingsDocument, options);
      }
export function useGetJournalingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJournalingsQuery, GetJournalingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJournalingsQuery, GetJournalingsQueryVariables>(GetJournalingsDocument, options);
        }
export type GetJournalingsQueryHookResult = ReturnType<typeof useGetJournalingsQuery>;
export type GetJournalingsLazyQueryHookResult = ReturnType<typeof useGetJournalingsLazyQuery>;
export type GetJournalingsQueryResult = Apollo.QueryResult<GetJournalingsQuery, GetJournalingsQueryVariables>;
export const GetProfileDocument = gql`
    query GetProfile {
  me {
    id
    familyName
    givenName
    tel
    avatarUrl
    email
    goal
    team {
      id
    }
  }
}
    `;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const GetEnterpriseDocument = gql`
    query GetEnterprise {
  myEnterprise {
    id
    nameHan
  }
}
    `;

/**
 * __useGetEnterpriseQuery__
 *
 * To run a query within a React component, call `useGetEnterpriseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnterpriseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnterpriseQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnterpriseQuery(baseOptions?: Apollo.QueryHookOptions<GetEnterpriseQuery, GetEnterpriseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnterpriseQuery, GetEnterpriseQueryVariables>(GetEnterpriseDocument, options);
      }
export function useGetEnterpriseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnterpriseQuery, GetEnterpriseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnterpriseQuery, GetEnterpriseQueryVariables>(GetEnterpriseDocument, options);
        }
export type GetEnterpriseQueryHookResult = ReturnType<typeof useGetEnterpriseQuery>;
export type GetEnterpriseLazyQueryHookResult = ReturnType<typeof useGetEnterpriseLazyQuery>;
export type GetEnterpriseQueryResult = Apollo.QueryResult<GetEnterpriseQuery, GetEnterpriseQueryVariables>;
export const GetLineGraphDocument = gql`
    query GetLineGraph {
  me {
    id
    lineGraph {
      ...LienGraph
    }
  }
}
    ${LienGraphFragmentDoc}`;

/**
 * __useGetLineGraphQuery__
 *
 * To run a query within a React component, call `useGetLineGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLineGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLineGraphQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLineGraphQuery(baseOptions?: Apollo.QueryHookOptions<GetLineGraphQuery, GetLineGraphQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLineGraphQuery, GetLineGraphQueryVariables>(GetLineGraphDocument, options);
      }
export function useGetLineGraphLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLineGraphQuery, GetLineGraphQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLineGraphQuery, GetLineGraphQueryVariables>(GetLineGraphDocument, options);
        }
export type GetLineGraphQueryHookResult = ReturnType<typeof useGetLineGraphQuery>;
export type GetLineGraphLazyQueryHookResult = ReturnType<typeof useGetLineGraphLazyQuery>;
export type GetLineGraphQueryResult = Apollo.QueryResult<GetLineGraphQuery, GetLineGraphQueryVariables>;
export const GetQuestionsDocument = gql`
    query GetQuestions {
  myEnterprise {
    id
    questions {
      id
      description
      title
      kind
    }
  }
}
    `;

/**
 * __useGetQuestionsQuery__
 *
 * To run a query within a React component, call `useGetQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionsQuery, GetQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionsQuery, GetQuestionsQueryVariables>(GetQuestionsDocument, options);
      }
export function useGetQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionsQuery, GetQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionsQuery, GetQuestionsQueryVariables>(GetQuestionsDocument, options);
        }
export type GetQuestionsQueryHookResult = ReturnType<typeof useGetQuestionsQuery>;
export type GetQuestionsLazyQueryHookResult = ReturnType<typeof useGetQuestionsLazyQuery>;
export type GetQuestionsQueryResult = Apollo.QueryResult<GetQuestionsQuery, GetQuestionsQueryVariables>;
import theme from "@/shared/theme";
import { Box, Flex, useBoolean } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BottomArrowIcon, Text } from "@/components";
import { useGetAdminSidebarLazyQuery } from "@/apollo/generated";
import { nonEmpty } from "@/utils/nonEmpty";
import { useRefetchContext } from "@/hooks/useRefetchContext";
import { useNavigate } from "react-router-dom";

export const Sidebar: React.FC = () => {
  const { t } = useTranslation("admin");
  const pageSize = 30;
  const [isOpen, { toggle: toggleOpen }] = useBoolean(true);
  const [getAdminSidebar, { data, fetchMore, refetch }] = useGetAdminSidebarLazyQuery();
  const { isRefetch, setRefetch } = useRefetchContext();
  const navigate = useNavigate();

  const companies = nonEmpty(data?.adminHome?.enterprises.edges);
  const pageInfo = data?.adminHome?.enterprises.pageInfo;

  const fetchMoreAdminHome = useCallback(async () => {
    const endCursor = pageInfo?.endCursor;
    const hasNextPage = pageInfo?.hasNextPage;

    if (!hasNextPage) return;

    await fetchMore({
      variables: {
        first: pageSize,
        after: endCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult.adminHome?.enterprises.edges) return prevResult;

        fetchMoreResult.adminHome.enterprises.edges = [
          ...nonEmpty(prevResult.adminHome?.enterprises.edges),
          ...nonEmpty(fetchMoreResult.adminHome.enterprises.edges)
        ];

        return fetchMoreResult;
      }
    });
  }, [fetchMore, pageInfo]);

  const scroll = useCallback(
    (event: React.UIEvent) => {
      if (
        event.currentTarget.scrollTop + event.currentTarget.clientHeight >=
        event.currentTarget.scrollHeight
      ) {
        fetchMoreAdminHome();
      }
    },
    [fetchMoreAdminHome]
  );

  useEffect(() => {
    getAdminSidebar();
  }, [getAdminSidebar]);

  useEffect(() => {
    if (isRefetch)
      refetch().then(() => {
        setRefetch(false);
      });
  }, [refetch, isRefetch, setRefetch]);

  return (
    <Box minW="214px">
      <Flex
        pos="fixed"
        w="214px"
        minW="214px"
        zIndex="1"
        h="100%"
        bgColor={theme.text.white}
        p="32px 16px 32px 23px"
        flexDir="column"
        boxShadow="0 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 15px -3px rgba(0, 0, 0, 0.10)"
      >
        <Flex
          h="23px"
          align="center"
          justify="space-between"
          w="100%"
          sx={{ "& svg": { transform: isOpen ? "rotate(0deg)" : "rotate(180deg)" } }}
        >
          <Text color={theme.text.black900} textType="body16b">
            {t("listOfCompanies")}
          </Text>
          <BottomArrowIcon w="12px" h="7.41px" onClick={toggleOpen} cursor="pointer" />
        </Flex>
        {isOpen && (
          <Flex
            p="14px 29px 14px 12px"
            flexDir="column"
            overflowY="scroll"
            onScroll={scroll}
            sx={{
              "::-webkit-scrollbar": {
                height: "1px",
                "&:horizontal": {
                  backgroundColor: "transparent"
                }
              }
            }}
          >
            {companies.map((item) => (
              <Text
                mb="16px"
                key={item.node?.id}
                color={theme.text.black900}
                textType="body14r"
                cursor="pointer"
                onClick={() => {
                  navigate(`/admin/enterprises/${item.node?.id}/edit`);
                }}
              >
                {item.node?.nameHan}
              </Text>
            ))}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

import { InMemoryCache, TypePolicies } from "@apollo/client";

const typePolicies: TypePolicies = {
  Query: {
    fields: {
      adminHome: {
        merge(existing = [], incoming: any) {
          return { ...existing, ...incoming };
        }
      }
    }
  }
};

export const cache = new InMemoryCache({ typePolicies });

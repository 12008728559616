import React, { ReactNode, createContext, useState, useCallback } from "react";
import {
  getLocalStorageItem,
  Key,
  removeLocalStorageItem,
  setLocalStorageItem
} from "@/utils/utils";

type AuthHeaderContextType = {
  authHeader?: string;
  setAuthHeader: (header: string) => void;
  removeAuthHeader: () => void;
  adminHeader?: string;
  setAdminHeader: (adminHeader: string) => void;
  removeAdminHeader: () => void;
};

export const AuthHeaderContext = createContext<AuthHeaderContextType | null>(null);

export const AuthHeaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [authHeader, setAuthHeader] = useState(getLocalStorageItem<string>(Key.authorization));
  const [adminHeader, setAdminHeader] = useState(
    getLocalStorageItem<string>(Key.adminAuthorization)
  );

  return (
    <AuthHeaderContext.Provider
      value={{
        authHeader,
        adminHeader,
        setAuthHeader: useCallback(
          (header: string) => {
            setAuthHeader(header);
            setLocalStorageItem(Key.authorization, header);
          },
          [setAuthHeader]
        ),
        removeAuthHeader: useCallback(() => {
          setAuthHeader(undefined);
          removeLocalStorageItem(Key.authorization);
        }, [setAuthHeader]),
        setAdminHeader: useCallback(
          (header: string) => {
            setAdminHeader(header);
            setLocalStorageItem(Key.adminAuthorization, header);
          },
          [setAdminHeader]
        ),
        removeAdminHeader: useCallback(() => {
          setAdminHeader(undefined);
          removeLocalStorageItem(Key.adminAuthorization);
        }, [setAdminHeader])
      }}
    >
      {children}
    </AuthHeaderContext.Provider>
  );
};

import { Text } from "@/components";
import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import React from "react";

export const TextTitle: React.FC<{ text: string; color: string }> = ({ text, color }) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return (
    <Flex direction="row" align="center" justify="center">
      <Box w="32px" h="2px" background={color} />
      <Text m={isLargerThan768 ? "0 40px" : "0 18px"} color={color} textAlign="center" fontSize={isLargerThan768 ? "32px" : "18px"} fontWeight={300} letterSpacing="3.2px">
        {text}
      </Text>
      <Box w="32px" h="2px" background={color} />
    </Flex>
  );
};

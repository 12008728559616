import { createIcon } from "@chakra-ui/icons";

export const FileIcon = createIcon({
  displayName: "FileIcon",
  viewBox: "0 0 22 20",
  path: (
    <g fill="none">
      <path
        d="M17.9834 5.63672L13.455 1.43359C13.3288 1.31641 13.1583 1.25 12.9794 1.25H4.04055C3.6681 1.25 3.36719 1.5293 3.36719 1.875V18.125C3.36719 18.4707 3.6681 18.75 4.04055 18.75H17.5078C17.8803 18.75 18.1812 18.4707 18.1812 18.125V6.08008C18.1812 5.91406 18.1096 5.75391 17.9834 5.63672ZM16.6282 6.36719H12.668V2.69141L16.6282 6.36719ZM16.6661 17.3438H4.88225V2.65625H11.2371V6.875C11.2371 7.09256 11.3302 7.30121 11.496 7.45505C11.6617 7.60889 11.8865 7.69531 12.1209 7.69531H16.6661V17.3438Z"
        fill="currentColor"
      />
    </g>
  )
});

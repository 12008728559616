import React from "react";
import { Input as ChakraInput, InputProps } from "@chakra-ui/react";
import theme from "@/shared/theme";

type PropsType = Omit<InputProps, "onChange"> & {
  styleType?: "default" | "flushed";
};

export const Input = React.forwardRef<HTMLInputElement, PropsType>(
  ({ styleType, ...rest }, ref) => {
    const customStyles: Record<NonNullable<PropsType["styleType"]>, InputProps> = {
      default: {
        variant: "outline",
        _focus: { border: `2px solid ${theme.service.turquoise400}` },
        _invalid: { border: `2px solid ${theme.error.red}` }
      },
      flushed: {
        variant: "flushed",
        _focus: { borderBottom: `1px solid ${theme.service.turquoise400}` },
        _invalid: { borderBottom: `1px solid ${theme.error.red}` }
      }
    };

    if (styleType) {
      return <ChakraInput ref={ref} {...rest} {...customStyles[styleType]} />;
    } else {
      return <ChakraInput ref={ref} {...rest} />;
    }
  }
);

Input.displayName = "Input";

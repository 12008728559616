import { createIcon } from "@chakra-ui/icons";

export const Rectangle1Icon = createIcon({
  displayName: "Rectangle1Icon",
  viewBox: "0 0 172 117",
  path: (
    <svg width="172" height="117" viewBox="0 0 172 117" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.38281 2.22851C2.38281 1.95237 2.60667 1.72852 2.88281 1.72852H143.226C143.421 1.72852 143.598 1.84149 143.679 2.01803L170.125 59.0033C170.188 59.1389 170.187 59.2956 170.122 59.4303L143.695 114.445C143.612 114.618 143.437 114.729 143.245 114.729H2.88282C2.60667 114.729 2.38281 114.505 2.38281 114.229V2.22851Z"
        stroke="#0B8487"
        strokeWidth="3"
      />
    </svg>
  )
});
export const RectangleSP1Icon = createIcon({
  displayName: "RectangleSP1Icon",
  viewBox: "0 0 155 60",
  path: (
    <svg width="155" height="60" viewBox="0 0 155 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 2C1 1.44772 1.44772 1 2 1H129.23C129.531 1 129.816 1.13555 130.006 1.36906L153.18 29.8643L153.956 29.2334L153.18 29.8643C153.483 30.2368 153.478 30.772 153.169 31.1393L130.016 58.644C129.826 58.8697 129.546 59 129.251 59H1.99999C1.44771 59 1 58.5523 1 58V2Z"
        stroke="#0B8487"
        strokeWidth="2"
      />
    </svg>
  )
});

import React, { useEffect, useState } from "react";
import {
  Slider as ChakraSlider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Box
} from "@chakra-ui/react";
import theme from "@/shared/theme";

type PropsType = {
  defaultValue: number;
  color: string;
  onChange: (value: number) => void;
};

export const Slider: React.FC<PropsType> = ({ color, defaultValue, onChange }) => {
  const markList = [0, 1, 2, 3, 4, 5];
  const [sliderValue, setSliderValue] = useState(0);

  useEffect(() => {
    setSliderValue(defaultValue);
  }, [setSliderValue, defaultValue]);

  return (
    <ChakraSlider
      key={defaultValue}
      defaultValue={defaultValue}
      min={0}
      max={5}
      step={1}
      w="498px"
      h="24px"
      onChangeEnd={(value) => {
        setSliderValue(value);
        onChange(value);
      }}
    >
      {markList.map((m) => (
        <SliderMark value={m} mt="-1" fontSize="sm" key={m}>
          <Box w="3px" h="18px" borderRadius="4px" bgColor={theme.text.black200} />
        </SliderMark>
      ))}
      {sliderValue > 0 && (
        <SliderMark
          value={sliderValue}
          textAlign="center"
          bg={color}
          color={theme.text.white}
          mt="-8"
          ml="-5"
          w="12"
          borderRadius="4px"
        >
          {sliderValue}
        </SliderMark>
      )}
      <SliderTrack bg={theme.text.black200} ml="1px">
        <SliderFilledTrack bg={color} />
      </SliderTrack>
      <SliderThumb bg={color} ml="1px" />
    </ChakraSlider>
  );
};

import React, { createContext, ReactNode, useState } from "react";

export type RefetchContextType = {
  isRefetch: boolean;
  setRefetch: (refetch: boolean) => void;
};

export const RefetchContext = createContext<RefetchContextType | null>(null);

export const RefetchContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isRefetch, setRefetch] = useState<boolean>(false);

  return (
    <RefetchContext.Provider value={{ isRefetch, setRefetch }}>{children}</RefetchContext.Provider>
  );
};

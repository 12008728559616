import { createIcon } from "@chakra-ui/icons";

export const RecordIcon = createIcon({
  displayName: "RecordIcon",
  viewBox: "0 0 36 36",
  path: (
    <g fill="none">
      <path
        d="M9.05977 26.4375C9.13008 26.4375 9.20039 26.4305 9.2707 26.4199L15.184 25.3828C15.2543 25.3687 15.3211 25.3371 15.3703 25.2844L30.273 10.3816C30.3056 10.3491 30.3315 10.3105 30.3491 10.268C30.3668 10.2254 30.3759 10.1798 30.3759 10.1338C30.3759 10.0877 30.3668 10.0422 30.3491 9.99962C30.3315 9.95709 30.3056 9.91846 30.273 9.88594L24.4301 4.03945C24.3633 3.97266 24.2754 3.9375 24.1805 3.9375C24.0855 3.9375 23.9977 3.97266 23.9309 4.03945L9.02812 18.9422C8.97539 18.9949 8.94375 19.0582 8.92969 19.1285L7.89258 25.0418C7.85838 25.2301 7.8706 25.424 7.92818 25.6065C7.98576 25.7891 8.08697 25.9548 8.22305 26.0895C8.45508 26.3145 8.74688 26.4375 9.05977 26.4375ZM11.4293 20.3062L24.1805 7.55859L26.7574 10.1355L14.0062 22.8832L10.8809 23.4352L11.4293 20.3062ZM30.9375 29.3906H5.0625C4.44023 29.3906 3.9375 29.8934 3.9375 30.5156V31.7812C3.9375 31.9359 4.06406 32.0625 4.21875 32.0625H31.7812C31.9359 32.0625 32.0625 31.9359 32.0625 31.7812V30.5156C32.0625 29.8934 31.5598 29.3906 30.9375 29.3906Z"
        fill="#18A3A7"
      />
    </g>
  )
});

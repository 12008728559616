import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Text, Button } from "@/components";
import { Divider, Box } from "@chakra-ui/react";
import theme from "@/shared/theme";

export const EmailResetForm: React.FC = () => {
  const { t } = useTranslation(["emailreset"]);
  const navigate = useNavigate();

  return (
    <Box w="556px">
      <Text textType="title32">{t("title")}</Text>
      <Divider bgColor={theme.service.turquoise400} h="1px" m="14px 0 16px" />
      <Box h="70vh" minH="490px" pos="relative">
        <Text textType="body14r" lineHeight="28.96px" m="40px 0 0">
          {t("description1")}
        </Text>
        <Text textType="body14r" lineHeight="28.96px">
          {t("description2")}
        </Text>
        <Button
          mt="74px"
          w="556px"
          buttonSize="xl"
          buttonType="fill"
          type="submit"
          onClick={() => navigate("/auth/signin")}
        >
          {t("linkto")}
        </Button>
        <Text
          textType="body12r"
          color={theme.service.turquoiseText}
          textAlign="center"
          pos="absolute"
          bottom="0"
          left="160px"
        >
          © 2023 FUJITSU LEARNING MEDIA LIMITED
        </Text>
      </Box>
    </Box>
  );
};

import { Suspense, lazy, ElementType } from "react";
import { useRoutes } from "react-router-dom";
import { Loading, UserLayout } from "@/components";
import GuestGuard from "@/guards/GuestGuard";
import AuthGuard from "@/guards/AuthGuard";
import { Layout } from "@/components/pages/admin";
import AdminAuthGuard from "@/guards/AdminAuthGuard";

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "admin",
      children: [
        {
          path: "auth",
          children: [{ path: "signin", element: <AdminSignin /> }]
        },
        {
          path: "/admin",
          element: (
            <AdminAuthGuard>
              <Layout />
            </AdminAuthGuard>
          ),
          children: [
            { element: <AdminHome />, index: true },
            {
              path: "/admin/enterprises/create",
              element: <EnterpriseCreate />
            },
            {
              path: "/admin/enterprises/:id/edit",
              element: <EnterpriseEdit />
            },
            {
              path: "/admin/team/:id/edit",
              element: <TeamEdit />
            },
            {
              path: "/admin/login-information",
              element: <AdminLoginInformation />
            }
          ]
        }
      ]
    },
    {
      path: "auth",
      children: [
        {
          path: "signup",
          element: (
            <GuestGuard>
              <Signup />
            </GuestGuard>
          )
        },
        { path: "signin", element: <Signin /> },
        { path: "email-reset", element: <EmailReset /> },
        { path: "password-reset", element: <PasswordReset /> },
        { path: "password-reset-input", element: <PasswordResetInput /> },
        { path: "password-reset-success", element: <PasswordResetSuccess /> }
      ]
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          <UserLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Home />, index: true },
        { path: "home", element: <LandingPage /> },
        { path: "record", element: <Record /> },
        { path: "coaching-schedules", element: <CoachingScheduleList /> },
        { path: "members", element: <MemberTable /> },
        { path: "journalings-line", element: <JournalingLineChart /> },
        { path: "journalings", element: <Journalings /> },
        {
          path: "user",
          children: [
            {
              path: "profile",
              element: <Profile />
            },
            {
              path: "login-information",
              element: <LoginInformation />
            }
          ]
        }
      ]
    },
    { path: "terms-of-use", element: <TermsOfUse /> }
  ]);
}

const Profile = Loadable(lazy(() => import("../pages/user/me/profile")));
const LoginInformation = Loadable(lazy(() => import("../pages/user/me/login-information")));
const Signup = Loadable(lazy(() => import("../pages/auth/signup")));
const Signin = Loadable(lazy(() => import("../pages/auth/signin")));
const EmailReset = Loadable(lazy(() => import("../pages/auth/email-reset")));
const PasswordReset = Loadable(lazy(() => import("../pages/auth/password-reset")));
const PasswordResetInput = Loadable(lazy(() => import("../pages/auth/password-reset-input")));
const PasswordResetSuccess = Loadable(lazy(() => import("../pages/auth/password-reset-success")));
const TermsOfUse = Loadable(lazy(() => import("../pages/terms-of-use")));
const MemberTable = Loadable(lazy(() => import("../pages/user/members/member-list")));
const Home = Loadable(lazy(() => import("../pages/user/home")));
const Record = Loadable(lazy(() => import("../components/pages/user/record/RecordForm")));
const CoachingScheduleList = Loadable(lazy(() => import("../components/pages/user/coaching-schedule/CoachingScheduleList")));
const JournalingLineChart = Loadable(lazy(() => import("../components/pages/user/journaling/LineChart")));
const Journalings = Loadable(lazy(() => import("../pages/user/journaling/journalings")));
const TeamEdit = Loadable(lazy(() => import("../pages/admin/team/[id]/edit")));
const EnterpriseCreate = Loadable(lazy(() => import("../pages/admin/enterprises/create")));
const EnterpriseEdit = Loadable(lazy(() => import("../pages/admin/enterprises/[id]/edit")));
const AdminHome = Loadable(lazy(() => import("../pages/admin")));
const AdminSignin = Loadable(lazy(() => import("@/pages/admin/auth/signin")));
const AdminLoginInformation = Loadable(lazy(() => import("@/pages/admin/me/login-information")));
const LandingPage = Loadable(lazy(() => import("@/pages/landing-page")));

import React, { useState } from "react";
import { Button, Flex, Select } from "@chakra-ui/react";
import { BottomArrowIcon, Text } from "@/components";
import { useTranslation } from "react-i18next";
import theme from "@/shared/theme";

type PropsType = {
  totalCount: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  onPageNext: () => void;
  onPagePrev: () => void;
  onChangePageSize: (pageSize: number) => void;
  defaultPageSize: number;
  pageNumber: number;
};

export const Pagination: React.FC<PropsType> = ({
  pageNumber,
  hasNextPage,
  hasPrevPage,
  totalCount,
  onPageNext,
  onPagePrev,
  onChangePageSize,
  defaultPageSize
}) => {
  const { t } = useTranslation(["admin"]);
  const [value, setValue] = useState<string>();

  const pageOptions: { value: string; label: string }[] = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "50", label: "50" }
  ];

  return (
    <Flex justifyContent="flex-end" mt="20px" alignItems="center">
      <Flex h="41px" align="center">
        <Flex
          alignItems="center"
          h="21px"
          lineHeight="21px"
          mr="50px"
          sx={{
            "& .chakra-select__icon-wrapper": {
              top: "59%"
            }
          }}
        >
          <Text mr="16px" fontSize="14px" fontWeight="500">
            {t("numberOfDisplay")}
          </Text>
          <Select
            size="sm"
            w="auto"
            variant="unstyled"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              onChangePageSize(parseInt(e.target.value));
            }}
            defaultValue={defaultPageSize.toString()}
          >
            {pageOptions.map(({ value, label }) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex alignItems="center" h="21px" lineHeight="21px">
          <Text mr="15px" color={theme.text.gray700}>
            {(pageNumber - 1) * defaultPageSize + 1}-
            {pageNumber * defaultPageSize > totalCount ? totalCount : pageNumber * defaultPageSize}
            &nbsp;&nbsp;of {totalCount}
          </Text>
          <Flex>
            <Button
              isDisabled={!hasPrevPage}
              onClick={onPagePrev}
              bgColor="transparent"
              _active={{ bgColor: "transparent" }}
              _hover={{ bgColor: "transparent" }}
            >
              <BottomArrowIcon transform="rotate(-90deg)" />
            </Button>
            <Button
              isDisabled={!hasNextPage}
              onClick={onPageNext}
              bgColor="transparent"
              _active={{ bgColor: "transparent" }}
              _hover={{ bgColor: "transparent" }}
            >
              <BottomArrowIcon transform="rotate(90deg)" w="1em" h="1em" />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

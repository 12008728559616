import React, { FC, ReactNode } from "react";
import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import { MainContent, Text } from "@/components";
import theme from "@/shared/theme";
import { Fade } from "react-awesome-reveal";
import { useInView } from "react-intersection-observer";
import "@/styles/fade.css";

interface FadeInSectionProps {
  triggerClass: string;
  animationClass: string;
  children: ReactNode;
  threshold: number;
}

export const FadeInSection: FC<FadeInSectionProps> = ({
  triggerClass,
  animationClass,
  children,
  threshold
}) => {
  const { ref, inView } = useInView({
    threshold: threshold,
    triggerOnce: false,
    delay: 1500,
    trackVisibility: true
  });

  return (
    <div
      ref={ref}
      className={`${triggerClass} ${inView ? animationClass : ""}`}
      style={{ height: "100%" }}
    >
      {children}
    </div>
  );
};

export const StepsToChange: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const dataList: {
    title: string;
    text: string;
    list: {
      title_item: string;
      text_item: string;
    }[];
  }[] = [
    {
      title: "個人への効果と変革ステップ",
      text: "感情を起点とした振り返りを通じて、目標達成に向けた自発的な行動変容につなげます",
      list: [
        {
          title_item: "感情の可視化",
          text_item:
            '振り返りの設問に答えていくことで、見えていた事象と行動だけでなく、見えていなかった"感情"が記録されていきます。'
        },
        {
          title_item: "自己認識",
          text_item:
            "対話を通して、メンバーの視点も取り入れながら自己の内面を深堀することで、感情の源にある考え方や価値観、判断基準の癖に自ら気づくことができます。"
        },
        {
          title_item: "自発的な変容",
          text_item:
            "気づきを通して、これまでの行動を俯瞰的に捉え直すことで、目標達成に向けて取るべきアクションを特定、実行できます。"
        }
      ]
    },
    {
      title: "チームへの効果と変革ステップ",
      text: "価値観や考え方を対話することで、目標達成に向けてスピード感を持って取り組む関係性を作ります",
      list: [
        {
          title_item: "メンバーの理解",
          text_item:
            "オープンに共有するチームの記録を通して、事象や感情が可視化されることで、メンバーの置かれている状況の理解につながります。"
        },
        {
          title_item: "協働意識の醸成",
          text_item:
            "対話を通して、見えていなかったメンバーの考え方や価値観、判断基準を聴き合うことで、共感し、協働する意識が高まります。"
        },
        {
          title_item: "関係性の強化",
          text_item:
            "メンバーの考え方や価値観、判断基準を理解することで、相手に合わせた働きかけができるようになり、チームとしての関係性が強化されていきます。"
        }
      ]
    }
  ];

  return (
    <Box pb={isLargerThan768 ? "8.3vw" : "17vw"} color={theme.text.black800}>
      <MainContent>
        <Flex textAlign={"center"} flexDir={"column"} gap={"8.3vw"}>
          {dataList.map((item) => (
            <Box key={item.title}>
              <Text textType="title32" mb={"24px"} fontSize={!isLargerThan768 ? "20px" : "32px"}>
                {item.title}
              </Text>
              <Text fontSize={isLargerThan768 ? "24px" : "16px"}>{item.text}</Text>
              <Box pt={"6.66vw"}>
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"stretch"}
                  gap={isLargerThan768 ? "3.7%" : "24px"}
                  flexDir={!isLargerThan768 ? "column" : undefined}
                >
                  {/* {item.list.map((i, idx) => (
                    <Box flex={1} key={i.title_item}>
                      {isLargerThan768 ? (
                        <FadeInSection triggerClass="flipLeftTopTrigger" animationClass="flipLeftTop" threshold={0.1 + idx * 0.3}>
                          <Box border={`2px solid ${theme.primary.green500}`} p={"2.22vw 2.77vw"} height={"100%"} textAlign={"left"} bgColor={theme.text.white} borderRadius={"24px"}>
                            <Text color={theme.primary.green900} textType="title32" mb={"2.22vw"} fontSize={"32px"}>{`STEP ${idx + 1}`}</Text>
                            <Text color={theme.text.black800} fontSize={"28px"} fontWeight={700} mb={"1.11vw"}>
                              {i.title_item}
                            </Text>
                            <Text fontWeight={300} color={theme.text.black800} lineHeight={1.75}>
                              {i.text_item}
                            </Text>
                          </Box>
                        </FadeInSection>
                      ) : (
                        <Fade duration={1500} fraction={0.4}>
                          <Box border={`2px solid ${theme.primary.green500}`} p={"24px"} textAlign={"left"} bgColor={theme.text.white} borderRadius={"24px"}>
                            <Text color={theme.primary.green900} textType="title32" mb={"12px"} fontSize={"24px"}>{`STEP ${idx + 1}`}</Text>
                            <Text color={theme.text.black800} fontSize={"24px"} fontWeight={700} mb={"16px"}>
                              {i.title_item}
                            </Text>
                            <Text fontWeight={300} color={theme.text.black800} lineHeight={1.75}>
                              {i.text_item}
                            </Text>
                          </Box>
                        </Fade>
                      )}
                    </Box>
                  ))} */}
                  {isLargerThan768
                    ? item.list.map((i, idx) => (
                        <Box key={i.title_item} flex={1}>
                          <Fade
                            duration={2000}
                            delay={idx * 1000}
                            style={{ height: "100%", flex: 1 }}
                          >
                            <Box
                              border={`2px solid ${theme.primary.green500}`}
                              p={"2.22vw 2.77vw"}
                              height={"100%"}
                              textAlign={"left"}
                              bgColor={theme.text.white}
                              borderRadius={"24px"}
                            >
                              <Text
                                color={theme.primary.green900}
                                textType="title32"
                                mb={"2.22vw"}
                                fontSize={"32px"}
                              >{`STEP ${idx + 1}`}</Text>
                              <Text
                                color={theme.text.black800}
                                fontSize={"28px"}
                                fontWeight={700}
                                mb={"1.11vw"}
                              >
                                {i.title_item}
                              </Text>
                              <Text fontWeight={300} color={theme.text.black800} lineHeight={1.75}>
                                {i.text_item}
                              </Text>
                            </Box>{" "}
                          </Fade>
                        </Box>
                      ))
                    : item.list.map((i, idx) => (
                        <Fade duration={1500} fraction={0.4} key={i.title_item}>
                          <Box
                            border={`2px solid ${theme.primary.green500}`}
                            p={"24px"}
                            textAlign={"left"}
                            bgColor={theme.text.white}
                            borderRadius={"24px"}
                          >
                            <Text
                              color={theme.primary.green900}
                              textType="title32"
                              mb={"12px"}
                              fontSize={"24px"}
                            >{`STEP ${idx + 1}`}</Text>
                            <Text
                              color={theme.text.black800}
                              fontSize={"24px"}
                              fontWeight={700}
                              mb={"16px"}
                            >
                              {i.title_item}
                            </Text>
                            <Text fontWeight={300} color={theme.text.black800} lineHeight={1.75}>
                              {i.text_item}
                            </Text>
                          </Box>
                        </Fade>
                      ))}
                </Flex>
              </Box>
            </Box>
          ))}
        </Flex>
      </MainContent>
    </Box>
  );
};

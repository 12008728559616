import React from "react";
import { Box, Flex, Divider } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Text, CulteeIcon } from "@/components";
import theme from "@/shared/theme";

export const AuthBackground: React.FC = () => {
  const { t } = useTranslation(["signup"]);

  return (
    <Flex
      w="50%"
      minW="704px"
      h="100vh"
      bgImage="/assets/authBackground.svg"
      bgRepeat="no-repeat"
      bgSize="100% 100vh"
      color={theme.text.white}
      alignItems="center"
    >
      <Box w="590px" m="0 auto" textAlign="center" pt="27px">
        <CulteeIcon w="410px" h="80px" />
        <Box m="60px 0 64px">
          <Text textType="title24">{t("websiteIntroduction1")}</Text>
          <Text textType="title24">{t("websiteIntroduction2")}</Text>
        </Box>
        <Divider h="1px" w="100%" bgColor={theme.text.white} mb="24px" />
        <Text textType="title20">{t("projectIntroductionTitle")}</Text>
        <Flex p="24px 88px 0" justifyContent="space-between">
          <Flex
            w="195px"
            h="80px"
            borderRadius="5px"
            bgColor={theme.text.white}
            alignItems="center"
            justifyContent="center"
            p="16px 26px"
          >
            <Text textType="body16m" color={theme.service.turquoiseText}>
              {t("projectIntroduction1")}
            </Text>
          </Flex>
          <Flex
            w="195px"
            h="80px"
            borderRadius="5px"
            bgColor={theme.text.white}
            alignItems="center"
            justifyContent="center"
            p="16px 41px"
          >
            <Text textType="body16m" color={theme.service.turquoiseText}>
              {t("projectIntroduction2")}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

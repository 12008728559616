import { createIcon } from "@chakra-ui/icons";

export const MemberIcon = createIcon({
  displayName: "MemberIcon",
  viewBox: "0 0 22 20",
  path: (
    <g fill="none">
      <path
        d="M14.4788 5.15625C14.4788 5.07031 14.403 5 14.3105 5H6.2301C6.13751 5 6.06176 5.07031 6.06176 5.15625V6.09375C6.06176 6.17969 6.13751 6.25 6.2301 6.25H14.3105C14.403 6.25 14.4788 6.17969 14.4788 6.09375V5.15625ZM14.3105 7.8125H6.2301C6.13751 7.8125 6.06176 7.88281 6.06176 7.96875V8.90625C6.06176 8.99219 6.13751 9.0625 6.2301 9.0625H14.3105C14.403 9.0625 14.4788 8.99219 14.4788 8.90625V7.96875C14.4788 7.88281 14.403 7.8125 14.3105 7.8125ZM10.1019 10.625H6.2301C6.13751 10.625 6.06176 10.6953 6.06176 10.7812V11.7188C6.06176 11.8047 6.13751 11.875 6.2301 11.875H10.1019C10.1945 11.875 10.2703 11.8047 10.2703 11.7188V10.7812C10.2703 10.6953 10.1945 10.625 10.1019 10.625ZM9.09189 16.6406H4.37835V2.89062H16.1622V9.60938C16.1622 9.69531 16.238 9.76562 16.3305 9.76562H17.5089C17.6015 9.76562 17.6773 9.69531 17.6773 9.60938V2.10938C17.6773 1.76367 17.3764 1.48438 17.0039 1.48438H3.53664C3.16419 1.48438 2.86328 1.76367 2.86328 2.10938V17.4219C2.86328 17.7676 3.16419 18.0469 3.53664 18.0469H9.09189C9.18448 18.0469 9.26023 17.9766 9.26023 17.8906V16.7969C9.26023 16.7109 9.18448 16.6406 9.09189 16.6406ZM16.5999 15.1875C17.2101 14.6738 17.5931 13.9316 17.5931 13.1055C17.5931 11.5527 16.2359 10.293 14.563 10.293C12.8901 10.293 11.5328 11.5527 11.5328 13.1055C11.5328 13.9316 11.9158 14.6738 12.526 15.1875C11.3266 15.8223 10.5017 16.998 10.4386 18.3535C10.4344 18.4414 10.5123 18.5156 10.607 18.5156H11.6191C11.7075 18.5156 11.7811 18.4512 11.7874 18.3672C11.8695 17.0156 13.0837 15.9375 14.563 15.9375C16.0423 15.9375 17.2564 17.0156 17.3385 18.3672C17.3427 18.4492 17.4163 18.5156 17.5068 18.5156H18.519C18.6158 18.5156 18.6915 18.4414 18.6873 18.3535C18.6263 16.9961 17.7993 15.8223 16.5999 15.1875ZM14.563 11.543C15.493 11.543 16.2464 12.2422 16.2464 13.1055C16.2464 13.9688 15.493 14.668 14.563 14.668C13.6329 14.668 12.8796 13.9688 12.8796 13.1055C12.8796 12.2422 13.6329 11.543 14.563 11.543Z"
        fill="currentColor"
      />
    </g>
  )
});

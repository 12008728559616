import React from "react";
import { useGetRecentFeelingQuery } from "@/apollo/generated";
import { Box, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Text, Avatar, Loading } from "@/components";
import theme from "@/shared/theme";
import { nonEmpty } from "@/utils/nonEmpty";
import { useNavigate } from "react-router-dom";
import { UserStatus } from "@/apollo/generated";

export const RecentFeeling: React.FC = () => {
  const { t } = useTranslation(["home"]);
  const navigate = useNavigate();
  const { data, loading } = useGetRecentFeelingQuery();

  const userRecentFeeling = nonEmpty(data?.myTeam.users?.nodes).filter(
    (u) => u.status === UserStatus.Activated
  );

  if (loading) return <Loading />;

  if (!userRecentFeeling.length)
    return (
      <>
        <Text textType="title20">{t("recentFeelingTitle")}</Text>
        <Text>{t("emptyMsg")}</Text>
      </>
    );

  return (
    <Box flexWrap="wrap">
      <Text textType="title24">{t("recentFeelingTitle")}</Text>
      <Flex flexWrap="wrap">
        {userRecentFeeling?.map((u) => (
          <Box
            key={u?.id}
            w={{ base: "115px", "2xl": "135px" }}
            m="12px 0"
            mr={{ base: "20px", "2xl": "39px" }}
            cursor="pointer"
            onClick={() =>
              navigate("/journalings", {
                state: {
                  userId: u.id
                }
              })
            }
          >
            <Flex alignItems="center">
              <Avatar src={u?.avatarUrl ?? ""} size="xs" mr="8px" />
              <Text textType="body12b" _hover={{ color: theme.service.turquoise400 }}>
                {u?.familyName + " " + u?.givenName}
              </Text>
            </Flex>
            <Box>
              {u?.recentFeeling &&
                Object.entries(u?.recentFeeling)
                  .map((r) => Object.assign(r, { r: +r[0] }))
                  .map((rItem, idx) => (
                    <Flex
                      key={idx}
                      w="115px"
                      h="57.5px"
                      borderRadius="4px"
                      bgImage={`/assets/${rItem[0]}.svg`}
                      bgSize="auto auto"
                      bgPos="46% 34%"
                      bgRepeat="no-repeat"
                      alignItems="center"
                      justifyContent="center"
                      mt="8px"
                    >
                      <Text color={theme.text.white} textType="body16b">
                        {t(`emotions.${rItem[0]}`, { ns: "common" }) +
                          " " +
                          Number(rItem[1]).toFixed(2)}
                      </Text>
                    </Flex>
                  ))}
            </Box>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

import React, { useCallback } from "react";
import { Flex, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { CulteeIcon, Text, Avatar } from "@/components";
import theme from "@/shared/theme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUserLogoutMutation } from "@/apollo/generated";
import { useAuthHeaderContext } from "@/hooks/useAuthHeaderContext";
import { useGetProfileQuery } from "@/apollo/generated";

export const UserHeader: React.FC = () => {
  const { removeAuthHeader } = useAuthHeaderContext();
  const { t } = useTranslation(["common"]);
  const { data } = useGetProfileQuery();
  const [userLogoutMutation] = useUserLogoutMutation();
  const navigate = useNavigate();

  const userName =
    data?.me.familyName && data?.me.givenName ? data?.me.familyName + " " + data?.me.givenName : "";
  const avatarUrl = data?.me.avatarUrl;

  const userLogout = useCallback(() => {
    userLogoutMutation({ variables: { input: {} } }).then(() => {
      removeAuthHeader();
      navigate("/auth/signin");
    });
  }, [userLogoutMutation, removeAuthHeader, navigate]);

  return (
    <>
      <Flex
        h="48px"
        p="8px 26px"
        justifyContent="space-between"
        borderBottom={`1px solid ${theme.text.black200}`}
        bgColor={theme.text.white}
        zIndex={9999}
        w="100%"
        pos="fixed"
        top="0"
        left="0"
      >
        <CulteeIcon
          w="116px"
          h="30px"
          color={theme.service.turquoiseText}
          cursor="pointer"
          onClick={() => navigate("/")}
        />
        <Flex>
          <Menu>
            <MenuButton>
              <Flex
                minW="144px"
                h="32px"
                border={`1px solid ${theme.text.black200}`}
                borderRadius="4px"
                p="4px 16px"
                justifyContent="space-between"
                alignItems="center"
                gap="16px"
              >
                <Text textType="body14b" color={theme.text.black900}>
                  {userName}
                </Text>
                <Avatar size="xs" src={avatarUrl ?? ""} />
              </Flex>
            </MenuButton>
            <MenuList
              mt="1px"
              ml="16px"
              h="120px"
              p="10px 0"
              minW="0"
              w="128px"
              boxShadow="0px 0px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
            >
              <MenuItem onClick={() => navigate("/user/profile")}>
                <Text fontSize="14px" fontWeight="600" color={theme.text.black700}>
                  {t("userHeader.profileSetting")}
                </Text>
              </MenuItem>
              <MenuItem onClick={() => navigate("/user/login-information")}>
                <Text fontSize="14px" fontWeight="600" color={theme.text.black700}>
                  {t("userHeader.loginInformation")}
                </Text>
              </MenuItem>
              <MenuItem onClick={userLogout}>
                <Text fontSize="14px" fontWeight="600" color={theme.text.black700}>
                  {t("userHeader.logout")}
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </>
  );
};

import { MainContent, TextTitle, Text } from "@/components";
import theme from "@/shared/theme";
import { Box, Center, CircularProgress, Flex, useMediaQuery } from "@chakra-ui/react";
import React, { useState } from "react";
import { CustomLineBox } from "./CustomLineBox";
import { TimeBadgeLineGroup } from "./TimeBadgeLineGroup";
import { TimeBadgeGroup } from "./TimeBadgeGroup";

export const CustomerCase: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isLargerThan1200] = useMediaQuery("(min-width: 1200px)");
  const [isLargerThan956] = useMediaQuery("(min-width: 956px)");
  const [isLargerThan823] = useMediaQuery("(min-width: 823px)");
  const [isLargerThan1013] = useMediaQuery("(min-width: 1013px)");

  const comments: {
    id: string;
    title: string;
    content: { id: string; text: string }[];
  }[] = [
    {
      id: "1",
      title: "目線を高める視点と選択への気づき（行動変容）",
      content: [
        { id: "1", text: "明確な答えがなく全て手探りで不安だったが、目線が高い考え方を聞いて自身に活かすことができた。" },
        { id: "2", text: "やらないことを決める”ことが難しいと思っていたが、自分にはない意見や考え方を取り入れられた。" }
      ]
    },
    {
      id: "2",
      title: "中堅社員同士の絆の深化（心理的安全性）",
      content: [
        { id: "1", text: "自分は合っているのか自信がなかったが、お互いの悩みやストレスが共感でき、1人じゃないと思えるようになった。" },
        { id: "2", text: "プログラムを通して、仲間が増えた。" }
      ]
    },
    {
      id: "3",
      title: "チーム・組織として変化していく意識の向上（変革意識）",
      content: [
        { id: "1", text: "「こんな組織にしたい」と思っても、具体化する方法がわからなかったが、アドバイスをもらえるので意欲がわいてきた。" },
        { id: "2", text: "モチベーションが保てず活動が長続きしないことも多かったが、定期的に見直すことで活動を忘れないようにできた。" }
      ]
    },
    {
      id: "4",
      title: "新たな気づき（自己認識）",
      content: [{ id: "1", text: "組織を変えることの難しさだけでなく、己の事も良く分かってないということに気づけた。" }]
    }
  ];

  const comments2: {
    id: string;
    title: string;
    content: { id: string; text: string }[];
  }[] = [
    {
      id: "1",
      title: "相手の声を聴きだす姿勢の向上（傾聴力）",
      content: [{ id: "1", text: "対話・相互コーチングに取り組むなかで「質問」の仕方にも色々バリエーションがあることを学び、「質問」をうまく活用し、聴く時間を長くとれるようになった。" }]
    },
    {
      id: "2",
      title: "自ら働きかけようとする動き（主体性）",
      content: [
        { id: "1", text: "各リーダーの思考や取り組み例を参考に、自身からの積極的な発信・働きかけ・問いかけすることで議論・取り組みを進めることができた。" },
        { id: "2", text: "受動的な姿勢から、わずかではあるが能動的になれた。" },
        { id: "3", text: "こちら（リーダー）からの発信等に対しメンバーが回答・行動を起こしてくれる場面が増えたように思える。" },
        { id: "4", text: "目標達成に向け議論を重ね互いに協力し合いながら目標達成に向け進めることができた。" }
      ]
    },
    {
      id: "3",
      title: "チーム・組織として変化していく意識が芽生えた（変革意識）",
      content: [
        { id: "1", text: "一人で悩まずチームで悩み、チームの中で醸成される風土の中で目標に向かう姿勢へと変わった。" },
        { id: "2", text: "他施策の現状や課題を知ったことで、自身の重点施策だけでなく、事業部全体で何を優先して取り組むかを考えるようになった。" }
      ]
    },
    {
      id: "4",
      title: "メンバー間で安心感・信頼感が生まれた（心理的安全性）",
      content: [
        { id: "1", text: "過去の自身の経験に捕らわれず、メンバーが主体的に行動したことや、メンバー同士で合意形成したことを尊重するようになった。" },
        { id: "2", text: "やらされ感はなく、こうやりたい（だから行動した！）が多くなった。" }
      ]
    }
  ];

  const circles: { id: string; text1: string; text2: string; text3: string; percentage: number }[] = [
    { id: "1", text1: "プログラムを通じた", text2: "意識・思考", text3: "の変化あり", percentage: 88.9 },
    { id: "2", text1: "プログラムを通じた", text2: "行動", text3: "の変化あり", percentage: 88.9 }
  ];

  const tableTitles: { id: string; title: string; width: string }[] = [
    { id: "1", title: "内容", width: "175px" },
    { id: "2", title: "日数", width: "60px" },
    { id: "3", title: "1ヶ月目", width: "138.4px" },
    { id: "4", title: "2ヶ月目", width: "138.4px" },
    { id: "5", title: "3ヶ月目", width: "138.4px" },
    { id: "6", title: "4ヶ月目", width: "138.4px" },
    { id: "7", title: "5ヶ月目", width: "138.4px" }
  ];

  const tableTitles2: { id: string; title: string; width: string }[] = [
    { id: "1", title: "内容", width: "175px" },
    { id: "2", title: "日数", width: "60px" },
    { id: "3", title: "1ヶ月目", width: "96.57px" },
    { id: "4", title: "2ヶ月目", width: "96.57px" },
    { id: "5", title: "3ヶ月目", width: "96.57px" },
    { id: "6", title: "4ヶ月目", width: "96.57px" },
    { id: "7", title: "5ヶ月目", width: "96.57px" },
    { id: "8", title: "6ヶ月目", width: "96.57px" },
    { id: "9", title: "7ヶ月目", width: "96.57px" }
  ];

  const tableData: { title: string; description: string; schedule: string }[] = [
    {
      title: "DX施策の具体化",
      description: "マネージャー・リーダーとしてのチームへの向き合い方の方針をつくる",
      schedule: "1回4h"
    },
    {
      title: "対話・相互コーチング",
      description: "チームの状況と自信を内省し、アクションを考える",
      schedule: "4回各2h"
    },
    {
      title: "Culteeを活用したリーダー同士のリフレクション",
      description: "",
      schedule: "月1記入"
    },
    {
      title: "振り返り会",
      description: "プロジェクトとチーム・自身の振り返りを行う",
      schedule: "1回4h"
    },
    {
      title: "ティーチング",
      description: "他社実践者から知見を得る",
      schedule: "4回各2h"
    }
  ];

  const tableData2: { title: string; description: string; schedule: string }[] = [
    {
      title: "メインプログラム",
      description: "自身のありたい姿・キャリアとチームの成長課題を考える",
      schedule: "2日間"
    },
    {
      title: "自走プログラム",
      description: "チームの状況と自身を内省し、活動計画の実行と振り返りを行う",
      schedule: "6回\n各1.5h"
    },
    {
      title: "Culteeを活用した\nリフレクションと上司との対話・現場実践",
      description: "",
      schedule: "月1-2回"
    }
  ];

  return (
    <MainContent>
      <Flex flexDirection="column" p={isLargerThan768 ? "120px 0" : "64px 0"} w="100%" id="customer-case">
        <TextTitle text="お客様事例" color={theme.text.black800} />
        <Text fontSize={isLargerThan768 ? "20px" : "14px"} fontWeight={400} lineHeight="35px" color={theme.text.black800} m={isLargerThan768 ? "120px 0" : "48px 0"} textAlign="center">
          様々なお客様に、Culteeのプロトタイプを用いた変革プロジェクトのご支援をさせていただきました。
        </Text>
        <Box pos="relative" w="100%">
          <Flex flexDirection="row" justify="center" alignItems="end">
            <Box
              w="5.185%"
              borderTop="2px solid #18A3A7"
              borderLeft="2px solid #18A3A7"
              borderTopLeftRadius={isLargerThan768 ? "24px" : "8px"}
              h={isLargerThan768 ? "79px" : "48px"}
              position="absolute"
              left="0"
              top={isLargerThan768 ? (isLargerThan956 ? "228px" : "264px") : "169px"}
            />
            <Box
              w="42.59%"
              p={isLargerThan768 ? (isLargerThan823 ? (isLargerThan1013 ? "50px 40px 44px" : "50px 32px 44px") : "50px 28px 44px") : "20px 12px"}
              h={isLargerThan768 ? "auto" : "171px"}
              onClick={() => setActiveTab(0)}
              sx={{
                cursor: "pointer",
                bg: activeTab === 0 ? theme.text.white : theme.gary.gray100,
                borderTopRadius: isLargerThan768 ? "24px" : "8px",
                border: activeTab === 0 ? "2px solid #18A3A7" : "auto",
                borderBottom: activeTab === 0 ? "none" : "2px solid #18A3A7"
              }}
            >
              <Center
                w={isLargerThan768 ? "107px" : "66px"}
                p={isLargerThan768 ? "4px 24px" : "2px 12px"}
                bg="#0B8487"
                borderRadius="24px"
                m="0 auto"
                fontSize={isLargerThan768 ? "20px" : "14px"}
                lineHeight={isLargerThan768 ? "30px" : "21px"}
                fontWeight={400}
                color={theme.text.white}
              >
                事例 1
              </Center>
              <Text mt={isLargerThan768 ? "24px" : "12px"} textAlign="center" fontWeight={700} fontSize={isLargerThan768 ? "24px" : "16px"} lineHeight={isLargerThan768 ? "36px" : "24px"} color="#1A1A1A">
                DX推進のためのリーダー向け
                <br />
                組織力強化プログラム
              </Text>
            </Box>
            <Box w="4.444%" bg="#18A3A7" h="2px" />
            <Box
              w="42.59%"
              p={isLargerThan768 ? (isLargerThan823 ? (isLargerThan1013 ? "50px 40px 44px" : "50px 32px 44px") : "50px 28px 44px") : "20px 12px"}
              h={isLargerThan768 ? (isLargerThan956 ? "auto" : "266px") : "171px"}
              onClick={() => setActiveTab(1)}
              sx={{
                cursor: "pointer",
                bg: activeTab === 1 ? theme.text.white : theme.gary.gray100,
                borderTopRadius: isLargerThan768 ? "24px" : "8px",
                border: activeTab === 1 ? "2px solid #18A3A7" : "auto",
                borderBottom: activeTab === 1 ? "none" : "2px solid #18A3A7"
              }}
            >
              <Center
                w={isLargerThan768 ? "107px" : "66px"}
                p={isLargerThan768 ? "4px 24px" : "2px 12px"}
                bg="#0B8487"
                borderRadius="24px"
                m="0 auto"
                fontSize={isLargerThan768 ? "20px" : "14px"}
                lineHeight={isLargerThan768 ? "30px" : "21px"}
                fontWeight={400}
                color={theme.text.white}
              >
                事例 2
              </Center>
              <Text mt={isLargerThan768 ? "24px" : "12px"} textAlign="center" fontWeight={700} fontSize={isLargerThan768 ? "24px" : "16px"} lineHeight={isLargerThan768 ? "36px" : "24px"} color="#1A1A1A">
                次世代リーダーシップ
                <br />
                育成プログラム
              </Text>
            </Box>
            <Box
              w="5.185%"
              borderTop="2px solid #18A3A7"
              borderRight="2px solid #18A3A7"
              borderTopRightRadius={isLargerThan768 ? "24px" : "8px"}
              h={isLargerThan768 ? "79px" : "48px"}
              position="absolute"
              right="0"
              top={isLargerThan768 ? (isLargerThan956 ? "228px" : "264px") : "169px"}
            />
          </Flex>
        </Box>
        <Flex
          flexDirection="column"
          gap={isLargerThan768 ? "64px" : "48px"}
          p={isLargerThan768 ? "0 5.185% 108px" : "0 5.185% 48px"}
          w="100%"
          borderBottomRadius={isLargerThan768 ? "24px" : "8px"}
          border="2px solid #18A3A7"
          borderTop="none"
          mt={isLargerThan768 ? "77px" : "46px"}
        >
          <Text whiteSpace="pre-wrap" textType={isLargerThan768 ? "title24" : "body14b"} color="#1A1A1A" lineHeight={isLargerThan768 ? "42px" : "24.5px"}>
            {activeTab === 0
              ? "「リーダー層の主体性向上と、次年度のアクション策定につながりました」\n見えていなかったリアルな”社内の声”を可視化し、組織で問題意識を共有"
              : "「1人じゃないと思えるようになり、リーダーシップへの意識も高まりました」\n感情に着目したリフレクションで生まれた中堅社員同士の絆"}
          </Text>
          <Flex flexDirection={isLargerThan768 ? "row" : "column"}>
            <Flex
              p={isLargerThan768 ? "16px" : "8px"}
              borderRadius={isLargerThan768 ? "4px" : "2px"}
              bg="#0B8487"
              w={isLargerThan768 ? "16.6%" : "100%"}
              mr={isLargerThan768 ? "4.149%" : 0}
              minW={isLargerThan768 ? "16.6%" : "100%"}
              justify="center"
              align="center"
            >
              <Text textType={isLargerThan768 ? "title20" : "body16b"} lineHeight={isLargerThan768 ? "35px" : "24px"} color={theme.text.white} textAlign="center">
                対象
              </Text>
            </Flex>
            <Flex align="center" mt={isLargerThan768 ? 0 : "16px"}>
              <Text fontSize={isLargerThan768 ? "20px" : "14px"} fontWeight={500} lineHeight={isLargerThan768 ? "30px" : "21px"} color="#1A1A1A">
                {activeTab === 0 ? "管理職・リーダークラス　約10名（サービス業）" : "次期リーダー候補となる中堅社員　約15名（情報通信業）"}
              </Text>
            </Flex>
          </Flex>
          <Flex flexDirection={isLargerThan768 ? "row" : "column"}>
            <Flex
              p={isLargerThan768 ? "16px" : "8px"}
              borderRadius={isLargerThan768 ? "4px" : "2px"}
              bg="#0B8487"
              w={isLargerThan768 ? "16.6%" : "100%"}
              minW={isLargerThan768 ? "16.6%" : "100%"}
              mr={isLargerThan768 ? "4.149%" : 0}
              justify="center"
              align="center"
            >
              <Text textType={isLargerThan768 ? "title20" : "body16b"} lineHeight={isLargerThan768 ? "35px" : "24px"} color={theme.text.white} textAlign="center">
                ゴール設定
              </Text>
            </Flex>
            <Flex
              flexDirection="column"
              mt={isLargerThan768 ? 0 : "16px"}
              gap={isLargerThan768 ? "16px" : "12px"}
              fontSize={isLargerThan768 ? "20px" : "14px"}
              fontWeight={500}
              lineHeight={isLargerThan768 ? "30px" : "21px"}
              color="#1A1A1A"
            >
              <Text>{activeTab === 0 ? "1.DX実現に向けてリーダーが主体的にチームの理想形を掲げられる" : "1.事業部変革をリードする存在としての意識・行動変革"}</Text>
              <Text>{activeTab === 0 ? "2.社内の「タテ」＆「ヨコ」の連携を強め、組織力を底上げ" : "2.思いと役割を自分ごととして表現できる自分らしいリーダーシップの発揮"}</Text>
              <Text>{activeTab === 0 ? "3.「個人」と「組織」の双方が意識変革・行動変容しつづけられる組織文化の醸成" : "3.中堅社員同士で励まし合える関係性の構築"}</Text>
            </Flex>
          </Flex>
          <Box>
            <Flex
              p={isLargerThan768 ? "16px" : "8px"}
              borderRadius={isLargerThan768 ? "4px" : "2px"}
              bg="#0B8487"
              w={isLargerThan768 ? "16.6%" : "100%"}
              minW={isLargerThan768 ? "16.6%" : "100%"}
              justify="center"
              align="center"
            >
              <Text textType={isLargerThan768 ? "title20" : "body16b"} lineHeight={isLargerThan768 ? "35px" : "24px"} color={theme.text.white} textAlign="center">
                スケジュール
              </Text>
            </Flex>
            {activeTab === 0 ? (
              <Flex overflowX={isLargerThan1200 ? undefined : "auto"} mt={isLargerThan768 ? "40px" : "16px"} maxW="975px" flexDirection="column">
                <Flex gap="8px" mb="17px">
                  {tableTitles.map((item, index) => (
                    <Box h="37px" key={index} w={item.width} minW={item.width} textAlign="center" bg="#5D5D5D" p="8px 12px" color={theme.text.white} fontSize="14px" fontWeight={700}>
                      {item.title}
                    </Box>
                  ))}
                </Flex>
                <Flex flexDirection="column" gap="8px" pos={"relative"}>
                  {tableData.map((item, index) => (
                    <Flex key={index} gap="8px">
                      <Flex
                        flexDirection="column"
                        justify="center"
                        minW="175.32px"
                        w="175.32px"
                        h={index === 2 ? "120px" : "88px"}
                        bg={index === 1 || index === 2 ? "#0B8487" : "#E7EDEE"}
                        p="4.5px 12px"
                        color={index === 1 || index === 2 ? theme.text.white : "#1A1A1A"}
                      >
                        <Text fontSize="14px" fontWeight={700} mb="4px">
                          {item.title}
                        </Text>
                        <Text fontSize="12px" fontWeight={500}>
                          {item.description}
                        </Text>
                      </Flex>
                      <Box w="60px" minW="60px" fontSize="12px" fontWeight={500} alignContent="center" color="#1A1A1A" p="8px 4px" bg={index === 1 || index === 2 ? "#CEE6E7" : "#E7EDEE"}>
                        {item.schedule}
                      </Box>
                      {Array.from(Array(5), (_, index2) => (
                        <Flex gap="3px" key={index2}>
                          {Array.from(Array(4), (_, idx) => (
                            <Flex key={idx} w="32.29px" bg={index === 1 || index === 2 ? "#CEE6E7" : "#E7EDEE"} alignItems={"center"} justifyContent={"center"} pos={"relative"}>
                              {index + index2 + idx === 0 && (
                                <>
                                  <CustomLineBox type="topToBottom" w={"89.5px"} height={"87px"} isFirstStep />
                                  <Box w="20px" h="20px" bg="#4E666A" borderRadius="50px" />
                                </>
                              )}
                              {index === 2 && index2 !== 4 && idx === 2 && (
                                <>
                                  <CustomLineBox type="bottomToTop" w={"42px"} height={"79.5px"} />
                                  <Box w="20px" h="20px" bg="#0B8487" borderRadius="50px" m="-30px auto 0" />
                                </>
                              )}
                              {index === 3 && index2 === 4 && idx === 2 && <Box w="20px" h="20px" bg="#4E666A" borderRadius="50px" />}
                            </Flex>
                          ))}
                        </Flex>
                      ))}
                    </Flex>
                  ))}
                  <Box bgColor={theme.primary.green900} p={"10px 12px"} pos={"absolute"} left={"290px"} top="264px" zIndex={99} whiteSpace="nowrap">
                    <Text color={theme.text.white} fontSize={"12px"} fontWeight={700}>
                      1ヶ月の感情と状況の振り返りをCulteeを使ってリーダー・メンバーが行い、対話の場で次月のアクションを決める
                    </Text>
                  </Box>
                  <TimeBadgeLineGroup />
                  <TimeBadgeGroup />
                </Flex>
              </Flex>
            ) : (
              <Flex overflowX={isLargerThan1200 ? undefined : "auto"} mt="40px" maxW="975px" flexDirection="column">
                <Flex gap="8px" mb="17px">
                  {tableTitles2.map((item, index) => (
                    <Box h="37px" key={index} w={item.width} minW={item.width} textAlign="center" bg="#5D5D5D" p="8px 12px" color={theme.text.white} fontSize="14px" fontWeight={700}>
                      {item.title}
                    </Box>
                  ))}
                </Flex>
                <Flex flexDirection="column" gap="8px">
                  {tableData2.map((item, index) => (
                    <Flex key={index} gap="8px">
                      <Flex flexDirection="column" justify="center" minW="175.32px" w="175.32px" h="88px" bg={index === 2 ? "#0B8487" : "#E7EDEE"} p="4.5px 12px" color={index === 2 ? theme.text.white : "#1A1A1A"}>
                        <Text fontSize="14px" fontWeight={700} mb="4px" whiteSpace="pre-wrap">
                          {item.title}
                        </Text>
                        <Text fontSize="12px" fontWeight={500}>
                          {item.description}
                        </Text>
                      </Flex>
                      <Box w="60px" whiteSpace="pre-wrap" minW="60px" fontSize="12px" fontWeight={500} alignContent="center" color="#1A1A1A" p="8px 4px" bg={index === 2 ? "#CEE6E7" : "#E7EDEE"}>
                        {item.schedule}
                      </Box>
                      {Array.from(Array(7), (_, index2) => (
                        <Box key={index2} w="96.57px" minW="96.57px" bg={index === 2 ? "#CEE6E7" : "#E7EDEE"} alignContent="center" pos={"relative"}>
                          {index === 0 && index2 === 0 && (
                            <Flex ml="13px">
                              <Box w="55px" pos={"relative"} bg="#4E666A" h="34px" fontSize="12px" fontWeight={700} color={theme.text.white} alignContent="center" pl="16px">
                                0回目
                                <CustomLineBox type="topToBottom" w={"75px"} height={"80px"} />
                              </Box>
                              <Box w="0" h="0" border="17px solid transparent" borderLeftColor="#4E666A" />
                            </Flex>
                          )}
                          {index === 1 && index2 !== 0 && (
                            <>
                              <Flex ml="13px">
                                <Box w="55px" bg="#4E666A" h="34px" fontSize="12px" fontWeight={700} color={theme.text.white} alignContent="center" pl="16px">
                                  {index2}回目
                                </Box>
                                <Box w="0" h="0" border="17px solid transparent" borderLeftColor="#4E666A" />
                              </Flex>
                            </>
                          )}
                          {index === 1 && index2 !== 0 && index2 !== 6 && <CustomLineBox type="left" />}
                          {index === 2 && index2 !== 6 && <Box w="20px" h="20px" bg="#0B8487" borderRadius="50px" m="0 auto" />}
                        </Box>
                      ))}
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            )}
          </Box>
          <Box>
            <Flex
              p={isLargerThan768 ? "16px" : "8px"}
              borderRadius={isLargerThan768 ? "4px" : "2px"}
              bg="#0B8487"
              w={isLargerThan768 ? "16.6%" : "100%"}
              minW={isLargerThan768 ? "16.6%" : "100%"}
              justify="center"
              align="center"
            >
              <Text textType={isLargerThan768 ? "title20" : "body16b"} lineHeight={isLargerThan768 ? "35px" : "24px"} color={theme.text.white} textAlign="center">
                成果
              </Text>
            </Flex>
            {activeTab === 0 ? (
              <Flex gap={isLargerThan768 ? "4.923%" : 0} mt={isLargerThan768 ? "40px" : "16px"} flexDirection={isLargerThan768 ? "row" : "column"}>
                <Flex flexDirection="column" gap={isLargerThan768 ? "61px" : 0} w={isLargerThan768 ? "32.513%" : "100%"} minW={isLargerThan768 ? "317px" : 0}>
                  <Box
                    textAlign="center"
                    color="#0B8487"
                    border={isLargerThan768 ? "2px solid #18A3A7" : "1px solid #18A3A7"}
                    p={isLargerThan768 ? "16px" : "6px 8px"}
                    borderRadius={isLargerThan768 ? "4px" : "2px"}
                    fontSize={isLargerThan768 ? "18px" : "16px"}
                    fontWeight={700}
                    mb={isLargerThan768 ? 0 : "18px"}
                  >
                    アンケート結果
                  </Box>
                  {circles.map((item) => (
                    <Box
                      w={isLargerThan768 ? "280px" : "188px"}
                      h={isLargerThan768 ? "280px" : "188px"}
                      pos="relative"
                      key={item.id}
                      m={isLargerThan768 ? "" : "0 auto"}
                      mb={!isLargerThan768 && item.id === "1" ? "24px" : 0}
                    >
                      <CircularProgress value={item.percentage} size={isLargerThan768 ? "280px" : "188px"} m="0 auto" color="#0B8487" trackColor="#E7EDEE" />
                      <Flex
                        flexDirection="column"
                        pos="absolute"
                        top={isLargerThan768 ? "80px" : "60.89px"}
                        left={isLargerThan768 ? "57px" : "33.5px"}
                        w={isLargerThan768 ? "165px" : "123px"}
                        h={isLargerThan768 ? "162px" : "78px"}
                        align="center"
                      >
                        <Text fontWeight={700} fontSize={isLargerThan768 ? "16px" : "12px"} lineHeight={isLargerThan768 ? "24px" : "18px"} color="#1A1A1A">
                          {item.text1}
                        </Text>
                        <Flex fontWeight={700} fontSize={isLargerThan768 ? "16px" : "12px"} lineHeight={isLargerThan768 ? "24px" : "18px"} justify="center">
                          <Text color="#0B8487">{item.text2}</Text>
                          <Text color="#1A1A1A">{item.text3}</Text>
                        </Flex>
                        <Flex align="end">
                          <Text fontWeight={900} fontSize={isLargerThan768 ? "48px" : "28px"} color="#1A1A1A">
                            {item.percentage}
                          </Text>
                          <Text fontWeight={500} fontSize={isLargerThan768 ? "16px" : "11px"} color="#1A1A1A" lineHeight="24px" mb={isLargerThan768 ? "10px" : "5px"}>
                            %
                          </Text>
                        </Flex>
                      </Flex>
                    </Box>
                  ))}
                </Flex>
                <Flex flexDirection="column" gap={isLargerThan768 ? "36px" : 0} w={isLargerThan768 ? "62.564%" : "100%"} mt={isLargerThan768 ? 0 : "16px"}>
                  <Box
                    textAlign="center"
                    color="#0B8487"
                    border={isLargerThan768 ? "2px solid #18A3A7" : "1px solid #18A3A7"}
                    p={isLargerThan768 ? "16px" : "6px 8px"}
                    borderRadius={isLargerThan768 ? "4px" : "2px"}
                    fontSize={isLargerThan768 ? "18px" : "16px"}
                    fontWeight={700}
                    mb={isLargerThan768 ? 0 : "10px"}
                  >
                    お客様からのコメント
                  </Box>
                  {comments2.map((comment, idx) => (
                    <Box
                      key={comment.id}
                      borderRadius="4px"
                      p="24px 3.934%"
                      shadow={isLargerThan768 ? "0 4px 4px rgba(0, 0, 0, 0.25)" : "0 1.21px 1.21px rgba(0, 0, 0, 0.25)"}
                      mb={!isLargerThan768 && idx !== comments2.length - 1 ? "16px" : 0}
                    >
                      <Text mb={isLargerThan768 ? "8px" : "10px"} fontSize={isLargerThan768 ? "18px" : "16px"} fontWeight={700} lineHeight={isLargerThan768 ? "31.5px" : "24px"}>
                        {comment.title}
                      </Text>
                      {comment.content.map((item) => (
                        <Box as="li" key={item.id} fontSize={isLargerThan768 ? "16px" : "12px"} fontWeight={300} lineHeight={isLargerThan768 ? "28px" : "21px"} listStyleType="disc">
                          {item.text}
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Flex>
              </Flex>
            ) : (
              <Flex gap={isLargerThan768 ? "36px" : 0} flexDirection="column" mt={isLargerThan768 ? "40px" : "16px"}>
                <Box
                  textAlign="center"
                  color="#0B8487"
                  border={isLargerThan768 ? "2px solid #18A3A7" : "1px solid #18A3A7"}
                  p={isLargerThan768 ? "16px" : "6px 8px"}
                  borderRadius={isLargerThan768 ? "4px" : "2px"}
                  fontSize={isLargerThan768 ? "18px" : "16px"}
                  fontWeight={700}
                  mb={isLargerThan768 ? 0 : "10px"}
                >
                  お客様からのコメント
                </Box>
                {comments.map((comment, idx) => (
                  <Box
                    key={comment.id}
                    mb={!isLargerThan768 && idx !== comments.length - 1 ? "16px" : 0}
                    borderRadius={isLargerThan768 ? "4px" : "2px"}
                    p={isLargerThan768 ? "24px 2.46%" : "16px"}
                    shadow={isLargerThan768 ? "0 4px 4px rgba(0, 0, 0, 0.25)" : "0 1.21px 1.21px rgba(0, 0, 0, 0.25)"}
                  >
                    <Text mb={isLargerThan768 ? "8px" : "10px"} fontSize={isLargerThan768 ? "18px" : "16px"} fontWeight={700} lineHeight={isLargerThan768 ? "31.5px" : "24px"}>
                      {comment.title}
                    </Text>
                    {comment.content.map((item) => (
                      <Box as="li" key={item.id} fontSize={isLargerThan768 ? "16px" : "14px"} fontWeight={300} lineHeight={isLargerThan768 ? "28px" : "24.5px"} listStyleType="disc">
                        {item.text}
                      </Box>
                    ))}
                  </Box>
                ))}
              </Flex>
            )}
          </Box>
        </Flex>
      </Flex>
    </MainContent>
  );
};

import { createIcon } from "@chakra-ui/icons";

export const SelectStampIcon = createIcon({
  displayName: "SelectStampIcon",
  viewBox: "0 0 32 32",
  path: (
    <g fill="none">
      <path
        d="M26.1706 6.3999H28.0653C28.2338 6.3999 28.318 6.43546 28.318 6.50657V15.8932C28.318 15.9643 28.2338 15.9999 28.0653 15.9999H26.1706C26.0022 15.9999 25.918 15.9643 25.918 15.8932V6.50657C25.918 6.43546 26.0022 6.3999 26.1706 6.3999Z"
        fill="currentColor"
      />
      <path
        d="M22.5101 9.6001H31.8868C31.9612 9.6001 31.9984 9.68431 31.9984 9.85273V11.7475C31.9984 11.9159 31.9612 12.0001 31.8868 12.0001H22.5101C22.4356 12.0001 22.3984 11.9159 22.3984 11.7475V9.85273C22.3984 9.68431 22.4356 9.6001 22.5101 9.6001Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9157 5.73292C21.4178 3.41636 18.0733 2 14.3984 2C6.66719 2 0.398438 8.26875 0.398438 16C0.398438 23.7313 6.66719 30 14.3984 30C21.1607 30 26.8041 25.2041 28.1125 18.8285L25.8054 18.2517C24.7563 23.5934 20.0468 27.625 14.3984 27.625C7.97969 27.625 2.77344 22.4188 2.77344 16C2.77344 9.58125 7.97969 4.375 14.3984 4.375C17.2806 4.375 19.9183 5.42469 21.95 7.16252L23.9157 5.73292Z"
        fill="currentColor"
      />
      <circle cx="11.2016" cy="12.8002" r="1.6" fill="currentColor" />
      <ellipse cx="19.2016" cy="12.8002" rx="1.6" ry="1.6" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0064 17.6001C11.3985 17.6001 10.9796 18.2056 11.3226 18.7075C11.5644 19.0613 11.858 19.3884 12.2024 19.6773C14.2333 21.3814 17.261 21.1165 18.9651 19.0857C19.0678 18.9633 19.1633 18.8373 19.2518 18.7081C19.5956 18.2062 19.1769 17.6001 18.5685 17.6001C18.2538 17.6001 17.9702 17.774 17.7717 18.0182C17.761 18.0313 17.7503 18.0443 17.7394 18.0572C16.6033 19.4111 14.5848 19.5877 13.2309 18.4517C13.0723 18.3186 12.9299 18.1735 12.8039 18.0188C12.605 17.7745 12.3213 17.6001 12.0064 17.6001Z"
        fill="currentColor"
      />
    </g>
  )
});

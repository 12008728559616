import React, { useCallback, useEffect } from "react";
import { CulteeAdministerIcon, Text } from "@/components";
import { Box, Flex, Divider, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import theme from "@/shared/theme";
import { useGetMeLazyQuery, useAdminLogoutMutation } from "@/apollo/generated";
import { useAuthHeaderContext } from "@/hooks/useAuthHeaderContext";
import { useNavigate } from "react-router-dom";

export const Header: React.FC = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { adminHeader, removeAdminHeader } = useAuthHeaderContext();
  const [getMe, { data }] = useGetMeLazyQuery();
  const [adminLogoutMutation] = useAdminLogoutMutation();

  const AdminLogout = useCallback(() => {
    adminLogoutMutation({ variables: { input: {} } }).then(() => {
      removeAdminHeader();
      navigate("/admin/auth/signin");
    });
  }, [adminLogoutMutation, navigate, removeAdminHeader]);

  useEffect(() => {
    if (adminHeader) {
      getMe();
    }
  }, [adminHeader, getMe]);

  return (
    <Box w="100%" pos="fixed" zIndex="2">
      <Flex
        minW="1438px"
        bg={theme.text.white}
        w="100%"
        h="48px"
        align="center"
        p="8px 26px 8px 16px"
        justify="space-between"
      >
        <Flex
          align="center"
          cursor="pointer"
          onClick={() => {
            navigate("/admin");
          }}
        >
          <CulteeAdministerIcon w="116px" h="32px" />
          <Text
            color={theme.text.black900}
            textType="body16b"
            letterSpacing="0.48px"
            lineHeight="150%"
          >
            {t("managementPage")}
          </Text>
        </Flex>
        {adminHeader && (
          <Menu>
            <MenuButton>
              <Flex
                minW="93px"
                h="32px"
                border={`1px solid ${theme.text.black200}`}
                borderRadius="4px"
                p="4px 16px"
                justifyContent="space-between"
                alignItems="center"
                gap="16px"
              >
                <Text textType="body14b" color={theme.text.black900}>
                  {data?.adminHome?.me.name ?? ""}
                </Text>
              </Flex>
            </MenuButton>
            <MenuList mt="1px" ml="16px" minW="0" boxShadow={theme.boxshadows.xs}>
              <MenuItem onClick={() => navigate("/admin/login-information")}>
                <Text fontSize="14px" fontWeight="600" color={theme.text.black700}>
                  {t("login.info")}
                </Text>
              </MenuItem>
              <MenuItem onClick={AdminLogout}>
                <Text fontSize="14px" fontWeight="600" color={theme.text.black700}>
                  {t("logout")}
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
      <Divider w="100%" h="1px" color={theme.text.black200} />
    </Box>
  );
};

import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthHeaderContext } from "@/hooks/useAuthHeaderContext";

type AdminAuthGuardProps = {
  children: ReactNode;
};

export default function AdminAuthGuard({ children }: AdminAuthGuardProps) {
  const navigate = useNavigate();
  const { adminHeader } = useAuthHeaderContext();
  const [view, setView] = useState<ReactNode | null>(null);

  useEffect(() => {
    if (!adminHeader) {
      setView(null);
      navigate("/admin/auth/signin");
      return;
    }

    setView(children);
  }, [adminHeader, children, navigate]);

  return <>{view}</>;
}

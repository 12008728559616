import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

type PropsType = {
  idx: number;
} & BoxProps;

export const StampBox: React.FC<PropsType> = ({ idx, ...rest }) => {
  return (
    <Box
      bgImage={"/assets/stamps.svg"}
      w="32px"
      h="32px"
      bgSize="72px"
      bgPos={`52px ${44 + 42 * idx}px`}
      {...rest}
    />
  );
};

import { MainContent, Text, TextTitle } from "@/components";
import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import theme from "@/shared/theme";
import { LinkButtons } from "./LinkButtons";

export const Question: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const questions: { Q: string; A: string }[] = [
    {
      Q: "Culteeの料金について教えてください。",
      A: "お客様の課題やご要望を伺った上で、最適なプログラム・活用方法を提案させていただき、個別にお見積りいたします。まずは「お問い合わせ」よりご連絡ください。弊社の担当者からご連絡差し上げます。"
    },
    {
      Q: "Culteeのサービス内容や特長について教えてください。",
      A: "組織内外の内省と対話をさまざまな仕掛けでサポートします。単なるツールの提供ではなく、メンバー同士で課題を乗り越え、成長していく組織の土壌づくりに伴走いたします。"
    },
    { Q: "自社(または、自身)に適したカスタマイズをしてもらえますか？", A: "はい。お客様の課題やご要望を伺った上で、一部の設問を変更することが可能です。" },
    { Q: "Culteeの申込みから利用開始まではどれくらいの期間が必要ですか？", A: "お申込み完了後、最短翌日から利用可能です。ご要望にあわせて日程を調整いたします。" },
    { Q: "Culteeの利用人数に制限はありますか", A: "利用制限はございませんが、振り返り・対話のサイクルを効果的に回すためには1チーム最大15名くらいまでをおすすめします。複数のチームを同時に登録することも可能です。" }
  ];

  return (
    <Flex direction="column" alignItems="center" background={theme.gary.gray100} p={isLargerThan768 ? "120px 0" : "64px 0"} id="question-and-answer">
      <MainContent>
        <TextTitle text="Q&A" color={theme.text.black800} />
        <Flex
          background={theme.text.white}
          p={isLargerThan768 ? "80px 60px" : "24px 16px"}
          direction="column"
          gap={isLargerThan768 ? "40px" : "24px"}
          mt={isLargerThan768 ? "120px" : "48px"}
          maxW="1080px"
          borderRadius="4px"
        >
          {questions.map((question, index) => (
            <Box key={index} borderRadius="4px" overflow="hidden">
              <Flex direction="row" p={isLargerThan768 ? "10px 40px" : "8px 16px 10px 16px"} background={theme.primary.green900} color={theme.text.white} align="center">
                <Text mr={isLargerThan768 ? "64px" : "16px"} fontSize={isLargerThan768 ? "32px" : "24px"} fontWeight={700} letterSpacing="0.96px">
                  Q
                </Text>
                <Text fontSize={isLargerThan768 ? "20px" : "16px"} fontWeight={700} letterSpacing="0.6px">
                  {question.Q}
                </Text>
              </Flex>
              <Flex direction="row" p={isLargerThan768 ? "20px 40px" : "12px 16px"} background={theme.gary.gray100} align="center" color={theme.text.black800}>
                <Text mr={isLargerThan768 ? "64px" : "16px"} fontSize={isLargerThan768 ? "32px" : "24px"} fontWeight={700} letterSpacing="0.96px">
                  A
                </Text>
                <Text fontSize={isLargerThan768 ? "18px" : "14px"} fontWeight={400} letterSpacing="0.54px" lineHeight="1.75">
                  {question.A}
                </Text>
              </Flex>
            </Box>
          ))}
        </Flex>
        <Box mt={isLargerThan768 ? "240px" : "128px"}>
          <LinkButtons />
        </Box>
      </MainContent>
    </Flex>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Text,
  Avatar,
  Loading,
  ReflectionIcon,
  Button,
  SearchIcon,
  EmotionRadarChart,
  StampBox,
  SelectStampIcon,
  StampList,
  JournalingSearchModal
} from "@/components";
import {
  Box,
  Divider,
  Flex,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  UnorderedList,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import theme from "@/shared/theme";
import {
  ReactionStampFragment,
  useGetJournalingsLazyQuery,
  useReactingToGoodThingMutation,
  useReactingToSwitchingActionMutation,
  JournalingFilterInput,
  useGetProfileQuery,
  Question,
  QuestionKind,
  useGetQuestionsLazyQuery
} from "@/apollo/generated";
import { nonEmpty } from "@/utils/nonEmpty";
import { useLocation } from "react-router-dom";
import moment from "moment-timezone";

export const JournalingList: React.FC = () => {
  const pageSize = 10;
  const toast = useToast();
  const location = useLocation();
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const { t } = useTranslation(["journaling"]);
  const [getRecentJournalings, { data, loading, refetch, fetchMore }] =
    useGetJournalingsLazyQuery();
  const [getQuestions, { data: questionsData }] = useGetQuestionsLazyQuery();
  const { data: profileData } = useGetProfileQuery();
  const [reactingToGoodThingMutation] = useReactingToGoodThingMutation();
  const [reactingToSwitchingActionMutation] = useReactingToSwitchingActionMutation();
  const [input, setInput] = useState<JournalingFilterInput>({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const currentUserId = profileData?.me.id;

  const pageInfo = data?.myTeam.journalings?.pageInfo;
  const teamJournalings = nonEmpty(data?.myTeam.journalings?.nodes);

  const getStampLists = useCallback((values: ReactionStampFragment[]) => {
    let idxArr: number[] = [];
    let countArr: number[] = [];
    let userNames: Array<Array<string>> = new Array<Array<string>>();

    values.forEach((v: ReactionStampFragment) => {
      if (v.user) {
        if (idxArr.includes(Number(v?.emojiIndex))) {
          const index = idxArr.findIndex((a) => a === v?.emojiIndex);
          countArr[index]++;
          userNames[index]?.push(v.user.familyName + " " + v.user.givenName ?? "");
        } else {
          idxArr.push(v?.emojiIndex ?? 0);
          countArr.push(1);
          userNames.push([v.user.familyName + " " + v.user.givenName ?? ""]);
        }
      }
    });

    return idxArr.map((item, idx) => {
      return {
        stampIdx: item,
        count: countArr[idx],
        userNames: userNames[idx]
      };
    });
  }, []);

  const questions: Question[] = useMemo(() => {
    const array: Question[] = [];
    if (questionsData?.myEnterprise.questions) {
      questionsData?.myEnterprise.questions.forEach((question) => {
        if (question.kind === QuestionKind.GoodThing) {
          array.unshift(question);
        } else if (question.kind === QuestionKind.SwitchingAction) {
          array.push(question);
        }
      });
    }
    return array;
  }, [questionsData?.myEnterprise.questions]);

  const getWeekDay = useCallback((day: number) => {
    switch (day) {
      case 0:
        return "日";
      case 1:
        return "月";
      case 2:
        return "火";
      case 3:
        return "水";
      case 4:
        return "木";
      case 5:
        return "金";
      case 6:
        return "土";
      default:
        break;
    }
  }, []);

  const getMorejournalings = useCallback(async () => {
    const endCursor = pageInfo?.endCursor;
    const hasNextPage = pageInfo?.hasNextPage;

    if (!hasNextPage) return;

    setFetchMoreLoading(true);

    await fetchMore({
      variables: {
        first: pageSize,
        after: endCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult.myTeam.journalings) return prevResult;

        fetchMoreResult.myTeam.journalings.nodes = [
          ...nonEmpty(prevResult.myTeam.journalings?.nodes),
          ...nonEmpty(fetchMoreResult.myTeam.journalings?.nodes)
        ];

        return fetchMoreResult;
      }
    });

    setFetchMoreLoading(false);
  }, [fetchMore, pageInfo]);

  const handleSubmit = useCallback(
    (action: string) => {
      refetch();

      if (action === "DESTROY") return;

      toast({
        position: "top",
        description: t("addStampSuccess"),
        status: "success",
        duration: 3000,
        isClosable: true
      });
    },
    [refetch, t, toast]
  );

  const reactingTo = useCallback(
    (idx: number, type: "goodThing" | "switchAction", id: string) => {
      type === "goodThing" &&
        reactingToGoodThingMutation({ variables: { input: { id: id, emojiIndex: idx } } })
          .then((res) => handleSubmit(res.data?.reactingToGoodThing?.action ?? ""))
          .catch(() => {});
      type === "switchAction" &&
        reactingToSwitchingActionMutation({ variables: { input: { id: id, emojiIndex: idx } } })
          .then((res) => handleSubmit(res.data?.reactingToSwitchingAction?.action ?? ""))
          .catch(() => {});
    },
    [reactingToGoodThingMutation, reactingToSwitchingActionMutation, handleSubmit]
  );

  useEffect(() => {
    if (location.state && location.state.userId) {
      setInput({ userIds: [location.state.userId] });
    }
  }, [location.state]);

  useEffect(() => {
    getRecentJournalings({
      variables: {
        first: pageSize,
        filter: input
      }
    });
  }, [getRecentJournalings, pageSize, input]);

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  if (loading || !data?.myTeam.journalings?.nodes) return <Loading />;

  return (
    <Box p="24px">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        pos="sticky"
        top="48px"
        bgColor={theme.text.white}
        zIndex={999}
      >
        <Flex>
          <ReflectionIcon
            w="30px"
            h="30px"
            mr="8px"
            color={theme.service.turquoiseText}
            alignSelf="center"
          />
          <Text textType="title32">{t("title")}</Text>
        </Flex>
        <Button buttonType="fill" w="154px" h="37px" leftIcon={<SearchIcon />} onClick={onOpen}>
          <Text textType="body14b">{t("search")}</Text>
        </Button>
      </Flex>
      {!teamJournalings.length ? (
        <Text>{t("emptyMsg")}</Text>
      ) : (
        <Flex flexWrap="wrap" w="100%" pb="32px">
          {teamJournalings.map((r, idx) => (
            <Flex pt={idx ? "32px" : "22px"} key={idx}>
              <Box>
                <Flex alignItems="center">
                  <Avatar src={r.user?.avatarUrl ?? ""} w="36px" h="36px" size="md" />
                  <Text textType="title24" color="#505050" ml="8px" mr="16px">
                    {r?.user?.familyName + " " + r?.user?.givenName}
                  </Text>
                  <Text textType="title24" color="#505050">
                    {moment(r.updatedAt).format("YYYY年MM月DD日")}
                    {"（" + getWeekDay(moment(r.updatedAt).day()) + "）"}
                    {moment(r.updatedAt).tz("Asia/Tokyo").format("HH:mm")}
                  </Text>
                </Flex>
                <Flex pl="44px">
                  <Text textType="body12r" color={theme.text.gray500}>
                    {r.user?.goal ?? ""}
                  </Text>
                </Flex>
                <Flex pt="8px" minW={{ base: "1130px", "2xl": "1392px" }}>
                  {[
                    { title: questions[0]?.title ?? "３Good things", lists: r.goodThing },
                    { title: questions[1]?.title ?? "Switching Actions", lists: r.switchingAction }
                  ].map((l, idxTop) => (
                    <Box
                      key={l.title}
                      w="28%"
                      maxW="458px"
                      minH="200px"
                      ml={idxTop === 1 ? { base: "16px", "2xl": "40px" } : ""}
                      p="16px"
                      border={`1px solid ${theme.text.black200}`}
                      borderRadius="4px"
                    >
                      <Text textType="body16b">{l.title}</Text>
                      <Divider bgColor={theme.service.turquoise400} h="1px" m="16px 0" />
                      <UnorderedList spacing={2}>
                        {l.lists?.text?.map(
                          (t, idx1) =>
                            t && (
                              <ListItem key={idx1}>
                                <Text textType="body14r">{t}</Text>
                              </ListItem>
                            )
                        )}
                      </UnorderedList>
                      <Flex pt="16px" flexWrap="wrap">
                        {getStampLists(l.lists?.reactionStamps!).map((item, idx) => (
                          <Popover trigger="hover" key={idx} placement="top">
                            <PopoverTrigger>
                              <Flex key={idx} alignItems="center" mr="12px">
                                <StampBox
                                  mr="4px"
                                  mb="4px"
                                  idx={Number(item.stampIdx)}
                                  cursor="pointer"
                                  onClick={() =>
                                    reactingTo(
                                      item.stampIdx,
                                      !!idxTop ? "switchAction" : "goodThing",
                                      r.id
                                    )
                                  }
                                />
                                <Text textType="body16m" minW="24px" textAlign="center">
                                  {item.count}
                                </Text>
                              </Flex>
                            </PopoverTrigger>
                            <PopoverContent
                              w={item.userNames?.join(",").length > 46 ? "360px" : "249px"}
                            >
                              <PopoverArrow />
                              <PopoverBody p="15px 10px 18px">
                                <Box>
                                  <StampBox m="0 auto" idx={Number(item.stampIdx)} />
                                  <Text color={theme.text.black900} pt="8px" textType="body12r">
                                    <Text textType="body12b" fontWeight={700} as="span">
                                      {item.userNames?.join(",")}
                                    </Text>
                                    {t("hasReacted")}
                                  </Text>
                                </Box>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        ))}
                        <Popover placement="top-start" autoFocus={false}>
                          <PopoverTrigger>
                            <SelectStampIcon
                              cursor="pointer"
                              w="32px"
                              h="32px"
                              color={theme.text.black500}
                              _hover={{ color: theme.service.turquoise400 }}
                            />
                          </PopoverTrigger>
                          <PopoverContent w="264px">
                            <PopoverBody p="0">
                              <StampList
                                type={!!idxTop ? "switchAction" : "goodThing"}
                                id={r.id ?? ""}
                                onSubimt={handleSubmit}
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Box>
                  ))}
                  <Box ml="30px">
                    <EmotionRadarChart
                      data={[
                        r.pleasure ?? 0,
                        r.confidence ?? 0,
                        r.fear ?? 0,
                        r.surprise ?? 0,
                        r.sadness ?? 0,
                        r.repugnance ?? 0,
                        r.anger ?? 0,
                        r.expectation ?? 0
                      ]}
                    />
                  </Box>
                </Flex>
                {r.user?.id === currentUserId && (
                  <Box pt="24px" maxW="calc(56% + 16px)">
                    <Text textType="title20" color="#505050" mb="8px">
                      {t("situation")}
                    </Text>
                    <Text textType="body14r" whiteSpace="pre-wrap">
                      {r.situation}
                    </Text>
                  </Box>
                )}
              </Box>
            </Flex>
          ))}
          {pageInfo?.hasNextPage && (
            <Flex alignItems="center" justifyContent="center" pt="20px" w="100%">
              <Button
                onClick={getMorejournalings}
                buttonType="fill"
                w="196px"
                h="46px"
                isLoading={fetchMoreLoading}
              >
                <Text textType="body16b">{t("fetchMore")}</Text>
              </Button>
            </Flex>
          )}
        </Flex>
      )}
      <JournalingSearchModal
        isOpen={isOpen}
        onClose={onClose}
        onSearch={setInput}
        initState={input}
      />
    </Box>
  );
};

import { createIcon } from "@chakra-ui/icons";

export const CultureChangeSupport1Icon = createIcon({
  displayName: "CultureChangeSupport1Icon",
  viewBox: "0 0 340 292",
  path: (
    <svg width="340" height="292" viewBox="0 0 340 292" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="339" height="291" transform="translate(0.03125 0.228516)" fill="white" />
      <path d="M172.704 284.103L113.585 289.534L94.8877 267.211L128.064 268.417L172.704 278.068V284.103Z" fill="#D9D9D9" />
      <path
        d="M113.585 291.22C113.089 291.22 112.613 291.003 112.292 290.615L93.5897 268.292C93.1598 267.783 93.0763 267.065 93.3685 266.468C93.6606 265.871 94.2867 265.504 94.9461 265.524L128.126 266.731C128.226 266.731 128.322 266.747 128.423 266.768L173.063 276.423C173.84 276.59 174.395 277.279 174.395 278.072V284.103C174.395 284.976 173.731 285.702 172.863 285.786L153.856 287.53C152.934 287.618 152.108 286.933 152.024 286.003C151.941 285.076 152.621 284.254 153.552 284.166L171.027 282.559V279.428L127.863 270.095L98.623 269.031L114.32 287.768L144.412 285.005C145.338 284.917 146.16 285.602 146.244 286.533C146.327 287.459 145.647 288.282 144.716 288.369L113.744 291.212C113.694 291.216 113.64 291.22 113.59 291.22H113.585Z"
        fill="#1A1A1A"
      />
      <path
        d="M105.032 235.926C105.032 235.926 86.7062 230.04 83.0835 235.926C83.0835 235.926 77.7997 247.534 77.2321 253.257C77.2321 253.257 71.9066 260.106 85.2037 255.018L93.4967 254.576C93.4967 254.576 114.874 269.632 134.327 265.09L154.928 257.423L140.145 242.892L127.144 245.339L109.891 237.996L105.037 235.93L105.032 235.926Z"
        fill="white"
      />
      <path
        d="M126.57 267.637C110.752 267.637 95.9395 258.249 93.0138 256.287L85.5556 256.684C79.4455 258.996 76.3862 259.184 75.0256 257.343C73.8153 255.707 74.9672 253.574 75.6057 252.622C76.4029 246.578 81.3319 235.696 81.5489 235.225C81.5782 235.162 81.6116 235.099 81.6491 235.037C85.8436 228.229 101.098 232.837 102.826 233.384C103.715 233.664 104.203 234.615 103.924 235.5C103.644 236.389 102.692 236.882 101.808 236.598C96.3026 234.853 86.8077 233.363 84.5748 236.723C84.0531 237.888 79.4204 248.319 78.9112 253.415C78.8778 253.733 78.761 254.033 78.5648 254.284C78.3561 254.555 78.1475 254.91 78.0139 255.206H78.0181C78.7819 255.206 80.6141 254.96 84.604 253.436C84.7668 253.374 84.9421 253.336 85.1174 253.328L93.4103 252.885C93.7859 252.864 94.1616 252.973 94.4704 253.19C94.6791 253.336 115.393 267.699 133.844 263.467L151.857 256.763L139.595 244.708L127.463 246.991C127.133 247.054 126.795 247.016 126.49 246.887L109.237 239.545C108.381 239.182 107.98 238.188 108.343 237.333C108.707 236.477 109.7 236.076 110.555 236.439L127.338 243.581L139.837 241.231C140.38 241.131 140.939 241.298 141.332 241.686L156.115 256.216C156.528 256.621 156.703 257.205 156.582 257.773C156.461 258.337 156.06 258.8 155.522 259L134.921 266.668C134.854 266.693 134.787 266.714 134.716 266.731C132.012 267.361 129.278 267.637 126.578 267.637H126.57Z"
        fill="#1A1A1A"
      />
      <path
        d="M131.744 242.045C131.744 242.045 173.847 228.784 182.007 213.632C182.007 213.632 201.552 172.772 203.885 145.377C203.885 145.377 207.382 124.978 217.29 122.065L252.257 107.493L292.469 99.3326L319.277 112.74C319.277 112.74 336.76 114.489 337.344 142.464C337.344 142.464 336.18 203.08 330.349 233.384L330.933 279.428L247.599 273.601L227.203 271.268L221.957 225.808L220.792 219.981C220.792 219.981 208.676 237.09 199.937 244.666C199.937 244.666 160.454 263.316 152.295 267.399C152.295 267.399 148.651 264.193 131.752 242.045H131.744Z"
        fill="white"
      />
      <path
        d="M337.336 142.464C337.336 142.464 336.171 203.08 330.341 233.384L330.925 279.428L247.591 273.601L227.194 271.268L221.948 225.808L220.784 219.981C220.784 219.981 208.668 237.09 199.928 244.666C199.928 244.666 160.446 263.316 152.287 267.399C152.287 267.399 148.643 264.193 131.744 242.045C131.744 242.045 159.716 230.241 179.824 216.692C179.824 216.692 201.556 172.772 203.885 145.377C203.885 145.377 207.382 124.978 217.29 122.065L252.257 107.493L292.469 99.3326L337.344 142.464H337.336Z"
        fill="white"
      />
      <path
        d="M200.199 168.865C200.07 168.865 199.936 168.848 199.803 168.819C198.897 168.602 198.338 167.692 198.559 166.786C200.416 159.056 201.643 151.805 202.203 145.239C202.203 145.193 202.211 145.143 202.219 145.097C202.257 144.885 203.146 139.767 205.295 134.211C208.279 126.498 212.119 121.877 216.718 120.479L251.601 105.94C251.702 105.898 251.806 105.865 251.914 105.844L292.127 97.6838C293.041 97.5001 293.93 98.0887 294.114 99.0028C294.297 99.917 293.709 100.806 292.795 100.99L252.745 109.117L217.933 123.626C217.875 123.651 217.82 123.672 217.762 123.688C210.504 125.821 206.506 140.159 205.558 145.598C204.982 152.314 203.73 159.707 201.84 167.575C201.652 168.347 200.963 168.869 200.199 168.869V168.865Z"
        fill="#1A1A1A"
      />
      <path
        d="M227.189 272.958C226.342 272.958 225.616 272.324 225.515 271.464L220.278 226.071L219.881 224.084C215.929 229.423 207.623 240.225 201.029 245.94C200.912 246.04 200.783 246.123 200.645 246.19C200.248 246.378 161.088 264.877 153.037 268.905C152.423 269.21 151.684 269.114 151.167 268.663C151.013 268.53 147.206 265.094 130.399 243.068C130.09 242.663 129.982 242.145 130.099 241.649C130.215 241.156 130.549 240.743 131.008 240.522C131.117 240.471 142.265 235.091 178.562 215.448C180.407 211.937 192.49 188.7 196.4 174.963C196.655 174.066 197.59 173.548 198.483 173.803C199.38 174.057 199.898 174.992 199.643 175.886C195.328 191.046 181.872 216.408 181.3 217.48C181.146 217.773 180.908 218.015 180.616 218.173C151.96 233.684 138.788 240.409 134.318 242.646C146.054 257.973 150.921 263.521 152.619 265.341C162.24 260.607 195.975 244.662 198.984 243.239C207.456 235.809 219.276 219.171 219.397 219C219.785 218.449 220.465 218.186 221.125 218.324C221.784 218.466 222.298 218.983 222.427 219.643L223.591 225.47C223.6 225.516 223.608 225.562 223.612 225.607L228.859 271.068C228.967 271.994 228.303 272.829 227.377 272.938C227.31 272.946 227.248 272.95 227.181 272.95L227.189 272.958Z"
        fill="#1A1A1A"
      />
      <path
        d="M330.924 281.119C330.001 281.119 329.25 280.376 329.238 279.453L328.653 233.409C328.653 233.296 328.662 233.179 328.683 233.071C334.417 203.255 335.636 143.04 335.644 142.434C335.661 141.504 336.416 140.769 337.364 140.781C338.294 140.798 339.037 141.57 339.016 142.501C339.004 143.106 337.785 203.38 332.026 233.538L332.606 279.412C332.618 280.342 331.871 281.106 330.94 281.119C330.932 281.119 330.928 281.119 330.92 281.119H330.924Z"
        fill="#1A1A1A"
      />
      <path d="M231.167 118.3C231.167 118.3 230.654 175.664 242.436 193.079L287.503 101.908L281.359 92.1781L246.021 121.631L231.172 118.3H231.167Z" fill="white" />
      <path d="M241.238 132.187L237.314 123.935L239.105 121.626H246.021L250.849 126.222L247.206 132.521L241.238 132.187Z" fill="#0B8487" />
      <path
        d="M247.206 134.065C247.177 134.065 247.148 134.065 247.118 134.065L241.15 133.735C240.587 133.702 240.082 133.368 239.84 132.855L235.916 124.598C235.666 124.068 235.733 123.446 236.092 122.983L237.882 120.679C238.174 120.303 238.625 120.082 239.101 120.082H246.017C246.413 120.082 246.793 120.232 247.081 120.508L251.914 125.099C252.431 125.592 252.544 126.377 252.185 126.994L248.537 133.293C248.262 133.773 247.749 134.065 247.198 134.065H247.206ZM242.239 130.693L246.342 130.922L248.901 126.502L245.403 123.175H239.865L239.122 124.131L242.244 130.697L242.239 130.693Z"
        fill="#0B8487"
      />
      <path d="M237.315 171.311C237.315 171.311 238.935 138.335 241.239 132.187L247.207 132.516L252.937 172.333L243.976 189.234L237.32 171.306L237.315 171.311Z" fill="#0B8487" />
      <path
        d="M243.972 190.783C243.939 190.783 243.905 190.783 243.868 190.783C243.259 190.741 242.733 190.349 242.52 189.777L235.863 171.849C235.792 171.653 235.759 171.444 235.767 171.235C235.834 169.879 237.424 137.947 239.79 131.644C240.028 131.01 240.642 130.605 241.326 130.642L247.294 130.972C248.029 131.014 248.634 131.569 248.738 132.295L254.469 172.112C254.515 172.438 254.456 172.767 254.306 173.055L245.345 189.956C245.078 190.466 244.548 190.779 243.981 190.779L243.972 190.783ZM238.876 171.069L244.223 185.465L251.334 172.058L245.859 133.994L242.37 133.802C240.708 140.389 239.319 162.286 238.88 171.073L238.876 171.069Z"
        fill="#0B8487"
      />
      <path
        d="M242.691 225.612C239.719 225.612 237.303 223.195 237.303 220.223C237.303 217.251 239.719 214.834 242.691 214.834C245.662 214.834 248.079 217.251 248.079 220.223C248.079 223.195 245.662 225.612 242.691 225.612ZM242.691 217.931C241.426 217.931 240.395 218.962 240.395 220.227C240.395 221.492 241.426 222.523 242.691 222.523C243.955 222.523 244.986 221.492 244.986 220.227C244.986 218.962 243.955 217.931 242.691 217.931Z"
        fill="#1A1A1A"
      />
      <path
        d="M272.649 156.209C271.84 156.209 271.155 155.575 271.105 154.756C271.055 153.905 271.702 153.17 272.554 153.12L297.904 151.584C298.755 151.53 299.49 152.181 299.54 153.037C299.59 153.888 298.943 154.623 298.092 154.673L272.741 156.209C272.708 156.209 272.679 156.209 272.645 156.209H272.649Z"
        fill="#1A1A1A"
      />
      <path
        d="M259.775 7.87375C259.775 7.87375 234.195 -9.62826 210.852 14.6316C210.852 14.6316 179.237 26.5779 206.244 50.554L230.484 62.1413L263.406 74.0667L270.292 77.8568C270.292 77.8568 279.474 64.5122 281.528 43.0072C281.528 43.0072 284.462 11.3591 259.775 7.86958V7.87375Z"
        fill="#0B8487"
      />
      <path
        d="M270.4 79.4012C270.112 79.4221 269.816 79.3636 269.549 79.2133L262.767 75.4817L229.958 63.5938C229.912 63.5772 229.862 63.5563 229.816 63.5354L205.576 51.9481C205.447 51.8855 205.325 51.8062 205.217 51.7102C196.511 43.9797 192.742 36.5373 194.023 29.5958C195.885 19.4945 207.767 14.2142 209.971 13.3168C222.412 0.539848 235.484 -0.645595 244.286 0.623332C252.796 1.85052 258.906 5.48199 260.334 6.39194C285.818 10.2822 283.096 42.8235 283.067 43.1533C280.997 64.8127 271.949 78.1782 271.565 78.7375C271.289 79.1382 270.855 79.372 270.396 79.4053L270.4 79.4012ZM231.085 60.7137L263.935 72.6141C264.01 72.6433 264.086 72.6725 264.152 72.7143L269.758 75.7989C271.949 72.1215 278.343 60.1168 279.991 42.8652C280 42.7943 280.667 35.0764 278.401 27.0955C275.734 17.7038 270.296 11.9393 262.22 9.92323C261.798 10.182 261.264 10.2321 260.78 10.0192L259.144 9.28877C259.144 9.28877 259.136 9.28877 259.132 9.2846C259.132 9.2846 259.132 9.2846 259.127 9.2846C259.127 9.2846 259.127 9.2846 259.123 9.2846C259.04 9.24703 258.96 9.20111 258.889 9.14685C257.667 8.32038 233.99 -7.17807 211.97 15.7127C211.811 15.8755 211.615 16.0049 211.402 16.0884C211.277 16.1385 198.756 20.9888 197.07 30.1802C196.014 35.9404 199.391 42.3602 207.116 49.2642L231.089 60.7262L231.085 60.7137Z"
        fill="#0B8487"
      />
      <path
        d="M272.195 68.4818C272.195 68.4818 280.675 56.4186 274.586 50.17C274.586 50.17 266.606 42.5147 262.687 52.5451L260.705 56.4687L258.539 56.8611C258.539 56.8611 257.466 48.8927 250.663 47.0603C250.663 47.0603 234.265 40.1689 235.116 30.3514C235.116 30.3514 232.049 29.792 228.58 29.1868C219.933 27.6758 218.046 26.0145 211.06 28.9614C200.838 33.2732 203.393 39.3006 202.458 40.5195C202.458 40.5195 201.565 38.7831 202.888 46.6512C202.888 46.6512 206.736 64.867 205.359 70.8652C205.359 70.8652 205.463 73.5199 206.598 76.9886C207.925 83.5754 211.277 93.3511 219.749 102.801C223.192 106.641 229.236 107.664 234.085 105.903C235.663 105.331 236.761 105.381 238.155 104.838C238.593 107.205 239.173 111.133 239.448 116.48L241.886 121.956L275.341 84.1764C275.341 84.1764 272.069 74.1377 272.186 68.4818H272.195Z"
        fill="white"
      />
      <path
        d="M230.366 107.873C227.09 108.102 223.592 107.318 220.229 104.141C203.96 88.7846 204.469 69.112 204.498 68.2814C204.657 63.694 203.012 57.6207 202.996 57.5581C202.77 56.7358 203.254 55.8843 204.081 55.6589C204.907 55.4377 205.754 55.9177 205.98 56.74C206.051 57.0071 207.766 63.3225 207.591 68.3899C207.582 68.5777 207.136 87.524 222.353 101.887C227.282 106.541 232.553 104.625 237.207 102.935L237.311 102.897C239.511 102.1 251.126 94.5073 254.974 83.5335C255.258 82.7279 256.138 82.3022 256.944 82.586C257.749 82.8699 258.175 83.7506 257.891 84.5562C253.621 96.7195 241.293 104.738 238.363 105.802L238.258 105.84C235.946 106.679 233.238 107.664 230.366 107.864V107.873Z"
        fill="#1A1A1A"
      />
      <path
        d="M258.651 58.4054C257.899 58.4597 257.202 57.9546 257.036 57.1949C255.721 51.2301 250.07 48.4877 250.011 48.4585C249.982 48.446 249.953 48.4293 249.928 48.4168C234.415 39.9183 233.605 30.8438 233.58 30.4598C233.521 29.6083 234.16 28.8695 235.016 28.8068C235.863 28.7484 236.597 29.3829 236.664 30.226C236.681 30.3805 237.616 38.1276 251.376 45.6786C252.073 46.0167 258.459 49.2933 260.057 56.5229C260.241 57.3577 259.715 58.1842 258.88 58.3678C258.805 58.3845 258.73 58.3971 258.655 58.4012L258.651 58.4054Z"
        fill="#1A1A1A"
      />
      <path
        d="M267.269 73.0692C265.629 73.1861 264.268 72.6518 263.488 72.0757C262.799 71.5707 262.653 70.6023 263.158 69.9136C263.659 69.229 264.614 69.0787 265.303 69.5713C265.579 69.7591 267.932 71.1741 271.013 67.4926C271.559 66.8372 272.536 66.7496 273.191 67.3006C273.846 67.8474 273.934 68.8241 273.383 69.4794C271.255 72.0215 269.089 72.944 267.273 73.0734L267.269 73.0692Z"
        fill="#1A1A1A"
      />
      <path
        d="M232.552 57.1491C233.892 57.0531 235.056 58.0632 235.148 59.4031C235.244 60.743 234.234 61.9076 232.89 61.9994C231.551 62.0954 230.386 61.0853 230.294 59.7454C230.198 58.4055 231.208 57.2409 232.552 57.1491Z"
        fill="#1A1A1A"
      />
      <path
        d="M212.523 63.6034C213.405 62.5888 213.297 61.0518 212.282 60.1704C211.268 59.289 209.731 59.397 208.85 60.4116C207.968 61.4263 208.076 62.9633 209.091 63.8447C210.105 64.7261 211.642 64.618 212.523 63.6034Z"
        fill="#1A1A1A"
      />
      <path
        d="M237.19 50.5581C237.027 50.5832 236.856 50.579 236.685 50.5498C233.45 49.9571 229.715 51.4681 229.677 51.4806C228.889 51.8062 227.983 51.4305 227.657 50.6374C227.332 49.8485 227.707 48.9469 228.496 48.6172C228.684 48.542 233.1 46.7388 237.244 47.5027C238.083 47.6571 238.642 48.4627 238.488 49.3017C238.367 49.9696 237.828 50.4579 237.19 50.554V50.5581Z"
        fill="#1A1A1A"
      />
      <path
        d="M221.189 81.747L217.783 81.985C216.932 82.0434 216.193 81.4048 216.131 80.5491C216.072 79.6976 216.715 78.9587 217.566 78.8961L220.972 78.6582C221.823 78.5998 222.562 79.2426 222.625 80.0941C222.683 80.9456 222.045 81.6844 221.189 81.747Z"
        fill="#1A1A1A"
      />
      <path
        d="M265.323 64.4413C265.323 64.4413 265.282 64.4413 265.261 64.4413C264.405 64.4663 263.696 63.7943 263.666 62.9428C263.441 55.4252 267.924 54.0436 268.111 53.9893C268.934 53.7472 269.789 54.2272 270.027 55.0454C270.261 55.8551 269.806 56.6983 269.009 56.9488C268.85 57.0072 266.609 57.938 266.759 62.8509C266.784 63.6858 266.146 64.3828 265.323 64.4413Z"
        fill="#1A1A1A"
      />
      <path
        d="M242.362 121.247C242.203 121.259 242.04 121.247 241.882 121.205C241.372 121.08 240.959 120.7 240.788 120.203L237.771 111.346C237.729 111.22 237.7 111.087 237.691 110.953C237.462 107.677 236.406 104.947 236.397 104.917C236.089 104.124 236.481 103.223 237.274 102.914C238.067 102.601 238.964 102.993 239.277 103.786C239.327 103.911 240.471 106.854 240.763 110.532L242.942 116.926L274.365 87.2401C273.451 84.0428 270.546 73.4698 270.65 68.4483C270.654 68.177 270.734 67.9057 270.876 67.6761C270.926 67.5926 275.921 59.2945 274.911 53.0208C274.498 50.4663 272.399 48.4585 269.916 48.2456C267.391 48.0244 265.5 49.6189 264.135 53.1085C263.822 53.9057 262.925 54.2939 262.132 53.985C261.335 53.6762 260.942 52.7787 261.255 51.9815C263.626 45.9081 267.42 44.9189 270.183 45.161C274.056 45.4991 277.332 48.5963 277.967 52.5283C279.039 59.1693 274.799 67.0667 273.743 68.9033C273.818 74.4799 277.558 87.1441 277.599 87.2735C277.766 87.8328 277.599 88.4381 277.178 88.8388L243.317 120.825C243.054 121.075 242.716 121.217 242.366 121.242L242.362 121.247Z"
        fill="#1A1A1A"
      />
      <path
        d="M202.762 50.8629C201.965 50.9172 201.243 50.3495 201.126 49.5481L200.15 42.9405C200.137 42.8653 200.133 42.7902 200.133 42.7109C200.141 36.5624 202.086 32.0127 205.917 29.1826C213.831 23.3431 226.798 27.225 227.345 27.3961C228.163 27.6466 228.618 28.5106 228.367 29.3287C228.117 30.1468 227.253 30.606 226.435 30.3555C226.314 30.318 214.394 26.7616 207.745 31.6787C204.774 33.8743 203.255 37.5517 203.225 42.6024L204.185 49.0931C204.311 49.9363 203.726 50.7252 202.883 50.8504C202.841 50.8546 202.804 50.8587 202.762 50.8629Z"
        fill="#1A1A1A"
      />
      <path d="M254.207 129.136L242.254 119.706L278.235 83.8048L287.6 92.9002L258.001 131.949L254.207 129.136Z" fill="white" />
      <path
        d="M258.109 133.493C257.75 133.518 257.379 133.418 257.066 133.184C256.386 132.667 256.252 131.698 256.77 131.018L285.542 93.063L278.251 85.9796L244.578 119.573L255.167 127.925C255.839 128.455 255.952 129.428 255.426 130.096C254.896 130.768 253.923 130.88 253.255 130.354L241.298 120.925C240.952 120.65 240.739 120.241 240.71 119.802C240.685 119.36 240.847 118.93 241.16 118.617L277.141 82.7154C277.738 82.1185 278.706 82.1143 279.311 82.6987L288.677 91.7983C289.236 92.3409 289.303 93.2216 288.831 93.8436L259.232 132.892C258.952 133.264 258.535 133.472 258.109 133.502V133.493Z"
        fill="#1A1A1A"
      />
      <path d="M234.259 122.832L234.776 115.611L237.998 106.654L242.255 119.706L234.689 128.964L234.259 122.832Z" fill="white" />
      <path
        d="M234.797 130.509C234.605 130.521 234.409 130.5 234.221 130.442C233.616 130.25 233.19 129.707 233.144 129.077L232.714 122.945C232.71 122.87 232.71 122.799 232.714 122.724L233.232 115.499C233.24 115.357 233.269 115.219 233.319 115.085L236.541 106.132C236.767 105.51 237.351 105.105 238.019 105.109C238.678 105.118 239.262 105.548 239.467 106.178L243.72 119.23C243.883 119.731 243.778 120.282 243.449 120.687L235.886 129.945C235.611 130.283 235.214 130.48 234.797 130.509ZM235.811 122.837L235.961 124.966L240.523 119.381L237.931 111.425L236.308 115.933L235.811 122.837Z"
        fill="#1A1A1A"
      />
      <path
        d="M289.041 90.1285C289.041 90.1285 249.608 164.394 240.902 199.736C240.902 199.736 278.289 140.322 290.067 132.642L278.799 127.007L294.675 123.935L302.359 98.3265L289.045 90.1327L289.041 90.1285Z"
        fill="white"
      />
      <path
        d="M235.781 109.334C235.781 109.334 231.173 135.71 240.902 185.908L242.438 193.079L240.902 199.736C240.902 199.736 220.418 153.638 221.44 133.664L229.633 130.592L222.463 128.029L229.633 111.128L235.777 109.338L235.781 109.334Z"
        fill="white"
      />
      <path
        d="M240.9 201.285C240.696 201.285 240.491 201.243 240.295 201.16C239.598 200.863 239.218 200.104 239.398 199.365C248.05 164.244 287.277 90.1454 287.674 89.4024C287.874 89.0267 288.221 88.747 288.634 88.6343C289.047 88.5216 289.485 88.5842 289.852 88.8096L303.166 97.0034C303.763 97.3707 304.038 98.0928 303.838 98.7649L298.821 115.486L296.155 124.373C295.988 124.924 295.533 125.337 294.969 125.446L283.563 127.654L290.758 131.252C291.255 131.498 291.58 131.995 291.61 132.546C291.643 133.101 291.376 133.627 290.908 133.932C279.544 141.341 242.582 199.966 242.207 200.555C241.914 201.018 241.414 201.277 240.896 201.277L240.9 201.285ZM289.635 92.3117C284.635 101.85 256.656 155.8 245.42 189.794C255.729 173.869 276.931 141.996 287.181 132.93L278.1 128.388C277.507 128.092 277.169 127.453 277.261 126.794C277.352 126.139 277.849 125.613 278.5 125.483L293.458 122.586L300.528 99.0111L289.635 92.3075V92.3117Z"
        fill="#1A1A1A"
      />
      <path
        d="M240.9 201.285C240.295 201.285 239.736 200.93 239.485 200.367C238.642 198.472 218.864 153.7 219.894 133.585C219.928 132.972 220.32 132.433 220.896 132.216L225.132 130.626L221.944 129.486C221.543 129.344 221.217 129.04 221.046 128.651C220.875 128.263 220.871 127.817 221.038 127.424L228.208 110.523C228.392 110.098 228.755 109.772 229.197 109.643L235.341 107.852C235.85 107.702 236.405 107.827 236.806 108.186C237.202 108.541 237.39 109.079 237.298 109.605C237.252 109.868 232.866 136.353 242.415 185.615L243.947 192.757C243.993 192.979 243.993 193.208 243.943 193.429L242.407 200.087C242.257 200.734 241.71 201.214 241.046 201.281C240.996 201.285 240.946 201.289 240.896 201.289L240.9 201.285ZM222.949 134.75C222.716 150.949 235.562 183.132 240.454 194.778L240.85 193.066L239.385 186.233C231.247 144.25 233.033 119.084 233.918 111.492L230.767 112.41L224.523 127.128L230.149 129.136C230.758 129.353 231.171 129.933 231.176 130.58C231.18 131.231 230.779 131.811 230.174 132.041L222.949 134.75Z"
        fill="#1A1A1A"
      />
      <path
        d="M237.657 25.0502C237.215 25.0794 236.764 24.925 236.43 24.5785C235.838 23.9607 235.859 22.984 236.476 22.3913C247.553 11.7598 251.622 11.8058 252.858 12.315C253.646 12.6406 254.022 13.5464 253.692 14.3353C253.392 15.0532 252.611 15.4289 251.881 15.241C251.543 15.2536 248.108 15.5124 238.621 24.6202C238.35 24.879 238.008 25.021 237.661 25.046L237.657 25.0502Z"
        fill="#1A1A1A"
      />
      <path
        d="M129.728 275.35C131.039 277.538 137.595 276.223 137.595 276.223C139.782 280.593 149.277 276.319 149.277 276.319L163.818 276.223C183.05 286.278 195.287 280.155 195.287 280.155L214.957 275.346C214.957 262.231 207.09 257.861 207.09 257.861L189.169 261.359L168.852 255.715C166.293 255.006 163.664 254.555 161.014 254.417C148.368 253.749 138.906 256.55 138.906 256.55C117.829 261.943 117.053 268.743 117.053 268.743C117.053 268.743 115.112 274.57 122.103 273.601C122.103 273.601 123.656 276.711 129.728 275.35Z"
        fill="white"
      />
      <path
        d="M137.595 277.913C136.935 277.913 136.309 277.525 136.038 276.878C135.508 275.605 135.595 274.315 136.297 273.142C139.293 268.133 154.36 266.422 157.348 266.13C158.275 266.042 159.101 266.718 159.189 267.645C159.281 268.571 158.6 269.398 157.674 269.49C146.488 270.583 140.132 273.297 139.189 274.874C139.039 275.125 139.03 275.3 139.147 275.58C139.506 276.44 139.101 277.429 138.241 277.788C138.029 277.876 137.807 277.917 137.59 277.917L137.595 277.913Z"
        fill="#1A1A1A"
      />
      <path
        d="M129.729 277.037C129.228 277.037 128.727 276.811 128.397 276.385C126.987 274.57 127.158 272.896 127.55 271.811C129.758 265.721 145.968 261.91 149.194 261.208C150.104 261.012 151.001 261.588 151.202 262.498C151.398 263.408 150.822 264.306 149.912 264.506C137.838 267.136 131.423 271.03 130.722 272.958C130.605 273.284 130.563 273.672 131.06 274.311C131.632 275.046 131.498 276.106 130.764 276.678C130.455 276.916 130.092 277.032 129.729 277.032V277.037Z"
        fill="#1A1A1A"
      />
      <path
        d="M122.099 275.292C121.335 275.292 120.643 274.766 120.459 273.99C118.205 264.381 140.296 258.562 142.825 257.932C143.731 257.706 144.645 258.253 144.87 259.159C145.096 260.061 144.549 260.979 143.643 261.204C136.644 262.957 122.567 268.2 123.744 273.222C123.956 274.127 123.393 275.037 122.487 275.25C122.358 275.279 122.229 275.296 122.099 275.296V275.292Z"
        fill="#1A1A1A"
      />
      <path
        d="M218.451 212.342C217.65 212.342 216.936 211.766 216.795 210.948C212.558 187.051 211.511 167.775 211.377 155.812C211.231 142.81 212.083 135.568 212.116 135.267C212.229 134.341 213.068 133.681 213.99 133.794C214.916 133.907 215.576 134.746 215.463 135.668C215.43 135.956 212.099 165.137 220.113 210.359C220.275 211.278 219.662 212.15 218.748 212.313C218.648 212.33 218.547 212.338 218.451 212.338V212.342Z"
        fill="#1A1A1A"
      />
      <path
        d="M337.335 133.08C336.596 133.08 335.916 132.588 335.711 131.84C335.678 131.719 332.21 119.735 318.646 114.309C318.587 114.288 318.533 114.259 318.479 114.23L300.995 104.905C300.173 104.467 299.864 103.444 300.303 102.622C300.741 101.799 301.763 101.491 302.586 101.929L319.99 111.212C335.094 117.298 338.817 130.396 338.971 130.951C339.217 131.849 338.687 132.78 337.786 133.022C337.636 133.063 337.489 133.08 337.339 133.08H337.335Z"
        fill="#1A1A1A"
      />
      <path
        d="M185.091 283.707C179.586 283.707 172.208 282.438 163.414 277.913L149.637 278.005C147.725 278.819 140.079 281.745 136.82 278.072C134.729 278.385 131.127 278.644 129.161 277.179C124.766 277.913 122.446 276.456 121.369 275.371C119.011 275.526 117.275 274.945 116.189 273.639C114.566 271.685 115.209 269.022 115.409 268.342C115.705 266.885 118.176 260.119 138.46 254.926C139.19 254.714 148.56 252.071 161.106 252.735C162.036 252.785 162.75 253.578 162.7 254.509C162.65 255.44 161.861 256.158 160.926 256.104C148.748 255.461 139.478 258.145 139.382 258.174C139.361 258.178 139.34 258.186 139.324 258.191C121.022 262.87 118.886 268.463 118.727 268.981C118.723 269.114 118.702 269.131 118.652 269.281C118.502 269.761 118.326 270.947 118.79 271.489C119.207 271.986 120.33 272.145 121.87 271.932C122.625 271.827 123.301 272.128 123.598 272.821C123.76 273.059 125.046 274.67 129.357 273.706C130.067 273.547 130.797 273.86 131.173 274.482C131.598 274.979 134.416 275.133 137.262 274.57C138.009 274.424 138.76 274.791 139.098 275.471C140.188 277.65 146.068 275.91 148.581 274.783C148.794 274.687 149.027 274.637 149.261 274.637L163.802 274.541C163.802 274.541 163.81 274.541 163.814 274.541C164.086 274.541 164.353 274.607 164.595 274.733C174.09 279.696 181.732 280.493 186.465 280.288C191.581 280.067 194.507 278.66 194.536 278.648C194.645 278.594 194.766 278.552 194.883 278.523L213.238 274.035C213.046 269.615 211.882 265.85 209.762 262.836C208.572 261.142 207.37 260.132 206.727 259.66L189.486 263.024C189.227 263.074 188.965 263.062 188.71 262.995L168.393 257.352C167.496 257.101 166.97 256.17 167.22 255.273C167.47 254.375 168.401 253.854 169.298 254.1L189.232 259.639L206.761 256.216C207.153 256.141 207.554 256.204 207.904 256.396C208.259 256.592 216.64 261.409 216.64 275.354C216.64 276.131 216.11 276.807 215.354 276.995L195.851 281.762C194.954 282.171 191.16 283.711 185.091 283.711V283.707Z"
        fill="#1A1A1A"
      />
      <path
        d="M312.278 117.987C312.278 117.987 294.794 124.982 286.635 164.031C286.635 164.031 279.206 205.847 264.344 219.839L197.036 256.992C197.036 256.992 204.031 267.048 211.898 283.657C211.898 283.657 275.274 259.614 304.703 239.799C304.703 239.799 326.848 207.742 329.761 191.426L312.278 117.991V117.987Z"
        fill="white"
      />
      <path
        d="M211.899 285.343C211.264 285.343 210.659 284.984 210.375 284.379C202.667 268.104 195.722 258.053 195.655 257.957C195.384 257.564 195.288 257.076 195.396 256.609C195.505 256.145 195.805 255.745 196.223 255.515L263.292 218.491C268.743 212.484 275.663 196.322 283.317 171.699C283.593 170.81 284.54 170.313 285.429 170.589C286.318 170.864 286.815 171.812 286.539 172.701C278.539 198.434 271.485 214.68 265.575 220.987C265.454 221.116 265.312 221.225 265.158 221.312L199.453 257.581C201.623 260.912 206.924 269.431 212.746 281.52C218.693 279.224 241.974 270.099 264.974 259.326C265.817 258.929 266.823 259.297 267.216 260.14C267.612 260.983 267.249 261.989 266.402 262.381C239.762 274.858 212.758 285.13 212.491 285.23C212.295 285.306 212.091 285.339 211.895 285.339L211.899 285.343Z"
        fill="#1A1A1A"
      />
      <path
        d="M273.922 258.608C273.296 258.608 272.699 258.262 272.407 257.66C271.998 256.821 272.344 255.811 273.183 255.402C285.913 249.183 296.113 243.519 303.509 238.568C305.128 236.277 317.979 217.681 328.187 190.825C328.517 189.952 329.489 189.514 330.362 189.848C331.234 190.178 331.672 191.154 331.338 192.023C320.312 221.041 306.209 240.597 306.067 240.789C305.95 240.947 305.808 241.085 305.646 241.198C298.104 246.278 287.682 252.076 274.665 258.437C274.427 258.554 274.173 258.608 273.926 258.608H273.922Z"
        fill="#1A1A1A"
      />
      <path
        d="M223.635 91.3893C220.68 91.3893 218.985 90.7548 218.864 90.7089C217.996 90.3708 217.562 89.3941 217.9 88.5259C218.234 87.6618 219.206 87.2319 220.07 87.5575C220.166 87.5908 224.256 89.0059 231.013 86.6684C231.894 86.3637 232.854 86.8312 233.159 87.7119C233.463 88.5926 232.996 89.5527 232.115 89.8574C228.685 91.0428 225.817 91.3893 223.63 91.3893H223.635Z"
        fill="#1A1A1A"
      />
      <path
        d="M287.799 224.585C287.47 224.585 287.136 224.489 286.844 224.288C286.764 224.234 281.447 220.686 273.313 221.087C272.378 221.129 271.593 220.415 271.543 219.484C271.497 218.553 272.215 217.764 273.146 217.714C282.486 217.259 288.505 221.337 288.759 221.508C289.523 222.039 289.715 223.09 289.185 223.854C288.855 224.326 288.329 224.581 287.795 224.581L287.799 224.585Z"
        fill="#1A1A1A"
      />
      <path
        d="M195.559 220.565C195.021 220.565 194.491 220.306 194.165 219.831C194.052 219.672 191.916 216.783 186.603 217.493C185.676 217.618 184.829 216.967 184.708 216.045C184.583 215.122 185.234 214.271 186.156 214.15C193.669 213.144 196.828 217.748 196.961 217.944C197.479 218.72 197.27 219.768 196.494 220.281C196.206 220.473 195.881 220.565 195.559 220.565Z"
        fill="#1A1A1A"
      />
      <path
        d="M123.877 259.18C123.781 259.18 123.685 259.171 123.584 259.155L111.352 257.022C109.478 257.347 101.506 258.533 97.5203 255.74C96.4018 254.96 95.6505 253.904 95.325 252.664C94.7908 251.984 94.3108 250.894 94.7198 249.65C95.3626 247.68 97.8751 245.297 111.719 244.349C112.65 244.291 113.455 244.988 113.518 245.919C113.58 246.849 112.879 247.655 111.948 247.718C100.713 248.486 98.426 250.151 98.0045 250.606C98.33 250.861 98.4719 251.178 98.5428 251.595C98.6347 252.167 98.9268 252.606 99.4569 252.981C101.802 254.626 107.804 254.271 111.034 253.657C111.235 253.62 111.439 253.62 111.64 253.653L124.169 255.841C125.087 255.999 125.7 256.876 125.542 257.794C125.4 258.612 124.686 259.192 123.881 259.192L123.877 259.18Z"
        fill="#1A1A1A"
      />
      <path
        d="M99.9622 245.146C99.766 245.146 99.5657 245.113 99.3695 245.038L88.0883 240.805C87.216 240.48 86.7736 239.507 87.0992 238.635C87.4247 237.762 88.3971 237.32 89.2694 237.646L100.551 241.878C101.423 242.204 101.865 243.176 101.54 244.049C101.285 244.725 100.642 245.142 99.9622 245.142V245.146Z"
        fill="#1A1A1A"
      />
      <path d="M32.7503 281.691L1.9575 213.256C1.14365 211.449 2.53346 209.416 4.51174 209.521L74.9579 212.689C77.5623 212.826 79.8661 214.408 80.9345 216.784L113.58 289.534L32.7461 281.691H32.7503Z" fill="#D9D9D9" />
      <path
        d="M113.59 291.22C113.535 291.22 113.481 291.22 113.427 291.212L99.9211 289.901C98.9946 289.809 98.3143 288.987 98.4061 288.06C98.4979 287.134 99.3201 286.458 100.247 286.545L110.86 287.576L79.4037 217.481C78.5898 215.673 76.8578 214.483 74.8753 214.379L25.1928 212.146C24.2621 212.104 23.5401 211.315 23.5818 210.385C23.6235 209.454 24.4082 208.732 25.3431 208.773L75.0381 211.011C78.3019 211.182 81.1483 213.131 82.4796 216.103L115.126 288.853C115.372 289.4 115.309 290.039 114.959 290.527C114.641 290.97 114.128 291.229 113.59 291.229V291.22Z"
        fill="#1A1A1A"
      />
      <path
        d="M89.2692 288.862C89.215 288.862 89.1607 288.862 89.1023 288.854L32.5876 283.369C31.9866 283.31 31.4607 282.935 31.2103 282.384L0.417481 213.949C-0.208559 212.559 -0.066659 210.977 0.793103 209.721C1.65286 208.464 3.07607 207.759 4.59943 207.838L16.8823 208.389C17.8131 208.431 18.5351 209.22 18.4933 210.151C18.4516 211.082 17.667 211.804 16.7321 211.762L4.43666 211.207C3.93165 211.173 3.66871 211.491 3.57689 211.624C3.48507 211.758 3.29309 212.117 3.49342 212.563L33.8856 280.109L89.4278 285.498C90.3544 285.589 91.0347 286.412 90.9429 287.338C90.8594 288.211 90.1248 288.862 89.2651 288.862H89.2692Z"
        fill="#1A1A1A"
      />
      <path
        d="M316.146 30.8772C316.146 30.8772 315.633 37.7812 308.859 39.6178C315.637 41.4544 316.146 48.3584 316.146 48.3584C316.146 48.3584 316.66 41.4544 323.434 39.6178C316.656 37.7812 316.146 30.8772 316.146 30.8772Z"
        fill="white"
      />
      <path
        d="M316.147 49.4436C315.579 49.4436 315.104 49.0054 315.062 48.4377C315.041 48.1914 314.498 42.2725 308.572 40.6655C308.1 40.5361 307.771 40.1062 307.771 39.6136C307.771 39.1211 308.1 38.6911 308.572 38.5659C314.498 36.9589 315.041 31.0442 315.062 30.7937C315.108 30.2302 315.579 29.792 316.147 29.792C316.715 29.792 317.186 30.2302 317.228 30.7937C317.249 31.0484 317.791 36.9631 323.718 38.5659C324.189 38.6953 324.519 39.1253 324.519 39.6136C324.519 40.102 324.189 40.5361 323.718 40.6655C317.754 42.2809 317.232 48.3751 317.228 48.4377C317.186 49.0054 316.715 49.4436 316.143 49.4436H316.147ZM311.727 39.6178C314.014 40.9118 315.358 42.8152 316.147 44.5098C316.932 42.8152 318.28 40.9118 320.567 39.6178C318.28 38.3238 316.936 36.4204 316.147 34.7257C315.362 36.4204 314.014 38.3238 311.727 39.6178Z"
        fill="#0B8487"
      />
      <path
        d="M169.554 111.296C169.554 111.296 169.04 118.2 162.267 120.036C169.045 121.873 169.554 128.777 169.554 128.777C169.554 128.777 170.067 121.873 176.841 120.036C170.063 118.2 169.554 111.296 169.554 111.296Z"
        fill="white"
      />
      <path
        d="M169.553 129.862C168.986 129.862 168.51 129.424 168.468 128.856C168.447 128.61 167.905 122.691 161.978 121.084C161.506 120.954 161.177 120.525 161.177 120.032C161.177 119.539 161.506 119.11 161.978 118.984C167.905 117.377 168.447 111.463 168.468 111.212C168.514 110.649 168.986 110.21 169.553 110.21C170.121 110.21 170.592 110.649 170.634 111.212C170.655 111.467 171.198 117.381 177.124 118.984C177.596 119.114 177.925 119.544 177.925 120.032C177.925 120.52 177.596 120.954 177.124 121.084C171.16 122.699 170.638 128.793 170.634 128.856C170.592 129.424 170.121 129.862 169.549 129.862H169.553ZM165.133 120.036C167.42 121.33 168.764 123.234 169.553 124.928C170.338 123.234 171.686 121.33 173.973 120.036C171.686 118.742 170.342 116.839 169.553 115.144C168.769 116.839 167.42 118.742 165.133 120.036Z"
        fill="#0B8487"
      />
    </svg>
  )
});

import React, { useState, useEffect } from "react";
import { Select as ChakraSelect, SelectProps } from "@chakra-ui/react";
import theme from "@/shared/theme";

export interface OptionType {
  label: string;
  value: string;
}

type PropsType = Omit<SelectProps, "value" | "onChange"> & {
  options: OptionType[];
  defaultValue?: string;
  onChange?: (value: string) => void;
};

export const Select: React.FC<PropsType> = ({ options, defaultValue, size, onChange, ...rest }) => {
  const basicStyles = {
    size,
    borderRadius: "4px",
    borderColor: theme.text.black200,
    focusBorderColor: theme.service.turquoiseText,
    color: theme.text.black900,
    fontSize: size === "sm" ? "12px" : size === "md" ? "16px" : "18px",
    variant: "outline"
  };

  const [value, setValue] = useState<typeof defaultValue>();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <ChakraSelect
      {...rest}
      {...basicStyles}
      value={value}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value);
        }
        setValue(e.target.value);
      }}
    >
      {options.map(({ value, label }) => (
        <option value={value} key={value}>
          {label}
        </option>
      ))}
    </ChakraSelect>
  );
};

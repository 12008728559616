import React, { useCallback } from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, CloseIcon, Text, Textarea } from "@/components";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import theme from "@/shared/theme";
import { useInviteUsersMutation } from "@/apollo/generated";

type PropsType = {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
};

export const MemberInviteModal: React.FC<PropsType> = ({ isOpen, onClose, refetch }) => {
  const { t } = useTranslation(["members"]);
  const toast = useToast();
  const [inviteUsersMutation, { loading: inviting }] = useInviteUsersMutation();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<{ emails: string }>({
    resolver: zodResolver(
      z
        .object({
          emails: z
            .string()
            .min(1, { message: t("form.validation.required", { ns: "common" }) ?? "" })
        })
        .refine(
          (data) => {
            const regex = RegExp(
              // eslint-disable-next-line no-control-regex
              "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])"
            );
            const emails = data.emails
              .split("\n")
              .map((email) => email.trim())
              .filter((e) => e !== "");

            if (emails.length < 1) return false;

            return emails.every((email) => regex.test(email));
          },
          { message: t("invalidatedEmail") ?? "", path: ["emails"] }
        )
    ),
    defaultValues: {}
  });

  const inviteUsers = useCallback(
    (values: { emails: string }) => {
      const emails = values.emails.split("\n").map((email) => email.trim());

      if (!emails.length) return;
      inviteUsersMutation({
        variables: {
          input: { emails, redirectUrl: window.location.origin + "/auth/signup" }
        }
      })
        .then((res) => {
          if (!res.data?.inviteUser?.duplicateEmails) {
            toast({
              description: t("inviting"),
              status: "success",
              position: "top",
              duration: 3000,
              isClosable: true
            });
          } else {
            toast({
              description: t("message"),
              status: "error",
              position: "top",
              duration: 3000,
              isClosable: true
            });
          }

          setValue("emails", "");
          refetch();
          onClose();
        })
        .catch(() => {});
    },
    [inviteUsersMutation, onClose, refetch, setValue, t, toast]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="630px" maxW="unset" p="28px 38px 64px 48px">
        <form onSubmit={handleSubmit(inviteUsers)}>
          <ModalHeader ps="0" pe="0">
            <Flex alignItems="center" justifyContent="space-between">
              <Text textType="title24">{t("inviteUserTitle")}</Text>
              <CloseIcon
                w="24px"
                h="24px"
                color={theme.text.black900}
                cursor="pointer"
                onClick={onClose}
              />
            </Flex>
          </ModalHeader>
          <ModalBody ps="0" pe="0">
            <Text textType="body14b" color={theme.text.black500} mb="16px">
              {t("email")}
            </Text>
            <FormControl isInvalid={!!errors["emails"]}>
              <Textarea
                {...register("emails")}
                minH="180px"
                placeholder={t("placeholder") ?? ""}
                fontSize="12px"
                fontWeight="500"
                p="12px 14px"
              />
              <FormErrorMessage>{errors["emails"] && errors["emails"]?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter ps="0" pe="0">
            <Button
              w="134px"
              buttonType="stroke"
              h="37px"
              mr="12px"
              onClick={() => {
                setValue("emails", "");
                onClose();
              }}
              borderRadius="40px"
            >
              <Text textType="body14b">{t("cancel", { ns: "common" })}</Text>
            </Button>
            <Button
              w="94px"
              buttonType="fill"
              type="submit"
              h="37px"
              isLoading={inviting}
              borderRadius="40px"
            >
              <Text textType="body14b">{t("invite")}</Text>
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

import React, { useCallback, useMemo, useState } from "react";
import { useGetCoachingSchedulesQuery } from "@/apollo/generated";
import { useTranslation } from "react-i18next";
import { Text, Button, AddIcon, Avatar, CloseIcon, Loading } from "@/components";
import { Box, Flex, Link, useDisclosure } from "@chakra-ui/react";
import theme from "@/shared/theme";
import { useGetProfileQuery } from "@/apollo/generated";
import moment from "moment";
import { SetStartTimeModal } from "../coaching-schedule/SetStartTimeModal";

export const CoachingSchedule: React.FC = () => {
  const { t } = useTranslation(["home"]);
  const { data, loading, refetch } = useGetCoachingSchedulesQuery();
  const { data: userProfile } = useGetProfileQuery();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedId, setSelectedId] = useState("");
  const [selectedNames, setSelectedNames] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>();

  const userId = userProfile?.me.id;

  const getWeekDay = useCallback((day: number) => {
    switch (day) {
      case 0:
        return "日";
      case 1:
        return "月";
      case 2:
        return "火";
      case 3:
        return "水";
      case 4:
        return "木";
      case 5:
        return "金";
      case 6:
        return "土";
      default:
        break;
    }
  }, []);

  const coachingSchedules = useMemo(() => {
    const nodes = data?.myTeam.coachingSchedules?.nodes;

    const matchUser = nodes?.map((n) => {
      const matchGroup = n?.coachingGroups?.filter((u) =>
        u.users?.find((uItem) => uItem.id === userId)
      );

      return {
        id: n?.id,
        date: n?.date,
        userGroup: matchGroup
      };
    });

    return matchUser;
  }, [data, userId]);

  if (loading) return <Loading />;

  return (
    <>
      <Box borderLeft={`1px solid ${theme.text.black200}`} p="0 25px" w="30%" minW="388px">
        <Text textType="title20" mb="16px">
          {t("coachingScheduleTitle")}
        </Text>
        <Link
          href="https://helpful-physician-069.notion.site/Cultee-8d5b2d15774b48f3bb84c2dcb2ee0823"
          target="_blank"
          _hover={{ textDecoration: "none" }}
        >
          <Button buttonType="fill" w="105px" h="37px" leftIcon={<AddIcon />}>
            <Text textType="body14b">{t("guide")}</Text>
          </Button>
        </Link>
        <Box>
          {coachingSchedules?.map((c) => (
            <Box key={c?.id} pt="16px">
              <Text mb="14px" textType="body16b">
                {c?.date ? new Date(c?.date).getMonth() + 1 : ""}
                {t("month", { ns: "common" })}
              </Text>
              {c?.userGroup &&
                c?.userGroup.map((group, idx: number) => (
                  <Box
                    key={idx}
                    border={`1px solid ${theme.text.black200}`}
                    borderRadius="4px"
                    p="16px"
                    mb="32px"
                  >
                    <Flex alignItems="center" flexWrap="wrap">
                      {group.users?.map((g, index) => (
                        <Flex key={index} alignItems="center">
                          {index ? <CloseIcon w="12.6px" h="12.6px" /> : null}
                          <Flex justifyContent="space-between" alignItems="center" w="139px">
                            <Flex alignItems="center" p="8px">
                              <Avatar src={g.avatarUrl ?? ""} mr="8px" size="xs" />
                              <Text textType="body14b">{g.familyName + " " + g.givenName}</Text>
                            </Flex>
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Flex alignItems="center" fontSize="16px" fontWeight={400} color="#000">
                        <Text minW="96px">{t("startTime")}</Text>
                        {group.startTime ? (
                          <Text>
                            {moment(group.startTime).format("YYYY年MM月DD日") +
                              "(" +
                              getWeekDay(moment(group.startTime).day()) +
                              ")" +
                              moment(group.startTime).format("HH:mm")}
                          </Text>
                        ) : (
                          <Text color="#B45555" fontWeight={700}>
                            {t("notEntered")}
                          </Text>
                        )}
                      </Flex>
                      <Button
                        w="60px"
                        h="32px"
                        fontSize="14px"
                        fontWeight={400}
                        onClick={() => {
                          setSelectedId(group.id ?? "");
                          setSelectedNames(
                            group.users?.length
                              ? group.users[0] && group.users[1]
                                ? [
                                    group.users[0].familyName + " " + group.users[0].givenName,
                                    group.users[1].familyName + " " + group.users[1].givenName
                                  ]
                                : []
                              : []
                          );
                          setSelectedDate(group.startTime);
                          onOpen();
                        }}
                      >
                        {t("update")}
                      </Button>
                    </Flex>
                  </Box>
                ))}
            </Box>
          ))}
        </Box>
      </Box>
      <SetStartTimeModal
        selectedId={selectedId}
        selectedNames={selectedNames}
        selectedDate={selectedDate}
        isOpen={isOpen}
        onClose={onClose}
        refetch={refetch}
      />
    </>
  );
};

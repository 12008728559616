import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Text, SigninForm, PinInputForm } from "@/components";
import { Divider, Box } from "@chakra-ui/react";
import theme from "@/shared/theme";

export const SigninFlows: React.FC = () => {
  const { t } = useTranslation(["signin", "common"]);
  const [step, setStep] = useState<number>(1);
  const [email, setEmail] = useState<string>();
  const navigate = useNavigate();

  return (
    <Box w="556px">
      <Text textType="title32">{t("signin")}</Text>
      <Divider bgColor={theme.service.turquoise400} h="1px" m="14px 0 16px" />
      <Box h="70vh" minH="490px" pos="relative">
        {step === 1 && (
          <SigninForm
            onSubmited={(email: string) => {
              setEmail(email);
              setStep(2);
            }}
          />
        )}
        {step === 2 && <PinInputForm onCompleted={() => navigate("/")} email={email ?? ""} />}
        <Text
          textType="body12r"
          color={theme.service.turquoiseText}
          textAlign="center"
          pos="absolute"
          bottom="0"
          left="160px"
        >
          © 2023 FUJITSU LEARNING MEDIA LIMITED
        </Text>
      </Box>
    </Box>
  );
};

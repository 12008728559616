import React from "react";
import { Avatar as ChakraAvatar, AvatarProps } from "@chakra-ui/react";
import { AvatarIcon } from "@/components";
import theme from "@/shared/theme";

export const Avatar: React.FC<AvatarProps> = ({ ...rest }) => {
  return (
    <ChakraAvatar {...rest} bgColor={theme.text.black200} icon={<AvatarIcon boxSize="1.6em" />} />
  );
};

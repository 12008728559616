import React, { useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Divider,
  Flex,
  UnorderedList,
  ListItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useToast,
  PopoverArrow
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Text, Avatar, Loading, SelectStampIcon, StampBox, StampList } from "@/components";
import theme from "@/shared/theme";
import { nonEmpty } from "@/utils/nonEmpty";
import {
  useGetRecentJournalingsQuery,
  ReactionStampFragment,
  useReactingToGoodThingMutation,
  useReactingToSwitchingActionMutation,
  useGetQuestionsLazyQuery,
  QuestionKind,
  Question
} from "@/apollo/generated";
import { useNavigate } from "react-router-dom";

export const RecentJournalings: React.FC = () => {
  const { t } = useTranslation(["home"]);
  const toast = useToast();
  const navigate = useNavigate();
  const { data, loading, refetch, fetchMore } = useGetRecentJournalingsQuery();
  const [getQuestions, { data: questionsData }] = useGetQuestionsLazyQuery();
  const [reactingToGoodThingMutation] = useReactingToGoodThingMutation();
  const [reactingToSwitchingActionMutation] = useReactingToSwitchingActionMutation();

  const userRecentFeeling = nonEmpty(data?.myTeam.recentJournalings?.nodes);
  const pageInfo = data?.myTeam.recentJournalings?.pageInfo;

  const questions: Question[] = useMemo(() => {
    const array: Question[] = [];
    if (questionsData?.myEnterprise.questions) {
      questionsData?.myEnterprise.questions.forEach((question) => {
        if (question.kind === QuestionKind.GoodThing) {
          array.unshift(question);
        } else if (question.kind === QuestionKind.SwitchingAction) {
          array.push(question);
        }
      });
    } else {
    }
    return array;
  }, [questionsData?.myEnterprise.questions]);

  const getStampLists = useCallback((values: ReactionStampFragment[]) => {
    let idxArr: number[] = [];
    let countArr: number[] = [];
    let userNames: Array<Array<string>> = new Array<Array<string>>();

    values.forEach((v: ReactionStampFragment) => {
      if (v.user) {
        if (idxArr.includes(Number(v?.emojiIndex))) {
          const index = idxArr.findIndex((a) => a === v?.emojiIndex);

          countArr[index]++;
          userNames[index]?.push(v.user.familyName + " " + v.user.givenName ?? "");
        } else {
          idxArr.push(v?.emojiIndex ?? 0);
          countArr.push(1);
          userNames.push([v.user.familyName + " " + v.user.givenName ?? ""]);
        }
      }
    });

    return idxArr.map((item, idx) => {
      return {
        stampIdx: item,
        count: countArr[idx],
        userNames: userNames[idx]
      };
    });
  }, []);

  const handleSubmit = useCallback(
    (action: string) => {
      refetch();

      if (action === "DESTROY") return;

      toast({
        position: "top",
        description: t("addStampSuccess"),
        status: "success",
        duration: 3000,
        isClosable: true
      });
    },
    [refetch, t, toast]
  );

  const reactingTo = useCallback(
    (idx: number, type: "goodThing" | "switchAction", id: string) => {
      type === "goodThing" &&
        reactingToGoodThingMutation({ variables: { input: { id: id, emojiIndex: idx } } })
          .then((res) => handleSubmit(res.data?.reactingToGoodThing?.action ?? ""))
          .catch(() => {});
      type === "switchAction" &&
        reactingToSwitchingActionMutation({ variables: { input: { id: id, emojiIndex: idx } } })
          .then((res) => handleSubmit(res.data?.reactingToSwitchingAction?.action ?? ""))
          .catch(() => {});
    },
    [reactingToGoodThingMutation, reactingToSwitchingActionMutation, handleSubmit]
  );

  const handleScroll = useCallback(async () => {
    const showHeight = window.innerHeight;
    const scrollTopHeight = document.body.scrollTop || document.documentElement.scrollTop;
    const allHeight = document.body.scrollHeight;
    if (allHeight <= showHeight + scrollTopHeight) {
      const endCursor = pageInfo?.endCursor;
      const hasNextPage = pageInfo?.hasNextPage;

      if (!hasNextPage) return;

      await fetchMore({
        variables: {
          first: 30,
          after: endCursor
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (fetchMoreResult.myTeam.recentJournalings?.nodes)
            fetchMoreResult.myTeam.recentJournalings.nodes = [
              ...nonEmpty(prevResult.myTeam.recentJournalings?.nodes),
              ...fetchMoreResult.myTeam.recentJournalings.nodes
            ];

          return fetchMoreResult;
        }
      });
    }
  }, [fetchMore, pageInfo]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  if (loading) return <Loading />;

  if (!userRecentFeeling.length)
    return (
      <>
        <Text textType="title20">{t("recentJournalingTitle")}</Text>
        <Text>{t("emptyMsg")}</Text>
      </>
    );

  return (
    <Box flexWrap="wrap">
      <Text textType="title20">{t("recentJournalingTitle")}</Text>
      <Flex flexWrap="wrap" w="100%">
        {userRecentFeeling.map((r, idx) => (
          <Box pt={idx ? "32px" : "22px"} key={idx} w="100%">
            <Flex
              alignItems="center"
              cursor="pointer"
              display="inline-flex"
              onClick={() =>
                navigate("/journalings", {
                  state: {
                    userId: r.user?.id
                  }
                })
              }
            >
              <Avatar src={r.user?.avatarUrl ?? ""} w="27px" h="27px" size="xs" mr="8px" />
              <Text textType="body12b" _hover={{ color: theme.service.turquoise400 }}>
                {r.user?.familyName + " " + r.user?.givenName}
              </Text>
            </Flex>
            <Flex pl="35px">
              <Text textType="body12r" color={theme.text.gray500}>
                {r.user?.goal ?? ""}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" pt="8px" pr="8px">
              {[
                { title: questions[0]?.title ?? "３Good things", lists: r.goodThing },
                { title: questions[1]?.title ?? "Switching Actions", lists: r.switchingAction }
              ].map((l, idxTop) => (
                <Box
                  key={l.title}
                  w="calc(50% - 8px)"
                  minH="200px"
                  p="16px"
                  border={`1px solid ${theme.text.black200}`}
                  borderRadius="4px"
                >
                  <Text textType="body16b">{l.title}</Text>
                  <Divider bgColor={theme.service.turquoise400} h="1px" m="16px 0" />
                  <UnorderedList spacing={2}>
                    {l.lists?.text?.map(
                      (t, idx1) =>
                        t && (
                          <ListItem key={idx1}>
                            <Text textType="body14r">{t}</Text>
                          </ListItem>
                        )
                    )}
                  </UnorderedList>
                  <Flex pt="16px" flexWrap="wrap">
                    {getStampLists(l.lists?.reactionStamps!).map((item, idx) => (
                      <Popover trigger="hover" key={idx} placement="top">
                        <PopoverTrigger>
                          <Flex alignItems="center" mr="12px">
                            <StampBox
                              mr="4px"
                              mb="4px"
                              idx={Number(item.stampIdx)}
                              cursor="pointer"
                              onClick={() =>
                                reactingTo(
                                  item.stampIdx,
                                  !!idxTop ? "switchAction" : "goodThing",
                                  r.id
                                )
                              }
                            />
                            <Text textType="body16m" minW="24px" textAlign="center">
                              {item.count}
                            </Text>
                          </Flex>
                        </PopoverTrigger>
                        <PopoverContent
                          w={item.userNames?.join(",").length > 46 ? "360px" : "249px"}
                        >
                          <PopoverArrow />
                          <PopoverBody p="15px 10px 18px">
                            <Box>
                              <StampBox m="0 auto" idx={Number(item.stampIdx)} />
                              <Text color={theme.text.black900} pt="8px" textType="body12r">
                                <Text textType="body12b" fontWeight={700} as="span">
                                  {item.userNames?.join(",")}
                                </Text>
                                {t("hasReacted")}
                              </Text>
                            </Box>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    ))}
                    <Popover placement="top-start" autoFocus={false}>
                      <PopoverTrigger>
                        <SelectStampIcon
                          cursor="pointer"
                          w="32px"
                          h="32px"
                          color={theme.text.black500}
                          _hover={{ color: theme.service.turquoise400 }}
                        />
                      </PopoverTrigger>
                      <PopoverContent w="264px">
                        <PopoverBody p="0">
                          <StampList
                            type={!!idxTop ? "switchAction" : "goodThing"}
                            id={r.id ?? ""}
                            onSubimt={handleSubmit}
                          />
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                </Box>
              ))}
            </Flex>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

import { makeVar } from "@apollo/client";
import { GraphQLError } from "graphql/error";
import { NetworkError } from "@apollo/client/errors";
import { ReactiveListener } from "@apollo/client/cache/inmemory/reactiveVars";
import { Key, getLocalStorageItem, removeLocalStorageItem } from "@/utils/utils";

export type ApiErrorType = GraphQLError | Exclude<NetworkError, null>;

export type CustomGraphQLError = GraphQLError & {
  extensions: { code: string; codes: string[] };
};

export const getErrorCodes = (error: ApiErrorType): string[] => {
  const graphQLError = error as CustomGraphQLError;

  if (graphQLError?.extensions) {
    return graphQLError.extensions.code
      ? [graphQLError.extensions.code, ...(graphQLError.extensions.codes || [])]
      : [...(graphQLError.extensions.codes || [])];
  }

  if (error as NetworkError) return ["NETWORK_ERROR"];

  return ["UNCATCHED"];
};

const apiErrorsVar = makeVar<ApiErrorType[]>([]);

const apiErrorsListener: ReactiveListener<ApiErrorType[]> = (errors) => {
  apiErrorsVar.onNextChange(apiErrorsListener);

  for (let err of errors) {
    for (let errCode of getErrorCodes(err)) {
      if (errCode === "UNAUTHENTICATED") {
        if (getLocalStorageItem<string>(Key.authorization)) {
          removeLocalStorageItem(Key.authorization);
          window.location.reload();
        }

        return;
      }
    }
  }
};

apiErrorsVar.onNextChange(apiErrorsListener);

export default apiErrorsVar;

import { createIcon } from "@chakra-ui/icons";

export const AddIcon = createIcon({
  displayName: "AddIcon",
  viewBox: "0 0 23 22",
  path: (
    <g fill="currentColor">
      <path
        d="M10.8262 3.26562H12.1738C12.2936 3.26562 12.3535 3.32292 12.3535 3.4375V18.5625C12.3535 18.6771 12.2936 18.7344 12.1738 18.7344H10.8262C10.7064 18.7344 10.6465 18.6771 10.6465 18.5625V3.4375C10.6465 3.32292 10.7064 3.26562 10.8262 3.26562Z"
        fill="currentColor"
      />
      <path
        d="M3.95312 10.1836H19.0469C19.1667 10.1836 19.2266 10.2409 19.2266 10.3555V11.6445C19.2266 11.7591 19.1667 11.8164 19.0469 11.8164H3.95312C3.83333 11.8164 3.77344 11.7591 3.77344 11.6445V10.3555C3.77344 10.2409 3.83333 10.1836 3.95312 10.1836Z"
        fill="currentColor"
      />
    </g>
  )
});

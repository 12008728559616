import React, { useCallback, useEffect, useState } from "react";
import { Flex, Img, Link, useMediaQuery } from "@chakra-ui/react";
import { Button, MenuIcon, NavigationDrawer } from "@/components";
import { useTranslation } from "react-i18next";
import theme from "@/shared/theme";

export const LandingPageHeader: React.FC<{ isAtTop: boolean }> = ({ isAtTop }) => {
  const { t } = useTranslation(["landing_page"]);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const scrollThreshold = 1000;

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;

    if (
      currentScrollY >= document.getElementById("tt")!.offsetTop &&
      currentScrollY <= document.getElementById("ss")!.offsetTop
    ) {
      setShowHeader(false);

      return;
    }

    if (currentScrollY > scrollThreshold) {
      if (currentScrollY > lastScrollY) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
    }
    setLastScrollY(currentScrollY);
  }, [lastScrollY, scrollThreshold]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Flex
      pos={"fixed"}
      left={0}
      top={showHeader ? 0 : isLargerThan768 ? "-120px" : "-80px"}
      zIndex={10000}
      bgColor={theme.text.white}
      w={"100%"}
      shadow={isAtTop ? undefined : showHeader ? "0 4px 4px rgba(0, 0, 0, 0.25)" : undefined}
      transition="top 0.5s"
    >
      <Flex
        p={isLargerThan768 ? "32px 40px" : "16px 24px"}
        justify="space-between"
        w={"100%"}
        alignItems={"center"}
      >
        <Img src="/assets/lp_logo.png" alt="lp_logo" w="240px" h="36.21px" draggable="false" />
        <Flex alignItems={"center"} gap={"24px"}>
          <Link
            href="https://www.knowledgewing.com/kw/od/download.html"
            isExternal
            textDecorationLine="none !important"
          >
            <Button
              buttonType="stroke"
              height={"56px"}
              borderRadius={"56px"}
              w={"212px"}
              display={isLargerThan768 ? "block" : "none"}
              hasShadow
            >
              {t("downloadDocuments")}
            </Button>
          </Link>
          <Link
            href="https://www.knowledgewing.com/kw/od/questionpage.html"
            isExternal
            textDecorationLine="none !important"
          >
            <Button
              buttonType="fill"
              height={"56px"}
              borderRadius={"56px"}
              w={"179px"}
              display={isLargerThan768 ? "block" : "none"}
              hasShadow
            >
              {t("inquiry")}
            </Button>
          </Link>
          <MenuIcon
            w={"48px"}
            height={"48px"}
            cursor={"pointer"}
            onClick={() => setIsDrawerOpen(true)}
          />
        </Flex>
      </Flex>
      <NavigationDrawer isDrawerOpen={isDrawerOpen} onDrawerClose={() => setIsDrawerOpen(false)} />
    </Flex>
  );
};

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Text, PasswordResetForm, PasswordResetIdForm, Button } from "@/components";
import { Divider, Box } from "@chakra-ui/react";
import theme from "@/shared/theme";

export const PasswordResetIdInput: React.FC = () => {
  const { t } = useTranslation(["passwordreset"]);
  const [step, setStep] = useState<number>(1);
  const [, setEmail] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/auth/password-reset-input") {
      setStep(3);
    }
    if (location.pathname === "/auth/password-reset-success") {
      setStep(4);
    }
  }, [location.pathname]);

  return (
    <Box w="556px">
      <Text textType="title32">{step === 4 ? t("step4.title") : t("title1")}</Text>
      <Divider bgColor={theme.service.turquoise400} h="1px" m="14px 0 16px" />
      <Box h="70vh" minH="490px" pos="relative">
        {step === 1 && (
          <>
            <Text textType="body14r" lineHeight="28.96px" m="40px 0">
              {t("step1.description")}
            </Text>
            <PasswordResetIdForm
              onSubmited={(email: string) => {
                setStep(2);
              }}
            />
          </>
        )}
        {step === 2 && (
          <>
            <Text textType="body14r" lineHeight="28.96px" m="40px 0 20px">
              {t("step2.description1")}
            </Text>
            <Text textType="body14r" lineHeight="28.96px">
              {t("step2.description2")}
            </Text>
          </>
        )}
        {step === 3 && (
          <>
            <Text textType="body14r" lineHeight="28.96px" m="40px 0">
              {t("step3.description")}
            </Text>
            <PasswordResetForm
              onSubmited={(email: string) => {
                setEmail(email);
                navigate("/auth/password-reset-success");
              }}
            />
          </>
        )}
        {step === 4 && (
          <>
            <Text textType="body14r" lineHeight="28.96px" m="40px 0 20px">
              {t("step4.description1")}
            </Text>
            <Text textType="body14r" lineHeight="28.96px" m="0 0 20px">
              {t("step4.description2")}
            </Text>
            <Text textType="body14r" lineHeight="28.96px">
              {t("step4.description3")}
            </Text>
            <Button
              mt="74px"
              w="556px"
              buttonSize="xl"
              buttonType="fill"
              type="submit"
              onClick={() => navigate("/auth/signin")}
            >
              {t("step4.linkto")}
            </Button>
          </>
        )}
        <Text
          textType="body12r"
          color={theme.service.turquoiseText}
          textAlign="center"
          pos="absolute"
          bottom="0"
          left="160px"
        >
          © 2023 FUJITSU LEARNING MEDIA LIMITED
        </Text>
      </Box>
    </Box>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, CloseIcon, Text } from "@/components";
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import theme from "@/shared/theme";
import { useSetCoachingGroupMutation } from "@/apollo/generated";
import moment from "moment";

type PropsType = {
  selectedId: string;
  selectedNames: string[];
  selectedDate?: Date | null;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
};

export const SetStartTimeModal: React.FC<PropsType> = ({
  selectedId,
  selectedNames,
  selectedDate,
  isOpen,
  onClose,
  refetch
}) => {
  const { t } = useTranslation(["home"]);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const [setCoachingGroupMutation, { loading }] = useSetCoachingGroupMutation();

  const startTime = useMemo(() => {
    return new Date(date + "T" + time);
  }, [date, time]);

  const setCoachingGroup = useCallback(() => {
    if (!selectedId || !startTime) return;

    setCoachingGroupMutation({ variables: { input: { id: selectedId, startTime: startTime } } })
      .then((res) => {
        if (res.data?.setCoachingGroup?.coachingGroup.id) {
          refetch();
        }
      })
      .catch(() => {});
  }, [startTime, refetch, setCoachingGroupMutation, selectedId]);

  useEffect(() => {
    setDate(selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : "");
    setTime(selectedDate ? moment(selectedDate).format("HH:mm") : "");
  }, [selectedDate]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="646px" h="288px" maxW="unset" p="28px 38px 12px 48px" borderRadius="24px">
        <ModalHeader ps="0" pe="0">
          <Flex justifyContent="flex-end">
            <CloseIcon
              w="22px"
              h="22px"
              color={theme.text.black900}
              cursor="pointer"
              onClick={onClose}
            />
          </Flex>
        </ModalHeader>
        <ModalBody ps="0" pe="0">
          <Text fontSize="20px" fontWeight={800} mb="28px">
            {selectedNames[0] + "さんと" + selectedNames[1] + "さんのコーチング実施日時"}
          </Text>
          <Flex>
            <Input
              size="sm"
              type="date"
              w="178px"
              mr="50px"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
            <Input
              size="sm"
              type="time"
              w="110px"
              value={time}
              onChange={(e) => {
                setTime(e.target.value);
              }}
            />
          </Flex>
        </ModalBody>
        <ModalFooter ps="0" pe="0">
          <Button
            w="134px"
            buttonType="stroke"
            h="37px"
            mr="12px"
            onClick={onClose}
            borderRadius="40px"
          >
            <Text textType="body14b">{t("cancel", { ns: "common" })}</Text>
          </Button>
          <Button
            w="134px"
            buttonType="fill"
            type="submit"
            h="37px"
            borderRadius="40px"
            isLoading={loading}
            disabled={!date || !time}
            onClick={async () => {
              onClose();
              await setCoachingGroup();
            }}
          >
            <Text textType="body14b">決定</Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

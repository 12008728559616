import React from "react";
import { Text as ChakraText, TextProps } from "@chakra-ui/react";

type PropsType = TextProps & {
  textType?:
    | "title32"
    | "title24"
    | "title20"
    | "paragraph24"
    | "body16b"
    | "body16m"
    | "body16r"
    | "body14b"
    | "body14m"
    | "body14r"
    | "body12b"
    | "body12m"
    | "body12r";
};

export const Text: React.FC<PropsType> = ({ textType, children, ...rest }) => {
  const typeStyles: Record<NonNullable<PropsType["textType"]>, TextProps> = {
    title32: { fontSize: "32px", fontWeight: 700 },
    title24: { fontSize: "24px", fontWeight: 700 },
    title20: { fontSize: "20px", fontWeight: 700 },
    paragraph24: { fontSize: "24px", fontWeight: 400 },
    body16b: { fontSize: "16px", fontWeight: 700 },
    body16m: { fontSize: "16px", fontWeight: 500 },
    body16r: { fontSize: "16px", fontWeight: 400 },
    body14b: { fontSize: "14px", fontWeight: 700 },
    body14m: { fontSize: "14px", fontWeight: 500 },
    body14r: { fontSize: "14px", fontWeight: 400 },
    body12b: { fontSize: "12px", fontWeight: 700 },
    body12m: { fontSize: "12px", fontWeight: 500 },
    body12r: { fontSize: "12px", fontWeight: 400 }
  };

  if (textType) {
    return (
      <ChakraText {...typeStyles[textType]} {...rest}>
        {children}
      </ChakraText>
    );
  }

  return <ChakraText {...rest}>{children}</ChakraText>;
};

import React from "react";
import { Box, Center, Flex, Img, useMediaQuery } from "@chakra-ui/react";
import { MainContent, Text } from "@/components";
import theme from "@/shared/theme";

export const DetailOfFunction: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return (
    <Box p={isLargerThan768 ? "8.3vw 0 0" : "17vw 0 0"} bgColor={theme.text.white} color={theme.text.black800} id="detail-of-function">
      <MainContent>
        <Flex alignItems={"center"} gap={isLargerThan768 ? "40px" : "16px"} w={"fit-content"} m={"0 auto"} letterSpacing={"10%"}>
          <Box w={"32px"} h={"2px"} bgColor={theme.text.black800} />
          <Text fontSize={isLargerThan768 ? "32px" : "18px"} fontWeight={300}>
            機能詳細
          </Text>
          <Box w={"32px"} h={"2px"} bgColor={theme.text.black800} />
        </Flex>
        <Center pt={"8.3vw"}>
          <Img src="/assets/computer.svg" alt="computer" draggable="false" w={isLargerThan768 ? "61.1vw" : "87.2vw"} />
        </Center>
      </MainContent>
    </Box>
  );
};

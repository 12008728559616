import React from "react";
import { Td, Tr, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { Avatar, Text, MoreIcon } from "@/components";
import theme from "@/shared/theme";
import { useTranslation } from "react-i18next";
import { MemberFragment } from "@/apollo/generated";

type PropsType = {
  user: MemberFragment;
  onDelete: (id: string) => void;
  onMemberTeamChange: (id: string) => void;
  onMemberStatusChange: (id: string) => void;
};

export const MemberTableTr: React.FC<PropsType> = ({
  user,
  onDelete,
  onMemberTeamChange,
  onMemberStatusChange
}) => {
  const { t } = useTranslation(["members"]);

  if (!user) return null;

  return (
    <Tr
      h="64px"
      borderBottom={`1px solid red`}
      alignItems="center"
      justifyContent="space-between"
      fontSize="14px"
    >
      <Td w="97px">
        <Avatar src={user.avatarUrl ?? ""} />
      </Td>
      <Td w="138px">
        <Text textType="body16r">{user.familyName + " " + user.givenName}</Text>
      </Td>
      <Td w="200px">
        <Text textType="body16r">{user.email}</Text>
      </Td>
      <Td>{user.status && <Text textType="body16r">{t(`status.${user.status}`)}</Text>}</Td>
      <Td justifySelf="flex-end" w="88px">
        <Menu>
          <MenuButton>
            <MoreIcon w="18px" h="7px" />
          </MenuButton>
          <MenuList
            h="112px"
            minW="0"
            w="138px"
            p="8px 0"
            boxShadow="0px 1px 12px rgba(118, 118, 118, 0.15)"
          >
            <MenuItem onClick={() => onMemberTeamChange(user.id)}> {t("changeTeam")}</MenuItem>
            <MenuItem onClick={() => onMemberStatusChange(user.id)}> {t("changeRole")}</MenuItem>
            <MenuItem onClick={() => onDelete(user.id)}>
              <Text textType="body14r" color={theme.error.redDark}>
                {t("delete", { ns: "common" })}
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
};

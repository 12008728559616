const theme = {
  text: {
    black900: "#000000",
    black800: "#1A1A1A",
    black700: "#626262",
    black500: "#9E9E9E",
    black200: "#DADADA",
    gray100: "#F5F5F5",
    gray200: "#FAFAFA",
    gray400: "#A0AEC0",
    gray500: "#767676",
    gray600: "#7C7B7B",
    gray700: "#2D3748",
    white: "#FFFFFF",
    ahubAppeal: "#FF7364"
  },
  gary: {
    gray50: "#D9D9D9",
    gray100: "#E7EDEE",
    gray200: "#AEAEAE"
  },
  primary: {
    green50: "#CEE6E7",
    green500: "#18A3A7",
    green900: "#0B8487",
    green1000: "#046769"
  },
  service: {
    turquoise400: "#03C7CD",
    turquoise50: "#CCFCFE",
    turquoiseText: "#18A3A7"
  },
  error: {
    red: "#DE2B46",
    redLight: "#FCEDEF",
    redDark: "#B01B31"
  },
  favourite: {
    yellow: "#F1DD24",
    yellowLight: "#F8EE92",
    yellowDark: "#7B7010"
  },
  emotions: {
    anger: "#DB3B37",
    sadness: "#224580",
    trust: "#129D63",
    joy: "#EFC143",
    digust: "#753E8B",
    hope: "#C9BC4A",
    fear: "#096307",
    shock: "#0096D0"
  },
  boxshadows: {
    xs: "0px 0px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
  }
};

export default theme;

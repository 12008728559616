import { createIcon } from "@chakra-ui/icons";

export const MoreIcon = createIcon({
  displayName: "MoreIcon",
  viewBox: "0 0 24 6",
  path: (
    <g fill="currentColor">
      <path
        d="M6.33268 2.99967C6.33268 1.44134 5.05768 0.166341 3.49935 0.166341C1.94102 0.166341 0.666016 1.44134 0.666016 2.99967C0.666016 4.55801 1.94102 5.83301 3.49935 5.83301C5.05768 5.83301 6.33268 4.55801 6.33268 2.99967ZM9.16602 2.99967C9.16602 4.55801 10.441 5.83301 11.9993 5.83301C13.5577 5.83301 14.8327 4.55801 14.8327 2.99967C14.8327 1.44134 13.5577 0.166341 11.9993 0.166341C10.441 0.166341 9.16602 1.44134 9.16602 2.99967ZM17.666 2.99967C17.666 4.55801 18.941 5.83301 20.4993 5.83301C22.0577 5.83301 23.3327 4.55801 23.3327 2.99967C23.3327 1.44134 22.0577 0.166341 20.4993 0.166341C18.941 0.166341 17.666 1.44134 17.666 2.99967Z"
        fill="#333333"
      />
    </g>
  )
});

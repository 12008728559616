import theme from "@/shared/theme";
import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { Text } from "@/components";

export const TimeBadgeGroup: React.FC = () => {
  const list: string[] = ["組織変革とDX人材育成", "顧客・従業員体験づくり", "現場DXとデータサイエンス", "組織コミュニケーション"];

  return (
    <Flex pos={"absolute"} bottom={"12px"} left={"284px"} gap={"38px"}>
      {list.map((item, index) => (
        <Box key={item}>
          <Flex pos={"relative"}>
            <Flex w="40px" bg={"#4E666A"} h="34px" fontSize="12px" fontWeight={700} color={theme.text.white} justifyContent="end" alignItems={"center"} pl="8px">
              {index + 1}回目
            </Flex>
            <Box w="0" h="0" border="17px solid transparent" borderLeftColor={"#4E666A"} />
          </Flex>
          <Text mt={"4px"} fontSize={"12px"} fontWeight={500} color="#4E666A" wordBreak={"keep-all"}>
            {item}
          </Text>
        </Box>
      ))}
    </Flex>
  );
};

import { Box, BoxProps, Flex } from "@chakra-ui/react";
import React from "react";

type PropsType = {
  type: "topToBottom" | "bottomToTop" | "left";
  isFirstStep?: boolean;
} & BoxProps;

export const CustomLineBox: React.FC<PropsType> = ({ type, isFirstStep, ...rest }) => {
  const customStyles: Record<NonNullable<PropsType["type"]>, BoxProps> = {
    topToBottom: {
      borderLeft: `2px solid #4E666A`,
      borderBottom: `2px solid #4E666A`,
      pos: "absolute",
      top: "50%",
      left: "50%",
      transform: isFirstStep ? "translateY(10px) translateX(-1px)" : "translateY(17px)"
    },
    bottomToTop: {
      borderRight: `2px solid #4E666A`,
      borderBottom: `2px solid #4E666A`,
      pos: "absolute",
      top: "-43px",
      left: "27px",
      transform: "translateY(10px) translateX(-1px)"
    },
    left: {
      height: "34px",
      width: isFirstStep ? "76px" : "42px",
      pos: "absolute",
      right: isFirstStep ? "-58px" : "-24px",
      top: isFirstStep ? "0" : "27px",
      alignItems: "center"
    }
  };

  if (type === "topToBottom")
    return (
      <Box {...rest} {...customStyles[type]} zIndex={99}>
        <Box w="0" h="0" border="6px solid transparent" borderLeftColor="#4E666A" pos={"absolute"} right={"-10px"} bottom={"-7px"} />
      </Box>
    );

  if (type === "left")
    return (
      <Flex {...rest} {...customStyles[type]} zIndex={99}>
        <Flex h={"12px"} alignItems={"center"}>
          <Box w={isFirstStep ? "64px" : "32px"} bgColor={"#4E666A"} height={"2px"} />
          <Box w="0" h="0" border="6px solid transparent" borderLeftColor="#4E666A" />
        </Flex>
      </Flex>
    );

  if (type === "bottomToTop")
    return (
      <Box {...rest} {...customStyles[type]} zIndex={99}>
        <Box w="0" h="0" border="6px solid transparent" borderBottomColor="#4E666A" pos={"absolute"} right={"-7px"} top={"-9px"} />
      </Box>
    );

  return <></>;
};

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, SignupForm, PinInputForm, SignupCompleted } from "@/components";
import { Divider, Box } from "@chakra-ui/react";
import theme from "@/shared/theme";

export const SignupFlows: React.FC = () => {
  const { t } = useTranslation(["signup", "common"]);
  const [step, setStep] = useState<number>(1);
  const [email, setEmail] = useState<string>();

  return (
    <Box w="556px">
      <Text textType="title32">{step === 3 ? t("memberSignupCompleted") : t("memberSignup")}</Text>
      <Divider bgColor={theme.service.turquoise400} h="1px" m="14px 0 16px" />
      <Box h="670px" pos="relative">
        {step === 1 && (
          <SignupForm
            onSubmited={(email: string) => {
              setEmail(email);
              setStep(2);
            }}
          />
        )}
        {step === 2 && <PinInputForm onCompleted={() => setStep(3)} email={email ?? ""} />}
        {step === 3 && <SignupCompleted />}
        <Text
          textType="body12r"
          color={theme.service.turquoiseText}
          textAlign="center"
          pos="absolute"
          bottom="0"
          left="160px"
        >
          © 2023 FUJITSU LEARNING MEDIA LIMITED
        </Text>
      </Box>
    </Box>
  );
};

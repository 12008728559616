import { Box } from "@chakra-ui/react";
import { ReactNode, useEffect } from "react";
import { useInView } from "react-intersection-observer";

type PropsType = {
  currentIdx: number;
  onSetActiveIdx: (value: number) => void;
  children: ReactNode;
};

export const InViewComponent: React.FC<PropsType> = ({ children, currentIdx, onSetActiveIdx }) => {
  const rootMarginTop = 8.3 * (window.innerWidth / 100);
  const { ref, inView } = useInView({
    rootMargin: `-${rootMarginTop}px 0px 0px 0px`
  });

  useEffect(() => {
    if (inView) {
      onSetActiveIdx(currentIdx);
    }
  }, [inView, currentIdx, onSetActiveIdx]);

  return <Box ref={ref}>{children}</Box>;
};

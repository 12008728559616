import React, { useEffect, useRef, useState } from "react";
import { Box, Flex, Img, useMediaQuery } from "@chakra-ui/react";
import { MainContent, Text } from "@/components";
import theme from "@/shared/theme";
import { Fade } from "react-awesome-reveal";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const UtilizationCycle: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [activeIdx, setActiveIdx] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const tabs = gsap.utils.toArray(".li");

  const dataList: {
    title: string;
    text: string;
  }[] = [
    {
      title: "目標設定",
      text: "チーム目標、個人目標の達成に向けて、今、重点的に取り組みたい目標を設定します。目標設定後は、定期的な振り返りと対話を通して、達成に向けたアクション検討と、状況に合わせた目標の更新を行っていきます。"
    },
    {
      title: "振り返り",
      text: "感情を起点に、起きた事象や設定した目標に対する行動を振り返ります。定期的に振り返りを行うことで情報が蓄積し、自身の変化を振り返るデータとしても活用できるようになります。"
    },
    {
      title: "対話",
      text: "設定した目標に対する振り返りを深め、次の目標設定やアクションにつなげる対話を実施します。1か月単位でペアが変更されるため、様々なメンバーの視点を取り入れることができます。"
    }
  ];

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "center center",
        pin: true,
        scrub: 0,
        onUpdate: (self) => {
          const progress = self.progress;
          const newIdx = Math.round(progress * (tabs.length - 1));
          if (newIdx !== activeIdx && newIdx !== -1) {
            setActiveIdx(newIdx);
          }
        }
      }
    });

    return () => {
      tl.scrollTrigger?.kill();
    };
  }, [containerRef, tabs, activeIdx]);

  useEffect(() => {
    imgRef.current!.style.opacity = "0";

    setTimeout(() => {
      imgRef.current!.style.opacity = ".5";
    }, 40);

    setTimeout(() => {
      imgRef.current!.style.opacity = ".8";
    }, 100);

    setTimeout(() => {
      imgRef.current!.style.opacity = "1";
    }, 200);
  }, [activeIdx]);

  return (
    <Box
      height={"auto"}
      p={isLargerThan768 ? "8.3vw 0" : "17vw 0"}
      bgColor={theme.text.white}
      color={theme.text.black800}
    >
      <MainContent>
        <Flex
          alignItems={"center"}
          gap={isLargerThan768 ? "40px" : "16px"}
          w={"fit-content"}
          m={"0 auto"}
          letterSpacing={"10%"}
        >
          <Box w={"32px"} h={"2px"} bgColor={theme.text.black800} />
          <Text
            fontSize={isLargerThan768 ? "32px" : "18px"}
            fontWeight={300}
            lineHeight={!isLargerThan768 ? "31.5px" : undefined}
            textAlign={!isLargerThan768 ? "center" : undefined}
          >
            チーム運営における
            {!isLargerThan768 && <br />}
            お悩みにお応えします
          </Text>
          <Box w={"32px"} h={"2px"} bgColor={theme.text.black800} />
        </Flex>
        <Box p={"8.3vw 0"} textAlign={"center"} id="usage">
          <Text textType="title32" mb={"24px"} fontSize={!isLargerThan768 ? "20px" : "32px"}>
            Culteeの活用サイクル
          </Text>
          <Text fontSize={isLargerThan768 ? "24px" : "16px"}>
            ３つのプロセスの循環で、目標達成に向けて
            {isLargerThan768 && <br />}
            個人とチームが自ら気づき変わることをご支援します
          </Text>
        </Box>
        <Flex
          gap={"4.5%"}
          alignItems={"center"}
          justifyContent={"space-between"}
          hidden={!isLargerThan768}
          ref={containerRef}
        >
          <Box w={"50%"}>
            <Img
              src={`/assets/polygon_${activeIdx + 1}.svg`}
              alt="polygon"
              draggable="false"
              transition={"all .2s ease-in-out"}
              ref={imgRef}
            />
          </Box>
          <Flex flexDir={"column"} gap={"48px"} w={"45.5%"} ref={listRef}>
            {dataList.map((item, idx) => (
              <Box
                className="li"
                key={item.title}
                p={"16px 40px"}
                shadow={"0 4px 4px rgba(0, 0, 0, 0.25)"}
                borderRadius={"4px"}
                borderLeft={idx === activeIdx ? `16px solid ${theme.primary.green900}` : undefined}
                pl={idx === activeIdx ? "24px" : undefined}
                color={idx === activeIdx ? theme.text.black800 : theme.gary.gray200}
                transition={"all .2s ease-in-out"}
              >
                <Text textType="title20" mb={"8px"}>
                  {item.title}
                </Text>
                <Text lineHeight={1.75}>{item.text}</Text>
              </Box>
            ))}
          </Flex>
        </Flex>
        <Flex hidden={isLargerThan768} gap={"40px"} flexDir={"column"}>
          {dataList.map((item, idx) => (
            <Fade duration={1500} fraction={0.4} key={item.title}>
              <Img
                src={`/assets/polygon_${idx + 1}.svg`}
                alt="polygon"
                draggable="false"
                mb={"24px"}
                w={"100%"}
              />
              <Box
                key={item.title}
                cursor={"pointer"}
                p={"16px 40px"}
                shadow={"0 4px 4px rgba(0, 0, 0, 0.25)"}
                borderRadius={"4px"}
                borderLeft={`16px solid ${theme.primary.green900}`}
                pl={"24px"}
                color={theme.text.black800}
                transition={"all .2s ease-out"}
              >
                <Text textType="title24" mb={"8px"}>
                  {item.title}
                </Text>
                <Text fontSize={"14px"} fontWeight={300} lineHeight={1.75}>
                  {item.text}
                </Text>
              </Box>
            </Fade>
          ))}
        </Flex>
      </MainContent>
    </Box>
  );
};

import { Text } from "@/components";
import { Flex, Img, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import theme from "@/shared/theme";

export const Footer: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return (
    <Flex direction="column" align="center" p={isLargerThan768 ? "40px 160px 80px" : "64px 24px"}>
      <Img src="/assets/lp_logo.png" alt="lp_logo" draggable="false" w="280px" h="42.24px" mb={isLargerThan768 ? "24px" : "12px"} />
      <Text fontSize={isLargerThan768 ? "16px" : "12px"} fontWeight={400} color={theme.text.black800} letterSpacing="0.6px">
        ©Fujitsu Learning Media All Rights Reserved.
      </Text>
    </Flex>
  );
};

import { useContext } from "react";
import { AuthHeaderContext } from "@/contexts/AuthHeaderContext";

export const useAuthHeaderContext = () => {
  const ctx = useContext(AuthHeaderContext);

  if (!ctx) {
    throw new Error("AuthHeader context must be use inside AuthHeaderProvider");
  }

  return ctx;
};

import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "@/components";

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [view, setView] = useState<ReactNode | null>(null);

  useEffect(() => {
    if (!location.search) {
      setView(<Loading />);
      return;
    }

    setView(children);
  }, [children, location.search, navigate]);

  return <>{view}</>;
}

export enum Key {
  authorization = "__authorization",
  adminAuthorization = "__admin_authorization"
}

export const getLocalStorageItem = <T extends unknown>(key: Key): T | undefined => {
  if (typeof window !== "undefined") {
    const item = window.sessionStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : undefined;
  }
};

export const setLocalStorageItem = <T extends unknown>(key: Key, value: T) =>
  window.sessionStorage.setItem(key, JSON.stringify(value));

export const removeLocalStorageItem = (key: Key) => window.sessionStorage.removeItem(key);

export const clearLocalStorage = () => window.sessionStorage.clear();

import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Radio,
  RadioGroup,
  Textarea,
  useToast
} from "@chakra-ui/react";
import { Text } from "@/components";
import { useTranslation } from "react-i18next";
import theme from "@/shared/theme";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EnterpriseStatus,
  Question,
  QuestionKind,
  useCreateEnterpriseMutation,
  useGetEnterpriseInfoLazyQuery,
  useUpdateEnterpriseMutation
} from "@/apollo/generated";
import { useRefetchContext } from "@/hooks/useRefetchContext";
import { getByteLengthOfString } from "@/utils/getByteLengthOfString";

type questionType = {
  id: number;
  title1: string;
  title2: string;
  label: string;
};

type FormType = {
  name: string;
  nameHan: string;
  status: EnterpriseStatus;
  note: string;
  questions: {
    title: string;
    description: string;
  }[];
};

type PropsType = {
  id?: string;
};

export const EnterpriseForm: React.FC<PropsType> = ({ id }) => {
  const toast = useToast();
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const location = useLocation();
  const { setRefetch } = useRefetchContext();
  const [getEnterpriseInfo, { data }] = useGetEnterpriseInfoLazyQuery();
  const [statusVal, setStatusVal] = useState(EnterpriseStatus.Activated);
  const [questions1Id, setQuestions1Id] = useState<string>();
  const [questions2Id, setQuestions2Id] = useState<string>();
  const [createEnterpriseMutation] = useCreateEnterpriseMutation();
  const [updateEnterpriseMutation] = useUpdateEnterpriseMutation();

  const questionInputs: questionType[] = useMemo(() => {
    return [
      {
        id: 0,
        label: t("questionContent.questionRequest1"),
        title1: t("questionContent.question1Text1"),
        title2: t("questionContent.question1Text2")
      },
      {
        id: 1,
        label: t("questionContent.questionRequest2"),
        title1: t("questionContent.question2Text1"),
        title2: t("questionContent.question2Text2")
      }
    ];
  }, [t]);

  const formShape = {
    name: z
      .string()
      .min(1, { message: t("enterpriseCreate.message") ?? "" })
      .refine(
        (val) => {
          return getByteLengthOfString(val) <= 1000;
        },
        { message: t("enterpriseCreate.digitalPrompt") ?? "" }
      ),
    nameHan: z
      .string()
      .min(1, { message: t("enterpriseCreate.message") ?? "" })
      .refine(
        (val) => {
          return getByteLengthOfString(val) <= 1000;
        },
        { message: t("enterpriseCreate.digitalPrompt") ?? "" }
      ),
    status: z
      .string()
      .min(1, { message: t("enterpriseCreate.message") ?? "" })
      .refine(
        (val) => {
          return getByteLengthOfString(val) <= 1000;
        },
        { message: t("enterpriseCreate.digitalPrompt") ?? "" }
      ),
    note: z.string().refine(
      (val) => {
        return getByteLengthOfString(val) <= 1000;
      },
      { message: t("enterpriseCreate.digitalPrompt") ?? "" }
    ),
    questions: z.array(
      z.object({
        title: z
          .string()
          .min(1, { message: t("enterpriseCreate.message") ?? "" })
          .refine(
            (val) => {
              return getByteLengthOfString(val) <= 1000;
            },
            { message: t("enterpriseCreate.digitalPrompt") ?? "" }
          ),
        description: z
          .string()
          .min(1, { message: t("enterpriseCreate.message") ?? "" })
          .refine(
            (val) => {
              return getByteLengthOfString(val) <= 1000;
            },
            { message: t("enterpriseCreate.digitalPrompt") ?? "" }
          )
      })
    )
  };

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<FormType>({
    resolver: zodResolver(z.object(formShape))
  });

  const handleFormSubmit = useCallback(
    (values: FormType) => {
      const input = {
        name: values.name,
        nameHan: values.nameHan,
        status: values.status,
        note: values.note,
        questions: [
          {
            title: values.questions[0]?.title ?? "",
            kind: QuestionKind.GoodThing,
            description: values.questions[0].description,
            id: questions1Id
          },
          {
            title: values.questions[1]?.title ?? "",
            kind: QuestionKind.SwitchingAction,
            description: values.questions[1].description,
            id: questions2Id
          }
        ]
      };

      if (id) {
        updateEnterpriseMutation({
          variables: { input: { ...input, id } }
        })
          .then(() => {
            navigate("/admin");
          })
          .catch(() => {
            toast({
              position: "top",
              description: t("enterpriseCreate.errorMessage"),
              status: "error",
              duration: 3000,
              isClosable: true
            });
          });
      } else {
        createEnterpriseMutation({
          variables: { input: input }
        })
          .then(() => {
            navigate("/admin");
            setRefetch(true);
          })
          .catch(() => {
            toast({
              position: "top",
              description: t("enterpriseCreate.errorMessage"),
              status: "error",
              duration: 3000,
              isClosable: true
            });
          });
      }
    },
    [
      createEnterpriseMutation,
      id,
      navigate,
      questions1Id,
      questions2Id,
      setRefetch,
      t,
      toast,
      updateEnterpriseMutation
    ]
  );

  useEffect(() => {
    if (id) {
      getEnterpriseInfo({ variables: { id } });
    }
  }, [getEnterpriseInfo, id]);

  useEffect(() => {
    const enterprise = data?.adminHome?.enterprise;

    if (enterprise) {
      setValue("name", enterprise?.name ?? "");
      setValue("nameHan", enterprise?.nameHan ?? "");
      setValue("note", enterprise?.note ?? "");

      if (enterprise?.status) {
        setStatusVal(enterprise.status);
        setValue("status", enterprise.status);
      }

      if (enterprise?.questions) {
        const array: Question[] = [];
        enterprise.questions.forEach((question) => {
          if (question.kind === QuestionKind.GoodThing) {
            array.unshift(question);
          } else if (question.kind === QuestionKind.SwitchingAction) {
            array.push(question);
          }
        });

        const questions1 = array[0];
        const questions2 = array[1];

        if (questions1) {
          setValue("questions.0.title", questions1.title ?? t("questionContent.question1Text1"));
          setValue(
            "questions.0.description",
            questions1.description ?? t("questionContent.question1Text2")
          );
          setQuestions1Id(questions1.id);
        }

        if (questions2) {
          setValue("questions.1.title", questions2.title ?? t("questionContent.question1Text2"));
          setValue(
            "questions.1.description",
            questions2.description ?? t("questionContent.question2Text2")
          );
          setQuestions2Id(questions2.id);
        }
      }
    } else {
      setStatusVal(EnterpriseStatus.Activated);
    }
  }, [data, id, setValue, t]);

  useEffect(() => {
    setValue("status", statusVal);
  }, [setValue, statusVal]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Flex pt="52px" flexDir="column">
        <Text textType="title24" color={theme.text.black900}>
          {t("enterpriseCreate.info")}
        </Text>
        <Flex mt="16px">
          <Text color={theme.text.gray600} textType="body16r" minW="128px" mr="84px">
            {t("enterpriseCreate.name")}
          </Text>
          <FormControl isInvalid={!!errors["name"]}>
            <Input
              w="399px"
              h="36px"
              focusBorderColor={theme.service.turquoiseText}
              defaultValue={data?.adminHome?.enterprise.name || ""}
              {...register("name")}
            />
            <FormErrorMessage>
              {errors["name"] && (errors["name"]?.message as string)}
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex mt="30px">
          <Text color={theme.text.gray600} textType="body16r" minW="128px" mr="84px">
            {t("enterpriseCreate.kana")}
          </Text>
          <FormControl isInvalid={!!errors["nameHan"]}>
            <Input
              w="399px"
              h="36px"
              focusBorderColor={theme.service.turquoiseText}
              defaultValue={data?.adminHome?.enterprise.nameHan || ""}
              {...register("nameHan")}
            />
            <FormErrorMessage>
              {errors["nameHan"] && (errors["nameHan"]?.message as string)}
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex mt="43px">
          <Text color={theme.text.gray600} textType="body16r" minW="128px" mr="84px">
            {t("enterpriseCreate.note")}
          </Text>
          <FormControl isInvalid={!!errors["note"]}>
            <Textarea
              w="399px"
              h="124px"
              focusBorderColor={theme.service.turquoiseText}
              defaultValue={data?.adminHome?.enterprise.note || ""}
              {...register("note")}
            />
            <FormErrorMessage>
              {errors["note"] && (errors["note"]?.message as string)}
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex mt="54px">
          <Text color={theme.text.gray600} textType="body16r" minW="128px" mr="84px">
            {t("enterpriseCreate.staus")}
          </Text>
          <RadioGroup
            value={statusVal}
            onChange={(val) => {
              setStatusVal(
                val === EnterpriseStatus.Activated
                  ? EnterpriseStatus.Activated
                  : EnterpriseStatus.Stopped
              );
            }}
            sx={{
              "& .chakra-radio__control": {
                _checked: {
                  bgColor: theme.service.turquoiseText,
                  borderColor: theme.service.turquoiseText
                }
              }
            }}
          >
            <Radio
              value={EnterpriseStatus.Activated}
              mr="72px"
              size="lg"
              colorScheme={theme.service.turquoiseText}
            >
              <Text color={theme.text.gray600} textType="body16r">
                {t("enterpriseCreate.ACTIVATED")}
              </Text>
            </Radio>
            <Radio
              value={EnterpriseStatus.Stopped}
              size="lg"
              colorScheme={theme.service.turquoiseText}
            >
              <Text color={theme.text.gray600} textType="body16r">
                {t("enterpriseCreate.STOPPED")}
              </Text>
            </Radio>
          </RadioGroup>
        </Flex>
        <Divider w="696px" mt="57px" h="1px" color={theme.text.gray500} />
        <Text textType="title24" color={theme.text.black900} mt="82px">
          {t("questionContent.title")}
        </Text>
        {questionInputs.map((item) => (
          <Flex mt={item.id === 0 ? "57px" : "67px"} key={item.id}>
            <Text minW="163px" mr="46px" textType="body16b">
              {item.label}
            </Text>
            <Flex flexDir="column" w="399px">
              <Flex flexDir="column">
                <Text textType="body12r" color={theme.text.gray600}>
                  {item.title1}
                </Text>
                <FormControl
                  isInvalid={
                    !!(
                      errors["questions"] &&
                      errors["questions"][item.id] &&
                      errors["questions"][item.id]?.["title"]
                    )
                  }
                >
                  <Input
                    mt="10px"
                    h="36px"
                    fontSize="14px"
                    fontWeight="400"
                    focusBorderColor={theme.service.turquoiseText}
                    defaultValue={
                      item.id === 0 ? t("goodThingsTitle") ?? "" : t("switchingActionsTitle") ?? ""
                    }
                    {...register(`questions.${item.id}.title`)}
                  />
                  <FormErrorMessage>
                    {errors["questions"] &&
                      errors["questions"][item.id] &&
                      (errors["questions"][item.id]?.["title"]?.message as string)}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex flexDir="column" mt="26px">
                <Text textType="body12r" color={theme.text.gray600}>
                  {item.title2}
                </Text>
                <FormControl
                  isInvalid={
                    !!(
                      errors["questions"] &&
                      errors["questions"][item.id] &&
                      errors["questions"][item.id]?.["description"]
                    )
                  }
                >
                  <Input
                    mt="10px"
                    h="36px"
                    fontSize="14px"
                    fontWeight="400"
                    focusBorderColor={theme.service.turquoiseText}
                    defaultValue={
                      item.id === 0 ? t("goodThingsMsg") ?? "" : t("switchingActionsMsg") ?? ""
                    }
                    {...register(`questions.${item.id}.description`)}
                  />
                  <FormErrorMessage>
                    {errors["questions"] &&
                      errors["questions"][item.id] &&
                      (errors["questions"][item.id]?.["description"]?.message as string)}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
            </Flex>
          </Flex>
        ))}
        <Button
          w="200px"
          h="48px"
          ml="209px"
          mt="89px"
          mb="46px"
          borderRadius="4px"
          bgColor={theme.service.turquoiseText}
          type="submit"
        >
          <Text color={theme.text.white}>
            {location.pathname.includes("edit") ? t("enterpriseEdit.update") : t("register")}
          </Text>
        </Button>
      </Flex>
    </form>
  );
};

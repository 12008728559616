import { createIcon } from "@chakra-ui/icons";

export const ArrowIcon = createIcon({
  displayName: "ArrowIcon",
  viewBox: "0 0 11 17",
  path: (
    <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.16688 0.940918L10.0469 2.82092L3.94021 8.94092L10.0469 15.0609L8.16688 16.9409L0.166875 8.94092L8.16688 0.940918Z" fill="#1A1A1A" />
    </svg>
  )
});

import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormErrorMessage
} from "@chakra-ui/react";
import { Button, Text, CloseIcon, Avatar, Input } from "@/components";
import { useTranslation } from "react-i18next";
import { useGetMembersQuery, JournalingFilterInput } from "@/apollo/generated";
import theme from "@/shared/theme";
import { nonEmpty } from "@/utils/nonEmpty";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import moment from "moment";
import { UserStatus } from "@/apollo/generated";

type PropsType = {
  isOpen: boolean;
  initState: JournalingFilterInput;
  onClose: () => void;
  onSearch: (value: JournalingFilterInput) => void;
};

type Form = {
  startDate: Date;
  endDate: Date;
};

export const JournalingSearchModal: React.FC<PropsType> = ({
  isOpen,
  initState,
  onClose,
  onSearch
}) => {
  const { t } = useTranslation(["journaling"]);
  const [selectMembers, setSelectMembers] = useState<string[]>([]);
  const { data } = useGetMembersQuery();

  const memebrs = nonEmpty(data?.myTeam.users?.nodes).filter(
    (n) => n.status === UserStatus.Activated
  );

  const dateInputs: { key: "startDate" | "endDate"; defaultValue: string }[] = useMemo(
    () => [
      {
        key: "startDate",
        defaultValue: initState.dateFrom ? moment(initState.dateFrom).format("YYYY-MM-DD") : ""
      },
      {
        key: "endDate",
        defaultValue: initState.dateTo ? moment(initState.dateTo).format("YYYY-MM-DD") : ""
      }
    ],
    [initState]
  );

  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
    clearErrors
  } = useForm<Form>({
    resolver: zodResolver(
      z
        .object({
          startDate: z.any(),
          endDate: z.any()
        })
        .refine(
          (data) => {
            if (data.startDate && !data.endDate) return true;
            if (!data.startDate && data.endDate) return true;
            if (!data.startDate && !data.endDate) return true;

            if (data.startDate && data.endDate)
              return moment(data.endDate).diff(moment(data.startDate)) > 0;
          },
          {
            message: "絞り込む日付に誤りがあります",
            path: ["startDate"]
          }
        )
    ),
    defaultValues: {}
  });

  const values = watch();

  const confirmSearch = useCallback(
    (values: Form) => {
      const input: JournalingFilterInput = {
        dateFrom: new Date(values.startDate),
        dateTo: new Date(values.endDate),
        userIds: selectMembers
      };

      onSearch(input);
      reset();
      onClose();
    },
    [selectMembers, onSearch, onClose, reset]
  );

  const memberClick = useCallback(
    (id: string) => {
      let memberArray = [...selectMembers];
      if (memberArray.includes(id)) {
        memberArray = memberArray.filter((m) => m !== id);
      } else {
        memberArray.push(id);
      }
      setSelectMembers(memberArray);
    },
    [selectMembers, setSelectMembers]
  );

  useEffect(() => {
    if (!initState) return;

    setSelectMembers(initState.userIds ?? []);
  }, [initState, setSelectMembers]);

  useEffect(() => {
    if (moment(values.endDate).diff(moment(values.startDate)) > 0) clearErrors("startDate");
  }, [clearErrors, values.endDate, values.startDate]);

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} isCentered>
      <ModalOverlay />
      <ModalContent w="630px" maxW="unset" p="32px">
        <ModalHeader ps="0" pe="0">
          <Flex alignItems="center" justifyContent="space-between">
            <Text textType="title24" color={theme.text.black900}>
              {t("searchTitle")}
            </Text>
            <CloseIcon
              w="24px"
              h="24px"
              color={theme.text.black900}
              cursor="pointer"
              onClick={() => {
                setSelectMembers(initState.userIds ?? []);
                onClose();
              }}
            />
          </Flex>
        </ModalHeader>
        <ModalBody p="0">
          <form onSubmit={handleSubmit(confirmSearch)}>
            <Text textType="body16b" color={theme.text.black700}>
              {t("member")}
            </Text>
            <Box h="187px" overflow="scroll" p="16px 0">
              {memebrs.map((m) => (
                <Flex
                  key={m.id}
                  float="left"
                  minW="125px"
                  h="41px"
                  p="8px"
                  alignItems="center"
                  borderRadius="4px"
                  border={`1px solid ${theme.text.black200}`}
                  mr="8px"
                  mb="16px"
                  cursor="pointer"
                  onClick={() => memberClick(m.id)}
                  bgColor={selectMembers.includes(m.id) ? theme.service.turquoiseText : undefined}
                  color={selectMembers.includes(m.id) ? theme.text.white : undefined}
                >
                  <Avatar src={m.avatarUrl ?? ""} size="xs" mr="8px" />
                  <Text textType="body14b">{m.familyName + " " + m.givenName}</Text>
                </Flex>
              ))}
            </Box>
            <Divider h="1px" />
            <Flex justifyContent="space-between" pt="16px">
              {dateInputs.map((input) => (
                <FormControl key={input.key} isInvalid={!!errors[input.key]} w="270px">
                  <Input type="date" {...register(input.key)} defaultValue={input.defaultValue} />
                  <FormErrorMessage>{errors[input.key]?.message}</FormErrorMessage>
                </FormControl>
              ))}
            </Flex>
            <Flex mt="24px" justifyContent="flex-end">
              <Button
                w="134px"
                buttonType="stroke"
                h="37px"
                mr="12px"
                onClick={() => {
                  setSelectMembers(initState.userIds ?? []);
                  onClose();
                }}
                borderRadius="40px"
              >
                {t("cancel", { ns: "common" })}
              </Button>
              <Button w="94px" buttonType="fill" h="37px" type="submit" borderRadius="40px">
                {t("comfirm")}
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

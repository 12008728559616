import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./lang/i18n";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { ApolloProvider } from "@apollo/client";
import { client } from "@/apollo/client";
import { AuthHeaderProvider } from "@/contexts/AuthHeaderContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <ChakraProvider portalZIndex={99999}>
      <AuthHeaderProvider>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </AuthHeaderProvider>
    </ChakraProvider>
  </React.StrictMode>
);

import React from "react";
import { Center, Text, Spinner } from "@chakra-ui/react";
import theme from "@/shared/theme";

export const Loading: React.FC<{ height?: string }> = ({ height }) => {
  return (
    <Center h={height ?? "80vh"} flexDirection="column">
      <Spinner color={theme.service.turquoise400} size="lg" speed="1s" />
      <Text mt={2}>loading...</Text>
    </Center>
  );
};

import { createIcon } from "@chakra-ui/icons";

export const EyeIcon = createIcon({
  displayName: "EyeIcon",
  viewBox: "0 0 16 14",
  path: (
    <g fill="none">
      <path
        d="M17.4018 6.49609C15.5503 2.5957 12.7515 0.632812 8.9995 0.632812C5.2456 0.632812 2.44872 2.5957 0.597159 6.49805C0.522892 6.65531 0.484375 6.82706 0.484375 7.00098C0.484375 7.17489 0.522892 7.34665 0.597159 7.50391C2.44872 11.4043 5.24755 13.3672 8.9995 13.3672C12.7534 13.3672 15.5503 11.4043 17.4018 7.50195C17.5522 7.18555 17.5522 6.81836 17.4018 6.49609ZM8.9995 11.9609C5.84911 11.9609 3.54247 10.3633 1.91552 7C3.54247 3.63672 5.84911 2.03906 8.9995 2.03906C12.1499 2.03906 14.4565 3.63672 16.0835 7C14.4585 10.3633 12.1518 11.9609 8.9995 11.9609ZM8.92138 3.5625C7.02294 3.5625 5.48388 5.10156 5.48388 7C5.48388 8.89844 7.02294 10.4375 8.92138 10.4375C10.8198 10.4375 12.3589 8.89844 12.3589 7C12.3589 5.10156 10.8198 3.5625 8.92138 3.5625ZM8.92138 9.1875C7.71239 9.1875 6.73388 8.20898 6.73388 7C6.73388 5.79102 7.71239 4.8125 8.92138 4.8125C10.1304 4.8125 11.1089 5.79102 11.1089 7C11.1089 8.20898 10.1304 9.1875 8.92138 9.1875Z"
        fill="#767676"
      />
    </g>
  )
});

import { Flex } from "@chakra-ui/react";
import React from "react";
import { Header, Sidebar } from ".";
import { Outlet } from "react-router-dom";
import theme from "@/shared/theme";
import { RefetchContextProvider } from "@/contexts/RefetchContext";

export const Layout: React.FC = () => {
  return (
    <RefetchContextProvider>
      <Flex maxW="100vw" bg={theme.text.gray200} minH="100vh" minW="1438px" flexDir="column">
        <Header />
        <Flex pt="48px" minW="1438px">
          <Sidebar />
          <Flex w="calc(100vw - 214px)" p="32px 24px" justify="center" minW="1224px">
            <Flex
              w="1176px"
              bgColor={theme.text.white}
              p="66px 68px 23px"
              flexDir="column"
              minH="calc(100vh - 48px - 48px - 16px)"
            >
              <Outlet />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </RefetchContextProvider>
  );
};

import { createIcon } from "@chakra-ui/icons";

export const HomeIcon = createIcon({
  displayName: "HomeIcon",
  viewBox: "0 0 22 20",
  path: (
    <g fill="none">
      <path
        d="M1.40234 10.2967L6.02969 6.6264L10.5571 3.0359C10.6174 2.98803 10.6984 2.98803 10.7587 3.0359L15.2844 6.6264L19.9117 10.2967"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8024 8.2207V17.489C16.8024 17.5863 16.7214 17.6661 16.6215 17.6661H10.659H4.69652C4.5966 17.6661 4.51562 17.5879 4.51562 17.489V8.2207"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  )
});

import React from "react";
import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import { MainContent, Text, TextTitle } from "@/components";
import theme from "@/shared/theme";
import { LinkButtons } from "./LinkButtons";

export const Concept2: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const list: {
    img: string;
    title: string;
    content: string;
  }[] = [
    {
      img: "/assets/thing_1.svg",
      title: "感情から自分を知る",
      content:
        'EI(Emotional Intelligence:感情の知性)の観点から、メンバー個人の活動の振り返りをサポートします。目に見えない資産である自身の"感情"を可視化することで、行動の源を見つめなおし、より良いネクストアクションにつなげることができます。'
    },
    {
      img: "/assets/thing_2.svg",
      title: "自分たちで変わる",
      content: "コーチングの手法を使って、目標達成への気づきを引き出す対話をサポートします。毎月のランダムペア設定と気軽に取り組めるガイドで、お互いの成長を後押しできます。"
    },
    {
      img: "/assets/thing_3.svg",
      title: "チームの土台を作る",
      content:
        "全員がフラットなオーナー(管理者)として記録を閲覧できる仕組みで、タテ・ヨコで助け合う・支え合う関係性の土台作りをサポートします。いつでも・だれでも、チームやメンバーの今の気持ちや状況がわかることで、より良いチームワークを生み出すための行動につなげることができます。"
    }
  ];

  return (
    <Box bgImage="url(/assets/three_things.png)" bgRepeat={"no-repeat"} w="100%" h="100%" bgSize={isLargerThan768 ? "100% 90%" : "120% 100%"} bgPos={isLargerThan768 ? "bottom" : "bottom"}>
      <MainContent>
        <Box p={isLargerThan768 ? "220px 0 240px" : "100px 24px 16px 24px"} id="concept" textAlign="center">
          <TextTitle text="Concept" color={theme.text.black800} />
          <Flex mt={isLargerThan768 ? "120px" : "60px"} flexDirection="column" gap={isLargerThan768 ? "40px" : "10px"} fontSize={isLargerThan768 ? "28px" : "16px"}>
            <Text>助け合う・支え合うことで</Text>
            <Flex justifyContent={"center"}>
              <Text>
                チームの『
                <Text as={"span"} textType={isLargerThan768 ? "title32" : "title20"}>
                  成長
                </Text>
                』と『
                <Text as={"span"} textType={isLargerThan768 ? "title32" : "title20"}>
                  自律性
                </Text>
                』を生み出す
              </Text>
            </Flex>
            <Text>チーム開発支援ツール</Text>
          </Flex>
        </Box>
        <Box p={isLargerThan768 ? "120px 80px" : "472px 0 96px"} id="three-things-can-do" mb={isLargerThan768 ? "158px" : "131px"}>
          <TextTitle text="Culteeだからできる3つのこと" color={theme.text.white} />
          <Box mt="120px">
            {list.map((item) => (
              <Flex key={item.title} alignItems="center" gap={"24px"} flexDir={!isLargerThan768 ? "column" : undefined}>
                <img src={item.img} width={!isLargerThan768 ? 200 : undefined} alt="thing_img" />
                <Flex flexDir={"column"} gap={"16px"}>
                  <Text fontSize={"28px"} fontWeight={700} color={theme.text.white}>
                    {item.title}
                  </Text>
                  <Text fontSize={isLargerThan768 ? "18px" : "14px"} color={theme.text.white} lineHeight={1.75}>
                    {item.content}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Box>
        </Box>
        <Box p={isLargerThan768 ? "80px 0" : "64px 0"}>
          <LinkButtons isGray />
        </Box>
      </MainContent>
    </Box>
  );
};

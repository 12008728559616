import React from "react";
import { Box, Flex, Img, useMediaQuery } from "@chakra-ui/react";
import { MainContent, Text } from "@/components";
import theme from "@/shared/theme";

export const Concerns: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const dataList: {
    image: string;
    text: string;
  }[] = [
    { image: "avatar1.svg", text: "組織の目標が自分事になっていないメンバーが多い" },
    { image: "avatar2.svg", text: "メンバーとの1on1に限界を感じている" },
    { image: "avatar3.svg", text: "テレワークが増え、メンバーの状況が見えない" },
    { image: "avatar4.svg", text: "チームの中から新しいアイディアが生まれない" },
    { image: "avatar5.svg", text: "メンバー同士の連携がとれていない" },
    { image: "avatar6.svg", text: "チームの意識を変えたいがどこから手をつけたらいいかわからない" }
  ];

  return (
    <Box p={isLargerThan768 ? "8.3vw 0" : "17vw 0"} bgColor={theme.gary.gray100} color={theme.text.black800} id="concerns">
      <MainContent>
        <Flex alignItems={"center"} gap={isLargerThan768 ? "40px" : "16px"} w={"fit-content"} m={"0 auto"} letterSpacing={"10%"}>
          <Box w={"32px"} h={"2px"} bgColor={theme.text.black800} />
          <Text fontSize={isLargerThan768 ? "32px" : "18px"} fontWeight={300}>
            こんなお悩みありませんか？
          </Text>
          <Box w={"32px"} h={"2px"} bgColor={theme.text.black800} />
        </Flex>
        <Box mt={"8.3vw"}>
          <Flex bgColor={theme.primary.green900} borderRadius={"32px"} p={"4.44vw 3.68vw"} gap={isLargerThan768 ? "40px" : "16px"} flexWrap={"wrap"}>
            {dataList.map((item) => (
              <Flex
                key={item.image}
                bgColor={theme.text.white}
                w={isLargerThan768 ? "calc(50% - 20px)" : "100%"}
                borderRadius={"24px"}
                p={isLargerThan768 ? "23px 32px" : "16px"}
                alignItems="center"
                gap={isLargerThan768 ? "24px" : "8px"}
                flexDir={isLargerThan768 ? undefined : "column"}
                textAlign={isLargerThan768 ? undefined : "center"}
              >
                <Img src={`/assets/${item.image}`} alt="avatar_img" w="100px" draggable="false" />
                <Text fontSize={"18px"} fontWeight={700} lineHeight={"31.5px"}>
                  {item.text}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Box>
      </MainContent>
    </Box>
  );
};

import React, { useCallback, useMemo } from "react";
import { Button, Text } from "@/components";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import theme from "@/shared/theme";
import { useAuthHeaderContext } from "@/hooks/useAuthHeaderContext";
import { EnterpriseEdgeNodeFragment } from "@/apollo/generated";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { format } from "date-fns";

type FormType = {
  teamId: string;
  month: string;
};

type PropsType = {
  isOpen: boolean;
  onClose: () => void;
  selectCompany: EnterpriseEdgeNodeFragment;
};

export const ExportModal: React.FC<PropsType> = ({ isOpen, onClose, selectCompany }) => {
  const { t } = useTranslation(["admin"]);
  const { adminHeader } = useAuthHeaderContext();

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<FormType>({
    resolver: zodResolver(
      z.object({
        teamId: z.string().min(1, { message: t("enterpriseCreate.message") ?? "" }),
        month: z.string().min(1, { message: t("enterpriseCreate.message") ?? "" })
      })
    )
  });

  const handleFileDownload = useCallback(
    (values: FormType) => {
      const nameHan = selectCompany?.node?.nameHan;
      const teamName = selectCompany.node?.teams?.filter((i) => {
        return i.id === values.teamId;
      })[0].name;
      const [year, month] = values.month.split(/年|月/);
      const exportTime = `${year}/${month}`;

      const headers = new Headers();
      headers.append("authorization", adminHeader || "");
      headers.append("X-Req-From-Admin", "");
      fetch(
        process.env.REACT_APP_CULTEE_API_RESTAPIS_URL +
          `/download_csv?team_id=${values.teamId}&month=${exportTime}`,
        {
          method: "GET",
          headers: headers
        }
      )
        .then((res) => res.blob())
        .then((blob) => {
          let a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = `${nameHan ?? ""}-${teamName ?? ""}-${values.month ?? ""}.csv`;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          a.remove();
          onClose();
        });
    },
    [adminHeader, onClose, selectCompany.node?.nameHan, selectCompany.node?.teams]
  );

  const dateArr: string[] = useMemo(() => {
    const array: string[] = [];
    if (selectCompany.node?.createdAt) {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      const createdYear = new Date(selectCompany.node?.createdAt).getFullYear();
      const createdMonth = new Date(selectCompany.node?.createdAt).getMonth() + 1;

      if (currentYear === createdYear) {
        for (let month = createdMonth; month <= currentMonth; month++) {
          array.push(currentYear + "-" + month);
        }
      } else {
        for (let year = createdYear; year <= currentYear; year++) {
          if (year === createdYear) {
            for (let month = createdMonth; month <= 12; month++) {
              array.push(year + "-" + month);
            }
          } else if (year === currentYear) {
            for (let month = 1; month <= currentMonth; month++) {
              array.push(year + "-" + month);
            }
          } else {
            for (let month = 1; month <= 12; month++) {
              array.push(year + "-" + month);
            }
          }
        }
      }
    }

    return array
      .map((date) => {
        return format(new Date(date), "yyyy年MM月");
      })
      .reverse();
  }, [selectCompany.node?.createdAt]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="560px" maxW="unset" p="36px 53px 49px 56px" borderRadius="4px">
        <form onSubmit={handleSubmit(handleFileDownload)}>
          <ModalBody p="0" mb="41px">
            <Text textType="title32" mb="21px" letterSpacing="0.96px">
              {t("csv.title")}
            </Text>
            <Flex>
              <Text textType="body16r" letterSpacing="0.48px" mr="17px">
                {t("csv.enterpriseName")}
              </Text>
              <Text textType="body16b" letterSpacing="0.48px">
                {selectCompany?.node?.nameHan ?? ""}
              </Text>
            </Flex>
            <Flex flexDir="column" w="450px" mt="24px">
              <FormControl isInvalid={!!errors["teamId"]}>
                <Select
                  placeholder={t("csv.placeholder1") ?? ""}
                  fontSize="16px"
                  fontWeight="700"
                  letterSpacing="0.48px"
                  h="44px"
                  lineHeight="44px"
                  isInvalid={!!errors["teamId"]}
                  {...register("teamId")}
                >
                  {selectCompany?.node?.teams?.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors["teamId"] && errors["teamId"]?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors["month"]} mt="32px">
                <Select
                  placeholder={t("csv.placeholder2") ?? ""}
                  fontSize="16px"
                  fontWeight="700"
                  letterSpacing="0.48px"
                  h="44px"
                  lineHeight="44px"
                  isInvalid={!!errors["month"]}
                  {...register("month")}
                >
                  {dateArr?.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors["month"] && errors["month"]?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
          </ModalBody>
          <ModalFooter p="0" justifyContent="space-between">
            <Button
              p="14px 53.5px"
              w="210px"
              h="58px"
              borderRadius="4px"
              colorScheme="blackAlpha"
              variant="outline"
              onClick={onClose}
            >
              <Text textType="title20" color={theme.text.black900}>
                {t("csv.cancel")}
              </Text>
            </Button>
            <Button
              p="14px 53.5px"
              w="210px"
              h="58px"
              borderRadius="4px"
              color={theme.text.white}
              bgColor={theme.service.turquoiseText}
              type="submit"
              buttonType="fill"
            >
              <Text textType="title20">{t("csv.export")}</Text>
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

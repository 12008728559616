import { useContext } from "react";
import { RefetchContext } from "@/contexts/RefetchContext";

export const useRefetchContext = () => {
  const ctx = useContext(RefetchContext);

  if (!ctx) {
    throw new Error("Refetch context must be use inside RefetchContextProvider");
  }

  return ctx;
};

import theme from "@/shared/theme";
import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { CustomLineBox } from "./CustomLineBox";

export const TimeBadgeLineGroup: React.FC = () => {
  return (
    <Flex pos={"absolute"} top={"122px"} left={"361px"} gap={"55px"}>
      {Array.from(Array(4), (_, index) => (
        <Flex pos={"relative"} key={index}>
          <Box w="55px" bg={theme.primary.green900} h="34px" fontSize="12px" fontWeight={700} color={theme.text.white} alignContent="center" pl="16px">
            {index + 1}回目
          </Box>
          <Box w="0" h="0" border="17px solid transparent" borderLeftColor={theme.primary.green900} />
          {index !== 3 ? (
            <CustomLineBox type="left" isFirstStep />
          ) : (
            <Flex h={"228px"} pos={"absolute"} right={"-27px"} top={"16px"}>
              <Box w={"20px"} borderTop={`2px solid #4E666A`} borderRight={`2px solid #4E666A`} />
              <Box w={"26px"} borderBottom={`2px solid #4E666A`} pos={"relative"}></Box>
              <Box w="0" h="0" border="6px solid transparent" borderLeftColor="#4E666A" pos={"absolute"} bottom={"-5px"} right="-10px" />
            </Flex>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

import { createIcon } from "@chakra-ui/icons";

export const BottomArrowIcon = createIcon({
  displayName: "BottomArrowIcon",
  viewBox: "0 0 12 8",
  path: (
    <g xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
      <path
        d="M1.41 7.40991L6 2.82991L10.59 7.40991L12 5.99991L6 -8.82626e-05L1.23266e-07 5.99991L1.41 7.40991Z"
        fill="#333333"
      />
    </g>
  )
});

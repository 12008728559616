import { createIcon } from "@chakra-ui/icons";

export const SearchIcon = createIcon({
  displayName: "SearchIcon",
  viewBox: "0 0 22 20",
  path: (
    <g fill="none">
      <path
        d="M19.139 16.6895L13.6743 11.6172C14.5223 10.5996 14.981 9.35547 14.981 8.04688C14.981 6.48047 14.3224 5.01172 13.1314 3.9043C11.9404 2.79687 10.3538 2.1875 8.66825 2.1875C6.98274 2.1875 5.39612 2.79883 4.20511 3.9043C3.012 5.00977 2.35547 6.48047 2.35547 8.04688C2.35547 9.61133 3.0141 11.084 4.20511 12.1895C5.39612 13.2969 6.98063 13.9062 8.66825 13.9062C10.0781 13.9062 11.4164 13.4805 12.5127 12.6953L17.9775 17.7656C17.9935 17.7805 18.0125 17.7923 18.0335 17.8004C18.0544 17.8084 18.0769 17.8126 18.0995 17.8126C18.1222 17.8126 18.1447 17.8084 18.1656 17.8004C18.1865 17.7923 18.2056 17.7805 18.2216 17.7656L19.139 16.916C19.1551 16.9011 19.1678 16.8835 19.1765 16.864C19.1852 16.8446 19.1896 16.8238 19.1896 16.8027C19.1896 16.7817 19.1852 16.7609 19.1765 16.7414C19.1678 16.722 19.1551 16.7043 19.139 16.6895ZM12.0014 11.1406C11.1092 11.9668 9.92659 12.4219 8.66825 12.4219C7.4099 12.4219 6.22731 11.9668 5.3351 11.1406C4.445 10.3125 3.95471 9.21484 3.95471 8.04688C3.95471 6.87891 4.445 5.7793 5.3351 4.95312C6.22731 4.12695 7.4099 3.67188 8.66825 3.67188C9.92659 3.67188 11.1113 4.125 12.0014 4.95312C12.8915 5.78125 13.3818 6.87891 13.3818 8.04688C13.3818 9.21484 12.8915 10.3145 12.0014 11.1406Z"
        fill="currentColor"
      />
    </g>
  )
});

import { Flex, FlexProps } from "@chakra-ui/react";
import React from "react";
import { Text } from "@/components";
import theme from "@/shared/theme";

type PropsType = {
  message: string;
  isVisible: boolean;
} & FlexProps;

export const ErrorMessage: React.FC<PropsType> = ({ message, isVisible, ...rest }) => {
  if (isVisible) {
    return (
      <Flex
        borderRadius="4px"
        h="40px"
        border={`1px solid ${theme.error.redDark}`}
        alignItems="center"
        justifyContent="center"
        mb="16px"
        {...rest}
      >
        <Text textType="body16b" color={theme.error.redDark}>
          {message}
        </Text>
      </Flex>
    );
  }
  return null;
};

import { createIcon } from "@chakra-ui/icons";

export const ReflectionIcon = createIcon({
  displayName: "ReflectionIcon",
  viewBox: "0 0 22 20",
  path: (
    <g fill="none">
      <path
        d="M13.4565 17.4251C13.1051 16.6392 12.5953 15.9254 11.9553 15.3234C11.3173 14.7196 10.5615 14.2382 9.72965 13.9058C9.7222 13.9023 9.71475 13.9005 9.7073 13.897C10.8676 13.1055 11.6219 11.8163 11.6219 10.3618C11.6219 7.95226 9.55457 6 7.00298 6C4.45138 6 2.38402 7.95226 2.38402 10.3618C2.38402 11.8163 3.13833 13.1055 4.29865 13.8987C4.2912 13.9023 4.28375 13.904 4.2763 13.9075C3.44191 14.2399 2.69319 14.7166 2.05064 15.3251C1.41127 15.9276 0.901496 16.6414 0.549478 17.4269C0.203657 18.1959 0.0171477 19.0204 4.65732e-05 19.8558C-0.000450532 19.8746 0.00303608 19.8932 0.0103011 19.9107C0.0175661 19.9282 0.0284624 19.9441 0.042348 19.9576C0.0562336 19.971 0.0728275 19.9817 0.091152 19.989C0.109476 19.9963 0.129161 20 0.149045 20H1.26653C1.34848 20 1.41367 19.9384 1.41553 19.8628C1.45278 18.505 2.03015 17.2334 3.05079 16.2696C4.10682 15.2724 5.50927 14.7236 7.00298 14.7236C8.49669 14.7236 9.89913 15.2724 10.9552 16.2696C11.9758 17.2334 12.5532 18.505 12.5904 19.8628C12.5923 19.9402 12.6575 20 12.7394 20H13.8569C13.8768 20 13.8965 19.9963 13.9148 19.989C13.9331 19.9817 13.9497 19.971 13.9636 19.9576C13.9775 19.9441 13.9884 19.9282 13.9957 19.9107C14.0029 19.8932 14.0064 19.8746 14.0059 19.8558C13.9873 19.0151 13.8029 18.1972 13.4565 17.4251ZM7.00298 13.3869C6.1481 13.3869 5.3435 13.0721 4.7382 12.5005C4.13289 11.9289 3.79951 11.1691 3.79951 10.3618C3.79951 9.55452 4.13289 8.79472 4.7382 8.22312C5.3435 7.65151 6.1481 7.33668 7.00298 7.33668C7.85785 7.33668 8.66245 7.65151 9.26775 8.22312C9.87306 8.79472 10.2064 9.55452 10.2064 10.3618C10.2064 11.1691 9.87306 11.9289 9.26775 12.5005C8.66245 13.0721 7.85785 13.3869 7.00298 13.3869Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.61914 3.5C8.61914 1.567 10.2327 0 12.2231 0H17.9437C19.9342 0 21.5477 1.567 21.5477 3.5C21.5477 5.433 19.9342 7 17.9437 7H12.2231C10.2327 7 8.61914 5.433 8.61914 3.5ZM12.2231 1.44444C11.0541 1.44444 10.1065 2.36475 10.1065 3.5C10.1065 4.63525 11.0541 5.55556 12.2231 5.55556H17.9437C19.1127 5.55556 20.0604 4.63525 20.0604 3.5C20.0604 2.36475 19.1127 1.44444 17.9437 1.44444H12.2231Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6219 9C15.3244 9 15.0832 9.22386 15.0832 9.5C15.0832 9.77614 15.3244 10 15.6219 10C15.9194 10 16.1606 9.77614 16.1606 9.5C16.1606 9.22386 15.9194 9 15.6219 9ZM14.0059 9.5C14.0059 8.67157 14.7294 8 15.6219 8C16.5145 8 17.238 8.67157 17.238 9.5C17.238 10.3284 16.5145 11 15.6219 11C14.7294 11 14.0059 10.3284 14.0059 9.5Z"
        fill="currentColor"
      />
    </g>
  )
});

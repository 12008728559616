import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "@/shared/theme";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button, Text, ErrorMessage, EyeIcon, ViewOpenIcon } from "@/components";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Box,
  InputGroup,
  InputRightElement,
  useBoolean,
  Input
} from "@chakra-ui/react";
import { useAdminLoginMutation, UserLoginInput } from "@/apollo/generated";
import { PinInputForm } from "./PinInputForm";
import { useAuthHeaderContext } from "@/hooks/useAuthHeaderContext";

type SingInForm = UserLoginInput & {};

type InputType = {
  key: "email" | "password";
  title: string;
  placeholder: string;
}[];

export const SigninFlows: React.FC = () => {
  const { t } = useTranslation(["signin", "common"]);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [AdminLoginMutation, { loading }] = useAdminLoginMutation();
  const [isPasswordHidden, setPasswordHidden] = useBoolean(true);
  const { removeAdminHeader, removeAuthHeader } = useAuthHeaderContext();
  const [errorMsg, setErrorMsg] = useState<string>(t("errorMsg") ?? "");

  const [step, setStep] = useState<number>(1);
  const [uid, setUid] = useState<string>();

  const inputs: InputType = [
    { key: "email", title: t("email", { ns: "login_information" }), placeholder: "" },
    { key: "password", title: t("password"), placeholder: "" }
  ];

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<SingInForm>({
    resolver: zodResolver(
      z.object({
        email: z.string().email({ message: t("form.validation.email", { ns: "common" }) ?? "" }),
        password: z
          .string()
          .min(1, { message: t("form.validation.password", { ns: "common" }) ?? "" })
      })
    ),
    defaultValues: {}
  });

  const handleSignin = useCallback(
    (values: SingInForm) => {
      removeAdminHeader();
      removeAuthHeader();
      AdminLoginMutation({
        variables: { input: { uid: values.email, password: values.password } }
      }).then((res) => {
        if (res.data?.adminLogin?.problemMsg) {
          setIsInvalid(true);
          setErrorMsg(res.data.adminLogin.problemMsg);
          return;
        }
        setUid(res.data?.adminLogin?.uid ?? "");
        setStep(2);
      });
    },
    [AdminLoginMutation, removeAdminHeader, removeAuthHeader]
  );

  return (
    <Box>
      {step === 1 && (
        <Box
          w="560px"
          minH="460px"
          m="0 auto"
          bgColor={theme.text.white}
          p="36px 64px 58px 56px"
          borderRadius="4px"
        >
          <Text textType="title32" letterSpacing="0.96px" color={theme.text.black900}>
            {t("signin")}
          </Text>
          <Box>
            <form onSubmit={handleSubmit(handleSignin)}>
              <Box pos="relative">
                <ErrorMessage message={errorMsg} isVisible={isInvalid} h="auto" minH="40px" />
                <Flex justifyContent="space-between" pt="28px" wrap="wrap">
                  {inputs.map((input) => (
                    <FormControl key={input.key} isInvalid={!!errors[input.key]} mb="28px">
                      <Text
                        textType="body16b"
                        color={theme.text.black900}
                        letterSpacing="0.48px"
                        mb="10px"
                      >
                        {input.title}
                      </Text>
                      <InputGroup>
                        <Input
                          id={input.key}
                          border={`1px solid ${theme.text.black200}`}
                          borderRadius="4px"
                          h="37px"
                          p="8px 16px"
                          placeholder={input.placeholder}
                          type={
                            input.key === "password"
                              ? isPasswordHidden
                                ? "password"
                                : undefined
                              : undefined
                          }
                          sx={{
                            _focus: { border: `1px solid ${theme.service.turquoise400}` },
                            _invalid: { border: `1px solid ${theme.error.red}` }
                          }}
                          {...register(input.key)}
                        />
                        {input.key === "password" && (
                          <InputRightElement>
                            {!isPasswordHidden ? (
                              <EyeIcon
                                w="20px"
                                cursor="pointer"
                                onClick={() => setPasswordHidden.toggle()}
                              />
                            ) : (
                              <ViewOpenIcon
                                w="29px"
                                h="25px"
                                cursor="pointer"
                                onClick={() => setPasswordHidden.toggle()}
                              />
                            )}
                          </InputRightElement>
                        )}
                      </InputGroup>
                      <FormErrorMessage>{errors[input.key]?.message}</FormErrorMessage>
                    </FormControl>
                  ))}
                </Flex>
                <Button
                  mt="30px"
                  w="440px"
                  buttonSize="xl"
                  buttonType="fill"
                  type="submit"
                  isLoading={loading}
                >
                  {t("signin")}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      )}
      {step === 2 && <PinInputForm uid={uid ?? ""} />}
    </Box>
  );
};

import React from "react";
import { Box, Divider, Flex, Img, useMediaQuery } from "@chakra-ui/react";
import { MainContent, Text, TextTitle } from "@/components";
import theme from "@/shared/theme";

export const Organization2: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isLargerThan1300] = useMediaQuery("(min-width: 1300px)");
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const [isLargerThan450] = useMediaQuery("(min-width: 450px)");

  return (
    <Box
      bgImage={"url(/assets/organization_bg2.svg)"}
      bgRepeat={"no-repeat"}
      bgSize={isLargerThan1300 ? "180% 140%" : isLargerThan900 ? "300% 160%" : isLargerThan768 ? "600% 140%" : isLargerThan450 ? "1800% 151%" : "2000% 147%"}
      bgPos={isLargerThan1300 ? "center -50vw" : isLargerThan900 ? "center -80vw" : isLargerThan800 ? "center -90vw" : isLargerThan768 ? "center -110vw" : isLargerThan450 ? "center -138vw" : "center -200vw"}
      id="organization"
      pb={isLargerThan768 ? "496px" : "351px"}
      color={theme.text.white}
    >
      <MainContent>
        <Box p={isLargerThan768 ? "120px 0" : "64px 0 0"} mb={isLargerThan768 ? "60px" : "48px"}>
          <TextTitle text="組織カルチャー変革への私たちの想い" color={theme.text.white} />
          <Box textAlign={"center"} mt={isLargerThan768 ? "120px" : "48px"} fontSize={isLargerThan768 ? "28px" : "16px"}>
            <Text mb={isLargerThan768 ? "40px" : "10px"}>
              見えないものを見ることで、チーム
              {!isLargerThan768 && <br />}
              の関係性が変わる
            </Text>
            <Text>
              “know HOW”から “know YOU” な{!isLargerThan768 && <br />}
              組織をつくるために必要なこと
            </Text>
          </Box>
        </Box>
        <Flex p={isLargerThan768 ? "40px" : "16px 16px 48px"} bgColor={"rgba(26, 26, 26, 0.6)"} borderRadius={"16px"} justifyContent={"space-between"} flexDir={isLargerThan768 ? "row" : "column"}>
          <Box w={isLargerThan768 ? "44%" : "100%"}>
            <Img src="/assets/person.svg" alt="lp_logo" draggable="false" mb={isLargerThan768 ? "60px" : "32px"} />
            <Box>
              <Text fontWeight={700} lineHeight={isLargerThan768 ? "28px" : "24.5px"} fontSize={isLargerThan768 ? "16px" : "14px"}>
                富士通ラーニングメディア
              </Text>
              <Text fontWeight={700} lineHeight={isLargerThan768 ? "28px" : "24.5px"} fontSize={isLargerThan768 ? "16px" : "14px"}>
                ナレッジサービス事業本部 マネージャー
              </Text>
              <Text fontWeight={700} lineHeight={isLargerThan768 ? "28px" : "24.5px"} fontSize={isLargerThan768 ? "16px" : "14px"}>
                組織カルチャー変革パートナー
              </Text>
              <Text fontSize={isLargerThan768 ? "40px" : "28px"} fontWeight={700} mt={"12px"} mb={"20px"}>
                城能 雅也
              </Text>
              <Text fontSize={isLargerThan768 ? "14px" : "11px"} fontWeight={300} lineHeight={isLargerThan768 ? "24.5px" : "19.5px"}>
                ビジネスモデル/商品企画/地域活性/ブランディング/組織・風土づくりなど、新しいことを創る分野で3,000名を超える支援実績を持つ。常識には絶対に固執せず、難しい課題でも顧客と共にゼロから創ってきた。
              </Text>
            </Box>
          </Box>
          {!isLargerThan768 && <Divider w={"180px"} m={"12.8vw 0"} borderWidth={"1px"} />}
          <Flex flexDir={"column"} gap={"30px"} w={isLargerThan768 ? "51.2%" : "100%"} fontSize={isLargerThan768 ? "18px" : "14px"} fontWeight={isLargerThan768 ? 500 : 400} lineHeight={1.75}>
            <Text>「感情を起点にしたチーム開発」のきっかけは、お客様から「リモートワークが中心になり、組織内の感情が見えにくくなった」とのお声をいただいたことでした。</Text>
            <Text>テレワークやDX、人事制度の変革など、働き方を取り巻く環境が多様化したことで、現場の納得感を推進力とする「ボトムアップ(現場単位で構想すること)」の動きが重要性を増しています。</Text>
            <Text>
              変化を目指すチームが組織変革を進めていく場合、エンゲージメントという考え方が注目を集めていますが、そもそもこのエンゲージメントを高める目的は、社員にとっての「働きがい」と「働きやすさ」を向上させることで、結果的に企業としての競争力を高めることだと思っています。
            </Text>
            <Text>
              意思をもって変えていくということは、変えたい気持ちと変わることへの不安や恐怖との葛藤です。
              <br />
              やり方=know HOW を知ることではなく、一人ひとりをよく知ることで、私の願いを私たちチームや組織の願いに変えて、社会に届けていくための、共感と信頼を得ていくことを突き詰めていくことだと考えます。
              <br />
              すなわち “know YOU” から始めていくことが最も大切なアクションだと信じています。
            </Text>
            <Text>
              変わりたい組織やチームに「働きやすさ」と「働きがい」、「競争力」と「適応力」を。
              <br />
              常識に固執せず柔軟にやりきることに伴走する。 これが私たちのミッションです。
            </Text>
          </Flex>
        </Flex>
      </MainContent>
    </Box>
  );
};

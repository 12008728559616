import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Sidebar, UserHeader } from "@/components";
import { Outlet, useLocation } from "react-router-dom";
import { extendTheme } from "@chakra-ui/react";
import theme from "@/shared/theme";

extendTheme({ "2xl": "1920px" });

export const UserLayout: React.FC = () => {
  const { pathname } = useLocation();
  return (
    <Box w="100vw" minH="100vh">
      <UserHeader />
      <Flex
        w="100vw"
        bgColor="#FAFAFA"
        minW={
          pathname === "/journalings" || pathname === "/coaching-schedules" ? "1398px" : "1140px"
        }
      >
        <Sidebar />
        <Flex pl="214px" pt="82px" pb="34px" minH="100vh" justifyContent="center" w="100%">
          <Box
            h="100%"
            w="calc(100vw - 262px)"
            minW={
              pathname === "/journalings" || pathname === "/coaching-schedules" ? "1140px" : "884px"
            }
            bgColor={theme.text.white}
          >
            <Outlet />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

import { createIcon } from "@chakra-ui/icons";

export const CulteeNewIcon = createIcon({
  displayName: "CulteeNewIcon",
  viewBox: "0 0 360 99",
  path: (
    <g fill="none">
      <path
        d="M100.706 40.6262V38.0165C100.706 28.8445 92.8996 21.3817 83.3092 21.3817H70.5016C68.9159 21.3817 67.5132 22.5111 67.1777 24.0677L56.7182 73.1633L51.092 67.8676C50.4669 67.2724 49.6283 66.9519 48.7744 66.9519H45.2218C39.2602 66.8909 34.3964 62.4346 34.3964 57.0321V53.7509C34.3964 52.5605 33.7712 51.4465 32.7497 50.836L28.6177 48.3484L32.7802 44.8688C33.5578 44.2279 34 43.2664 34 42.2592V38.0623C34 32.5988 38.9095 28.1425 44.9474 28.1425H60.103L61.6278 21.3512H44.9474C35.1587 21.3512 27.1997 28.8445 27.1997 38.0623V40.672L22.1072 44.9452C20.9179 45.9371 20.2775 47.448 20.3995 48.9894C20.5215 50.5308 21.3753 51.9348 22.7018 52.7284L27.5809 55.6586V57.0168C27.5809 66.0057 35.3417 73.499 44.8712 73.7127H47.4022L56.4437 82.2132C57.0689 82.8084 57.9075 83.1289 58.7613 83.1289C59.0967 83.1289 59.4474 83.0831 59.7676 82.9763C60.9416 82.61 61.8107 81.6333 62.0699 80.4429L72.5447 31.271C72.9259 29.4397 74.5421 28.1425 76.4022 28.1425H83.2787C89.1183 28.1425 93.8907 32.553 93.8907 37.986V42.1523C93.8907 43.1443 94.3329 44.0905 95.08 44.7315L99.1509 48.2111L95.1257 50.6834C94.1194 51.2938 93.5095 52.3927 93.5095 53.583V56.849C93.5095 62.282 88.7524 66.6925 82.8975 66.6925H71.1877L69.8155 73.4838H82.8975C92.488 73.4838 100.294 66.021 100.294 56.849V55.4907L105.189 52.4842C106.47 51.7059 107.293 50.3477 107.415 48.8673C107.537 47.387 106.927 45.9371 105.783 44.9604L100.66 40.5957L100.706 40.6262Z"
        fill="white"
      />
      <path
        d="M154.314 58.1154C153.43 61.0608 151.92 63.3042 149.846 64.7845C147.773 66.2496 145.272 66.9974 142.406 66.9974C139.539 66.9974 136.886 66.3107 134.782 64.9524C132.678 63.5942 131.047 61.5949 129.918 58.9852C128.79 56.3756 128.211 53.1707 128.211 49.4927C128.211 45.8147 128.821 42.5641 130.01 39.9697C131.199 37.3905 132.907 35.3913 135.057 34.033C137.207 32.6748 139.753 31.988 142.604 31.988C145.455 31.988 147.803 32.629 149.572 33.9109C151.341 35.1929 152.713 37.2532 153.643 40.0155L153.719 40.2596L161.389 36.8411L161.312 36.6427C160.352 34.033 158.949 31.8354 157.165 30.0956C155.381 28.3558 153.186 27.0281 150.655 26.1735C148.139 25.3036 145.272 24.861 142.147 24.861C137.633 24.861 133.639 25.8682 130.269 27.837C126.9 29.8057 124.262 32.6748 122.432 36.3375C120.602 39.9849 119.688 44.426 119.688 49.508C119.688 54.59 120.618 59.031 122.432 62.6785C124.247 66.3259 126.9 69.195 130.269 71.179C133.639 73.1477 137.649 74.155 142.223 74.155C145.349 74.155 148.245 73.6361 150.838 72.6288C153.43 71.6216 155.732 70.0955 157.668 68.0962C159.589 66.097 161.053 63.6094 162.029 60.725L162.105 60.5114L154.344 57.917L154.283 58.1459L154.314 58.1154Z"
        fill="white"
      />
      <path
        d="M195.04 56.7584C195.04 58.5897 194.796 60.2074 194.308 61.5657C193.821 62.9087 193.134 64.038 192.25 64.8926C191.366 65.7473 190.375 66.3882 189.277 66.785C188.179 67.1818 187.036 67.3802 185.877 67.3802C183.452 67.3802 181.562 66.785 180.22 65.6099C178.894 64.4348 178.223 62.2372 178.223 59.0628V37.0866H170.096V61.001C170.096 64.0991 170.706 66.6477 171.926 68.5859C173.13 70.5393 174.777 71.9586 176.805 72.8438C178.802 73.7137 181.028 74.1562 183.391 74.1562C186.273 74.1562 188.819 73.6221 190.954 72.5538C192.875 71.5923 194.476 70.112 195.696 68.1586L196.016 73.439H203.198V37.0713H195.071V56.7584H195.04Z"
        fill="white"
      />
      <path
        d="M228.542 67.4107C227.963 67.5328 227.246 67.5938 226.392 67.5938C225.035 67.5938 224.044 67.2733 223.48 66.6629C222.901 66.0372 222.611 64.8621 222.611 63.1528V20.9248H214.484V63.7938C214.484 67.2886 215.262 69.8983 216.802 71.5923C218.342 73.2863 220.781 74.1409 224.029 74.1409C225.081 74.1409 226.163 74.0341 227.231 73.8052C228.298 73.5763 229.259 73.2863 230.082 72.8895L230.189 72.8437L231.119 66.6018L230.753 66.7392C229.868 67.0597 229.121 67.2886 228.527 67.4107H228.542Z"
        fill="white"
      />
      <path
        d="M257.451 65.0602C256.658 65.7622 255.789 66.3116 254.859 66.7084C253.929 67.1052 252.831 67.3036 251.581 67.3036C250.072 67.3036 248.852 66.9068 247.967 66.1437C247.098 65.3807 246.641 64.1292 246.641 62.4352V43.5723H258.976V37.0862H246.641V26.4644L238.514 28.7536V37.1015H231.623V43.5875H238.514V63.7477C238.56 66.22 239.078 68.2498 240.054 69.7912C241.03 71.3326 242.417 72.4619 244.186 73.1487C245.924 73.8202 247.861 74.1712 249.934 74.1712C252.008 74.1712 253.731 73.8812 255.347 73.3318C256.963 72.7824 258.351 72.0041 259.479 71.0273L259.586 70.9358L257.71 64.8618L257.451 65.0907V65.0602Z"
        fill="white"
      />
      <path
        d="M290.703 38.5348C288.248 37.085 285.214 36.3524 281.692 36.3524C278.17 36.3524 275.166 37.1155 272.467 38.6111C269.769 40.1067 267.619 42.3043 266.109 45.1277C264.585 47.951 263.822 51.3543 263.822 55.2459C263.822 59.1375 264.585 62.5408 266.109 65.3641C267.634 68.1875 269.799 70.3851 272.544 71.8807C275.288 73.3763 278.52 74.1394 282.119 74.1394C284.528 74.1394 286.769 73.8036 288.797 73.1169C290.825 72.4454 292.594 71.4534 294.073 70.1714C295.551 68.8895 296.741 67.3481 297.61 65.6083L297.717 65.3947L291.206 62.3271L291.099 62.5255C290.261 64.1127 289.102 65.3641 287.669 66.234C286.235 67.1192 284.314 67.5618 281.982 67.5618C279.877 67.5618 278.033 67.0887 276.508 66.1577C274.983 65.2268 273.779 63.838 272.94 62.0219C272.376 60.7858 271.995 59.3512 271.812 57.7488H298.037L298.067 57.5504C298.159 56.9704 298.235 56.2989 298.281 55.5206C298.326 54.7575 298.357 53.9334 298.357 53.0635C298.357 49.8892 297.701 47.0048 296.39 44.4867C295.079 41.9686 293.173 39.9541 290.718 38.5043L290.703 38.5348ZM276.294 44.3036C277.789 43.3726 279.557 42.8995 281.555 42.8995C284.299 42.8995 286.479 43.7542 288.05 45.4482C289.392 46.898 290.169 48.8972 290.367 51.4153H271.979C272.178 50.347 272.467 49.355 272.848 48.4699C273.641 46.6385 274.785 45.2345 276.279 44.3036H276.294Z"
        fill="white"
      />
      <path
        d="M337.834 44.5172C336.523 41.9991 334.617 39.9846 332.162 38.5348C329.707 37.085 326.673 36.3524 323.151 36.3524C319.629 36.3524 316.625 37.1155 313.926 38.6111C311.228 40.1067 309.078 42.3043 307.568 45.1277C306.044 47.951 305.281 51.3543 305.281 55.2459C305.281 59.1375 306.044 62.5408 307.568 65.3641C309.093 68.1875 311.258 70.3851 314.003 71.8807C316.747 73.3763 319.979 74.1394 323.578 74.1394C325.987 74.1394 328.228 73.8036 330.256 73.1169C332.284 72.4454 334.052 71.4534 335.531 70.1714C337.01 68.8895 338.2 67.3481 339.069 65.6083L339.176 65.3947L332.665 62.3271L332.558 62.5255C331.72 64.1127 330.561 65.3641 329.128 66.234C327.694 67.1192 325.773 67.5618 323.44 67.5618C321.336 67.5618 319.491 67.0887 317.967 66.1577C316.442 65.2268 315.238 63.838 314.399 62.0219C313.835 60.7858 313.454 59.3512 313.271 57.7488H339.496L339.526 57.5504C339.618 56.9704 339.694 56.2989 339.74 55.5206C339.785 54.7575 339.816 53.9334 339.816 53.0635C339.816 49.8892 339.16 47.0048 337.849 44.4867L337.834 44.5172ZM317.738 44.3188C319.232 43.3879 321.001 42.9148 322.998 42.9148C325.743 42.9148 327.923 43.7694 329.494 45.4634C330.835 46.9132 331.613 48.9125 331.811 51.4306H313.423C313.621 50.3623 313.911 49.3703 314.292 48.4852C315.085 46.6538 316.229 45.2498 317.723 44.3188H317.738Z"
        fill="white"
      />
    </g>
  )
});

import React from "react";
import { Button, Text } from "@/components";
import { Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import theme from "@/shared/theme";

type PropsType = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
};

export const DeleteModal: React.FC<PropsType> = ({ isOpen, onClose, onDelete }) => {
  const { t } = useTranslation(["admin"]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="560px" maxW="unset" p="36px 53px 49px 56px" borderRadius="4px">
        <ModalBody p="0" mb="41px">
          <Text textType="title32" mb="21px" letterSpacing="0.96px">
            {t("delete.headTitle")}
          </Text>
          <Flex flexDir="column">
            <Text textType="body16r" letterSpacing="0.48px">
              {t("delete.message1")}
            </Text>
            <Text textType="body16r" letterSpacing="0.48px">
              {t("delete.message2")}
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter p="0" justifyContent="space-between">
          <Button
            p="14px 53.5px"
            w="210px"
            h="58px"
            borderRadius="4px"
            colorScheme="blackAlpha"
            variant="outline"
            onClick={onClose}
          >
            <Text textType="title20" color={theme.text.black900}>
              {t("delete.cancel")}
            </Text>
          </Button>
          <Button
            p="14px 53.5px"
            w="210px"
            h="58px"
            borderRadius="4px"
            color={theme.text.white}
            bgColor={theme.error.redDark}
            colorScheme="red"
            onClick={onDelete}
          >
            <Text textType="title20">{t("delete.title")}</Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import React from "react";
import { Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, Link, useMediaQuery } from "@chakra-ui/react";
import { Button, Text } from "@/components";
import { CancelIcon } from "@/components/icons/CancelIcon";
import { useTranslation } from "react-i18next";
import theme from "@/shared/theme";
import { ScrollTrigger } from "gsap/ScrollTrigger";

type PropsType = {
  isDrawerOpen: boolean;
  onDrawerClose: () => void;
};

export const NavigationDrawer: React.FC<PropsType> = ({ isDrawerOpen, onDrawerClose }) => {
  const { t } = useTranslation(["landing_page"]);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const navigationList: { text: string; href: string }[] = [
    { text: "TOP", href: "top" },
    { text: "Concept", href: "concept" },
    { text: "Culteeだからできる3つのこと", href: "three-things-can-do" },
    { text: "チームの成果と成長を創出する関係性", href: "barrier-to-change" },
    { text: "よくあるお悩み", href: "concerns" },
    { text: "Culteeの活用サイクルと効果", href: "usage" },
    { text: "機能詳細", href: "detail-of-function" },
    { text: "私たちの想い", href: "organization" },
    { text: "ご支援のイメージ", href: "culture-change-support" },
    { text: "お客様事例", href: "customer-case" },
    { text: "Q&A", href: "question-and-answer" }
  ];

  return (
    <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose} size={isLargerThan768 ? "lg" : "full"} trapFocus={false}>
      <DrawerOverlay />
      <DrawerContent p={isLargerThan768 ? "40px 48px" : "20px 24px"}>
        <Flex justifyContent={"flex-end"} pb={isLargerThan768 ? "40px" : "18px"}>
          <CancelIcon w={isLargerThan768 ? "48px" : "40px"} h={isLargerThan768 ? "48px" : "40px"} cursor={"pointer"} onClick={onDrawerClose} />
        </Flex>
        <DrawerBody>
          <Flex flexDir={"column"} gap={isLargerThan768 ? "20px" : "10px"}>
            {navigationList.map((item) => {
              return (
                <Text
                  color={theme.text.black800}
                  key={item.text}
                  textType={isLargerThan768 ? "title20" : "body16b"}
                  lineHeight={isLargerThan768 ? undefined : "16px"}
                  cursor={"default"}
                  onClick={() => {
                    ScrollTrigger.getAll().forEach((trigger) => trigger.disable());

                    const ref = document.getElementById(item.href);

                    ref?.scrollIntoView();

                    onDrawerClose();
                  }}
                >
                  {item.text}
                </Text>
              );
            })}
          </Flex>
        </DrawerBody>
        <Flex alignItems={"center"} gap={isLargerThan768 ? "40px" : "16px"} pt={isLargerThan768 ? "40px" : "18px"} flexDir={isLargerThan768 ? "row" : "column"}>
          <Link href="https://www.knowledgewing.com/kw/od/download.html" isExternal textDecorationLine="none !important">
            <Button buttonType="stroke" height={"56px"} borderRadius={"56px"} w={"260px"} hasShadow>
              {t("downloadDocuments")}
            </Button>
          </Link>
          <Link href="https://www.knowledgewing.com/kw/od/questionpage.html" isExternal textDecorationLine="none !important">
            <Button buttonType="fill" height={"56px"} borderRadius={"56px"} w={"260px"} hasShadow>
              {t("inquiry")}
            </Button>
          </Link>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { Text, Button } from "@/components";
import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const SignupCompleted: React.FC = () => {
  const { t } = useTranslation(["signup"]);
  const navigate = useNavigate();

  return (
    <>
      <Box m="16px 0">
        <Text>{t("memberSignupCompletedMessage1")}</Text>
        <Text>{t("memberSignupCompletedMessage2")}</Text>
        <Text>{t("memberSignupCompletedMessage3")}</Text>
      </Box>
      <Button
        mt="16px"
        w="556px"
        buttonSize="xl"
        buttonType="fill"
        onClick={() => navigate("/auth/signin")}
      >
        {t("signupForm.goToSignin")}
      </Button>
    </>
  );
};

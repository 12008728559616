import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "./en/common.json";
import common_ja from "./ja/common.json";
import signup_en from "./en/signup.json";
import signup_ja from "./ja/signup.json";
import profile_en from "./en/profile.json";
import profile_ja from "./ja/profile.json";
import signin_en from "./en/signin.json";
import signin_ja from "./ja/signin.json";
import members_en from "./en/members.json";
import members_ja from "./ja/members.json";
import home_en from "./en/home.json";
import home_ja from "./ja/home.json";
import record_en from "./en/record.json";
import record_ja from "./ja/record.json";
import journaling_en from "./en/journaling.json";
import journaling_ja from "./ja/journaling.json";
import journalings_line_en from "./en/journalings-line.json";
import journalings_line_ja from "./ja/journalings-line.json";
import passwordreset_en from "./en/passwordreset.json";
import passwordreset_ja from "./ja/passwordreset.json";
import login_information_en from "./en/login-information.json";
import login_information_ja from "./ja/login-information.json";
import emailreset_en from "./en/emailreset.json";
import emailreset_ja from "./ja/emailreset.json";
import landing_page_en from "./en/landing_page.json";
import landing_page_ja from "./ja/landing_page.json";
import admin_en from "./en/admin.json";
import admin_ja from "./ja/admin.json";

const resources = {
  en: {
    common: common_en,
    signup: signup_en,
    signin: signin_en,
    profile: profile_en,
    members: members_en,
    home: home_en,
    record: record_en,
    journaling: journaling_en,
    journalings_line: journalings_line_en,
    passwordreset: passwordreset_en,
    login_information: login_information_en,
    emailreset: emailreset_en,
    landing_page: landing_page_en,
    admin: admin_en
  },
  ja: {
    common: common_ja,
    signup: signup_ja,
    signin: signin_ja,
    profile: profile_ja,
    members: members_ja,
    home: home_ja,
    record: record_ja,
    journaling: journaling_ja,
    journalings_line: journalings_line_ja,
    passwordreset: passwordreset_ja,
    login_information: login_information_ja,
    emailreset: emailreset_ja,
    landing_page: landing_page_ja,
    admin: admin_ja
  }
};

i18n.use(initReactI18next).init({
  returnNull: false,
  resources,
  lng: "ja",
  interpolation: { escapeValue: false }
});

export default i18n;

import {
  CultureChangeSupport1Icon,
  CultureChangeSupport2Icon,
  MainContent,
  Rectangle1Icon,
  Rectangle2Icon,
  Rectangle3Icon,
  Rectangle4Icon,
  Rectangle5Icon,
  Rectangle6Icon,
  RectangleSP1Icon,
  RectangleSP2Icon,
  RectangleSP3Icon,
  RectangleSP4Icon,
  RectangleSP5Icon,
  RectangleSP6Icon,
  Text
} from "@/components";
import { Box, Center, Divider, Flex, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import theme from "@/shared/theme";
import { LinkButtons } from "./LinkButtons";

export const CultureChangeSupport: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isSmallerThan1086] = useMediaQuery("(max-width: 1086px)");
  const months: { left: string; text: string }[] = [
    { left: isLargerThan768 ? "60px" : "52px", text: "1ヶ月" },
    { left: isLargerThan768 ? "35px" : "30px", text: "2ヶ月" },
    { left: isLargerThan768 ? "36px" : "31px", text: "3ヶ月" },
    { left: isLargerThan768 ? "36px" : "31px", text: "4ヶ月" },
    { left: isLargerThan768 ? "35px" : "30px", text: "5ヶ月" },
    { left: isLargerThan768 ? "54px" : "67px", text: "6ヶ月目以降" }
  ];
  const steps: { width: string; title: string; text: string; content: { title: string; content: string[] } }[] = [
    {
      width: "225px",
      title: "STEP1",
      text: "現状分析・ゴール設定",
      content: { title: "組織課題を可視化・変革プランを策定", content: ["パーパス/目的の定義", "意思決定プロセス分析", "関係性モデル診断", "導入施策決定"] }
    },
    {
      width: isLargerThan768 ? "481px" : "439px",
      title: "STEP2",
      text: "変革プロジェクト支援",
      content: {
        title: "組織の状態に応じた変革プログラムをカスタマイズ&導入に伴走",
        content: ["共創コミュニケーション支援の場づくり", "変革マインド醸成教育プログラム", "変革スキル強化プログラム", "関係性変革プログラム"]
      }
    },
    { width: "256px", title: "STEP3", text: "評価・改善", content: { title: "複数の指標で変化を測定・効果的な改善サイクル構築", content: ["エンゲージメント評価", "顧客ロイヤルティ評価", "ネクストアクション策定"] } }
  ];

  return (
    <MainContent background={theme.gary.gray100}>
      <Flex w="100%" direction="column" alignItems="center" p={isLargerThan768 ? "120px 0" : "64px 0"} id="culture-change-support">
        <Flex alignItems={"center"} gap={isLargerThan768 ? "40px" : "16px"} w={"fit-content"} m={"0 auto"} letterSpacing={"10%"} textAlign={"center"}>
          <Box w={"32px"} h={"2px"} bgColor={theme.text.black800} />
          <Text fontSize={isLargerThan768 ? "32px" : "18px"} fontWeight={300}>
            組織カルチャー変革に
            {!isLargerThan768 && <br />}
            つなげるご支援
          </Text>
          <Box w={"32px"} h={"2px"} bgColor={theme.text.black800} />
        </Flex>
        <Text
          color={theme.text.black800}
          fontSize={isLargerThan768 ? "20px" : "14px"}
          fontWeight={400}
          mt={isLargerThan768 ? "120px" : "48px"}
          textAlign={isLargerThan768 ? "center" : "left"}
          whiteSpace="pre-wrap"
          lineHeight="1.75"
        >
          {"Culteeを効果的にお使いいただき、組織カルチャー変革を実現するためのご支援をいたします。\n現状分析、ゴール設定をもとに、お客様に合わせた専属チームを編成し、変革プロジェクトに伴走いたします。"}
        </Text>
        <Flex background={theme.text.white} p={isLargerThan768 ? "80px 40px" : "24px 16px"} direction="column" mt={isLargerThan768 ? "120px" : "48px"} maxW="1080px" borderRadius="4px" w="100%">
          <Center h={isLargerThan768 ? (isSmallerThan1086 ? "532px" : "749px") : "415px"}>
            <Box
              w={isLargerThan768 ? (isSmallerThan1086 ? "487px" : "685px") : "200px"}
              h={isLargerThan768 ? (isSmallerThan1086 ? "487px" : "685px") : "200px"}
              border={`${isLargerThan768 ? (isSmallerThan1086 ? "2.84px" : "4px") : "1.39px"} dashed ${theme.primary.green900}`}
              borderRadius="100%"
              m="0 auto"
              pos="relative"
            >
              <CultureChangeSupport1Icon
                w={isLargerThan768 ? (isSmallerThan1086 ? "241px" : "339px") : "117px"}
                h={isLargerThan768 ? (isSmallerThan1086 ? "206.93px" : "291px") : "101px"}
                top={isLargerThan768 ? (isSmallerThan1086 ? "136.53px" : "192px") : "47.11px"}
                left={isLargerThan768 ? (isSmallerThan1086 ? "-94.535px" : "-132.94px") : "-49px"}
                pos="absolute"
              />
              <CultureChangeSupport2Icon
                w={isLargerThan768 ? (isSmallerThan1086 ? "339.9px" : "478px") : "158.97px"}
                h={isLargerThan768 ? (isSmallerThan1086 ? "241.78px" : "340px") : "114.33px"}
                top={isLargerThan768 ? (isSmallerThan1086 ? "130.33px" : "183px") : "52.11px"}
                right={isLargerThan768 ? (isSmallerThan1086 ? "-85.376px" : "-120.06px") : "-56px"}
                pos="absolute"
              />
              <Box
                pos="absolute"
                top={isLargerThan768 ? (isSmallerThan1086 ? "-12.8px" : "-18px") : "-95.18px"}
                left={isLargerThan768 ? (isSmallerThan1086 ? "78.8px" : "164.25px") : "-24px"}
                borderRadius={isLargerThan768 ? "8px" : "2px"}
                lineHeight={isLargerThan768 ? "31.5px" : "21px"}
                bg={theme.primary.green900}
                w={isLargerThan768 ? "326px" : "248px"}
                h={isLargerThan768 ? "96px" : "66px"}
                p={isLargerThan768 ? "16px 24px" : "12px 16px"}
                fontSize={isLargerThan768 ? "18px" : "14px"}
                fontWeight={700}
                whiteSpace="pre-wrap"
                color={theme.text.white}
              >
                {"・組織のありたい姿や現状、課題 \n・チーム内状況の情報共有"}
              </Box>
              <Box
                pos="absolute"
                bottom={isLargerThan768 ? (isSmallerThan1086 ? "-32.71px" : "-46px") : "-119.64px"}
                left={isLargerThan768 ? (isSmallerThan1086 ? "73.25px" : "173.25px") : "-32.42px"}
                borderRadius={isLargerThan768 ? "8px" : "2px"}
                lineHeight={isLargerThan768 ? "31.5px" : "21px"}
                bg={theme.primary.green900}
                w={isLargerThan768 ? "338px" : "263px"}
                h={isLargerThan768 ? "96px" : "66px"}
                p={isLargerThan768 ? "16px 24px" : "12px 16px"}
                fontSize={isLargerThan768 ? "18px" : "14px"}
                fontWeight={700}
                whiteSpace="pre-wrap"
                color={theme.text.white}
              >
                {"・プロジェクト全体のマネジメント\n・専用プログラムのご提供"}
              </Box>
              <Text
                fontSize={isLargerThan768 ? "18px" : "12px"}
                fontWeight={400}
                pos="absolute"
                whiteSpace="pre-wrap"
                left={isLargerThan768 ? (isSmallerThan1086 ? "-94.535px" : "-132.94px") : "-49px"}
                top={isLargerThan768 ? (isSmallerThan1086 ? "384px" : "523px") : "185px"}
                textAlign="center"
              >
                {isLargerThan768 ? "Culteeを活用し\nた日々の変革活動" : "Culteeを活用した\n日々の変革活動"}
              </Text>
              <Text
                fontSize={isLargerThan768 ? "18px" : "12px"}
                fontWeight={400}
                pos="absolute"
                whiteSpace="pre-wrap"
                right={isLargerThan768 ? (isSmallerThan1086 ? "-85.376px" : "-120.06px") : "-56px"}
                top={isLargerThan768 ? (isSmallerThan1086 ? "395px" : "523px") : "185px"}
                textAlign="center"
              >
                {"組織の状態に応じ\nた変革への伴走"}
              </Text>
              <Box
                w={isLargerThan768 ? (isSmallerThan1086 ? "17px" : "24px") : "8px"}
                h={isLargerThan768 ? (isSmallerThan1086 ? "17px" : "24px") : "8px"}
                pos="absolute"
                bottom={isLargerThan768 ? (isSmallerThan1086 ? "100px" : "129px") : "34px"}
                left={isLargerThan768 ? (isSmallerThan1086 ? "32px" : "55px") : "17px"}
                borderLeft={`${isLargerThan768 ? (isSmallerThan1086 ? "2.84px" : "4px") : "1.39px"} solid ${theme.primary.green900}`}
                borderTop={`${isLargerThan768 ? (isSmallerThan1086 ? "2.84px" : "4px") : "1.39px"} solid ${theme.primary.green900}`}
              />
              <Box
                w={isLargerThan768 ? (isSmallerThan1086 ? "17px" : "24px") : "8px"}
                h={isLargerThan768 ? (isSmallerThan1086 ? "17px" : "24px") : "8px"}
                pos="absolute"
                top={isLargerThan768 ? (isSmallerThan1086 ? "85px" : "109px") : "35px"}
                right={isLargerThan768 ? (isSmallerThan1086 ? "41px" : "65px") : "17px"}
                borderRight={`${isLargerThan768 ? (isSmallerThan1086 ? "2.84px" : "4px") : "1.39px"} solid ${theme.primary.green900}`}
                borderBottom={`${isLargerThan768 ? (isSmallerThan1086 ? "2.84px" : "4px") : "1.39px"} solid ${theme.primary.green900}`}
              />
            </Box>
          </Center>

          <Divider h="1px" color={theme.gary.gray50} m={isLargerThan768 ? "100px auto" : "48px"} />
          <Flex overflowX="auto" maxW={isLargerThan768 ? "1000px" : "945px"} p="0 2px" flexDirection="column" alignContent="center">
            <Flex h="100%">
              {months.map((month, index) => (
                <Box h={isLargerThan768 ? "117px" : "60px"} pos="relative" key={index}>
                  {index === 0 ? (
                    isLargerThan768 ? (
                      <Rectangle1Icon h="100%" w="171px" />
                    ) : (
                      <RectangleSP1Icon h="60px" w="155px" />
                    )
                  ) : index === 1 ? (
                    isLargerThan768 ? (
                      <Rectangle2Icon h="117px" w="170px" ml="-24px" />
                    ) : (
                      <RectangleSP2Icon h="60px" w="156px" ml="-21px" />
                    )
                  ) : index === 2 ? (
                    isLargerThan768 ? (
                      <Rectangle3Icon h="117px" w="170px" ml="-24px" />
                    ) : (
                      <RectangleSP3Icon h="60px" w="155px" ml="-21px" />
                    )
                  ) : index === 3 ? (
                    isLargerThan768 ? (
                      <Rectangle4Icon h="117px" w="171px" ml="-24px" />
                    ) : (
                      <RectangleSP4Icon h="60px" w="155px" ml="-21px" />
                    )
                  ) : index === 4 ? (
                    isLargerThan768 ? (
                      <Rectangle5Icon h="117px" w="170px" ml="-24px" />
                    ) : (
                      <RectangleSP5Icon h="60px" w="156px" ml="-21px" />
                    )
                  ) : isLargerThan768 ? (
                    <Rectangle6Icon h="117px" w="265px" ml="-24px" />
                  ) : (
                    <RectangleSP6Icon h="60px" w="273px" ml="-21px" />
                  )}
                  <Text pos="absolute" left={month.left} top={isLargerThan768 ? "42px" : "18px"} fontSize={isLargerThan768 ? "18px" : "16px"} fontWeight={700} color={theme.primary.green900}>
                    {month.text}
                  </Text>
                </Box>
              ))}
            </Flex>
            <Flex mt={isLargerThan768 ? "48px" : "24px"} gap="10px">
              {steps.map((step, index) => (
                <Box key={index} w={step.width} minW={step.width} borderRadius={"4px"} shadow="0 4px 4px rgba(0, 0, 0, 0.25)" mb="6px">
                  <Box p={isLargerThan768 ? "12px 24px 20px 24px" : "12px 20px 16px 20px"} bgColor={theme.primary.green900} borderRadius="4px 4px 0 0" fontWeight={700} color={theme.text.white}>
                    <Text mb="10px" fontSize={isLargerThan768 ? "24px" : "20px"}>
                      {step.title}
                    </Text>
                    <Text fontSize="16px">{step.text}</Text>
                  </Box>
                  <Box p={isLargerThan768 ? "40px 24px 64px 24px" : "24px 16px 48px 16px"} bgColor={theme.text.white} borderRadius="0 0 4px 4px" color={theme.text.black800}>
                    <Text mb="10px" fontSize={isLargerThan768 ? "18px" : "16px"} fontWeight={700}>
                      {step.content.title}
                    </Text>
                    {step.content.content.map((item, idx) => (
                      <Box as="li" key={idx} fontSize="14px" fontWeight={isLargerThan768 ? 400 : 300} lineHeight={isLargerThan768 ? "24.5px" : "24.5px"} listStyleType="disc">
                        {item}
                      </Box>
                    ))}
                    <Text fontSize="14px" fontWeight={isLargerThan768 ? 400 : 300} lineHeight="24.5px">
                      など
                    </Text>
                  </Box>
                </Box>
              ))}
            </Flex>
          </Flex>
        </Flex>
        <Box w="100%" mt={isLargerThan768 ? "240px" : "128px"}>
          <LinkButtons title={isLargerThan768 ? "お客様に寄り添った最適なプランで\n組織カルチャー変革をサポートします" : "お客様に寄り添った\n最適なプランで\n組織カルチャー変革を\nサポートします"} />
        </Box>
      </Flex>
    </MainContent>
  );
};

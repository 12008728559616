import React from "react";
import { Box, useMediaQuery } from "@chakra-ui/react";
import { MainContent, Text } from "@/components";
import theme from "@/shared/theme";

export const TeamIntroduction: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return (
    <Box p={isLargerThan768 ? "8.3vw 0" : "17vw 0"} bgColor={theme.gary.gray100} color={theme.text.black800}>
      <MainContent>
        <Box
          textAlign={isLargerThan768 ? "center" : "left"}
          fontSize={isLargerThan768 ? "24px" : "18px"}
          lineHeight={isLargerThan768 ? "42px" : "31.5px"}
          fontWeight={700}
          bgColor={theme.text.white}
          p={isLargerThan768 ? "3.88vw 2.25vw" : "6.4vw"}
          borderRadius={"4px"}
        >
          <Text>
            チームの中には、
            {isLargerThan768 && <br />}
            上司・部下間の「タテ」の関係性だけでなく、
            {isLargerThan768 && <br />}
            メンバー間の「ヨコ」の関係性もあります。
          </Text>
          <Text mt={isLargerThan768 ? "3vw" : "6vw"}>
            それぞれの関係性に向き合い、改善していくことは、
            {isLargerThan768 && <br />}
            個人とチームの意思決定スピードを高め、自律的な行動を促します。
          </Text>
        </Box>
      </MainContent>
    </Box>
  );
};

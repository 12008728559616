import { Box, Flex } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { StampBox } from "@/components";
import {
  useReactingToGoodThingMutation,
  useReactingToSwitchingActionMutation
} from "@/apollo/generated";

type PropsType = {
  onSubimt: (action: string) => void;
  type: "goodThing" | "switchAction";
  id: string;
};

export const StampList: React.FC<PropsType> = ({ onSubimt, type, id }) => {
  const stampLists = Array.from(Array(18), (v, k) => k);
  const [reactingToGoodThingMutation] = useReactingToGoodThingMutation();
  const [reactingToSwitchingActionMutation] = useReactingToSwitchingActionMutation();

  const reactingTo = useCallback(
    (idx: number) => {
      type === "goodThing" &&
        reactingToGoodThingMutation({ variables: { input: { id: id, emojiIndex: idx } } })
          .then((res) => onSubimt(res.data?.reactingToGoodThing?.action ?? ""))
          .catch(() => {});
      type === "switchAction" &&
        reactingToSwitchingActionMutation({ variables: { input: { id: id, emojiIndex: idx } } })
          .then((res) => onSubimt(res.data?.reactingToSwitchingAction?.action ?? ""))
          .catch(() => {});
    },
    [id, type, reactingToGoodThingMutation, reactingToSwitchingActionMutation, onSubimt]
  );

  return (
    <Flex
      w="264px"
      h="88px"
      flexWrap="wrap"
      overflowY="scroll"
      p="8px 22px"
      justifyContent="space-between"
    >
      {stampLists.map((idx) => (
        <Box key={idx}>
          <StampBox idx={idx} mb="8px" onClick={() => reactingTo(idx)} cursor="pointer" />
        </Box>
      ))}
    </Flex>
  );
};

import React from "react";
import { Button as ChakraButton, ButtonProps } from "@chakra-ui/react";
import theme from "@/shared/theme";

type PropsType = ButtonProps & {
  buttonType?: "fill" | "stroke";
  buttonSize?: "sm" | "md" | "lg" | "xl";
  hasShadow?: boolean;
};

export const Button: React.FC<PropsType> = ({ buttonType, buttonSize, children, hasShadow, ...rest }) => {
  const customSizes: Record<NonNullable<PropsType["buttonSize"]>, ButtonProps> = {
    xl: { height: "58px", fontSize: "20px", borderRadius: "4px", fontWeight: "700" },
    lg: { height: "46px", fontSize: "16px", borderRadius: "4px", fontWeight: "700" },
    md: { height: "35px", fontSize: "14px", borderRadius: "4px", fontWeight: "700" },
    sm: { height: "34px", fontSize: "12px", borderRadius: "4px", fontWeight: "700" }
  };

  const customStyles: Record<NonNullable<PropsType["buttonType"]>, ButtonProps> = {
    fill: {
      variant: "solid",
      bg: hasShadow ? theme.primary.green900 : theme.service.turquoiseText,
      color: theme.text.white,
      boxShadow: hasShadow ? "0 4px 4px rgba(0, 0, 0, 0.25)" : undefined,
      _disabled: { bg: "rgba(24, 163, 167, .5)" },
      _hover: {
        bgColor: theme.primary.green1000,
        color: theme.text.white,
        border: `1px solid ${theme.service.turquoiseText}`
      }
    },
    stroke: {
      variant: "solid",
      bg: theme.text.white,
      color: hasShadow ? theme.primary.green900 : theme.service.turquoiseText,
      border: `1px solid ${theme.service.turquoiseText}`,
      boxShadow: hasShadow ? "0 4px 4px rgba(0, 0, 0, 0.25)" : undefined,
      _hover: { bgColor: theme.primary.green50, color: theme.primary.green1000 },
      _disabled: { color: "rgba(24, 163, 167, .5)", borderColor: "rgba(24, 163, 167,.5)" }
    }
  };

  if (buttonType && buttonSize) {
    return (
      <ChakraButton {...customStyles[buttonType]} {...customSizes[buttonSize]} {...rest}>
        {children}
      </ChakraButton>
    );
  }

  if (buttonType && !buttonSize) {
    return (
      <ChakraButton {...customStyles[buttonType]} {...rest}>
        {children}
      </ChakraButton>
    );
  }

  if (!buttonType && buttonSize) {
    return (
      <ChakraButton {...customSizes[buttonSize]} {...rest}>
        {children}
      </ChakraButton>
    );
  }

  return <ChakraButton {...rest}>{children}</ChakraButton>;
};

import { createIcon } from "@chakra-ui/icons";

export const CloseIcon = createIcon({
  displayName: "CloseIcon",
  viewBox: "0 0 14 14",
  path: (
    <g fill="currentColor">
      <path d="M8.01187 7L13.1388 0.888672C13.2248 0.787109 13.1525 0.632812 13.0197 0.632812H11.4611C11.3693 0.632812 11.2814 0.673828 11.2209 0.744141L6.99234 5.78516L2.76383 0.744141C2.70523 0.673828 2.61734 0.632812 2.52359 0.632812H0.965C0.832188 0.632812 0.759922 0.787109 0.84586 0.888672L5.97281 7L0.84586 13.1113C0.826609 13.134 0.814259 13.1617 0.810275 13.1911C0.806291 13.2205 0.810841 13.2505 0.823386 13.2775C0.83593 13.3044 0.855941 13.3272 0.881043 13.3431C0.906145 13.359 0.935284 13.3674 0.965 13.3672H2.52359C2.61539 13.3672 2.70328 13.3262 2.76383 13.2559L6.99234 8.21484L11.2209 13.2559C11.2795 13.3262 11.3673 13.3672 11.4611 13.3672H13.0197C13.1525 13.3672 13.2248 13.2129 13.1388 13.1113L8.01187 7Z" />
    </g>
  )
});

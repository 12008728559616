import React from "react";
import { Button, Text } from "@/components";
import { Box, Center, Flex, Link, useMediaQuery } from "@chakra-ui/react";
import theme from "@/shared/theme";

export const LinkButtons: React.FC<{ isGray?: boolean; title?: string }> = ({ isGray, title }) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const links: { title: string; link: string; text: string }[] = [
    {
      title: "資料ダウンロード",
      link: "https://www.knowledgewing.com/kw/od/download.html",
      text: isLargerThan768 ? "チーム開発のお役立ち資料を一括ダウンロードできます。" : "チーム開発のお役立ち資料を\n一括ダウンロードできます。"
    },
    {
      title: "お問い合わせ",
      link: "https://www.knowledgewing.com/kw/od/questionpage.html",
      text: isLargerThan768 ? "組織課題解決の専門スタッフによる無料相談を実施しています。" : "組織課題解決の専門スタッフによる\n無料相談を実施しています。"
    }
  ];

  return (
    <Flex w="100%" borderRadius={isLargerThan768 ? "56px" : "16px"} background={isGray ? theme.gary.gray100 : theme.text.white} p={isLargerThan768 ? "80px 56px" : "32px 24px"} direction="column" maxW="1080px">
      <Center>
        <Box w="32px" h="2px" background={theme.text.black800} />
        <Text
          m={isLargerThan768 ? "0 40px" : "0 16px"}
          color={theme.text.black800}
          fontSize={isLargerThan768 ? "28px" : "16px"}
          fontWeight={300}
          lineHeight="1.75"
          letterSpacing="2.8px"
          textAlign="center"
          whiteSpace="pre-wrap"
        >
          {title ?? (isLargerThan768 ? "豊富な実績で組織カルチャー変革をサポートします" : "豊富な実績で\n組織カルチャー変革を\nサポートします")}
        </Text>
        <Box w="32px" h="2px" background={theme.text.black800} />
      </Center>
      <Flex direction={isLargerThan768 ? "row" : "column"} mt={isLargerThan768 ? "64px" : "32px"} justify="center" gap={isLargerThan768 ? "40px" : "24px"}>
        {links.map((item, index) => (
          <Flex direction="column" key={index} m="auto">
            <Link href={item.link} isExternal textDecorationLine="none !important">
              <Button
                buttonType={index === 0 ? "stroke" : "fill"}
                minW="279px"
                w={"100%"}
                h={isLargerThan768 ? "97px" : "56px"}
                borderRadius="56px"
                fontSize={isLargerThan768 ? "28px" : "16px"}
                fontWeight={700}
                letterSpacing="0.84px"
                hasShadow
              >
                {item.title}
              </Button>
            </Link>
            <Text
              mt={isLargerThan768 ? "32px" : "12px"}
              fontSize={isLargerThan768 ? "16px" : "14px"}
              fontWeight={400}
              lineHeight={isLargerThan768 ? "1.25" : "1.75"}
              color={theme.text.black800}
              textAlign="center"
              whiteSpace="pre-wrap"
            >
              {item.text}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

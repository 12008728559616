import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import theme from "@/shared/theme";
import { useTranslation } from "react-i18next";
import { Flex } from "@chakra-ui/react";

type PropsType = {
  data: number[];
};

export const EmotionRadarChart: React.FC<PropsType> = ({ data }) => {
  const { t } = useTranslation(["common"]);

  const chartOptions = useMemo(() => {
    return {
      series: [
        {
          name: "emotionChartGraph",
          data: data.map((d) => d)
        }
      ],
      options: {
        chart: {
          toolbar: { show: false },
          fontFamily: "Noto Sans JP",
          foreColor: "transparent"
        },
        fill: {
          colors: [theme.service.turquoise400],
          opacity: 0.5
        },
        plotOptions: {
          radar: {
            size: 100,
            polygons: {
              strokeColors: theme.text.black900,
              connectorColors: theme.text.black900
            }
          }
        },
        stroke: {
          colors: [theme.service.turquoise400],
          width: 3
        },
        markers: {
          size: 0
        },
        yaxis: {
          min: 0,
          max: 5,
          tickAmount: 5
        },
        xaxis: {
          min: 0,
          max: 100,
          categories: [
            t("emotions.pleasure"),
            t("emotions.confidence"),
            t("emotions.fear"),
            t("emotions.surprise"),
            t("emotions.sadness"),
            t("emotions.repugnance"),
            t("emotions.anger"),
            t("emotions.expectation")
          ],
          labels: {
            show: true,
            style: {
              colors: [
                theme.emotions.joy,
                theme.emotions.trust,
                theme.emotions.fear,
                theme.emotions.shock,
                theme.emotions.sadness,
                theme.emotions.digust,
                theme.emotions.anger,
                theme.emotions.hope
              ],
              fontWeight: 700
            }
          }
        }
      }
    };
  }, [data, t]);

  return (
    <Flex id="chart">
      <ReactApexChart
        options={chartOptions.options}
        series={chartOptions.series}
        type="radar"
        height={280}
        width={280}
      />
    </Flex>
  );
};

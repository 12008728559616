import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { Button, Text, Input, ErrorMessage } from "@/components";
import { zodResolver } from "@hookform/resolvers/zod";
import { Flex, FormControl, FormErrorMessage, Box, InputGroup } from "@chakra-ui/react";
import theme from "@/shared/theme";
import { UserLoginInput, useSendPasswordResetInstructionMutation } from "@/apollo/generated";

type PropsType = {
  onSubmited: (email: string) => void;
};

type SingInForm = UserLoginInput;

type InputType = {
  key: "email";
  title: string;
  placeholder: string;
}[];

export const PasswordResetIdForm: React.FC<PropsType> = ({ onSubmited }) => {
  const { t } = useTranslation(["passwordreset"]);
  const [isInvalid] = useState<boolean>(false);
  const [sendPasswordResetInstruction, { loading }] = useSendPasswordResetInstructionMutation();

  const inputs: InputType = [{ key: "email", title: t("enterpriseId"), placeholder: "" }];

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<SingInForm>({
    resolver: zodResolver(
      z.object({
        email: z.string().email("メールの形式が正しくありません。")
      })
    ),
    defaultValues: {}
  });

  const handleSignin = useCallback(
    (values: SingInForm) => {
      sendPasswordResetInstruction({
        variables: {
          input: {
            ...values,
            redirectUrl: window.location.origin + "/auth/password-reset-input"
          }
        }
      }).then((res) => {
        onSubmited(res.data?.sendPasswordResetInstruction?.user?.email ?? "");
      });
    },
    [onSubmited, sendPasswordResetInstruction]
  );

  return (
    <form onSubmit={handleSubmit(handleSignin)}>
      <Box>
        <ErrorMessage message={t("errorMsg")} isVisible={isInvalid} />
        <Flex justifyContent="space-between" pt="21px" wrap="wrap">
          {inputs.map((input) => (
            <FormControl key={input.key} isInvalid={!!errors[input.key]} mb="26px">
              <Text textType="body14b" color={theme.text.black500}>
                {input.title}
              </Text>
              <InputGroup>
                <Input
                  id={input.key}
                  placeholder={input.placeholder}
                  styleType="flushed"
                  {...register(input.key)}
                />
              </InputGroup>
              <FormErrorMessage>{errors[input.key]?.message}</FormErrorMessage>
            </FormControl>
          ))}
        </Flex>
        <Button
          mt="74px"
          w="556px"
          buttonSize="xl"
          buttonType="fill"
          type="submit"
          isLoading={loading}
        >
          {t("step1.send")}
        </Button>
      </Box>
    </form>
  );
};

import { Table, Tbody, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import {
  MemberTableTr,
  MemberTeamEditModal,
  MemberDeleteModal,
  MemberStatusChangeModal
} from "@/components";
import { MemberFragment, useRemoveMemberMutation } from "@/apollo/generated";
import { useTranslation } from "react-i18next";

export const MemberTable: React.FC<{ users: MemberFragment[]; refetch: () => void }> = ({
  users,
  refetch
}) => {
  const toast = useToast();
  const [clickId, setClickId] = useState<string>("");
  const { t } = useTranslation(["members"]);
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose
  } = useDisclosure();
  const {
    isOpen: isEditTeamModalOpen,
    onOpen: onEditTeamModalOpen,
    onClose: onEditTeamModalClose
  } = useDisclosure();
  const {
    isOpen: isMemberRoleModalOpen,
    onOpen: onMemberRoleModalOpen,
    onClose: onMemberRoleModalClose
  } = useDisclosure();

  const [removeMemberMutation] = useRemoveMemberMutation();

  const temaId = useMemo(() => {
    if (!users.filter((u) => u.id === clickId).length) return "";

    return users.filter((u) => u.id === clickId)[0].team?.id ?? "";
  }, [users, clickId]);

  const memberStatus = useMemo(() => {
    if (!users.filter((u) => u.id === clickId).length) return undefined;

    return users.filter((u) => u.id === clickId)[0].status;
  }, [users, clickId]);

  const removeMember = useCallback(() => {
    removeMemberMutation({ variables: { input: { id: clickId } } }).then(() => {
      toast({
        description: t("deleted"),
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true
      });
      onDeleteModalClose();
      refetch();
    });
  }, [removeMemberMutation, refetch, toast, t, clickId, onDeleteModalClose]);

  return (
    <>
      <Table>
        <Tbody>
          {users.map((user) => (
            <MemberTableTr
              key={user.id}
              user={user}
              onMemberTeamChange={(id) => {
                setClickId(id);
                onEditTeamModalOpen();
              }}
              onMemberStatusChange={(id) => {
                setClickId(id);
                onMemberRoleModalOpen();
              }}
              onDelete={(id) => {
                setClickId(id);
                onDeleteModalOpen();
              }}
            />
          ))}
        </Tbody>
      </Table>
      <MemberTeamEditModal
        userId={clickId}
        isOpen={isEditTeamModalOpen}
        onClose={onEditTeamModalClose}
        currentTeamId={temaId}
        onSubmited={refetch}
      />
      <MemberDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        onDelete={removeMember}
      />
      <MemberStatusChangeModal
        userId={clickId}
        isOpen={isMemberRoleModalOpen}
        onClose={onMemberRoleModalClose}
        initStatus={memberStatus}
        onSubmited={refetch}
      />
    </>
  );
};

import React, { useCallback } from "react";
import { Box, FormControl, FormErrorMessage, useToast } from "@chakra-ui/react";
import { Input, Text, Button } from "@/components";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useUpdateMyPasswordMutation } from "@/apollo/generated";
import theme from "@/shared/theme";

type Form = { currentPassword: string; newPassword: string; confirmNewPassword: string };

type InputType = {
  key: "currentPassword" | "newPassword" | "confirmNewPassword";
  title: string;
  placeholder: string;
}[];

export const PasswordForm: React.FC = () => {
  const { t } = useTranslation(["login_information"]);
  const toast = useToast();
  const [updateMyPassword, { loading }] = useUpdateMyPasswordMutation();

  const inputs: InputType = [
    { key: "currentPassword", title: t("currentPwd"), placeholder: t("currentPwdText") },
    { key: "newPassword", title: t("newPwd"), placeholder: t("newPwdText") },
    { key: "confirmNewPassword", title: t("confirmNewPwd"), placeholder: t("confirmNewText") }
  ];

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<Form>({
    resolver: zodResolver(
      z.object({
        currentPassword: z
          .string()
          .min(1, { message: t("form.validation.password", { ns: "common" }) ?? "" }),
        newPassword: z.string().refine(
          (pas) => {
            if (
              // eslint-disable-next-line no-useless-escape, no-control-regex
              /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()\-^\\@[\];:,.\/=~|`{}+*<>?_])\S{8,}$/.test(
                pas
              )
            ) {
              return true;
            } else return false;
          },
          { message: t("pwdErrorMsg") ?? "" }
        ),
        confirmNewPassword: z.string().refine(
          (pas) => {
            if (
              // eslint-disable-next-line no-useless-escape, no-control-regex
              /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()\-^\\@[\];:,.\/=~|`{}+*<>?_])\S{8,}$/.test(
                pas
              )
            ) {
              return true;
            } else return false;
          },
          { message: t("pwdErrorMsg") ?? "" }
        )
      })
    ),
    defaultValues: {}
  });

  const updatePassword = useCallback(
    (values: Form) => {
      if (
        values.confirmNewPassword === values.newPassword &&
        values.newPassword !== values.currentPassword
      ) {
        updateMyPassword({
          variables: {
            input: { currentPassword: values.currentPassword, newPassword: values.newPassword }
          }
        }).then((res) => {
          if (res.data?.updateMyPassword?.problemMsg) {
            toast({
              position: "top",
              description: res.data?.updateMyPassword?.problemMsg,
              status: "error",
              duration: 3000,
              isClosable: true
            });
            return;
          }
          toast({
            position: "top",
            description: t("pwdUpdateSuccess"),
            status: "success",
            duration: 3000,
            isClosable: true
          });
        });
      }
      setValue("currentPassword", "");
      setValue("newPassword", "");
      setValue("confirmNewPassword", "");
    },
    [setValue, t, toast, updateMyPassword]
  );

  return (
    <>
      <Box width="60%" height="200px" pos="relative">
        <Text textType="title24" mt="36px">
          {t("password")}
        </Text>
        <form onSubmit={handleSubmit(updatePassword)}>
          {inputs.map((input) => (
            <FormControl
              key={input.key}
              isInvalid={!!errors[input.key]}
              mt="16px"
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              alignItems="center"
              sx={{ "& .chakra-form__error-message": { width: "70%", ml: "30%" } }}
            >
              <Text textType="body14b" color={theme.text.black500} width="30%" whiteSpace="nowrap">
                {input.title}
              </Text>
              <Input
                id={input.key}
                placeholder={input.placeholder}
                styleType="default"
                type="password"
                {...register(input.key)}
                width="70%"
              />
              <FormErrorMessage>{errors[input.key]?.message}</FormErrorMessage>
            </FormControl>
          ))}
          <Button
            buttonType="fill"
            w="196px"
            mt="36px"
            mb="36px"
            pos="absolute"
            right="0"
            type="submit"
            isLoading={loading}
          >
            <Text textType="body16b">{t("change")}</Text>
          </Button>
        </form>
      </Box>
    </>
  );
};

import { createIcon } from "@chakra-ui/icons";

export const CulteeIcon = createIcon({
  displayName: "CulteeIcon",
  viewBox: "0 0 412 81",
  path: (
    <g fill="none">
      <path
        d="M104.019 25.527V22.1528C104.019 10.294 93.979 0.645204 81.6446 0.645204H65.1726C63.1332 0.645204 61.3291 2.10536 60.8977 4.118L47.4456 67.5951L40.2097 60.7482C39.4057 59.9786 38.3272 59.5643 37.2291 59.5643H32.66C24.9927 59.4853 18.7373 53.7237 18.7373 46.7386V42.4963C18.7373 40.9572 17.9333 39.5168 16.6195 38.7275L11.3053 35.5112L16.6587 31.0124C17.6588 30.1837 18.2274 28.9406 18.2274 27.6383V22.212C18.2274 15.1481 24.5417 9.38638 32.3071 9.38638H51.7989L53.7599 0.60574H32.3071C19.7178 0.60574 9.48161 10.294 9.48161 22.212V25.5862L2.93203 31.1111C1.40249 32.3936 0.578889 34.3471 0.735765 36.34C0.892641 38.3329 1.99077 40.1482 3.6968 41.1743L9.97184 44.9628V46.7189C9.97184 58.3409 19.9531 68.0292 32.209 68.3054H35.4642L47.0926 79.296C47.8966 80.0656 48.9752 80.48 50.0733 80.48C50.5047 80.48 50.9557 80.4207 51.3675 80.2826C52.8774 79.8091 53.9952 78.5462 54.3285 77.0072L67.8003 13.4314C68.2905 11.0636 70.3691 9.38638 72.7615 9.38638H81.6054C89.1158 9.38638 95.2536 15.0889 95.2536 22.1134V27.5001C95.2536 28.7827 95.8223 30.0061 96.7831 30.8348L102.019 35.3337L96.842 38.5302C95.5477 39.3195 94.7634 40.7402 94.7634 42.2792V46.5018C94.7634 53.5263 88.6452 59.2288 81.1151 59.2288H66.055L64.2902 68.0095H81.1151C93.4495 68.0095 103.49 58.3606 103.49 46.5018V44.7457L109.784 40.8586C111.431 39.8522 112.49 38.0961 112.647 36.1821C112.804 34.2681 112.02 32.3936 110.549 31.1308L103.96 25.4875L104.019 25.527Z"
        fill="currentColor"
      />
      <path
        d="M172.964 48.1392C171.827 51.9474 169.886 54.848 167.219 56.762C164.552 58.6562 161.336 59.6231 157.649 59.6231C153.963 59.6231 150.551 58.7351 147.845 56.979C145.139 55.2229 143.04 52.638 141.589 49.2639C140.138 45.8898 139.393 41.7461 139.393 36.9907C139.393 32.2354 140.177 28.0325 141.707 24.6781C143.236 21.3434 145.433 18.7586 148.198 17.0025C150.963 15.2463 154.237 14.3584 157.904 14.3584C161.571 14.3584 164.591 15.1871 166.866 16.8446C169.141 18.5021 170.905 21.1659 172.102 24.7373L172.2 25.053L182.063 20.6331L181.965 20.3766C180.73 17.0025 178.926 14.1611 176.631 11.9117C174.337 9.66224 171.513 7.94558 168.258 6.8406C165.023 5.71589 161.336 5.14367 157.316 5.14367C151.512 5.14367 146.374 6.44596 142.04 8.99136C137.707 11.5368 134.314 15.2463 131.961 19.982C129.608 24.6978 128.431 30.4398 128.431 37.0105C128.431 43.5811 129.628 49.3231 131.961 54.039C134.295 58.7549 137.707 62.4645 142.04 65.0296C146.374 67.575 151.531 68.8773 157.414 68.8773C161.434 68.8773 165.16 68.2064 168.494 66.9041C171.827 65.6018 174.788 63.6286 177.279 61.0438C179.749 58.4589 181.632 55.2426 182.887 51.5133L182.985 51.2371L173.004 47.8827L172.925 48.1786L172.964 48.1392Z"
        fill="currentColor"
      />
      <path
        d="M225.343 46.3847C225.343 48.7525 225.029 50.8441 224.402 52.6002C223.774 54.3366 222.892 55.7968 221.755 56.9017C220.617 58.0067 219.343 58.8355 217.931 59.3485C216.519 59.8615 215.048 60.118 213.558 60.118C210.44 60.118 208.008 59.3485 206.283 57.8291C204.577 56.3098 203.714 53.4684 203.714 49.3642V20.9505H193.262V51.8701C193.262 55.8757 194.046 59.1709 195.615 61.6768C197.164 64.2025 199.282 66.0376 201.89 67.182C204.459 68.3067 207.322 68.8789 210.362 68.8789C214.068 68.8789 217.343 68.1883 220.088 66.8071C222.559 65.564 224.618 63.65 226.186 61.1243L226.598 67.9515H235.834V20.9307H225.382V46.3847H225.343Z"
        fill="currentColor"
      />
      <path
        d="M268.43 60.1574C267.685 60.3153 266.763 60.3942 265.665 60.3942C263.92 60.3942 262.645 59.9798 261.92 59.1906C261.175 58.3816 260.802 56.8622 260.802 54.6523V0.0544434H250.35V55.481C250.35 59.9996 251.35 63.3737 253.331 65.5639C255.311 67.7541 258.449 68.8591 262.626 68.8591C263.979 68.8591 265.371 68.721 266.744 68.425C268.116 68.1291 269.352 67.7541 270.411 67.2411L270.548 67.1819L271.744 59.1116L271.273 59.2892C270.136 59.7036 269.175 59.9996 268.41 60.1574H268.43Z"
        fill="currentColor"
      />
      <path
        d="M305.611 57.1184C304.591 58.026 303.473 58.7364 302.277 59.2494C301.081 59.7624 299.669 60.0189 298.061 60.0189C296.12 60.0189 294.551 59.5059 293.413 58.5193C292.296 57.5327 291.707 55.9147 291.707 53.7245V29.336H307.571V20.95H291.707V7.21671L281.255 10.1765V20.9698H272.392V29.3558H281.255V55.4214C281.314 58.618 281.981 61.2423 283.236 63.2352C284.491 65.2281 286.276 66.6883 288.55 67.5762C290.786 68.4444 293.276 68.8982 295.943 68.8982C298.61 68.8982 300.826 68.5233 302.904 67.813C304.983 67.1026 306.767 66.0963 308.219 64.8335L308.356 64.7151L305.944 56.8619L305.611 57.1578V57.1184Z"
        fill="currentColor"
      />
      <path
        d="M348.376 22.8229C345.219 20.9484 341.317 20.0013 336.787 20.0013C332.257 20.0013 328.394 20.9879 324.923 22.9216C321.452 24.8553 318.687 27.6967 316.746 31.347C314.785 34.9974 313.804 39.3976 313.804 44.4292C313.804 49.4608 314.785 53.861 316.746 57.5114C318.707 61.1617 321.491 64.0031 325.021 65.9368C328.551 67.8705 332.708 68.8571 337.336 68.8571C340.434 68.8571 343.317 68.423 345.925 67.5351C348.533 66.6669 350.808 65.3843 352.71 63.7269C354.612 62.0694 356.141 60.0765 357.259 57.8271L357.396 57.5508L349.023 53.5847L348.886 53.8413C347.807 55.8934 346.317 57.5114 344.474 58.6361C342.63 59.7805 340.16 60.3527 337.159 60.3527C334.453 60.3527 332.08 59.7411 330.12 58.5374C328.159 57.3338 326.609 55.5382 325.531 53.1901C324.805 51.5918 324.315 49.737 324.08 47.6652H357.808L357.847 47.4087C357.965 46.6589 358.063 45.7907 358.122 44.7844C358.181 43.7978 358.22 42.7323 358.22 41.6076C358.22 37.5034 357.377 33.774 355.69 30.5183C354.004 27.2626 351.553 24.658 348.396 22.7835L348.376 22.8229ZM329.845 30.2815C331.767 29.0779 334.041 28.4662 336.61 28.4662C340.14 28.4662 342.944 29.5712 344.964 31.7614C346.69 33.6359 347.69 36.2208 347.945 39.4765H324.296C324.55 38.0953 324.923 36.8127 325.413 35.6683C326.433 33.3005 327.904 31.4852 329.825 30.2815H329.845Z"
        fill="currentColor"
      />
      <path
        d="M408.992 30.5578C407.305 27.302 404.854 24.6974 401.697 22.8229C398.54 20.9484 394.637 20.0013 390.108 20.0013C385.578 20.0013 381.715 20.9879 378.244 22.9216C374.773 24.8553 372.008 27.6967 370.067 31.347C368.106 34.9974 367.125 39.3976 367.125 44.4292C367.125 49.4608 368.106 53.861 370.067 57.5114C372.028 61.1617 374.812 64.0031 378.342 65.9368C381.872 67.8705 386.029 68.8571 390.657 68.8571C393.755 68.8571 396.638 68.423 399.246 67.5351C401.854 66.6669 404.128 65.3843 406.03 63.7269C407.933 62.0694 409.462 60.0765 410.58 57.8271L410.717 57.5508L402.344 53.5847L402.207 53.8413C401.128 55.8934 399.638 57.5114 397.794 58.6361C395.951 59.7805 393.48 60.3527 390.48 60.3527C387.774 60.3527 385.401 59.7411 383.44 58.5374C381.479 57.3338 379.93 55.5382 378.852 53.1901C378.126 51.5918 377.636 49.737 377.401 47.6652H411.129L411.168 47.4087C411.286 46.6589 411.384 45.7907 411.443 44.7844C411.502 43.7978 411.541 42.7323 411.541 41.6076C411.541 37.5034 410.698 33.774 409.011 30.5183L408.992 30.5578ZM383.146 30.3013C385.068 29.0976 387.343 28.4859 389.911 28.4859C393.441 28.4859 396.245 29.5909 398.265 31.7811C399.991 33.6557 400.991 36.2405 401.246 39.4963H377.597C377.852 38.115 378.224 36.8325 378.714 35.688C379.734 33.3202 381.205 31.5049 383.127 30.3013H383.146Z"
        fill="currentColor"
      />
    </g>
  )
});

import { ApolloClient, from, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { getLocalStorageItem, Key } from "@/utils/utils";
import { setContext } from "@apollo/client/link/context";
import apiErrorsVar, { ApiErrorType } from "@/apollo/errors";
import { cache } from "./cache";

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_CULTEE_API_GRAPHQL_URL
}) as unknown as ApolloLink;

const authLink = setContext((_, { headers }) => {
  const authorization = getLocalStorageItem<string>(Key.authorization);
  const adminAuthorization = getLocalStorageItem<string>(Key.adminAuthorization);

  if (authorization) {
    return { headers: { ...headers, authorization: authorization } };
  } else if (adminAuthorization) {
    return { headers: { ...headers, authorization: adminAuthorization, "X-Req-From-Admin": "" } };
  } else {
    return headers;
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  apiErrorsVar(
    [...(graphQLErrors || []), networkError].filter(
      (e: ApiErrorType | null | undefined): e is ApiErrorType => !!e
    )
  );
});

export const client = new ApolloClient({
  link: from([errorLink, authLink, uploadLink]),
  cache: cache,
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only"
    }
  }
});

import React from "react";
import { Textarea as ChakraTextarea, TextareaProps } from "@chakra-ui/react";
import theme from "@/shared/theme";

const basicStyles = {
  _focus: {
    color: theme.text.black700,
    borderColor: theme.service.turquoise400,
    boxShadow: "none"
  },
  _invalid: {
    borderColor: theme.error.red,
    color: theme.text.black700,
    boxShadow: "none"
  }
};

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ children, ...rest }, ref) => {
    return (
      <ChakraTextarea ref={ref} {...rest} {...basicStyles}>
        {children}
      </ChakraTextarea>
    );
  }
);

Textarea.displayName = "Textarea";

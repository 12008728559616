import React from "react";
import { PinInput as ChakrapinInput, PinInputField, HStack } from "@chakra-ui/react";
import theme from "@/shared/theme";

type PropsType = {
  onChange: (value: string) => void;
  isInvalid: boolean;
};

const basicStyles = {
  height: "100px",
  _active: { borderColor: theme.service.turquoise400 },
  _focus: { borderColor: theme.service.turquoise400 },
  fontSize: "32px",
  fontWeight: "700"
};

export const PinInput = React.forwardRef<HTMLInputElement, PropsType>(
  ({ isInvalid, onChange, ...rest }, ref) => {
    return (
      <HStack ref={ref} w="502px" justifyContent="space-between">
        <ChakrapinInput isInvalid={isInvalid} placeholder="" onChange={onChange} {...rest}>
          <PinInputField {...basicStyles} w="70px" />
          <PinInputField {...basicStyles} w="70px" />
          <PinInputField {...basicStyles} w="70px" />
          <PinInputField {...basicStyles} w="70px" />
          <PinInputField {...basicStyles} w="70px" />
          <PinInputField {...basicStyles} w="70px" />
        </ChakrapinInput>
      </HStack>
    );
  }
);

PinInput.displayName = "PinInput";

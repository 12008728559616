import React from "react";
import { Box, Center, Flex, Img, useMediaQuery } from "@chakra-ui/react";
import { CulteeNewIcon, Text } from "@/components";
import theme from "@/shared/theme";

export const TopContent: React.FC = () => {
  const [isLargerThan375] = useMediaQuery("(min-width: 375px)");
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isLargerThan960] = useMediaQuery("(min-width: 960px)");
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
  const [isLargerThan1440] = useMediaQuery("(min-width: 1440px)");

  const centerIconWidth = isLargerThan1280 ? 658 : isLargerThan960 ? 457 : 387;
  const logoIconWidth = isLargerThan1440 ? 454 : isLargerThan1280 ? 360 : isLargerThan960 ? 338 : isLargerThan768 ? 294 : 324;
  const textWidth = isLargerThan1440 ? 560 : isLargerThan1280 ? 384 : isLargerThan960 ? 257 : isLargerThan768 ? 185 : isLargerThan375 ? 321 : 136;

  return (
    <Flex minH={isLargerThan768 ? `calc(100vh)` : undefined} pos={"relative"} flexDir={isLargerThan768 ? "row" : "column"} id="top" pt={isLargerThan768 ? "120px" : "80px"}>
      <Box
        w={isLargerThan768 ? "50%" : "100%"}
        bgImage={"url(/assets/lp_top_rgb.png)"}
        bgRepeat={"no-repeat"}
        bgSize={"cover"}
        p={isLargerThan1280 ? "150px 51px" : isLargerThan375 && !isLargerThan768 ? "31px 24px" : "150px 51px"}
        style={{ boxSizing: "border-box" }}
        h={isLargerThan768 ? "unset" : "300px"}
      >
        <Box
          fontSize={isLargerThan375 && !isLargerThan768 ? "26px" : "36px"}
          fontWeight={"bold"}
          color={theme.text.white}
          w={textWidth}
          letterSpacing={isLargerThan768 && !isLargerThan960 ? "1px" : isLargerThan768 ? "3px" : "0px"}
        >
          <Text>自律型チーム育成</Text>
          <Text>
            コミュニ
            {isLargerThan768 && !isLargerThan960 && <br />}
            ケーション
            {isLargerThan768 && !isLargerThan960 && <br />}
            ツール
          </Text>
          <Text fontSize={isLargerThan768 ? "18px" : "14px"} mt={"16px"} fontWeight={400} letterSpacing={isLargerThan960 && !isLargerThan1280 ? "2px" : isLargerThan768 && !isLargerThan960 ? "0" : "1px"}>
            感情 × 行動 × データ活用で、
            {!isLargerThan768 && <br />}
            支え合い成長し続けるチームへ
          </Text>
        </Box>
        <CulteeNewIcon w={logoIconWidth} height={"auto"} color={theme.text.white} mt={isLargerThan768 ? "40px" : "12px"} />
      </Box>
      <Center w={isLargerThan768 ? "50%" : "100%"} bgImage={"url(/assets/lp_top_bg.svg)"} bgRepeat={"no-repeat"} bgSize={isLargerThan768 ? "cover" : "auto"} minH={isLargerThan768 ? "unset" : "300px"} />
      <Img
        w={centerIconWidth}
        src="/assets/lp_top_icon.svg"
        pos="absolute"
        left={"50%"}
        top={isLargerThan768 ? "50%" : "unset"}
        bottom={isLargerThan768 ? "unset" : "0"}
        style={{ translate: isLargerThan768 ? "-50% -50%" : "-50% 0" }}
      />
    </Flex>
  );
};

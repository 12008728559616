import React from "react";
import { Box, ComponentWithAs, Divider, Flex, IconProps } from "@chakra-ui/react";
import {
  Button,
  Text,
  MemberIcon,
  HistoryIcon,
  FileIcon,
  ReflectionIcon,
  HomeIcon,
  SearchIcon
} from "@/components";
import theme from "@/shared/theme";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

type MenuType = {
  key: string;
  icon: ComponentWithAs<"svg", IconProps>;
  title: string;
  to: string;
};

export const Sidebar: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const location = useLocation();
  const navigate = useNavigate();

  const menuList: MenuType[] = [
    {
      key: "/",
      icon: HomeIcon,
      title: t("sidebar.home"),
      to: "/"
    },
    {
      key: "/journalings",
      icon: ReflectionIcon,
      title: t("sidebar.journaling"),
      to: "/journalings"
    },
    {
      key: "/coaching-schedules",
      icon: FileIcon,
      title: t("sidebar.coaching"),
      to: "/coaching-schedules"
    },
    { key: "/members", icon: MemberIcon, title: t("sidebar.teamMembers"), to: "/members" },
    {
      key: "/journalings-line",
      icon: HistoryIcon,
      title: t("sidebar.reflection"),
      to: "/journalings-line"
    }
  ];

  return (
    <Box
      w="214px"
      minH="100vh"
      bgColor={theme.text.white}
      pos="fixed"
      left="0"
      top="48px"
      p="16px"
      zIndex={9999}
    >
      <Button
        buttonType="fill"
        w="182px"
        buttonSize="md"
        bgColor={theme.service.turquoiseText}
        leftIcon={<SearchIcon w="22px" h="20px" />}
        color={theme.text.white}
        onClick={() => navigate("/record")}
      >
        {t("sidebar.record")}
      </Button>
      <Divider bgColor={theme.text.black200} h="1px" m="8px 0" />
      {menuList.map((menu, idx) => (
        <Flex
          key={idx}
          p="8px 18px"
          borderRadius="4px"
          h="37px"
          color={location.pathname === menu.key ? theme.service.turquoiseText : theme.text.black700}
          bgColor={location.pathname === menu.key ? theme.service.turquoise50 : ""}
          _hover={{ color: theme.service.turquoiseText }}
          cursor="pointer"
          onClick={() => navigate(menu.to)}
        >
          <menu.icon mr="11px" w="22px" h="20px" />
          <Text textType="body14b">{menu.title}</Text>
        </Flex>
      ))}
      <Box
        pos="fixed"
        bottom="24px"
        left="24px"
        textAlign="center"
        color={theme.service.turquoiseText}
      >
        <Text fontSize="10px">{t("sidebar.bottomText1")}</Text>
        <Text fontSize="10px">{t("sidebar.bottomText2")}</Text>
      </Box>
    </Box>
  );
};

import React, { useCallback, useEffect } from "react";
import { Box, FormControl, FormErrorMessage, useToast } from "@chakra-ui/react";
import { Input, Text, Button } from "@/components";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useResetMyEmailMutation } from "@/apollo/generated";
import theme from "@/shared/theme";

type PropsType = { email: string };
type Form = { email: string };

type InputType = {
  key: "email";
  title: string;
  placeholder: string;
}[];

export const EmailForm: React.FC<PropsType> = ({ email }) => {
  const { t } = useTranslation(["login_information"]);
  const toast = useToast();
  const [resetMyEmail, { loading: resetLoading }] = useResetMyEmailMutation();

  const inputs: InputType = [{ key: "email", title: t("email"), placeholder: "" }];

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<Form>({
    resolver: zodResolver(
      z.object({
        email: z.string().email(t("emailErrorMsg") ?? "")
      })
    ),
    defaultValues: {}
  });

  const updateEmail = useCallback(
    (values: Form) => {
      resetMyEmail({
        variables: {
          input: {
            email: values.email,
            redirectUrl: window.location.origin + "/auth/email-reset",
            successNotificationRedirectUrl: window.location.origin + ""
          }
        }
      })
        .then((res) => {
          if (res.data?.resetMyEmail?.problemMsg) {
            setValue("email", email ?? "");
            return;
          }
          toast({
            position: "top",
            title: t("updateSuccess1"),
            description: t("updateSuccess2"),
            status: "success",
            duration: 3000,
            isClosable: true
          });
        })
        .catch(() => {});
    },
    [setValue, email, resetMyEmail, toast, t]
  );

  useEffect(() => {
    setValue("email", email ?? "");
  }, [email, setValue]);

  return (
    <Box width="60%" height="200px" pos="relative" mb="36px">
      <Text textType="title24">{t("email")}</Text>
      <form onSubmit={handleSubmit(updateEmail)}>
        {inputs.map((input) => (
          <FormControl
            key={input.key}
            isInvalid={!!errors[input.key]}
            mt="16px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            sx={{ "& .chakra-form__error-message": { width: "70%", ml: "30%" } }}
          >
            <Text textType="body14b" color={theme.text.black500} whiteSpace="nowrap">
              {input.title}
            </Text>
            <Input
              id={input.key}
              placeholder={input.placeholder}
              styleType="default"
              {...register(input.key)}
              width="70%"
            />
            <FormErrorMessage>{errors[input.key]?.message}</FormErrorMessage>
          </FormControl>
        ))}
        <Button
          buttonType="fill"
          w="196px"
          mt="36px"
          pos="absolute"
          right="0"
          type="submit"
          isLoading={resetLoading}
        >
          <Text textType="body16b">{t("emailSend")}</Text>
        </Button>
      </form>
    </Box>
  );
};

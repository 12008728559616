import { Tr, Td, Flex, Popover, PopoverTrigger, PopoverContent, Box } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Text } from "@/components";
import theme from "@/shared/theme";
import { useTranslation } from "react-i18next";
import { EnterpriseEdgeNodeFragment } from "@/apollo/generated";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

type PropsType = {
  companyInfo: EnterpriseEdgeNodeFragment;
  onOpen: () => void;
  setSelectedId: (id: string) => void;
  onCSVModalOpen: () => void;
};

export const CompanyTr: React.FC<PropsType> = ({
  companyInfo,
  onOpen,
  setSelectedId,
  onCSVModalOpen
}) => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const teamsText = useMemo(() => {
    const teamList: string[] = [];
    if (companyInfo.node?.teams) {
      companyInfo.node.teams.forEach((team) => team?.name && teamList.push(team?.name));
    }
    return teamList.toString();
  }, [companyInfo?.node?.teams]);

  return (
    <Tr>
      <Td p="28px 0 25px 46px">
        <Text w="153px" textType="body16r" color={theme.text.black900}>
          {companyInfo.node?.nameHan}
        </Text>
      </Td>
      <Td p="28px 0 25px 65px">
        <Text w="84px" textType="body16r" color={theme.text.black900}>
          {companyInfo.node?.createdAt &&
            format(new Date(companyInfo.node?.createdAt), "yyyy/MM/dd")}
        </Text>
      </Td>
      <Td p="28px 0 25px 64px">
        <Text w="400px" textType="body16r" color={theme.text.black900}>
          {teamsText}
        </Text>
      </Td>
      <Td p="28px 0 25px 28px">
        <Flex>
          <Text w="171px" textType="body16r" color={theme.text.black900}>
            {companyInfo.node?.status && t(`${companyInfo.node?.status}`)}
          </Text>
          <Popover placement="bottom-end" isLazy>
            <PopoverTrigger>
              <Box>
                <Text textType="title32" cursor="pointer" color={theme.text.black900}>
                  {t("omit")}
                </Text>
              </Box>
            </PopoverTrigger>
            <PopoverContent
              display="flex"
              flexDir="column"
              w="117px"
              h="131px"
              borderRadius="4px"
              bgColor={theme.text.white}
              boxShadow="0 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 15px -3px rgba(0, 0, 0, 0.10)"
              gap="3.98px"
              p="10.54px 0 10.54px 4px"
              sx={{
                "& p": {
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "16px"
                },
                "& div": {
                  cursor: "pointer"
                },
                "&:focus-visible": {
                  outline: "none",
                  outlineOffset: "none",
                  boxShadow: "none"
                }
              }}
            >
              <Flex
                p="4.2px 8px"
                borderRadius="8px"
                onClick={() => {
                  navigate(`/admin/enterprises/${companyInfo.node?.id}/edit`);
                }}
              >
                <Text color={theme.text.gray700}>{t("editCompanyInformation")}</Text>
              </Flex>
              <Flex
                p="4.2px 8px"
                borderRadius="8px"
                onClick={() => {
                  navigate(`/admin/team/${companyInfo.node?.id}/edit`);
                }}
              >
                <Text color={theme.text.gray700}>{t("teamEdit")}</Text>
              </Flex>
              <Flex
                p="4.2px 8px"
                borderRadius="8px"
                onClick={() => {
                  onCSVModalOpen();
                  setSelectedId(companyInfo.node?.id ?? "");
                }}
              >
                <Text color={theme.text.gray700}>{t("CSVexport")}</Text>
              </Flex>
              <Flex
                p="4.2px 8px"
                borderRadius="8px"
                onClick={() => {
                  onOpen();
                  setSelectedId(companyInfo.node?.id ?? "");
                }}
              >
                <Text color={theme.text.ahubAppeal}>{t("delete.title")}</Text>
              </Flex>
            </PopoverContent>
          </Popover>
        </Flex>
      </Td>
    </Tr>
  );
};

import React, { useCallback, useState } from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast
} from "@chakra-ui/react";
import { Button, Text, CloseIcon, Select } from "@/components";
import theme from "@/shared/theme";
import { useTranslation } from "react-i18next";
import { useUpdateMemberMutation, UserStatus } from "@/apollo/generated";

type PropsType = {
  userId: string;
  initStatus?: string | null;
  isOpen: boolean;
  onClose: () => void;
  onSubmited: () => void;
};

export const MemberStatusChangeModal: React.FC<PropsType> = ({
  userId,
  initStatus,
  isOpen,
  onClose,
  onSubmited
}) => {
  const { t } = useTranslation(["members"]);
  const toast = useToast();
  const [status, setStatus] = useState<UserStatus>();
  const [updateMemberStatusMutation, { loading }] = useUpdateMemberMutation();

  const userStatusOptions = [
    { label: t("status.ACTIVATED"), value: "ACTIVATED" },
    { label: t("status.RESTING"), value: "RESTING" }
  ];

  const updateMemberStatus = useCallback(() => {
    updateMemberStatusMutation({
      variables: { input: { status: status, id: userId } }
    })
      .then(() => {
        toast({
          description: t("updated"),
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true
        });
        onClose();
        onSubmited();
      })
      .catch(() => {});
  }, [status, userId, updateMemberStatusMutation, onSubmited, onClose, toast, t]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="630px" maxW="unset" p="17px 32px">
        <ModalHeader ps="0" pe="0">
          <Flex justifyContent="space-between" alignItems="center">
            <Text textType="title24" color={theme.text.black900}>
              {t("statusModal.title")}
            </Text>
            <CloseIcon
              w="24px"
              h="24px"
              color={theme.text.black900}
              cursor="pointer"
              onClick={onClose}
            />
          </Flex>
        </ModalHeader>
        <ModalBody p="0">
          <Text h="40px" textType="body14b" color={theme.text.black700} pb="20px">
            {t("statusModal.message")}
          </Text>
          <Select
            w="286px"
            h="44px"
            size="sm"
            placeholder="--"
            options={userStatusOptions}
            onChange={(value) => {
              value === "ACTIVATED"
                ? setStatus(UserStatus.Activated)
                : value === "PENDING"
                ? setStatus(UserStatus.Pending)
                : value === "RESTING"
                ? setStatus(UserStatus.Resting)
                : setStatus(undefined);
            }}
            defaultValue={initStatus ?? undefined}
          />
        </ModalBody>
        <ModalFooter ps="0" pe="0">
          <Button
            w="134px"
            buttonType="stroke"
            h="37px"
            mr="12px"
            onClick={onClose}
            borderRadius="40px"
          >
            {t("cancel", { ns: "common" })}
          </Button>
          <Button
            w="94px"
            buttonType="fill"
            h="37px"
            borderRadius="40px"
            onClick={updateMemberStatus}
            isLoading={loading}
          >
            {t("change")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

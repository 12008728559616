import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { Button, Text, Input, ErrorMessage, EyeIcon } from "@/components";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Box,
  InputGroup,
  InputRightElement
} from "@chakra-ui/react";
import theme from "@/shared/theme";
import { UserLoginInput, useResetPasswordMutation } from "@/apollo/generated";
import { useLocation } from "react-router-dom";
import { ViewOffIcon } from "@chakra-ui/icons";

type PropsType = {
  onSubmited: (email: string) => void;
};

type SingInForm = UserLoginInput & { confirmPassword?: string };

type InputType = {
  key: "email" | "password" | "confirmPassword";
  title: string;
  placeholder: string;
}[];

export const PasswordResetForm: React.FC<PropsType> = ({ onSubmited }) => {
  const { t } = useTranslation(["passwordreset"]);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [resetPassword, { loading }] = useResetPasswordMutation();
  const [hiddenIndex, setHiddenIndex] = useState<number[]>([]);
  const location = useLocation();
  const resetPasswordToken = new URLSearchParams(location.search).get("reset_password_token");

  const pwdInputs: InputType = [
    { key: "password", title: t("step3.enterprisePwd"), placeholder: "" },
    { key: "confirmPassword", title: t("step3.enterpriseRePwd"), placeholder: "" }
  ];

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<SingInForm>({
    resolver: zodResolver(
      z.object({
        password: z.string().refine(
          (pas) => {
            if (
              // eslint-disable-next-line no-useless-escape, no-control-regex
              /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()\-^\\@[\];:,.\/=~|`{}+*<>?_])\S{8,}$/.test(
                pas
              )
            ) {
              return true;
            } else return false;
          },
          { message: t("pwdErrorMsg") ?? "" }
        ),
        confirmPassword: z.string().refine(
          (pas) => {
            if (
              // eslint-disable-next-line no-useless-escape, no-control-regex
              /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()\-^\\@[\];:,.\/=~|`{}+*<>?_])\S{8,}$/.test(
                pas
              )
            ) {
              return true;
            } else return false;
          },
          { message: t("pwdErrorMsg") ?? "" }
        )
      })
    ),
    defaultValues: { confirmPassword: "" }
  });

  const handleSignin = useCallback(
    (values: SingInForm) => {
      if (values.password === values.confirmPassword) {
        resetPassword({
          variables: {
            input: { password: values.password, resetPasswordToken: resetPasswordToken ?? "" }
          }
        }).then((res) => {
          if (res.data?.resetPassword?.problemMsg) {
            setIsInvalid(true);
            return;
          }
          onSubmited(res.data?.resetPassword?.user?.email ?? "");
        });
      }
    },
    [onSubmited, resetPassword, resetPasswordToken]
  );

  const handleChangeEye = useCallback(
    (index: number) => {
      if (!hiddenIndex.includes(index)) {
        setHiddenIndex([...hiddenIndex, index]);
      } else {
        const array = [...hiddenIndex].filter((item) => item !== index);
        setHiddenIndex(array);
      }
    },
    [hiddenIndex]
  );

  return (
    <form onSubmit={handleSubmit(handleSignin)}>
      <Box>
        <ErrorMessage message={t("errorMsg")} isVisible={isInvalid} />
        <Flex justifyContent="space-between" pt="1.3125rem" wrap="wrap">
          {pwdInputs.map((input, index) => (
            <FormControl key={index} isInvalid={!!errors[input.key]} mb="1.625rem">
              <Text textType="body14b" color={theme.text.black500}>
                {input.title}
              </Text>
              <InputGroup>
                <Input
                  id={input.key}
                  placeholder={input.placeholder}
                  styleType="flushed"
                  type={
                    input.key === "password" || input.key === "confirmPassword"
                      ? !hiddenIndex.includes(index)
                        ? "password"
                        : undefined
                      : undefined
                  }
                  {...register(input.key)}
                />
                {(input.key === "password" || input.key === "confirmPassword") && (
                  <InputRightElement>
                    {hiddenIndex.includes(index) ? (
                      <EyeIcon
                        w="1.25rem"
                        cursor="pointer"
                        onClick={() => handleChangeEye(index)}
                      />
                    ) : (
                      <ViewOffIcon
                        w="1.25rem"
                        cursor="pointer"
                        onClick={() => handleChangeEye(index)}
                      />
                    )}
                  </InputRightElement>
                )}
              </InputGroup>
              <FormErrorMessage>{errors[input.key]?.message}</FormErrorMessage>
            </FormControl>
          ))}
        </Flex>
        <Button
          mt="4.625rem"
          w="34.75rem"
          buttonSize="xl"
          buttonType="fill"
          type="submit"
          isLoading={loading}
        >
          {t("step1.send")}
        </Button>
      </Box>
    </form>
  );
};

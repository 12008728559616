import React, { useCallback, useMemo, useState } from "react";
import { Button, PinInput, Text, ErrorMessage } from "@/components";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useVerifyOtpMutation } from "@/apollo/generated";
import { useAuthHeaderContext } from "@/hooks/useAuthHeaderContext";

type PropsType = {
  email: string;
  onCompleted: () => void;
};

export const PinInputForm: React.FC<PropsType> = ({ email, onCompleted }) => {
  const { t } = useTranslation(["signup"]);
  const { setAuthHeader } = useAuthHeaderContext();
  const [isInvaild, setIsInvalid] = useState<boolean>(false);
  const [otpInput, setOtpInput] = useState<string>();
  const [verifyOtpMutation, { loading }] = useVerifyOtpMutation();

  const isDisabled = useMemo(() => otpInput?.length !== 6, [otpInput]);

  const verifyOtp = useCallback(() => {
    verifyOtpMutation({ variables: { input: { otp: otpInput ?? "", email } } })
      .then((res) => {
        if (res.data?.verifyOtp?.problemMsg) return setIsInvalid(true);

        setAuthHeader(res.data?.verifyOtp?.credential ?? "");
        onCompleted();
      })
      .catch(() => {});
  }, [verifyOtpMutation, otpInput, email, setAuthHeader, onCompleted]);

  return (
    <>
      <Box m="16px 0">
        <ErrorMessage isVisible={!!isInvaild} message={t("signupForm.codeErrorMsg")} />
        <Text>{t("sendMailMessage1")}</Text>
        <Text>{t("sendMailMessage2")}</Text>
        <Text>{t("sendMailMessage3")}</Text>
      </Box>
      <PinInput isInvalid={!!isInvaild} onChange={setOtpInput} />
      <Button
        mt="32px"
        w="556px"
        buttonSize="xl"
        buttonType="fill"
        onClick={verifyOtp}
        isLoading={loading}
        isDisabled={isDisabled}
      >
        {t("signupForm.codeSending")}
      </Button>
    </>
  );
};

import { createIcon } from "@chakra-ui/icons";

export const Rectangle2Icon = createIcon({
  displayName: "Rectangle2Icon",
  viewBox: "0 0 169 117",
  path: (
    <svg width="169" height="117" viewBox="0 0 169 117" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.55971 2.43867C1.40622 2.1073 1.64821 1.72852 2.0134 1.72852H139.595C139.788 1.72852 139.964 1.83963 140.047 2.01399L167.113 58.999L168.468 58.3554L167.113 58.999C167.179 59.1369 167.178 59.2971 167.11 59.4341L140.063 114.449C139.979 114.62 139.805 114.729 139.614 114.729H2.05976C1.69168 114.729 1.44977 114.344 1.60891 114.012L0.259256 113.365L1.60891 114.012L27.1577 60.727C27.6094 59.7851 27.6166 58.6906 27.1776 57.7427L1.55971 2.43867Z"
        stroke="#0B8487"
        strokeWidth="3"
      />
    </svg>
  )
});

export const RectangleSP2Icon = createIcon({
  displayName: "RectangleSP2Icon",
  viewBox: "0 0 154 60",
  path: (
    <svg width="154" height="60" viewBox="0 0 154 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.45974 2.63491C0.923468 1.98236 1.38768 1 2.23232 1H127.354C127.649 1 127.929 1.13013 128.119 1.35562L152.13 29.8504C152.448 30.2276 152.444 30.7804 152.119 31.1521L128.128 58.6573C127.939 58.875 127.664 59 127.375 59H2.32699C1.4744 59 1.01287 58.0015 1.56529 57.3521L22.7574 32.4389C23.6981 31.333 23.7119 29.7121 22.7901 28.5904L1.45974 2.63491Z"
        stroke="#0B8487"
        strokeWidth="2"
      />
    </svg>
  )
});

import React, { ReactNode } from "react";
import { Box, Center, CenterProps } from "@chakra-ui/react";

interface MainContentProps extends CenterProps {
  children: ReactNode;
}

export const MainContent: React.FC<MainContentProps> = ({ children, ...props }) => {
  return (
    <Center p={"0 24px"} {...props}>
      <Box maxW={1080} w="100%">
        {children}
      </Box>
    </Center>
  );
};

import { createIcon } from "@chakra-ui/icons";

export const Rectangle4Icon = createIcon({
  displayName: "Rectangle4Icon",
  viewBox: "0 0 170 117",
  path: (
    <svg width="170" height="117" viewBox="0 0 170 117" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.56793 2.43969C1.41349 2.10822 1.65547 1.72852 2.02115 1.72852H140.433C140.626 1.72852 140.802 1.83917 140.885 2.01297L168.11 58.9979C168.176 59.1363 168.175 59.2975 168.107 59.4351L140.9 114.45C140.816 114.621 140.642 114.729 140.452 114.729H2.06786C1.69927 114.729 1.45738 114.343 1.6175 114.011L27.3126 60.7342C27.7689 59.7882 27.7762 58.6875 27.3327 57.7355L1.56793 2.43969Z"
        stroke="#0B8487"
        strokeWidth="3"
      />
    </svg>
  )
});

export const RectangleSP4Icon = createIcon({
  displayName: "RectangleSP4Icon",
  viewBox: "0 0 153 60",
  path: (
    <svg width="153" height="60" viewBox="0 0 153 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.44053 2.63248C0.907249 1.97937 1.37195 1 2.21511 1H126.515C126.811 1 127.092 1.13111 127.282 1.35804L151.14 29.8529C151.455 30.2293 151.45 30.7788 151.129 31.1498L151.884 31.8047L151.129 31.1498L127.292 58.6549C127.102 58.8741 126.826 59 126.536 59H2.30903C1.45793 59 0.995883 58.0045 1.54528 57.3545L22.6096 32.4317C23.5423 31.3283 23.5559 29.7169 22.6421 28.5978L1.44053 2.63248Z"
        stroke="#0B8487"
        strokeWidth="2"
      />
    </svg>
  )
});

import React, { useCallback, useMemo, useState } from "react";
import { Button, PinInput, Text, ErrorMessage } from "@/components";
import { Box, Divider } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAdminVerifyOtpMutation } from "@/apollo/generated";
import { useAuthHeaderContext } from "@/hooks/useAuthHeaderContext";
import theme from "@/shared/theme";
import { useNavigate } from "react-router-dom";

type PropsType = {
  uid: string;
};

export const PinInputForm: React.FC<PropsType> = ({ uid }) => {
  const { t } = useTranslation(["admin"]);
  const { setAdminHeader } = useAuthHeaderContext();
  const [isInvaild, setIsInvalid] = useState<boolean>(false);
  const [otpInput, setOtpInput] = useState<string>();
  const [adminVerifyOtpMutation, { loading }] = useAdminVerifyOtpMutation();
  const navigate = useNavigate();

  const isDisabled = useMemo(() => otpInput?.length !== 6, [otpInput]);

  const verifyOtp = useCallback(() => {
    const input = { otp: otpInput ?? "", uid };
    adminVerifyOtpMutation({ variables: { input: input } })
      .then((res) => {
        if (res.data?.adminVerifyOtp?.problemMsg) return setIsInvalid(true);

        setAdminHeader(res.data?.adminVerifyOtp?.credential ?? "");
        navigate("/admin");
      })
      .catch(() => {});
  }, [otpInput, uid, adminVerifyOtpMutation, setAdminHeader, navigate]);

  return (
    <Box w="649px" h="435px" bgColor={theme.text.white} p="17px 42px">
      <Text fontSize="32px" fontWeight={400} letterSpacing="0.96px" color={theme.text.black900}>
        {t("signin.title")}
      </Text>
      <Divider
        w="100%"
        h="2px"
        mt="12px"
        borderBottomWidth="0"
        bgColor={theme.service.turquoise50}
      />
      <Box mt="14px" mb="13px" p="7px 8px" minH="76px" w="456px" bgColor={theme.text.white}>
        <ErrorMessage isVisible={isInvaild} message={t("signin.codeErrorMsg")} />
        <Text textType="body14r" letterSpacing="0.42px">
          {t("signin.sendMailMessage1")}
        </Text>
        <Text textType="body14r" letterSpacing="0.42px">
          {t("signin.sendMailMessage2")}
        </Text>
      </Box>
      <PinInput isInvalid={isInvaild} onChange={setOtpInput} />
      <Button
        mt="32px"
        w="556px"
        buttonSize="xl"
        buttonType="fill"
        onClick={verifyOtp}
        isLoading={loading}
        isDisabled={isDisabled}
      >
        {t("signin.codeSending")}
      </Button>
    </Box>
  );
};

import React, { useCallback, useMemo, useState } from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast
} from "@chakra-ui/react";
import { Button, Text, CloseIcon, Select } from "@/components";
import theme from "@/shared/theme";
import { useTranslation } from "react-i18next";
import { useGetMyTeamsQuery, useUpdateMemberMutation } from "@/apollo/generated";

type PropsType = {
  userId: string;
  currentTeamId: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmited: () => void;
};

export const MemberTeamEditModal: React.FC<PropsType> = ({
  userId,
  currentTeamId,
  isOpen,
  onClose,
  onSubmited
}) => {
  const { t } = useTranslation(["members"]);
  const toast = useToast();
  const { data } = useGetMyTeamsQuery();
  const [teamId, setTeamId] = useState<string>("");
  const [updateMemberTeamMutation, { loading }] = useUpdateMemberMutation();

  const userTeamOptions = useMemo(() => {
    if (!data?.myEnterprise.teams) return [];

    return data?.myEnterprise.teams?.map((t) => {
      return {
        value: t?.id ?? "",
        label: t?.name ?? ""
      };
    });
  }, [data]);

  const updateMemberTeam = useCallback(() => {
    updateMemberTeamMutation({
      variables: { input: { teamId: teamId, id: userId } }
    })
      .then(() => {
        toast({
          description: t("updated"),
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true
        });
        onClose();
        onSubmited();
      })
      .catch(() => {});
  }, [teamId, userId, updateMemberTeamMutation, onSubmited, onClose, toast, t]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="630px" maxW="unset" p="17px 32px">
        <ModalHeader ps="0" pe="0">
          <Flex justifyContent="space-between" alignItems="center">
            <Text textType="title24" color={theme.text.black900}>
              {t("editModal.title")}
            </Text>
            <CloseIcon
              w="24px"
              h="24px"
              color={theme.text.black900}
              cursor="pointer"
              onClick={onClose}
            />
          </Flex>
        </ModalHeader>
        <ModalBody p="0">
          <Text h="40px" textType="body16r" color={theme.text.black700}>
            {t("editModal.message")}
          </Text>
          <Select
            w="286px"
            h="44px"
            size="sm"
            placeholder="--"
            options={userTeamOptions}
            onChange={setTeamId}
            defaultValue={currentTeamId}
          />
        </ModalBody>
        <ModalFooter ps="0" pe="0">
          <Button
            w="134px"
            buttonType="stroke"
            h="37px"
            mr="12px"
            onClick={onClose}
            borderRadius="40px"
          >
            {t("cancel", { ns: "common" })}
          </Button>
          <Button
            w="94px"
            buttonType="fill"
            h="37px"
            borderRadius="40px"
            onClick={updateMemberTeam}
            isLoading={loading}
          >
            {t("invite")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import { Box, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  CultureChangeSupport,
  CustomerCase,
  Footer,
  LandingPageHeader,
  MainContent,
  Question,
  TopContent
} from "@/components";
import { LinkButtons } from "@/components/pages/landingPage/LinkButtons";
import { StickyAnimation } from "@/components/pages/landingPage/StickyAnimation";
import { Carousel } from "@/components/pages/landingPage/Carousel";
import { BarrierToChange } from "@/components/pages/landingPage/BarrierToChange";
import { TeamIntroduction } from "@/components/pages/landingPage/TeamIntroduction";
import { Concerns } from "@/components/pages/landingPage/Concerns";
import { UtilizationCycle } from "@/components/pages/landingPage/UtilizationCycle";
import { StepsToChange } from "@/components/pages/landingPage/StepsToChange";
import { DetailOfFunction } from "@/components/pages/landingPage/DetailOfFunction";
import { Concept2 } from "@/components/pages/landingPage/Concept2";
import { Organization2 } from "@/components/pages/landingPage/Organization2";

const LandingPage: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isAtTop, setIsAtTop] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY !== 0) setIsAtTop(false);
      else setIsAtTop(true);
    };

    window?.addEventListener("scroll", handleScroll);

    return () => {
      window?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box pos={"relative"}>
      <LandingPageHeader isAtTop={isAtTop} />
      <TopContent />
      {/* <Box bgImage={isLargerThan768 ? "url(/assets/three_things.png)" : undefined} bgRepeat={"no-repeat"} bgSize="cover" bgPos={"center"}>
        {isLargerThan768 ? (
          <MainContent>
            <Concept />
            <ThreeThingsCanDo />
            <Box p={"5.5vw 0"}>
              <LinkButtons isGray />
            </Box>
          </MainContent>
        ) : (
          <Box>
            <MainContent>
              <Concept />
            </MainContent>
            <Box bgImage={isLargerThan768 ? undefined : "url(/assets/three_things.png)"} bgRepeat={"no-repeat"} bgSize={"100% 140%"} bgPos={"center"}>
              <MainContent>
                <ThreeThingsCanDo />
                <Box p={"5.5vw 0"}>
                  <LinkButtons isGray />
                </Box>
              </MainContent>
            </Box>
          </Box>
        )}
      </Box> */}
      <Concept2 />
      <BarrierToChange />
      <TeamIntroduction />
      <Concerns />
      <UtilizationCycle />
      <StepsToChange />
      {!isLargerThan768 && (
        <MainContent>
          <Box p={"5.5vw 0"}>
            <LinkButtons isGray title={"より詳細な\nサービスの特徴や効果は\nこちらから"} />
          </Box>
        </MainContent>
      )}
      <div>
        <DetailOfFunction />
      </div>
      {isLargerThan768 ? <StickyAnimation /> : <Carousel />}
      {isLargerThan768 && (
        <MainContent>
          <Box p={"5.5vw 0"} id="ss">
            <LinkButtons isGray title="より詳細なサービスの特徴や効果はこちらから" />
          </Box>
        </MainContent>
      )}
      {/* <Organization /> */}
      <Organization2 />
      <CultureChangeSupport />
      <CustomerCase />
      <Question />
      <Footer />
    </Box>
  );
};

export default LandingPage;

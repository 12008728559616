import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { Button, Text, Input, ErrorMessage, EyeIcon } from "@/components";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Box,
  InputGroup,
  InputRightElement,
  useBoolean,
  Link
} from "@chakra-ui/react";
import { ViewOffIcon } from "@chakra-ui/icons";
import theme from "@/shared/theme";
import { useUserLoginMutation, UserLoginInput } from "@/apollo/generated";
import { useAuthHeaderContext } from "@/hooks/useAuthHeaderContext";

type PropsType = {
  onSubmited: (email: string) => void;
};

type SingInForm = UserLoginInput & {};

type InputType = {
  key: "email" | "password";
  title: string;
  placeholder: string;
}[];

export const SigninForm: React.FC<PropsType> = ({ onSubmited }) => {
  const { t } = useTranslation(["signin"]);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [userLoginMutation, { loading }] = useUserLoginMutation();
  const [isPasswordHidden, setPasswordHidden] = useBoolean(true);
  const { removeAdminHeader, removeAuthHeader } = useAuthHeaderContext();
  const [errorMsg, setErrorMsg] = useState(t("errorMsg") ?? "");

  const inputs: InputType = [
    { key: "email", title: t("enterpriseId"), placeholder: "" },
    { key: "password", title: t("password"), placeholder: "" }
  ];

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<SingInForm>({
    resolver: zodResolver(
      z.object({
        email: z.string().email({ message: t("form.validation.email", { ns: "common" }) ?? "" }),
        password: z
          .string()
          .min(1, { message: t("form.validation.password", { ns: "common" }) ?? "" })
      })
    ),
    defaultValues: {}
  });

  const handleSignin = useCallback(
    (values: SingInForm) => {
      removeAdminHeader();
      removeAuthHeader();
      userLoginMutation({
        variables: { input: values }
      }).then((res) => {
        if (res.data?.userLogin?.problemMsg) {
          setIsInvalid(true);
          setErrorMsg(res.data?.userLogin?.problemMsg);
          return;
        }

        onSubmited(res.data?.userLogin?.email ?? "");
      });
    },
    [onSubmited, removeAdminHeader, removeAuthHeader, userLoginMutation]
  );

  return (
    <form onSubmit={handleSubmit(handleSignin)}>
      <Box pos="relative">
        <ErrorMessage message={errorMsg} isVisible={isInvalid} />
        <Flex justifyContent="space-between" pt="21px" wrap="wrap">
          {inputs.map((input) => (
            <FormControl key={input.key} isInvalid={!!errors[input.key]} mb="26px">
              <Text textType="body14b" color={theme.text.black500}>
                {input.title}
              </Text>
              <InputGroup>
                <Input
                  id={input.key}
                  placeholder={input.placeholder}
                  styleType="flushed"
                  type={
                    input.key === "password"
                      ? isPasswordHidden
                        ? "password"
                        : undefined
                      : undefined
                  }
                  {...register(input.key)}
                />
                {input.key === "password" && (
                  <InputRightElement>
                    {!isPasswordHidden ? (
                      <EyeIcon
                        w="20px"
                        cursor="pointer"
                        onClick={() => setPasswordHidden.toggle()}
                      />
                    ) : (
                      <ViewOffIcon
                        w="20px"
                        cursor="pointer"
                        onClick={() => setPasswordHidden.toggle()}
                      />
                    )}
                  </InputRightElement>
                )}
              </InputGroup>
              <FormErrorMessage>{errors[input.key]?.message}</FormErrorMessage>
            </FormControl>
          ))}
          <Link href="/auth/password-reset" pos="absolute" bottom="100px" right="0px">
            <Text textType="body14r">{t("passwordForget")}</Text>
          </Link>
        </Flex>
        <Button
          mt="74px"
          w="556px"
          buttonSize="xl"
          buttonType="fill"
          type="submit"
          isLoading={loading}
        >
          {t("signin")}
        </Button>
      </Box>
    </form>
  );
};

import React, { useRef } from "react";
import { Box, Flex, Text, Img } from "@chakra-ui/react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import theme from "@/shared/theme";
import { ArrowIcon } from "@/components/icons/ArrowIcon";
import { Fade } from "react-awesome-reveal";

export const Carousel: React.FC = () => {
  const swiperRef = useRef<any>(null);

  const slides: { title: string; content: { title: string; text: string }[]; imgList: string[]; coda: string }[] = [
    {
      title: "目標設定",
      content: [{ title: "設定した目標を、各画面で常時表示", text: "プロフィール設定画面にて、自身の目標を入力することができます。入力された目標を意識した行動を支援するために、各画面で常時表示されるようになります。" }],
      imgList: ["detail_part_1.svg"],
      coda: "目標は自由に設定することができます。"
    },
    {
      title: "コーチングスケジュール",
      content: [
        {
          title: "チームメンバー内でコーチングペアをランダムに設定",
          text: "毎月20日に、翌月のコーチングを実施するペアがCultee上で設定されます。ペアは、上司部下の偏りなくランダムに決定されるため、タテ・ヨコの様々な方向のコミュニケーションが活性化されます。"
        },
        {
          title: "チーム内に実施状況を共有",
          text: "コーチングペアが決定されると、コーチングの実施予定日を入力することができるようになります。実施予定日は、ペアと合わせて一覧表示されるため、チーム内で実施状況を確認し合うことができます。"
        }
      ],
      imgList: ["detail_part_2.svg"],
      coda: "コーチングペアと実施予定日が一覧で表示されます"
    },
    {
      title: "振り返りの記録",
      content: [
        {
          title: "『今日はどんな気持ちだった？』",
          text: "8つの感情を、その日の気持ちに合わせて0〜5の6段階で記録することができます。そうすることで、これまで見えていなかった感情を認識しやすくなります。"
        },
        {
          title: "『今日はどんな状況だった？』",
          text: "具体的なその日の状況を、日記感覚で記録することができます。この部分は、他のメンバーには開示されないため、振り返りのために自由に活用することができます。"
        },
        {
          title: "『３Good things（今日のよかったことは？）』",
          text: "今日のよかったことや人に自慢できることを箇条書きで記録することができます。目標達成に向けて、自身の良かった点を振り返る機会になります。"
        },
        {
          title: "『Switching Actions（次はどうしたい？）』",
          text: "本当は自分はどうしたかったのか・どうすればよかったのかを箇条書きで記録することができます。記録を通して、目標達成に向けたネクストアクションにつなげていきます。"
        }
      ],
      imgList: ["detail_part_4.svg"],
      coda: "4つの設問を通して、振り返りを記録できます。"
    },
    {
      title: "ジャーナリングノート",
      content: [
        { title: "メンバーが立てている目標を常時表示", text: "メンバーが立てている目標が常時表示されるため、”今”メンバーが何を目指して業務に励んでいるのかを一目で理解することができます。" },
        {
          title: `"今日のよかったこと""次はどうしたい"に対してメンバーがリアクション`,
          text: `メンバーの"今日のよかったこと""次はどうしたい"に対して、リアクションをつけることができます。豊富なリアクションの種類で、メンバー間のコミュニケーションを支援します。`
        },
        {
          title: `"どんな気持ちだった"をレーダーチャートで表示`,
          text: "メンバーの記録した気持ちをレーダーチャートにして表示します。レーダーチャートとして表示することで、メンバーの気持ちの直観的な理解を促します。"
        },
        {
          title: "メンバーや日付による絞込検索",
          text: "メンバーを絞り込んで記録を表示することができます。絞り込みを活用すれば、自身の記録だけを表示することも可能です。また、日付をもとに一定期間の記録に絞り込む機能もあります。"
        }
      ],
      imgList: ["detail_part_4.svg"],
      coda: "全メンバーの記録が一覧で表示されます。"
    },
    {
      title: "リフレクション",
      content: [
        {
          title: "過去4週間分の記録を振り返り",
          text: "自動集計した過去4週間分の記録をもとに、グラフとして表示されます。グラフを通して、感情の揺れ動きを視覚的に理解することで、感情を起点とした振り返りをサポートします。"
        },
        {
          title: "感情別にグラフを絞り込み",
          text: "8つの感情から、特定の感情に絞り込んでグラフを表示することができます。絞り込みは複数選択することもできるため、着目したい感情にフォーカスした振り返りが行えます。"
        }
      ],
      imgList: ["detail_part_5.svg"],
      coda: "自身の感情の動きがグラフで表示されます。"
    },
    {
      title: "コーチングガイド",
      content: [
        {
          title: "コーチングの進め方やポイントを掲載",
          text: "コーチングの進め方や、ポイントが掲載されたガイドをご提供いたします。ガイドを参考にすることで、初めてコーチングを行う方も、ポイントをおさえたコーチングを実施することができます。"
        }
      ],
      imgList: ["detail_part_6.svg"],
      coda: "コーチングの進め方やポイントが掲載されています。"
    },
    {
      title: "分析レポート",
      content: [
        {
          title: "データ集計・分析",
          text: "記録されたチームのデータを集計・分析するオプションもご用意しております。チーム単位・個人単位の感情データ分析や、頻出ワード分析などを行い、今のチームや個人の状況を読み解き、ありたい姿を実現するためのヒントをご提供いたします。また、継続して集計・分析を行うことで、チームの傾向や変化をつかむヒントとしてもご活用いただけます。"
        }
      ],
      imgList: ["detail_part_6.svg"],
      coda: "チームの”今”を分析してご提供いたします。\n※掲載内容はイメージです。内容はチームに合わせて調整いたします。"
    }
  ];

  return (
    <Fade duration={1000} fraction={0.2}>
      <Box mt="48px" maxWidth="768px" color={theme.text.black800} sx={{ "& .swiper-pagination-bullet-active": { bg: theme.primary.green900 } }}>
        <Swiper onSwiper={(swiper: any) => (swiperRef.current = swiper)} spaceBetween={16} slidesPerView={1.1067} centeredSlides={true} loop={true} pagination={true} modules={[Pagination]}>
          {slides.map((item, idx) => (
            <SwiperSlide key={idx}>
              <Box p="24px 16px 24px 16px" mb="48px" border={`1px solid ${theme.gary.gray50}`} shadow="0 4px 4px rgba(0, 0, 0, 0.25)" borderRadius="20px">
                <Box h="52px" alignContent="center" pos="relative">
                  <Text fontSize="16px" fontWeight={700} textAlign="center">
                    {item.title}
                  </Text>
                  <ArrowIcon cursor="pointer" pos="absolute" left="0" top="18px" aria-label="Previous Slide" onClick={() => swiperRef.current?.slidePrev()} />
                  <ArrowIcon cursor="pointer" transform="rotate(180deg)" pos="absolute" right="0" top="18px" onClick={() => swiperRef.current?.slideNext()} />
                </Box>
                <Flex gap="32px" flexDirection="column" mt="40px">
                  {item.content.map((content, index) => (
                    <Box key={index}>
                      <Text fontSize="20px" fontWeight={700} textAlign="start">
                        {content.title}
                      </Text>
                      <Text mt="20px" fontSize="14px" fontWeight={300} textAlign="start">
                        {content.text}
                      </Text>
                    </Box>
                  ))}
                </Flex>
                <Flex mt="40px" gap="10px" flexDirection="column">
                  {item.imgList.map((img, index) => (
                    <Img key={index} src={`/assets/${img}`} alt="computer" w="100%" />
                  ))}
                </Flex>
                <Text textAlign="start" whiteSpace="pre-wrap" fontSize="14px" fontWeight={400}>
                  {item.coda}
                </Text>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Fade>
  );
};

import { createIcon } from "@chakra-ui/icons";

export const DeleteIcon = createIcon({
  displayName: "DeleteIcon",
  viewBox: "0 0 36 36",
  path: (
    <g xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path
        d="M12.6 25.5L18 20.1L23.4 25.5L25.5 23.4L20.1 18L25.5 12.6L23.4 10.5L18 15.9L12.6 10.5L10.5 12.6L15.9 18L10.5 23.4L12.6 25.5ZM18 33C15.925 33 13.975 32.606 12.15 31.818C10.325 31.031 8.7375 29.9625 7.3875 28.6125C6.0375 27.2625 4.969 25.675 4.182 23.85C3.394 22.025 3 20.075 3 18C3 15.925 3.394 13.975 4.182 12.15C4.969 10.325 6.0375 8.7375 7.3875 7.3875C8.7375 6.0375 10.325 4.9685 12.15 4.1805C13.975 3.3935 15.925 3 18 3C20.075 3 22.025 3.3935 23.85 4.1805C25.675 4.9685 27.2625 6.0375 28.6125 7.3875C29.9625 8.7375 31.031 10.325 31.818 12.15C32.606 13.975 33 15.925 33 18C33 20.075 32.606 22.025 31.818 23.85C31.031 25.675 29.9625 27.2625 28.6125 28.6125C27.2625 29.9625 25.675 31.031 23.85 31.818C22.025 32.606 20.075 33 18 33Z"
        fill="#545454"
      />
    </g>
  )
});

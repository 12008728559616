import React, { useCallback, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import theme from "@/shared/theme";
import { useTranslation } from "react-i18next";
import { Box, useBoolean } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";
import { LienGraphFragment } from "@/apollo/generated";
import moment from "moment";

type PropsType = {
  initState: LienGraphFragment[];
};

export const EmotionLineChart: React.FC<PropsType> = ({ initState }) => {
  const { t } = useTranslation(["common"]);
  const maxDate = moment();
  const [isCheckAll, { toggle: toggleCheckAll }] = useBoolean(true);

  const daysList = useMemo(() => {
    return [
      maxDate.format("MM/DD"),
      ...Array.from(Array(27), () => maxDate.subtract(1, "day").format("MM/DD"))
    ].reverse();
  }, [maxDate]);

  const getEmotionLine = useCallback(
    (key: keyof LienGraphFragment) => {
      let emptyList = Array.from(Array(28), () => 0);

      initState.forEach((i) => {
        let m = moment(i.updatedAt).tz("Asia/Tokyo").format("MM/DD");

        const index = daysList.findIndex((d) => d === m);

        emptyList[index] = Number(i[key]);
      });

      return emptyList;
    },
    [initState, daysList]
  );

  const chartSeries: ApexOptions["series"] = useMemo(() => {
    return [
      {
        name: "",
        data: []
      },
      {
        name: "全て",
        data: []
      },
      {
        name: t("emotions.pleasure") ?? "",
        data: getEmotionLine("pleasure")
      },
      {
        name: t("emotions.confidence") ?? "",
        data: getEmotionLine("confidence")
      },
      {
        name: t("emotions.fear") ?? "",
        data: getEmotionLine("fear")
      },
      {
        name: t("emotions.surprise") ?? "",
        data: getEmotionLine("surprise")
      },
      {
        name: t("emotions.sadness") ?? "",
        data: getEmotionLine("sadness")
      },
      {
        name: t("emotions.repugnance") ?? "",
        data: getEmotionLine("repugnance")
      },
      {
        name: t("emotions.anger") ?? "",
        data: getEmotionLine("anger")
      },
      {
        name: t("emotions.expectation") ?? "",
        data: getEmotionLine("expectation")
      }
    ];
  }, [getEmotionLine, t]);

  const handleCheckAll = useCallback(
    (seriesIndex: number) => {
      if (seriesIndex === 1) {
        const myChart = ApexCharts.getChartByID("emotion-line");

        toggleCheckAll();

        if (!myChart) return;

        [
          t("emotions.pleasure"),
          t("emotions.confidence"),
          t("emotions.fear"),
          t("emotions.surprise"),
          t("emotions.sadness"),
          t("emotions.repugnance"),
          t("emotions.anger"),
          t("emotions.expectation")
        ].forEach((i) => (isCheckAll ? myChart.hideSeries(t(i)) : myChart.showSeries(t(i))));
      }
    },
    [isCheckAll, t, toggleCheckAll]
  );

  const chartOptions: ApexOptions = useMemo(() => {
    return {
      chart: {
        id: "emotion-line",
        height: 350,
        width: "calc(100vw - 300px)",
        minWidth: 1136,
        stacked: false,
        toolbar: { show: false },
        events: {
          legendClick: (chart, seriesIndex) => handleCheckAll(seriesIndex ?? 0)
        }
      },
      markers: {
        size: 3,
        showNullDataPoints: false,
        colors: [
          "",
          "",
          theme.emotions.joy,
          theme.emotions.trust,
          theme.emotions.fear,
          theme.emotions.shock,
          theme.emotions.sadness,
          theme.emotions.digust,
          theme.emotions.anger,
          theme.emotions.hope
        ]
      },
      grid: {
        show: true,
        borderColor: "#F0F0F0",
        position: "back",
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: [
        theme.text.white,
        theme.text.black900,
        theme.emotions.joy,
        theme.emotions.trust,
        theme.emotions.fear,
        theme.emotions.shock,
        theme.emotions.sadness,
        theme.emotions.digust,
        theme.emotions.anger,
        theme.emotions.hope
      ],
      stroke: {
        curve: "smooth",
        width: [0, 0, 1, 1, 1, 1, 1, 1, 1, 1]
      },
      plotOptions: {
        bar: {
          columnWidth: "20%"
        }
      },
      xaxis: {
        min: 1,
        max: 28,
        categories: daysList,
        labels: {
          style: {
            fontSize: "10px",
            colors: "#A3A3A3"
          }
        }
      },
      yaxis: [
        {
          max: 6,
          min: 0,
          tickAmount: 6,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: true,
            color: "#F0F0F0"
          },
          labels: {
            style: {
              fontSize: "10px",
              colors: "#A3A3A3"
            }
          }
        }
      ],
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          show: false
        }
      },
      legend: {
        show: true,
        offsetX: -120,
        fontSize: "16px",
        fontWeight: "700",
        showForSingleSeries: true,
        showForZeroSeries: true,
        itemMargin: {
          horizontal: 30
        }
      }
    };
  }, [daysList, handleCheckAll]);

  return (
    <Box id="chart" w="100%">
      <ReactApexChart options={chartOptions} series={chartSeries} type="line" height={350} />
    </Box>
  );
};

import { createIcon } from "@chakra-ui/icons";

export const CancelIcon = createIcon({
  displayName: "CancelIcon",
  viewBox: "0 0 64 64",
  path: (
    <g fill="currentColor">
      <rect width="64" height="64" fill="white" />
      <path
        d="M20.5335 43.4663C21.0224 43.9552 21.6446 44.1997 22.4002 44.1997C23.1557 44.1997 23.7779 43.9552 24.2668 43.4663L32.0002 35.733L39.8002 43.533C40.2891 44.0219 40.9006 44.2548 41.6348 44.2317C42.3673 44.2103 42.9779 43.9552 43.4668 43.4663C43.9557 42.9774 44.2002 42.3552 44.2002 41.5997C44.2002 40.8441 43.9557 40.2219 43.4668 39.733L35.7335 31.9997L43.5335 24.1997C44.0224 23.7108 44.2553 23.0992 44.2322 22.365C44.2108 21.6326 43.9557 21.0219 43.4668 20.533C42.9779 20.0441 42.3557 19.7997 41.6002 19.7997C40.8446 19.7997 40.2224 20.0441 39.7335 20.533L32.0002 28.2663L24.2002 20.4663C23.7113 19.9775 23.1006 19.7437 22.3682 19.765C21.6339 19.7881 21.0224 20.0441 20.5335 20.533C20.0446 21.0219 19.8002 21.6441 19.8002 22.3997C19.8002 23.1552 20.0446 23.7775 20.5335 24.2663L28.2668 31.9997L20.4668 39.7997C19.9779 40.2886 19.7451 40.8992 19.7682 41.6317C19.7895 42.3659 20.0446 42.9774 20.5335 43.4663ZM32.0002 58.6663C28.3113 58.6663 24.8446 57.9659 21.6002 56.565C18.3557 55.1659 15.5335 53.2663 13.1335 50.8663C10.7335 48.4663 8.83394 45.6441 7.43483 42.3997C6.03394 39.1552 5.3335 35.6886 5.3335 31.9997C5.3335 28.3108 6.03394 24.8441 7.43483 21.5997C8.83394 18.3552 10.7335 15.533 13.1335 13.133C15.5335 10.733 18.3557 8.83256 21.6002 7.43167C24.8446 6.03256 28.3113 5.33301 32.0002 5.33301C35.6891 5.33301 39.1557 6.03256 42.4002 7.43167C45.6446 8.83256 48.4668 10.733 50.8668 13.133C53.2668 15.533 55.1664 18.3552 56.5655 21.5997C57.9664 24.8441 58.6668 28.3108 58.6668 31.9997C58.6668 35.6886 57.9664 39.1552 56.5655 42.3997C55.1664 45.6441 53.2668 48.4663 50.8668 50.8663C48.4668 53.2663 45.6446 55.1659 42.4002 56.565C39.1557 57.9659 35.6891 58.6663 32.0002 58.6663Z"
        fill="#0B8487"
      />
    </g>
  )
});

import React from "react";
import { Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { Button, Text, CloseIcon } from "@/components";
import { useTranslation } from "react-i18next";
import theme from "@/shared/theme";

type PropsType = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
};

export const MemberDeleteModal: React.FC<PropsType> = ({ isOpen, onClose, onDelete }) => {
  const { t } = useTranslation(["members"]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="630px" maxW="unset" p="32px">
        <ModalHeader ps="0" pe="0">
          <Flex alignItems="center" justifyContent="space-between">
            <Text textType="title24" color={theme.text.black900}>
              {t("deleteModal.title")}
            </Text>
            <CloseIcon
              w="24px"
              h="24px"
              color={theme.text.black900}
              cursor="pointer"
              onClick={onClose}
            />
          </Flex>
        </ModalHeader>
        <ModalBody p="0">
          <Text textType="body16r" color={theme.text.black700}>
            {t("deleteModal.message1")}
          </Text>
          <Text textType="body16r" color={theme.text.black700}>
            {t("deleteModal.message2")}
          </Text>
          <Flex mt="24px" justifyContent="flex-end">
            <Button
              w="134px"
              buttonType="stroke"
              h="37px"
              mr="12px"
              onClick={onClose}
              borderRadius="40px"
            >
              {t("cancel", { ns: "common" })}
            </Button>
            <Button
              w="94px"
              buttonType="fill"
              h="37px"
              onClick={onDelete}
              bgColor={theme.error.redDark}
              borderRadius="40px"
            >
              {t("delete", { ns: "common" })}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

import React from "react";
import { Box, Center, Flex, ListItem, UnorderedList, useMediaQuery } from "@chakra-ui/react";
import { MainContent, Text } from "@/components";
import theme from "@/shared/theme";

export const BarrierToChange: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const Datalist: {
    label: string;
    title: string;
    contentList: string[];
  }[] = [
    {
      title: "自律的に動けない",
      label: "変化を妨げる要因１",
      contentList: ["自己認識と相互認識する機会が少なく、支援し合える関係性が薄まっている。", "目的を実現するためのチームの行動指針がない、あるいは共有されないことで自律的な行動が起こらない。"]
    },
    {
      title: "意思決定に時間がかかる",
      label: "変化を妨げる要因２",
      contentList: ["日々変わる相互の興味・関心を知らないことで、情報格差が生まれ、認識を合わせるために時間がかかる。", "世代・役割を超えた対話がないため、価値観の相違を埋めることができない。"]
    }
  ];

  return (
    <Box p={isLargerThan768 ? "8.3vw 0" : "17vw 0"} bgColor={theme.primary.green900} color={theme.text.white} pos={"relative"} id="barrier-to-change">
      <MainContent>
        <Box textAlign={"center"}>
          <Flex alignItems={"center"} gap={isLargerThan768 ? "40px" : "16px"} w={"fit-content"} m={"0 auto"} letterSpacing={"10%"}>
            <Box w={"32px"} h={"2px"} bgColor={theme.text.white} />
            <Text fontSize={isLargerThan768 ? "32px" : "18px"} fontWeight={300}>
              変化に適応し、
              {!isLargerThan768 && <br />}
              チームの成果と成長を
              {!isLargerThan768 && <br />}
              創出するために
            </Text>
            <Box w={"32px"} h={"2px"} bgColor={theme.text.white} />
          </Flex>
          <Box m={isLargerThan768 ? "8.3vw 0 5.5vw" : "8.3vw 0 17vw"} fontSize={isLargerThan768 ? "20px" : "14px"} lineHeight={isLargerThan768 ? "35px" : "24.5px"} textAlign={!isLargerThan768 ? "left" : undefined}>
            <Text>働く環境は、社会や個人の生活、周囲の仲間などから常に影響を受けています。</Text>
            <Text>また、変化への対応が求められるビジネスや業務も増えています。</Text>
          </Box>
          <Flex justify={"space-between"} gap={"40px"} textAlign={"left"} flexDir={!isLargerThan768 ? "column" : undefined}>
            {Datalist.map((item) => (
              <Box p={isLargerThan768 ? "32px 40px" : "24px"} flex={1} key={item.label} borderRadius={"4px"} bgColor={theme.gary.gray100} color={theme.text.black800}>
                <Center
                  bgColor={theme.text.white}
                  borderRadius={"40px"}
                  h={isLargerThan768 ? "40px" : "34px"}
                  w={isLargerThan768 ? "212px" : "175px"}
                  border={`1px solid ${theme.text.black800}`}
                  mb={isLargerThan768 ? "24px" : "16px"}
                  fontSize={isLargerThan768 ? "16px" : "12px"}
                  fontWeight={isLargerThan768 ? 400 : 500}
                >
                  {item.label}
                </Center>
                <Box>
                  <Text mb={"16px"} textType="title24">
                    {item.title}
                  </Text>
                  <UnorderedList>
                    {item.contentList.map((c) => (
                      <ListItem key={c} fontWeight={300} lineHeight={isLargerThan768 ? "28px" : "24.5px"} letterSpacing={isLargerThan768 ? "1px" : undefined} fontSize={isLargerThan768 ? "16px" : "14px"}>
                        {c}
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </Box>
            ))}
          </Flex>
        </Box>
      </MainContent>
      {isLargerThan768 ? (
        <Box
          w={0}
          h={0}
          borderTop={`67px solid ${theme.primary.green900}`}
          borderRight={`67px solid transparent`}
          borderLeft={`67px solid transparent`}
          pos={"absolute"}
          bottom={"-60px"}
          left={"50%"}
          transform={"translateX(-67px)"}
        />
      ) : (
        <Box
          w={0}
          h={0}
          borderTop={`40px solid ${theme.primary.green900}`}
          borderRight={`42px solid transparent`}
          borderLeft={`42px solid transparent`}
          pos={"absolute"}
          bottom={"-38px"}
          left={"50%"}
          transform={"translateX(-42px)"}
        />
      )}
    </Box>
  );
};

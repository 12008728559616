import { createIcon } from "@chakra-ui/icons";

export const Rectangle6Icon = createIcon({
  displayName: "Rectangle6Icon",
  viewBox: "0 0 264 117",
  path: (
    <svg width="264" height="117" viewBox="0 0 264 117" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.61981 2.446C1.45943 2.11399 1.70132 1.72852 2.07003 1.72852H219.191C219.35 1.72852 219.499 1.80364 219.593 1.93099L261.781 58.9102C261.913 59.0895 261.911 59.3349 261.776 59.5119L262.966 60.4243L261.776 59.5119L219.609 114.533C219.514 114.656 219.368 114.729 219.212 114.729H2.11888C1.74719 114.729 1.50544 114.337 1.67167 114.005L28.2848 60.7786C28.77 59.8082 28.7778 58.6678 28.3059 57.6909L1.61981 2.446Z"
        stroke="#0B8487"
        strokeWidth="3"
      />
    </svg>
  )
});
export const RectangleSP6Icon = createIcon({
  displayName: "RectangleSP6Icon",
  viewBox: "0 0 270 60",
  path: (
    <svg width="270" height="60" viewBox="0 0 270 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.51014 2.64121C0.966091 1.99013 1.42904 1 2.2775 1H225.383C225.583 1 225.779 1.06004 225.945 1.17234L226.506 0.344704L225.945 1.17235L267.955 29.6609C268.546 30.0614 268.539 30.9339 267.942 31.325L225.949 58.8365C225.786 58.9432 225.595 59 225.401 59H2.37412C1.51767 59 1.05751 57.9936 1.61777 57.3458L23.1441 32.4574C24.106 31.3452 24.12 29.6997 23.1771 28.5713L1.51014 2.64121Z"
        stroke="#0B8487"
        strokeWidth="2"
      />
    </svg>
  )
});

import { createIcon } from "@chakra-ui/icons";

export const HistoryIcon = createIcon({
  displayName: "HistoryIcon",
  viewBox: "0 0 22 20",
  path: (
    <g fill="none">
      <path
        d="M10.8186 9.2699C10.7438 9.25601 10.669 9.23518 10.5942 9.2074C10.5193 9.17962 10.452 9.13795 10.3921 9.0824L6.86821 5.81157L3.74829 7.91573C3.52384 8.06851 3.2919 8.08587 3.05249 7.96781C2.81307 7.84976 2.69336 7.65879 2.69336 7.3949C2.69336 7.28379 2.7158 7.18656 2.7607 7.10323C2.80559 7.0199 2.87292 6.95045 2.9627 6.8949L6.53153 4.47823C6.60635 4.43657 6.68116 4.40184 6.75598 4.37407C6.8308 4.34629 6.90562 4.33934 6.98044 4.35323C7.05526 4.36712 7.13007 4.38795 7.20489 4.41573C7.27971 4.44351 7.34705 4.48518 7.4069 4.54073L10.9308 7.81156L17.7991 3.2074C18.0236 3.05462 18.2555 3.03726 18.4949 3.15532C18.7344 3.27337 18.8541 3.46434 18.8541 3.72823C18.8541 3.83934 18.8316 3.93309 18.7867 4.00948C18.7418 4.08587 18.6745 4.15879 18.5847 4.22823L11.2675 9.1449C11.1927 9.18657 11.1179 9.22129 11.0431 9.24907C10.9682 9.27684 10.8934 9.28379 10.8186 9.2699ZM10.7962 17.2907C10.7213 17.2907 10.6465 17.2768 10.5717 17.2491C10.4969 17.2213 10.4296 17.1796 10.3697 17.1241L5.22969 12.4991L3.74829 13.4782C3.52384 13.631 3.2919 13.6484 3.05249 13.5303C2.81307 13.4123 2.69336 13.2213 2.69336 12.9574C2.69336 12.8463 2.71955 12.7525 2.77192 12.6761C2.82429 12.5998 2.89537 12.5268 2.98515 12.4574L4.91546 11.1866C4.99028 11.1449 5.06135 11.1102 5.12869 11.0824C5.19603 11.0546 5.2671 11.0477 5.34192 11.0616C5.41674 11.0755 5.49156 11.0928 5.56637 11.1136C5.64119 11.1345 5.70853 11.1727 5.76838 11.2282L10.8635 15.8324L14.5445 13.1241C14.6044 13.0824 14.668 13.0477 14.7353 13.0199C14.8027 12.9921 14.8737 12.9782 14.9486 12.9782H18.1807C18.3752 12.9782 18.5361 13.0373 18.6633 13.1553C18.7905 13.2734 18.8541 13.4227 18.8541 13.6032C18.8541 13.7838 18.7905 13.9331 18.6633 14.0511C18.5361 14.1692 18.3752 14.2282 18.1807 14.2282H15.173L11.2675 17.1449C11.1927 17.2005 11.1141 17.2386 11.0318 17.2595C10.9495 17.2803 10.871 17.2907 10.7962 17.2907Z"
        fill="currentColor"
      />
    </g>
  )
});

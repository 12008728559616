import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { Button, Text, Checkbox, Input, ErrorMessage, EyeIcon } from "@/components";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Flex,
  Link,
  useBoolean,
  FormControl,
  FormErrorMessage,
  Box,
  useToast,
  InputRightElement,
  InputGroup
} from "@chakra-ui/react";
import theme from "@/shared/theme";
import { UserSignUpInput, useUserSignUpMutation } from "@/apollo/generated";
import { useLocation } from "react-router-dom";
import { ViewOffIcon } from "@chakra-ui/icons";

type PropsType = {
  onSubmited: (email: string) => void;
};

type SingUpForm = {
  familyName: string;
  givenName: string;
  tel: string;
  password: string;
  verifyPassword?: string;
  email: string;
};

type NameInputType = {
  key: "givenName" | "familyName";
  title: string;
  placeholder: string;
}[];

export const SignupForm: React.FC<PropsType> = ({ onSubmited }) => {
  const { t } = useTranslation(["signup"]);
  const toast = useToast();
  const location = useLocation();
  const initEmail = decodeURIComponent(location.search).split("?email=")[1];
  const [isPasswordHidden, setPasswordHidden] = useBoolean(true);
  const [isVerifyPasswordHidden, setVerifyPasswordHidden] = useBoolean(true);

  const [userSignUpMutation, { loading, error }] = useUserSignUpMutation();
  const [agreementAgreed, { toggle: toggleAgreementAgreed }] = useBoolean();

  const nameInputs: NameInputType = [
    { key: "familyName", title: t("signupForm.familyName"), placeholder: "" },
    { key: "givenName", title: t("signupForm.givenName"), placeholder: "" }
  ];

  const otherInputs: {
    key: "tel" | "email" | "password" | "verifyPassword";
    title: string;
    placeholder: string;
  }[] = [
    { key: "tel", title: t("signupForm.phone"), placeholder: "" },
    { key: "email", title: t("signupForm.email"), placeholder: "" }
  ];

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<SingUpForm>({
    resolver: zodResolver(
      z
        .object({
          givenName: z
            .string()
            .min(1, { message: t("form.validation.required", { ns: "common" }) ?? "" }),
          familyName: z
            .string()
            .min(1, { message: t("form.validation.required", { ns: "common" }) ?? "" }),
          tel: z
            .string()
            .min(1, { message: t("form.validation.required", { ns: "common" }) ?? "" }),
          email: z
            .string()
            .email({ message: t("form.validation.required", { ns: "common" }) ?? "" }),
          password: z.string().refine(
            (pas) => {
              if (
                // eslint-disable-next-line no-useless-escape, no-control-regex
                /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()\-^\\@[\];:,.\/=~|`{}+*<>?_])\S{8,}$/.test(
                  pas
                )
              ) {
                return true;
              } else return false;
            },
            { message: t("pwdErrorMsg") ?? "" }
          ),
          verifyPassword: z.string().refine(
            (pas) => {
              if (
                // eslint-disable-next-line no-useless-escape, no-control-regex
                /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()\-^\\@[\];:,.\/=~|`{}+*<>?_])\S{8,}$/.test(
                  pas
                )
              ) {
                return true;
              } else return false;
            },
            { message: t("pwdErrorMsg") ?? "" }
          )
        })
        .refine((data) => data.password === data.verifyPassword, {
          message: t("signupForm.passwordNotMatch") ?? "",
          path: ["verifyPassword"]
        })
    ),
    defaultValues: {}
  });

  const handleSignup = useCallback(
    (values: SingUpForm) => {
      delete values.verifyPassword;

      const input: UserSignUpInput = {
        ...values
      };

      if (!agreementAgreed) {
        toast({
          position: "top",
          description: t("signupForm.toast"),
          status: "info",
          duration: 3000,
          isClosable: true
        });

        return;
      }

      userSignUpMutation({ variables: { input: input } })
        .then((res) => {
          if (!res.data?.userSignUp?.user) {
            return;
          }
          onSubmited(res.data.userSignUp.user.email ?? "");
        })
        .catch(() => {});
    },
    [onSubmited, agreementAgreed, toast, t, userSignUpMutation]
  );

  useEffect(() => setValue("email", initEmail), [initEmail, setValue]);

  return (
    <form onSubmit={handleSubmit(handleSignup)}>
      <Box w="560px">
        <ErrorMessage isVisible={!!error} message={t("signupForm.codeErrorMsg")} />
        <Flex justifyContent="space-between" wrap="wrap">
          <Flex w="560px" justifyContent="space-between">
            {nameInputs.map((input) => (
              <FormControl key={input.key} isInvalid={!!errors[input.key]}>
                <Text textType="body14b" color={theme.text.black500}>
                  {input.title}
                </Text>
                <Input
                  id={input.key}
                  placeholder={input.placeholder}
                  styleType="flushed"
                  w="272px"
                  {...register(input.key)}
                />
                <FormErrorMessage>{errors[input.key]?.message}</FormErrorMessage>
              </FormControl>
            ))}
          </Flex>
          {otherInputs.map((input) => (
            <FormControl key={input.key} w="100%" mt="32px" isInvalid={!!errors[input.key]}>
              <Text textType="body14b" color={theme.text.black500}>
                {input.title}
              </Text>
              <Input
                id={input.key}
                placeholder={input.placeholder}
                styleType="flushed"
                isReadOnly={input.key === "email"}
                {...register(input.key)}
              />
              <FormErrorMessage>{errors[input.key]?.message}</FormErrorMessage>
            </FormControl>
          ))}
          <FormControl w="100%" mt="32px" isInvalid={!!errors["password"]}>
            <Text textType="body14b" color={theme.text.black500}>
              {t("signupForm.password")}
            </Text>
            <InputGroup>
              <Input
                id="password"
                styleType="flushed"
                type={isPasswordHidden ? "password" : undefined}
                {...register("password")}
              />
              <InputRightElement>
                {isPasswordHidden ? (
                  <EyeIcon w="20px" cursor="pointer" onClick={() => setPasswordHidden.toggle()} />
                ) : (
                  <ViewOffIcon
                    w="20px"
                    cursor="pointer"
                    onClick={() => setPasswordHidden.toggle()}
                  />
                )}
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors["password"]?.message}</FormErrorMessage>
          </FormControl>
          <FormControl w="100%" mt="32px" isInvalid={!!errors["verifyPassword"]}>
            <Text textType="body14b" color={theme.text.black500}>
              {t("signupForm.verifyPassword")}
            </Text>
            <InputGroup>
              <Input
                id="verifyPassword"
                styleType="flushed"
                type={isVerifyPasswordHidden ? "password" : undefined}
                {...register("verifyPassword")}
              />
              <InputRightElement>
                {isVerifyPasswordHidden ? (
                  <EyeIcon
                    w="20px"
                    cursor="pointer"
                    onClick={() => setVerifyPasswordHidden.toggle()}
                  />
                ) : (
                  <ViewOffIcon
                    w="20px"
                    cursor="pointer"
                    onClick={() => setVerifyPasswordHidden.toggle()}
                  />
                )}
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors["verifyPassword"]?.message}</FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex mt="16px" alignContent="center">
          <Checkbox
            checked={agreementAgreed}
            onChange={toggleAgreementAgreed}
            alignSelf="flex-start"
            mr="16px"
            mt="2px"
          />
          <Text textType="body14b">
            <Link href="/terms-of-use" color={theme.service.turquoiseText}>
              {t("signupForm.termsOfService")}
            </Link>
            {t("signupForm.and")}
            <Link
              href="https://www.fujitsu.com/jp/group/flm/privacy/"
              target="_blank"
              color={theme.service.turquoiseText}
            >
              {t("signupForm.privacyPolicy")}
            </Link>
            {t("signupForm.agreeTo")}
          </Text>
        </Flex>
        <Flex justifyContent="center">
          <Button
            mt="34px"
            w="556px"
            buttonSize="xl"
            buttonType="fill"
            type="submit"
            isLoading={loading}
          >
            {t("signupForm.signup")}
          </Button>
        </Flex>
      </Box>
    </form>
  );
};

import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthHeaderContext } from "@/hooks/useAuthHeaderContext";
import LandingPage from "@/pages/landing-page";

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { authHeader } = useAuthHeaderContext();
  const [view, setView] = useState<ReactNode | null>(null);

  useEffect(() => {
    if (!authHeader) {
      if (location.pathname === "/home") return setView(<LandingPage />);

      navigate("/auth/signin");
      return;
    }

    setView(children);
  }, [authHeader, children, navigate, location.pathname]);

  return <>{view}</>;
}

import { Text } from "@/components";
import theme from "@/shared/theme";
import { Box, Flex, Img, ListItem, UnorderedList, useMediaQuery } from "@chakra-ui/react";
import React, { useCallback, useRef, useState } from "react";
import { InViewComponent } from "./InViewComponent";

export const StickyAnimation: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");
  const [activeIdx, setActiveIdx] = useState(0);
  const lists: {
    title: string;
    text: string;
    sub: { title: string; text: string }[];
    imgList: string[];
  }[] = [
    {
      title: "目標設定",
      text: "目標は自由に設定することができます。",
      imgList: ["detail_part_1.svg"],
      sub: [
        {
          title: "設定した目標を、各画面で常時表示",
          text: "プロフィール設定画面にて、自身の目標を入力することができます。入力された目標を意識した行動を支援するために、各画面で常時表示されるようになります。"
        }
      ]
    },
    {
      title: "コーチングスケジュール",
      text: "コーチングペアと実施予定日が一覧で表示されます。",
      imgList: ["detail_part_2.svg"],
      sub: [
        {
          title: "チームメンバー内でコーチングペアをランダムに設定",
          text: "毎月20日に、翌月のコーチングを実施するペアがCultee上で設定されます。ペアは、上司部下の偏りなくランダムに決定されるため、タテ・ヨコの様々な方向のコミュニケーションが活性化されます。"
        },
        {
          title: "チーム内に実施状況を共有",
          text: "コーチングペアが決定されると、コーチングの実施予定日を入力することができるようになります。実施予定日は、ペアと合わせて一覧表示されるため、チーム内で実施状況を確認し合うことができます。"
        }
      ]
    },
    {
      title: "振り返りの記録",
      text: "4つの設問を通して、振り返りを記録できます。",
      imgList: ["detail_part_3.svg"],
      sub: [
        {
          title: "『今日はどんな気持ちだった？』",
          text: "8つの感情を、その日の気持ちに合わせて0〜5の6段階で記録することができます。そうすることで、これまで見えていなかった感情を認識しやすくなります。"
        },
        {
          title: "『今日はどんな状況だった？』",
          text: "具体的なその日の状況を、日記感覚で記録することができます。この部分は、他のメンバーには開示されないため、振り返りのために自由に活用することができます。"
        },
        {
          title: "『３Good things（今日のよかったことは？）』",
          text: "今日のよかったことや人に自慢できることを箇条書きで記録することができます。目標達成に向けて、自身の良かった点を振り返る機会になります。"
        },
        {
          title: "『Switching Actions（次はどうしたい？）』",
          text: "本当は自分はどうしたかったのか・どうすればよかったのかを箇条書きで記録することができます。記録を通して、目標達成に向けたネクストアクションにつなげていきます。"
        }
      ]
    },
    {
      title: "ジャーナリングノート",
      text: "全メンバーの記録が一覧で表示されます。",
      imgList: ["detail_part_4.svg"],
      sub: [
        {
          title: "メンバーが立てている目標を常時表示",
          text: "メンバーが立てている目標が常時表示されるため、”今”メンバーが何を目指して業務に励んでいるのかを一目で理解することができます。"
        },
        {
          title: `"今日のよかったこと""次はどうしたい"に対してメンバーがリアクション`,
          text: `メンバーの"今日のよかったこと""次はどうしたい"に対して、リアクションをつけることができます。豊富なリアクションの種類で、メンバー間のコミュニケーションを支援します。`
        },
        {
          title: `"どんな気持ちだった"をレーダーチャートで表示`,
          text: "メンバーの記録した感情がレーダーチャートとして表示されます。感情が視覚的に表現されることで、メンバーの気持ちの直観的な理解につながります。"
        },
        {
          title: "メンバーや日付による絞込検索",
          text: "メンバーを絞り込んで記録を表示することができます。絞り込みを活用すれば、自身の記録だけを表示することも可能です。また、日付をもとに一定期間の記録に絞り込む機能もあります。"
        }
      ]
    },
    {
      title: "リフレクション",
      text: "自身の感情の動きがグラフで表示されます。",
      imgList: ["detail_part_5.svg"],
      sub: [
        {
          title: "過去4週間分の記録を振り返り",
          text: "自動集計した過去4週間分の記録をもとに、グラフとして表示されます。グラフを通して、感情の揺れ動きを視覚的に理解することで、感情を起点とした振り返りをサポートします。"
        },
        {
          title: "感情別にグラフを絞り込み",
          text: "8つの感情から、特定の感情に絞り込んでグラフを表示することができます。絞り込みは複数選択することもできるため、着目したい感情にフォーカスした振り返りが行えます。"
        }
      ]
    },
    {
      title: "コーチングガイド",
      text: "コーチングの進め方やポイントが掲載されています。",
      imgList: ["detail_part_6.svg"],
      sub: [
        {
          title: "コーチングの進め方やポイントを掲載",
          text: "コーチングの進め方や、ポイントが掲載されたガイドをご提供いたします。ガイドを参考にすることで、初めてコーチングを行う方も、ポイントをおさえたコーチングを実施することができます。"
        }
      ]
    },
    {
      title: "分析レポート",
      text: "チームの”今”を分析してご提供いたします。※掲載内容はイメージです。内容はチームに合わせて調整いたします。",
      imgList: ["detail_part_7.svg"],
      sub: [
        {
          title: "データ集計・分析",
          text: "記録されたチームのデータを集計・分析するオプションもご用意しております。チーム単位・個人単位の感情データ分析や、頻出ワード分析などを行い、今のチームや個人の状況を読み解き、ありたい姿を実現するためのヒントをご提供いたします。また、継続して集計・分析を行うことで、チームの傾向や変化をつかむヒントとしてもご活用いただけます。"
        }
      ]
    }
  ];
  const listRef = useRef<HTMLDivElement | null>(null);
  const rightRef = useRef<HTMLDivElement | null>(null);
  const divRefs = useRef<Array<HTMLDivElement | null>>([]);
  const h = listRef.current ? 120 + listRef.current.offsetHeight : 0;
  const mb = divRefs.current[6]?.offsetHeight
    ? document.getElementById("sticky_component")!.offsetHeight -
      120 -
      divRefs.current[6].offsetHeight
    : 0;

  const listItemClick = useCallback((index: number) => {
    const offsetTop = document.getElementById(`li${index}`)!.offsetTop;
    const ttOffsetTop = document.getElementById(`tt`)!.offsetTop;

    window.scrollTo({
      top: offsetTop + ttOffsetTop,
      left: 0
    });
  }, []);

  return (
    <Box height={h} id="tt" p="0 24px">
      <Box maxW={1080} w="100%" height={"100%"} margin={"0 auto"} pos={"relative"}>
        <Box pos={"sticky"} pb={"120px"} top={0} left={0} zIndex={1000} id="sticky_component">
          <Box h={"120px"} width={"100%"} bgColor={"white"} />
          <Flex gap={"3.7%"} alignItems={"flex-start"} justifyContent={"space-between"}>
            <Flex id="left_list" flexShrink={0} flexDir={"column"} w={"158px"} height={400}>
              <UnorderedList>
                {lists.map((item, idx) => (
                  <ListItem
                    whiteSpace={"pre"}
                    key={item.title}
                    color={idx === activeIdx ? theme.primary.green900 : theme.gary.gray200}
                    fontWeight={400}
                    lineHeight={isLargerThan768 ? "28px" : "24.5px"}
                    fontSize={"14px"}
                    onClick={() => listItemClick(idx)}
                    cursor={"pointer"}
                  >
                    {item.title}
                  </ListItem>
                ))}
              </UnorderedList>
            </Flex>
            <Box ref={rightRef} w={isLargerThan1080 ? "37.6%" : "34.6%"}>
              <Box>
                {lists[activeIdx].imgList.map((item) => (
                  <Img
                    src={`/assets/${item}`}
                    alt="computer"
                    draggable="false"
                    mb={isLargerThan768 ? "24px" : "12px"}
                    key={item}
                  />
                ))}
                <Text>{lists[activeIdx].text}</Text>
              </Box>
            </Box>
          </Flex>
        </Box>
        <Box
          w={"38%"}
          ref={listRef}
          position={"absolute"}
          left={"calc(170px + 2.77vw)"}
          top={"120px"}
          borderRight={`1px solid #D9D9D9`}
          pr={"40px"}
          zIndex={99}
        >
          {lists.map((item, index) => (
            <InViewComponent
              currentIdx={index}
              onSetActiveIdx={(index) => {
                setActiveIdx(index);
              }}
              key={item.title}
            >
              <Box
                key={item.title}
                mb={index !== 6 ? "calc(100vh - 120px)" : mb}
                className={index === 6 ? "last" : undefined}
                ref={(element) => {
                  divRefs.current[index] = element;
                }}
                id={`li${index}`}
              >
                <Box pb={"2.77vw"} borderBottom={index !== 6 ? `1px solid #D9D9D9` : undefined}>
                  <UnorderedList mb={"24px"}>
                    <ListItem
                      fontWeight={300}
                      lineHeight={isLargerThan768 ? "28px" : "24.5px"}
                      letterSpacing={isLargerThan768 ? "1px" : undefined}
                      fontSize={isLargerThan768 ? "16px" : "14px"}
                    >
                      {item.title}
                    </ListItem>
                  </UnorderedList>
                  <Flex flexDir={"column"} gap={"40px"}>
                    {item.sub.map((item) => (
                      <Box key={item.title}>
                        <Box key={item.title}>
                          <Text fontSize={"28px"} fontWeight={700} mb={"16px"}>
                            {item.title}
                          </Text>
                          <Text fontSize={"18px"} fontWeight={300} lineHeight={1.75}>
                            {item.text}
                          </Text>
                        </Box>
                      </Box>
                    ))}
                  </Flex>
                </Box>
              </Box>
            </InViewComponent>
          ))}
          <Box w={"100%"} h={"1px"} bgColor={"#D9D9D9"} />
        </Box>
      </Box>
    </Box>
  );
};

import { createIcon } from "@chakra-ui/icons";

export const CulteeAdministerIcon = createIcon({
  displayName: "CulteeAdministerIcon",
  viewBox: "0 0 116 32",
  path: (
    <g xmlns="http://www.w3.org/2000/svg" width="116" height="32" viewBox="0 0 116 32" fill="none">
      <path
        d="M32.4497 13.1317V12.2881C32.4497 9.32344 29.9343 6.91123 26.844 6.91123H22.7171C22.2062 6.91123 21.7542 7.27627 21.6461 7.77943L18.2758 23.6487L16.4629 21.937C16.2615 21.7446 15.9913 21.641 15.7161 21.641H14.5714C12.6504 21.6213 11.0832 20.1808 11.0832 18.4346V17.374C11.0832 16.9892 10.8818 16.6291 10.5526 16.4318L9.2212 15.6277L10.5624 14.503C10.813 14.2958 10.9555 13.9851 10.9555 13.6595V12.3029C10.9555 10.5369 12.5375 9.09653 14.483 9.09653H19.3665L19.8578 6.90137H14.483C11.3289 6.90137 8.76429 9.32344 8.76429 12.3029V13.1465L7.12336 14.5277C6.74015 14.8483 6.53381 15.3367 6.57311 15.8349C6.61242 16.3332 6.88754 16.787 7.31497 17.0435L8.88712 17.9906V18.4297C8.88712 21.3352 11.3878 23.7572 14.4584 23.8263H14.5125H15.274L18.1874 26.5739C18.3888 26.7663 18.659 26.8699 18.9341 26.8699C19.0422 26.8699 19.1552 26.8551 19.2584 26.8206C19.6367 26.7022 19.9167 26.3865 20.0002 26.0017L23.3755 10.1078C23.4983 9.51583 24.019 9.09653 24.6184 9.09653H26.8342C28.7158 9.09653 30.2536 10.5221 30.2536 12.2783V13.625C30.2536 13.9456 30.3961 14.2515 30.6368 14.4586L31.9486 15.5833L30.6516 16.3825C30.3273 16.5798 30.1308 16.935 30.1308 17.3197V18.3754C30.1308 20.1315 28.5979 21.5571 26.7114 21.5571H22.9382L22.496 23.7523H26.7114C29.8016 23.7523 32.317 21.3401 32.317 18.3754V17.9364L33.8941 16.9646C34.3068 16.713 34.5721 16.274 34.6114 15.7955C34.6507 15.317 34.4542 14.8483 34.0857 14.5326L32.435 13.1218L32.4497 13.1317Z"
        fill="#03C7CD"
      />
      <path
        d="M49.7233 18.7848C49.4383 19.7368 48.9519 20.462 48.2838 20.9405C47.6156 21.414 46.8099 21.6557 45.8862 21.6557C44.9626 21.6557 44.1077 21.4338 43.4298 20.9947C42.7518 20.5557 42.2261 19.9095 41.8625 19.0659C41.499 18.2224 41.3123 17.1865 41.3123 15.9977C41.3123 14.8088 41.5088 13.7581 41.892 12.9195C42.2752 12.0858 42.8255 11.4396 43.5182 11.0006C44.2109 10.5616 45.0314 10.3396 45.9501 10.3396C46.8688 10.3396 47.6254 10.5468 48.1953 10.9611C48.7652 11.3755 49.2074 12.0414 49.5071 12.9343L49.5317 13.0132L52.0029 11.9082L51.9783 11.8441C51.6688 11.0006 51.2168 10.2902 50.642 9.72789C50.0672 9.16553 49.3597 8.73637 48.5441 8.46012C47.7335 8.17894 46.8099 8.03589 45.8027 8.03589C44.3485 8.03589 43.0613 8.36146 41.9755 8.99781C40.8898 9.63416 40.0398 10.5616 39.4503 11.7455C38.8607 12.9244 38.5659 14.3599 38.5659 16.0026C38.5659 17.6453 38.8656 19.0807 39.4503 20.2597C40.0349 21.4387 40.8898 22.3661 41.9755 23.0074C43.0613 23.6437 44.3534 23.9693 45.8273 23.9693C46.8344 23.9693 47.7679 23.8016 48.6031 23.476C49.4383 23.1504 50.1802 22.6571 50.8041 22.0109C51.4231 21.3647 51.8948 20.5606 52.2092 19.6283L52.2338 19.5592L49.7331 18.7206L49.7134 18.7946L49.7233 18.7848Z"
        fill="#03C7CD"
      />
      <path
        d="M62.8462 18.3462C62.8462 18.9381 62.7676 19.461 62.6104 19.9C62.4532 20.3341 62.2321 20.6992 61.9471 20.9754C61.6622 21.2517 61.3428 21.4588 60.9891 21.5871C60.6354 21.7154 60.2669 21.7795 59.8935 21.7795C59.1124 21.7795 58.5031 21.5871 58.0708 21.2073C57.6434 20.8274 57.4272 20.1171 57.4272 19.091V11.9876H54.8086V19.7175C54.8086 20.7189 55.0051 21.5427 55.3982 22.1692C55.7863 22.8006 56.3169 23.2594 56.9703 23.5455C57.6139 23.8267 58.3312 23.9697 59.0927 23.9697C60.0212 23.9697 60.8417 23.7971 61.5295 23.4518C62.1486 23.141 62.6644 22.6625 63.0575 22.0311L63.1606 23.7379H65.4746V11.9827H62.856V18.3462H62.8462Z"
        fill="#03C7CD"
      />
      <path
        d="M73.6412 21.7894C73.4545 21.8289 73.2236 21.8486 72.9485 21.8486C72.5112 21.8486 72.1919 21.745 72.0101 21.5477C71.8234 21.3455 71.7301 20.9656 71.7301 20.4131V6.76367H69.1115V20.6203C69.1115 21.75 69.362 22.5935 69.8582 23.141C70.3544 23.6886 71.1405 23.9648 72.187 23.9648C72.526 23.9648 72.8748 23.9303 73.2187 23.8563C73.5626 23.7823 73.8721 23.6886 74.1374 23.5603L74.1718 23.5455L74.4715 21.528L74.3536 21.5724C74.0686 21.676 73.8279 21.75 73.6363 21.7894H73.6412Z"
        fill="#03C7CD"
      />
      <path
        d="M82.9564 21.0296C82.7009 21.2565 82.4208 21.4341 82.1211 21.5624C81.8215 21.6906 81.4677 21.7548 81.0649 21.7548C80.5785 21.7548 80.1854 21.6265 79.9005 21.3799C79.6204 21.1332 79.4731 20.7287 79.4731 20.1811V14.084H83.4476V11.9875H79.4731V8.5542L76.8544 9.29414V11.9925H74.6338V14.089H76.8544V20.6054C76.8692 21.4045 77.0362 22.0606 77.3507 22.5588C77.6651 23.0571 78.1122 23.4221 78.6821 23.6441C79.2421 23.8611 79.8661 23.9746 80.5343 23.9746C81.2024 23.9746 81.7576 23.8809 82.2784 23.7033C82.7991 23.5257 83.2462 23.2741 83.6098 22.9584L83.6442 22.9288L83.0399 20.9655L82.9564 21.0395V21.0296Z"
        fill="#03C7CD"
      />
      <path
        d="M93.6708 12.4557C92.8798 11.987 91.9022 11.7502 90.7673 11.7502C89.6324 11.7502 88.6645 11.9969 87.7949 12.4803C86.9253 12.9637 86.2326 13.6741 85.7462 14.5867C85.2549 15.4993 85.0093 16.5993 85.0093 17.8572C85.0093 19.1151 85.2549 20.2152 85.7462 21.1278C86.2375 22.0404 86.9352 22.7507 87.8195 23.2341C88.7038 23.7176 89.7454 23.9642 90.9048 23.9642C91.6811 23.9642 92.4033 23.8557 93.0567 23.6337C93.7101 23.4167 94.28 23.096 94.7566 22.6816C95.2332 22.2673 95.6164 21.7691 95.8964 21.2067L95.9308 21.1376L93.833 20.1461L93.7986 20.2102C93.5284 20.7233 93.155 21.1278 92.6932 21.4089C92.2313 21.6951 91.6123 21.8381 90.8606 21.8381C90.1826 21.8381 89.5882 21.6852 89.0969 21.3843C88.6056 21.0834 88.2174 20.6345 87.9472 20.0475C87.7654 19.6479 87.6426 19.1842 87.5837 18.6662H96.034L96.0438 18.6021C96.0733 18.4146 96.0978 18.1976 96.1126 17.946C96.1273 17.6994 96.1371 17.433 96.1371 17.1518C96.1371 16.1258 95.9259 15.1934 95.5034 14.3795C95.0809 13.5656 94.4667 12.9144 93.6757 12.4458L93.6708 12.4557ZM89.0281 14.3203C89.5096 14.0194 90.0795 13.8665 90.7231 13.8665C91.6074 13.8665 92.3099 14.1427 92.816 14.6903C93.2483 15.1589 93.4989 15.8051 93.5627 16.6191H87.6377C87.7016 16.2738 87.7949 15.9531 87.9178 15.667C88.1732 15.075 88.5417 14.6212 89.0232 14.3203H89.0281Z"
        fill="#03C7CD"
      />
      <path
        d="M108.857 14.3894C108.435 13.5754 107.821 12.9243 107.03 12.4557C106.239 11.987 105.261 11.7502 104.126 11.7502C102.991 11.7502 102.024 11.9969 101.154 12.4803C100.284 12.9637 99.5916 13.6741 99.1052 14.5867C98.6139 15.4993 98.3683 16.5993 98.3683 17.8572C98.3683 19.1151 98.6139 20.2152 99.1052 21.1278C99.5965 22.0404 100.294 22.7507 101.179 23.2341C102.063 23.7176 103.104 23.9642 104.264 23.9642C105.04 23.9642 105.762 23.8557 106.416 23.6337C107.069 23.4167 107.639 23.096 108.116 22.6816C108.592 22.2673 108.975 21.7691 109.255 21.2067L109.29 21.1376L107.192 20.1461L107.158 20.2102C106.887 20.7233 106.514 21.1278 106.052 21.4089C105.59 21.6951 104.971 21.8381 104.22 21.8381C103.542 21.8381 102.947 21.6852 102.456 21.3843C101.965 21.0834 101.576 20.6345 101.306 20.0475C101.124 19.6479 101.002 19.1842 100.943 18.6662H109.393L109.403 18.6021C109.432 18.4146 109.457 18.1976 109.472 17.946C109.486 17.6994 109.496 17.433 109.496 17.1518C109.496 16.1258 109.285 15.1934 108.862 14.3795L108.857 14.3894ZM102.382 14.3252C102.864 14.0243 103.434 13.8714 104.077 13.8714C104.961 13.8714 105.664 14.1477 106.17 14.6952C106.602 15.1638 106.853 15.8101 106.917 16.624H100.992C101.056 16.2787 101.149 15.958 101.272 15.6719C101.527 15.08 101.896 14.6262 102.377 14.3252H102.382Z"
        fill="#03C7CD"
      />
    </g>
  )
});

import { createIcon } from "@chakra-ui/icons";

export const AvatarIcon = createIcon({
  displayName: "AvatarIcon",
  viewBox: "0 0 30 30",
  path: (
    <g fill="none">
      <path
        d="M25.1514 22.3711C24.5988 21.062 23.7968 19.873 22.7901 18.8701C21.7865 17.8644 20.5976 17.0625 19.2891 16.5088C19.2774 16.5029 19.2657 16.5 19.254 16.4941C21.0792 15.1758 22.2657 13.0283 22.2657 10.6055C22.2657 6.5918 19.0137 3.33984 15.0001 3.33984C10.9864 3.33984 7.73445 6.5918 7.73445 10.6055C7.73445 13.0283 8.92097 15.1758 10.7462 16.4971C10.7344 16.5029 10.7227 16.5059 10.711 16.5117C9.39851 17.0654 8.22078 17.8594 7.21003 18.873C6.2043 19.8767 5.40243 21.0655 4.84871 22.374C4.30473 23.655 4.01135 25.0285 3.98445 26.4199C3.98367 26.4512 3.98915 26.4823 4.00058 26.5114C4.01201 26.5405 4.02915 26.5671 4.05099 26.5895C4.07283 26.6118 4.09893 26.6296 4.12776 26.6418C4.15658 26.6539 4.18755 26.6602 4.21882 26.6602H5.97664C6.10554 26.6602 6.20808 26.5576 6.21101 26.4316C6.2696 24.1699 7.17781 22.0518 8.78328 20.4463C10.4444 18.7852 12.6505 17.8711 15.0001 17.8711C17.3497 17.8711 19.5557 18.7852 21.2169 20.4463C22.8223 22.0518 23.7305 24.1699 23.7891 26.4316C23.7921 26.5605 23.8946 26.6602 24.0235 26.6602H25.7813C25.8126 26.6602 25.8436 26.6539 25.8724 26.6418C25.9012 26.6296 25.9273 26.6118 25.9492 26.5895C25.971 26.5671 25.9881 26.5405 25.9996 26.5114C26.011 26.4823 26.0165 26.4512 26.0157 26.4199C25.9864 25.0195 25.6964 23.6572 25.1514 22.3711ZM15.0001 15.6445C13.6553 15.6445 12.3897 15.1201 11.4376 14.168C10.4854 13.2158 9.96101 11.9502 9.96101 10.6055C9.96101 9.26074 10.4854 7.99512 11.4376 7.04297C12.3897 6.09082 13.6553 5.56641 15.0001 5.56641C16.3448 5.56641 17.6104 6.09082 18.5626 7.04297C19.5147 7.99512 20.0391 9.26074 20.0391 10.6055C20.0391 11.9502 19.5147 13.2158 18.5626 14.168C17.6104 15.1201 16.3448 15.6445 15.0001 15.6445Z"
        fill="white"
      />
    </g>
  )
});
